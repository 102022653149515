import { useEffect, useState } from "react";
import io from "socket.io-client";
import usePageVisibility from "./useActiveBroswerTab";

const useSocket = (url, options) => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    if (socket) {
      socket.on("connection", () => {
        console.log("connected");
      });
    }
  }, [socket]);

  useEffect(() => {
    let domain = window.location.hostname.indexOf("localhost") > -1 ? "http://localhost:8000" : "https://api.sohaibahmed.ca/";
    const newSocket = io(domain, options);
    setSocket(newSocket);

    // Clean up the socket connection when the component unmounts
    return () => {
      newSocket.disconnect();
    };
  }, [url]);

  return socket;
};

export default useSocket;
