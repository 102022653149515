import styled from "styled-components";
import React, { useEffect, useRef, useState, createContext, useContext } from "react";
import QsEditor from "../editor/questionEditor";
import { useParams } from "react-router-dom";

import { createQuestion, updateQuestion, GET, PUT } from "../../helpers/REST/api";
import { PlainTextInput } from "../editor/UI/questionModals/styles";
import { Checkbox } from "../layout/selection/checkbox";
import { Radio } from "../layout/selection/radio";
import { Box, Dropdown3, Collapse } from "../layout/containers/box";
import { QuestionAudioRecorder } from "../media/audioRecorder";
import { toast } from "react-toastify";
import { AddBtn, Button, CloseBtn, DeleteBtn, MicBtn } from "../layout/buttons/buttons";

import { RounderBtn } from "../layout/buttons/buttonStyles";
import { TextField } from "../layout/containers/input";
import { GetMedias } from "../media";
import { ModalElement } from "../../helpers/hooks/useModal";
import ImportDocQuestions from "./importQuestions";
import { Tag } from "antd";

// import QsEditorLogic, { QuestionCtx } from "./context";
// import { Heading, InlineQuestionFooter } from "./heading";

/**
 * @param  {Object} props.data => This will be qsData if fetched from server;
 */

const ansTemplate = {
  isCorrect: false,
  choice: "",
  refs: [],
  explanation: "",
  implications: [],
};
const qsTemplate = {
  questionAudio: undefined,
  correctAns: [""],
  questionType: "mcq_1",
  isPublished: false,
  isArchived: false,
  question: "",
  options: [ansTemplate],
};

export const QuestionCtx = createContext({});

export function QsEditorLogic(props) {
  const { onClose, onSave, editorCtx, qid, readOnly, fontSize } = props;
  const [isNewQs, setIsNewQs] = useState(null);
  const [state, setState] = useState(null);
  const [showMediaModal, setMediaModal] = useState(false);
  const [showAudio, setShowAudio] = useState(false);
  const [importQs, setImportQs] = useState(false);
  const params = useParams();
  const { doc_id, block_id } = params;

  function handleImportedMediaId(d) {
    //handleSave({ questionAudio: d._id });
    setState({ ...state, questionAudio: d._id });
  }
  function handleAudioDelete() {
    setState({ ...state, questionAudio: undefined });
  }
  function toggleMediaModal() {
    setMediaModal(!showMediaModal);
  }
  function addAnsChoice(e) {
    e.preventDefault();
    setState({ ...state, options: [...state.options, ansTemplate] });
  }
  function addCorrectAnswerField(e) {
    e.preventDefault();
    setState({ ...state, correctAns: [...state.correctAns, ""] });
  }
  function toggleAnsCorrectness(e, ansIndex) {
    let currentOption = { ...state.options[ansIndex] };
    currentOption.isCorrect = e;
    let newOptions = [...state.options];
    newOptions[ansIndex] = currentOption;
    setState({ ...state, options: newOptions });
  }
  function toggleRadioChoice(e, ansIndex) {
    let options = [...state.options];
    options = options.map((op, i) => ({ ...op, isCorrect: i === ansIndex }));
    setState({ ...state, options });
  }
  function handleEditorSave(stateKey, value, ansIndex, ansProperty) {
    let newState = Object.assign({}, state);
    if (stateKey === "question") {
      setState({ ...state, question: value });
    } else if (stateKey === "options") {
      if (newState.options[ansIndex] === undefined) {
        let ansChoiceObj = {};
        ansChoiceObj[ansProperty] = value;
        newState.options[ansIndex] = ansChoiceObj;
      } else {
        newState.options[ansIndex][ansProperty] = value;
      }
      setState(newState);
      return;
    }
  }
  function onAudioAnsTextChange(v, i) {
    let correctAns = [...state.correctAns];
    correctAns[i] = v;
    setState({ ...state, correctAns });
  }
  function convertTextToRTF() {}

  function addAudioIdToQsPrompt({ _id }) {
    if (state === null || state === undefined) {
      setState({ questionAudio: _id });
    } else if (typeof state === "object") {
      setState({ ...state, questionAudio: _id });
    }
  }
  function changeQsType(v) {
    setState({ ...state, questionType: v });
  }
  function handleSave(newProperty) {
    if (state.qid || state._id) {
      updateQuestion(qid, newProperty ? newProperty : state)
        .then((data) => {
          // onSave(data._id);
          toast.success("Question updated");
        })
        .catch((e) => {
          toast.warn("Uh oh -- That didn't save");
        });
    } else {
      alert("Trying to save");
    }
  }
  function handleCreate() {
    let copy = { ...state };
    if (doc_id && block_id) {
      copy.belongsToBlock = [doc_id, block_id];
    }
    if (copy.questionId === null) {
      delete copy.QuestionId;
    }

    createQuestion(copy).then((createdQsId) => {
      if (createdQsId) setIsNewQs(false);
      if (typeof onSave === "function") onSave(createdQsId);
    });
  }
  function deleteAnsChoice(i) {
    let opCopy = [...state.options];
    opCopy.splice(i, 1);
    // console.log(opCopy);
    setState({ ...state, options: opCopy });
  }

  useEffect(() => {
    async function fetch() {
      if (props.qid) {
        let qsData = await GET(`/questions/${props.qid}`);
        if (qsData) setState(qsData);
        if (qsData) setIsNewQs(false);
      } else {
        setState(qsTemplate);
      }
    }
    fetch();
  }, [props.qid]);

  return (
    <QuestionCtx.Provider
      value={{
        showAudio,
        setShowAudio,
        readOnly,
        fontSize,
        //Import Media
        showMediaModal, //
        handleImportedMediaId,
        handleAudioDelete,
        toggleMediaModal,
        isNewQs,
        handleSave,
        handleCreate,
        ...state,
        onClose,
        changeQsType,
        addAnsChoice,
        deleteAnsChoice,
        addAudioIdToQsPrompt,
        addCorrectAnswerField,
        onAudioAnsTextChange,
        onQsTextChange: (v) => handleEditorSave("question", v),
        onAnsRadioChange: (bool, i) => toggleRadioChoice(bool, i),
        onAnsCheckboxChange: (bool, i) => toggleAnsCorrectness(bool, i),
        onAnsOpTextChange: (v, i) => handleEditorSave("options", v, i, "choice"),

        // Import Tab
        toggleImport: () => setImportQs(!importQs),
        importQs, // Opens "import question" tab; else, create new question
        pasteImportedIdToDocument: (qsId) => {
          onSave(qsId);
          setImportQs(!importQs);
          //onClose();
        }, // _id => adds to the document. All work happens inside import; not here.
      }}
    >
      {props.children}
    </QuestionCtx.Provider>
  );
}

export default function CreateQuestion(props) {
  return (
    <QsEditorLogic {...props}>
      <Box type="col left" width="100%" margin="0 auto" background="#222" style={{ maxHeight: "80vh", ...props.style }}>
        <Heading />
        <CreateNewQuestion />
        {!props?.disabled && <ImportDocQuestions />}
      </Box>
    </QsEditorLogic>
  );
}

const CreateNewQuestion = () => {
  const context = useContext(QuestionCtx);
  const { importQs, fontSize } = context;
  if (!!importQs) return null;
  return (
    <>
      <AudioRecorderWrap />
      <QuestionPrompt fontSize={fontSize} />
      {/* <QuestionTypeSelection /> */}
      <div style={{ overflowY: "auto" }}>
        <AnswerChoices {...context} />
      </div>
      <InlineQuestionFooter />
    </>
  );
};

export const ViewQuestion = (props) => {
  const context = useContext(QuestionCtx);
  const [isOpen, setOpen] = useState(false);
  const { importQs, fontSize } = context;
  if (!!importQs) return null;
  return (
    <QsEditorLogic {...props}>
      <Box type="col left" width="100%" margin="0 auto" background="#222" style={{ maxHeight: "80vh", ...props.style }}>
        <QuestionPrompt fontSize={fontSize} />
        <Collapse showTitle={false} isOpen={isOpen} setOpen={setOpen}>
          <AudioRecorderWrap />

          <div style={{ overflowY: "auto" }}>
            <AnswerChoices {...context} />
          </div>
        </Collapse>
      </Box>
    </QsEditorLogic>
  );
};

const ViewQuestionHeading = () => {
  return null;
};

function ImportMedia() {
  const { showMediaModal, handleImportedMediaId, handleAudioDelete, toggleMediaModal } = useContext(QuestionCtx);
  if (!showMediaModal) return null;
  return (
    <ModalElement isOpen={showMediaModal} fade={showMediaModal} close={() => toggleMediaModal()}>
      <Box type="column" width="70vw" minHeight="70vh" margin="auto" background="none" padding="0" zIndex="10">
        <GetMedias
          onSelect={(v) => {
            handleImportedMediaId(v);
          }}
          optionsArray={["Audio"]}
        />
      </Box>
    </ModalElement>
  );
}
//TODO: Fix props.
export function AudioRecorderWrap() {
  const { addAudioIdToQsPrompt, questionAudio, handleAudioDelete, toggleMediaModal, readOnly, showAudio } = useContext(QuestionCtx);
  const [signed, setSigned] = useState(null);
  useEffect(() => {
    if (signed === null && questionAudio !== undefined && questionAudio !== signed) {
      GET(`/media/${questionAudio}/`).then((d) => {
        // console.log(d);
        setSigned(d);
      });
    }
    if (typeof questionAudio === "string" && questionAudio.indexOf("https://") > -1) {
      setSigned({ uri: questionAudio });
    }
  }, [questionAudio, signed]);

  const changeFileName = (v) => {
    PUT(`/media/${signed._id}`, {
      data: { fileName: signed?.fileName },
    }).then((d) => {
      setSigned({ ...signed, fileName: v });
    });
  };

  if (questionAudio) {
    return (
      <Box type="1 row centered" background="none" width="100%" padding="0">
        <Box type="1 row centered" background="none" width="100%" padding="0">
          <audio src={signed?.uri} controls />
        </Box>
        <div
          style={{
            width: "100%",
            margin: "0 auto",
          }}
        >
          <TextField
            defaultValue={signed?.fileName}
            hasEditBtn={false}
            isDecorated="true"
            label="Question Audio Title"
            style={{
              borderBottom: "1px solid white",
              width: "100%",
              color: "white",
              background: "none",
            }}
            onChange={(v) => {
              changeFileName(v);
            }}
            wrapStyles={{
              margin: "0 auto",
              width: "75%",
              background: "none",
            }}
            disabled={readOnly}
          />
        </div>
        <DeleteBtn
          onMouseDown={() => {
            handleAudioDelete();
            setSigned(null);
          }}
        />
      </Box>
    );
  }
  return (
    showAudio && (
      <Box type="1 row centered" background="none" padding="0 1em" justifyContent="space-between">
        <QuestionAudioRecorder uploadCallback={addAudioIdToQsPrompt} />{" "}
        <AddBtn
          tootltip="Import Audio"
          title="Import Audio"
          style={{ marginLeft: "1em" }}
          onMouseDown={() => {
            toggleMediaModal();
          }}
          disabled={readOnly}
        />
        <ImportMedia />
      </Box>
    )
  );
}
export function QuestionPrompt() {
  const { onQsTextChange, question, readOnly, fontSize } = useContext(QuestionCtx);
  if (question === undefined) return null;
  return (
    <>
      <h4 style={{ marginBottom: "0em", color: "#777" }}>Qs. Prompt</h4>
      <QsEditor fontSize={fontSize} readOnly={readOnly} placeholder="Type question..." background={"transparent"} onChange={(v) => onQsTextChange(v)} content={question} className="dark" />
    </>
  );
}
/**
 * @param  {string} questionType
 * @param  {array} options
 * @param  {string} correctAns
 * @param  {string} fontSize
 * @param  {boolean} isDisabled Done in "Import questions" to prevent editing.
 */
export function AnswerChoices(props) {
  let le_context = useContext(QuestionCtx);
  let questionType = le_context?.questionType || props?.questionType || undefined
   let options = le_context?.options || props?.options || undefined
    let correctAns = le_context?.correctAns || props?.correctAns || undefined
     let fontSize = le_context?.fontSize || props?.fontSize || undefined
      let isDisabled = le_context?.isDisabled || props?.isDisabled || undefined
       let question = le_context?.question || props?.question || undefined
       let readOnly = le_context?.readOnly || props?.readOnly || undefined
 
  if (questionType === "audioResponse") {
    return Array.isArray(correctAns) && correctAns.map((cA, cAI) => <AudioResponse op={cA} i={cAI} key={`correct_ans._${cAI}`} isDisabled={isDisabled} />);
  }
  return (
    <Box type="col left" padding="0" margin="1.5em 0" background="none" onMouseDown={(e) => !!isDisabled && e.preventDefault()}>
      <h4 style={{ marginBottom: "0em", color: "#777" }}>Answer Choices({options && options.length})</h4>
      {options &&
        options.map((option, i) => {
         
          if (questionType === "mcq_1") {
            return <RadioOption op={option} i={i} key={`ans._option_${i}`} fontSize={fontSize} isDisabled={isDisabled} />;
          } else if (questionType === "mcq") {
            return <CheckOption op={option} i={i} key={`ans._option_${i}`} fontSize={fontSize} isDisabled={isDisabled} />;
          } else if (questionType === "mcq_1+") {
            return <CheckOption op={option} i={i} key={`ans._option_${i}`} fontSize={fontSize} isDisabled={isDisabled} />;
          } else if (questionType === "boolean") {
            return <RadioOption op={option} i={i} key={`ans._option_${i}`} fontSize={fontSize} isDisabled={isDisabled} />; //TODO--present: i = 0: true; i = 1:false;
          } else if (questionType === "textualEvidence") {
            return <TextualEvidence op={option} i={i} key={`ans._option_${i}`} fontSize={fontSize} isDisabled={isDisabled} />;
          } else if (questionType === "audioResponse") {
            return <AudioResponse op={option} i={i} key={`ans._option_${i}`} fontSize={fontSize} isDisabled={isDisabled} />;
          }
          return null;
        })}
    </Box>
  );
}
export function QsViewAnswerChoices(props) {
  const {questionType, options, correctAns, fontSize, isDisabled, question, readOnly} = props
  
 
  if (questionType === "audioResponse") {
    return Array.isArray(correctAns) && correctAns.map((cA, cAI) => <AudioResponse op={cA} i={cAI} key={`correct_ans._${cAI}`} isDisabled={isDisabled} />);
  }
  return (
    <Box type="col left" padding="0" margin="1.5em 0" background="none" onMouseDown={(e) => !!isDisabled && e.preventDefault()}>
      <h4 style={{ marginBottom: "0em", color: "#777" }}>Answer Choices({options && options.length})</h4>
      {options &&
        options.map((option, i) => {
         
          if (questionType === "mcq_1") {
            return <RadioOption op={option} i={i} key={`ans._option_${i}`} fontSize={fontSize} isDisabled={isDisabled} />;
          } else if (questionType === "mcq") {
            return <CheckOption op={option} i={i} key={`ans._option_${i}`} fontSize={fontSize} isDisabled={isDisabled} />;
          } else if (questionType === "mcq_1+") {
            return <CheckOption op={option} i={i} key={`ans._option_${i}`} fontSize={fontSize} isDisabled={isDisabled} />;
          } else if (questionType === "boolean") {
            return <RadioOption op={option} i={i} key={`ans._option_${i}`} fontSize={fontSize} isDisabled={isDisabled} />; //TODO--present: i = 0: true; i = 1:false;
          } else if (questionType === "textualEvidence") {
            return <TextualEvidence op={option} i={i} key={`ans._option_${i}`} fontSize={fontSize} isDisabled={isDisabled} />;
          } else if (questionType === "audioResponse") {
            return <AudioResponse op={option} i={i} key={`ans._option_${i}`} fontSize={fontSize} isDisabled={isDisabled} />;
          }
          return null;
        })}
    </Box>
  );
}
export const RadioOption = ({ op, i, fontSize, isDisabled }) => {
  const { onAnsRadioChange, onAnsOpTextChange, questionType, options, deleteAnsChoice, readOnly, fontsize } = useContext(QuestionCtx);

  let boolChoice;
  let isBool = questionType === "boolean";
  if (isBool && i === 0) {
    boolChoice = [{ type: "paragraph", children: [{ text: "True" }] }];
  }
  if (isBool && i === 1) boolChoice = [{ type: "paragraph", children: [{ text: "False" }] }];

  return (
    <Box type="1 row centered" background="transparent" bRadius="0" width="95%" padding="0" margin="1em 0" justifyContent="flex-start" key={`mcq_answerChoice_${i}`}>
      <Radio
        checked={options[i]?.isCorrect}
        style={{ position: "relative", top: "0px" }}
        disabled={isDisabled || readOnly}
        readOnly={readOnly}
        onChange={(e) => !isDisabled && !readOnly && onAnsRadioChange(e, i)}
        value={"options_" + i}
        index={i}
      />

      <QsEditor
        fontSize={fontSize || fontsize}
        content={boolChoice || op.choice}
        placeholder="Type Answer..."
        background={"transparent"}
        onChange={(v) => !isDisabled && onAnsOpTextChange(v, i)}
        width="70%"
        className="dark"
        readOnly={isBool || isDisabled || readOnly}
      />
      <div className="flex-row" style={{ marginLeft: "auto", width: "min-content" }}>
        <RounderBtn>Tags</RounderBtn>
        <DeleteBtn
          tooltip={"Not yet implemented"}
          onMouseDown={() => {
            deleteAnsChoice(i);
          }}
          disabled={readOnly || isDisabled}
        />
      </div>
    </Box>
  );
};
export const CheckOption = ({ fontSize, op, i, isDisabled }) => {
  const { onAnsCheckboxChange, onAnsOpTextChange, options, readOnly, fontsize } = useContext(QuestionCtx);

  return (
    <Box type="1 row centered" background="transparent" bRadius="0" width="95%" padding="0" justifyContent="flex-start" key={`ansChoice_question_${i}`} margin="1em 0">
      <Checkbox
        name={"option_" + i}
        checked={options[i]?.isCorrect}
        onChange={(e) => isDisabled && onAnsCheckboxChange(e, i)}
        style={{
          position: "relative",
          top: "0px",
        }}
        disabled={readOnly}
        onMouseDown={(e) => {
          if (readOnly || isDisabled) {
            e.preventDefault();
            e.stopPropagation();
          }
        }}
      />

      <QsEditor
        fontSize={fontSize || fontsize}
        content={op.choice}
        placeholder="Type Answer..."
        background={"transparent"}
        onChange={(v) => isDisabled && onAnsOpTextChange(v, i)}
        width="80%"
        className="dark"
        readOnly={isDisabled || readOnly}
      />
    </Box>
  );
};

//TODO: Add 1. isActive, onMouseDown, onClick, handleFieldClick;
export const TextualEvidence = (props) => {
  const { op, i, addRef, isActive, onMouseDown, readOnly } = props;
  return (
    <div className={"flex-row"} key={`ansChoice_question_${i}`} style={{ width: "100%", margin: "1em 0" }}>
      <PlainTextInput
        ref={(el) => addRef(el, i)}
        placeholder={isActive ? "Click to activate this field; then select text ONLY from the editor..." : "Great! Now select text from the editor"}
        selected={isActive}
        readOnly={readOnly}
        onMouseDown={(e) => {
          props.handleFieldClick(e, i);
        }}
      />
    </div>
  );
};
export const AudioResponse = (props) => {
  const { op, i } = props;
  const { onAudioAnsTextChange, readOnly } = useContext(QuestionCtx);
  return (
    <Box type="1 row centered" background="transparent" bRadius="0" width="95%" padding="0" justifyContent="flex-start" key={`ansChoice_question_${i}`} margin="1em 0">
      <TextField
        isDecorated={true}
        placeholder={"Insert the response you want to hear..."}
        label={"Answer"}
        defaultValue={op}
        onChange={(v) => {
          onAudioAnsTextChange(v, i);
        }}
        disabled={readOnly}
      />
    </Box>
  );
};
export const QuestionTypeSelection = ({ type }) => {
  const { changeQsType, questionType, readOnly } = useContext(QuestionCtx);
  const btns = [
    { t: "Audio Response", qType: "audioResponse" },
    { t: "MCQ: One Correct", qType: "mcq_1" },
    { t: "True/False", qType: "boolean" },
    { t: "MCQ: One+ Correct", qType: "mcq_1+" },
    { t: "Cite Evid.", qType: "textualEvidence", disabled: true },
  ];

  if (questionType === undefined) return null;

  if (type === 1) {
    return (
      <Box type="row" background="none" justifyContent="space-around" width="100%">
        {btns.map(({ t, qType, ...rest }, bi) => (
          <RounderBtn
            style={{
              margin: "0.5em",
              background: questionType !== qType ? "transparent" : "var(--primary-color)",
              border: "2px solid var(--tertiary-color-darkest)",
              color: questionType !== qType ? "#ccc" : "white",
            }}
            {...rest}
            onMouseDown={() => {
              changeQsType(qType);
            }}
            key={`create_qs_btn_${t}`}
            disabled={readOnly}
          >
            {t}
          </RounderBtn>
        ))}
      </Box>
    );
  }

  return (
    <div style={{ margin: "0 auto" }}>
      <Dropdown3
        disabled={readOnly}
        title={"Question Type: " + questionType}
        wrapStyles={{ background: "var(--primary-color)", color: "snow", borderRadius: "var(--border-radius-med, 8px)", scale: "0.8" }}
      >
        {btns.map(({ t, qType, ...rest }, bi) => (
          <span
            active={qType === questionType}
            style={{
              margin: "0.5em",
              width: "100%",
            }}
            onMouseDown={() => {
              changeQsType(qType);
            }}
            key={`create_qs_btn_${t} ${bi}`}
            disabled={readOnly}
            {...rest}
          >
            {t}
          </span>
        ))}
      </Dropdown3>
    </div>
  );
};

export function Heading({props}) {
  const { isNewQs, onClose, toggleMediaModal, importQs, toggleImport, readOnly, showAudio, setShowAudio } = useContext(QuestionCtx);
  return (
    <Box type="1 row centered" background="none" padding={"0"} justifyContent={"space-between"} margin="0 0 2em 0" alignItems="center">
      <h3 style={{ color: "#777" }}>{importQs ? "Import Question: Select One" : isNewQs === null ? "New Question" : readOnly ? "View-only Question" : "Edit Question"}</h3>
      <div className="flex-row" style={{ width: "min-content", alignItems: "center" }}>
        <QuestionTypeSelection />

        <AddBtn
          tooltip="Import Question (Not yet implemented)"
          size="18px"
          color={!importQs ? "black" : "snow"}
          bg={!importQs ? "white" : "var(--primary-color)"}
          style={{ marginRight: "1em" }}
          onMouseDown={() => {
            toggleImport();
          }}
          disabled={readOnly}
        />
        <MicBtn
          tooltip="Show audio"
          size="18px"
          color={!showAudio ? "black" : "snow"}
          bg={!showAudio ? "white" : "var(--primary-color)"}
          style={{ marginRight: "1em" }}
          onMouseDown={() => {
            setShowAudio(!showAudio);
          }}
          disabled={readOnly}
        />
        {onClose && (
          <CloseBtn
            isOpen={true}
            type={1}
            style={{ marginRight: "1em" }}
            onClick={() => {
              onClose();
            }}
            disabled={readOnly}
          />
        )}
      </div>
    </Box>
  );
}
export function InlineQuestionFooter(props) {
  const { addAnsChoice, isNewQs, questionType, handleSave, handleCreate, onClose, readOnly } = useContext(QuestionCtx);
  if (readOnly) return null;
  return (
    <Box type="row" justifyContent="space-between" background="none" margin="2em 0 0 0" padding="0">
      <Box background="none" padding="0" width="33%" justifyContent="flex-start">
        <Button theme="secondary" onClick={() => onClose()}>
          Close
        </Button>
      </Box>
      <Box background="none" padding="0" width="55%" justifyContent="flex-end">
        <AddOptionsCTA questionType={questionType} />

        {isNewQs === null ? <Button onClick={() => handleCreate()}>Create Question</Button> : <Button onClick={() => handleSave()}>Update Question</Button>}
      </Box>
    </Box>
  );
}

export const AddOptionsCTA = (props) => {
  const { addAnsChoice, addCorrectAnswerField, readOnly } = useContext(QuestionCtx);
  if (props.questionType === "audioResponse") {
    return (
      <Button theme="primary" onClick={(e) => addCorrectAnswerField(e)}>
        Add Audio Correct Response
      </Button>
    );
  } else if (props.questionType === "boolean") {
    return null;
  }
  if (readOnly) return null;
  return (
    <Button disabled={readOnly} theme="primary" onClick={(e) => addAnsChoice(e)}>
      Add Answer Choice
    </Button>
  );
};
