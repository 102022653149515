import styled from "styled-components";

export const MasterBox = styled.div`
  display: ${({ flexFlow }) => "flex" || "block"};
  flex-flow: ${({ flexFlow }) => flexFlow};
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  border: ${({ border }) => border || "none"};
  border-radius: ${({ bRadius }) => bRadius || " var(--border-radius-med"});
  width: ${(props) => props.width || "100%"};
  margin: ${(props) => props.margin || "0"};
  padding: ${(props) => props.padding || "16px"};
  min-height: ${(props) => props.minHeight || undefined};
  max-height: ${(props) => props.height || undefined};
  background: ${(props) => props.background || "rgb(30, 30, 30)"};
  background: ${(props) => props.background || "linear-gradient(-25deg, rgba(30, 30, 30, 1) 0%, rgba(50, 50, 50, 0.7) 100%)"};
  overflow-x: ${({ overFlowX }) => overFlowX};
  overflow-y: ${({ overFlowY }) => overFlowY};
  opacity: ${({ opacity }) => opacity};
  transition: all 0.3s ease-in;
`;
export const BoxWrap = styled(MasterBox)``;

export const ShowOnHover = styled(BoxWrap)`
  opacity: 0;
  transition: all 0.1s ease-in;
  ${BoxWrap} .hoverParent:hover  & {
    opacity: 0.7;
  }
  svg {
    cursor: pointer;
  }
  ${BoxWrap} .hoverParent &:hover {
    opacity: 1;
    transform: scale(1.05);
  }
  ${BoxWrap} .hoverParent & svg:active {
    transform: scale(1.2);
    transition: transform 0.1s linear;
  }
`;
export const CollapseWrap = styled(MasterBox)`
  width: var(--width, 100%);
  background-color: var(--background, var(--davy-grey));
  background: ${(props) => props.background};
  max-height: ${({ height, isOpen }) => (height ? (isOpen ? height : "0px") : isOpen ? "800px" : "0px")};
  /* opacity: ${({ isOpen }) => (isOpen ? "1" : "0")}; */
  padding: ${({ isOpen }) => (isOpen ? "15px" : "0")};
  margin: ${({ isOpen }) => (isOpen ? "10px 0 10px 0" : "5px 0px")};

  overflow: hidden;
  transition: opacity 0.3s, max-height 0.3s;
`;

export const DropWrap = styled(MasterBox)`
  position: relative;
  top: 0px;
  width: var(--width, 100%);
  background-color: var(--background, var(--davy-grey));
  background: ${(props) => props.background};
  max-height: ${({ height, isOpen }) => (isOpen ? "500px" : "0px")};
  opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
  padding: ${({ isOpen }) => (isOpen ? "15px" : "0")};
  margin: ${({ isOpen }) => (isOpen ? "10px 0 10px 0" : "5px 0px")};
  border: ${(props) => props.border || "1px solid var(--davy-grey)"};
  transition: all 0.3s;
  & ${BoxWrap} {
    max-height: ${({ height, isOpen }) => (height ? (isOpen ? height : "0px") : isOpen ? "auto" : "0px")};
    opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
    overflow: hidden;
    transition: opacity 0.3s, max-height 0.3s;
  }
  .dropRowWrap {
    position: absolute;
    top: 0;
    width: 100%;
  }
  .dropRow {
    box-sizing: border-box;
    width: 100%;
    height: 50px;
    padding: 10px;
    text-align: left;
  }
`;

export const TimelineProgress = styled.div`
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 2px;

  left: -27px;
  // border: 1px solid grey;
  border-radius: 50%;
  background: var(--tertiary-background);
  z-index: 2;
`;
export const TimelineWrap = styled(MasterBox)`
  margin: 0;
  padding: 0;
  position: relative;
  min-height: 50px;
  height: auto;
  background-color: none;
  background: none;
  padding: 10px 16px;
  margin-left: 1em;
  font-size: 13px;
  color: #444;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  &:before: {
  }
  &:after {
    z-index: 1;
    content: ${({ isLast }) => (isLast ? undefined : `""`)};
    position: absolute;
    top: 73%;
    left: -0em;
    width: 1px;
    height: 60%;
    background: grey;
  }
`;

//Dropdown_2

export const DropDown2Wrap = styled.div`
  box-sizing: border-box;
  color: black;
  cursor: pointer;
  padding: 8px 10px;
  width: auto;
  text-align: center;
  font-weight: 500;
  font-size: 12px;
  margin: auto 0;
  position: relative;
  white-space: nowrap;
  z-index: 11;
  &:hover {
    background-color: rgb(30, 30, 30, 0.1);
  }
  &::after {
    display: inline-block;
    content: "\\2192";
    width: 20px;
    color: black;
    padding: 5px;
    transform: ${({ isOpen }) => (isOpen ? "rotate(90deg)" : "rotate(0deg)")};
    transition: transform 0.1s ease;
    white-space: nowrap;
  }
`;
export const Row = styled.div`
  display: flex;
  flex-direction: row wrap;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  width: auto;
  min-width: 150px;
  max-width: 300px;
  height: 40px;
  padding: 10px 20px;
  margin: 0;
  text-align: left;
  background: ${({ active }) => (active ? "var(--primary-color)" : "transparent")};
  color: ${({ active }) => (active ? "white" : "black")};
  cursor: pointer;
  user-select: none;
  span, div {
    color: ${({ active }) => (active ? "white" : "black")};
    margin: auto 0;
    white-space: nowrap;
  }
  &:hover {
    ${({ active }) => {
      if (!active) return "background: rgb(30, 30, 30, 0.1);";
    }}
  &:active {
    background: var(--primary-color);
    color: white;
  }
  
`;
