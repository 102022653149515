import { createContext, useContext, useCallback, useMemo, useState, useEffect } from "react";
import usePageVisibility from "../../helpers/hooks/useActiveBroswerTab";
import useSocket from "../../helpers/hooks/useSocket";
import LectureContext from "./context";
import { ModalElement } from "../../helpers/hooks/useModal";
import { Box } from "../layout/containers/box";
import { RounderBtn } from "../layout/buttons/buttonStyles";

export const MonitorCtx = createContext({});

export default function MonitorProvider({ children }) {
  const settings = useMemo(
    //!Replace useMemo with saved user settings.
    () => ({
      showBanner: true,
      monitFocus: false,
      monitTab: false,
      monitPageLeave: false,
    }),
    []
  );
  const socket = useSocket();
  const isVisible = usePageVisibility();
  const { isLive } = useContext(LectureContext);
  const [isActive, setIsActive] = useState(true);
  const [studyTime, setStudying] = useState(0);
  const [inGrace, setGracious] = useState(true);
  const { saved, elementIndex } = useContext(LectureContext);
  /**-------------------START: MONITOR TAB & MOUSE CHANGES-------------------**/
  const handleActive = useCallback(
    (boolean) => {
      if (settings === undefined && isActive !== boolean) setIsActive(boolean);
      if (typeof settings === "object" && settings.monitFocus && isActive !== boolean) setIsActive(boolean);
    },
    [settings, isActive]
  );
  const handleGrace = useCallback(
    (boolean) => {
      if (settings === undefined && boolean !== inGrace) setGracious(boolean);
      if (typeof settings === "object" && settings.monitFocus && boolean !== inGrace) setGracious(boolean);
    },
    [settings, inGrace]
  );

  useEffect(() => {
    let graceDelay;
    const handleMouseLeave = (e) => {
      // console.log("5s Grace period started");
      graceDelay = setTimeout(() => {
        handleGrace(false);
      }, 5000);
    };

    const handleMouseEnter = () => {
      if (graceDelay) {
        // console.log(graceDelay);
        clearTimeout(graceDelay);
        graceDelay = undefined;
      }
      if (settings?.showBanner === false) handleGrace(true);

      console.log("Back in good graces");
    };

    document.addEventListener("mouseleave", handleMouseLeave);
    document.addEventListener("mouseenter", handleMouseEnter);
    return () => {
      document.removeEventListener("mouseleave", handleMouseLeave);
      document.removeEventListener("mouseenter", handleMouseEnter);
    };
  }, [handleGrace, settings]);
  useEffect(() => {
    if (!isVisible && (typeof settings === "undefined" || settings.monitTab)) {
      handleActive(false);
    }
  }, [isVisible, handleActive, settings]);
  /**-------------------END: MONITOR TAB & MOUSE CHANGES-------------------**/
  useEffect(() => {
    if (!inGrace && isActive) handleActive(false);
    if (settings?.showBanner === false && inGrace && !isActive) handleActive(true);
  }, [inGrace, isActive, isVisible, settings, handleActive]);

  /**-------------------START: TIMER START & STOP-------------------**/
  useEffect(() => {
    const incrementSec = () => {
      setStudying(studyTime + 1);
    };
    let interval;
    if (isActive) {
      interval = setInterval(incrementSec, 1000);
    } else if (!inGrace) {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [isActive, inGrace, studyTime]);
  /**-------------------END: TIMER START & STOP---------------------**/
  /**-------------------START: TIMED PROGRESS---------------------**/
  useEffect(() => {
    if (socket !== null && saved === null) {
      socket.emit("activity", { time: new Date(), msg: "Lecture not started" });
    }
    if (socket !== null && saved !== null && elementIndex !== undefined) {
      socket.emit("activity", { time: new Date(), timer: studyTime, msg: `Started Element: ${saved.elementIndex}` });
    }
  }, [saved, elementIndex, socket]);

  /**-------------------START: TIME EMITTER---------------------**/
  useEffect(() => {
    if (socket !== null) {
      socket.emit("activity", { time: new Date(), tabVisible: isVisible, isFocussed: isVisible && inGrace });
    }
  }, [socket, isActive, inGrace, isVisible]);

  return (
    <MonitorCtx.Provider value={{ studyTime, isActive }}>
      <ModalElement isOpen={!isActive && settings.showBanner} fade={!isActive && settings?.showBanner}>
        <Box type="column" width="50dvw" style={{ height: "50dvh" }} justifyContent="space-between">
          <h1>Eek! You left the "classroom"</h1>
          <RounderBtn
            onClick={() => {
              setIsActive(true);
              handleGrace(true);
            }}
          >
            Continue
          </RounderBtn>
        </Box>
      </ModalElement>
      {children}
    </MonitorCtx.Provider>
  );
}
