import React from "react";
import styled from "styled-components";

const Background = styled.circle`
  fill: none;
  stroke: #ddd;
`;
const ProgCircumference = styled.circle`
  fill: none;
  stroke: ${(props) => props.stroke || "green"};
  strokelinecap: round;
  strokelinejoin: round;
`;
const InnerText = styled.text`
  font-size: 15px;
  font-weight: 400;
  fill: white;
`;
/**
 * @param  {Number} props.sqSize // range: advocated: 30-60;
 * @param  {Number} props.StrWidth // e.g. 1-5
 * @param  {Number} props.percentage  0 -100
 * @param {Boolean} props.showPercentage  0 -100
 * @param  {String} props.fill_stroke  0 -100
 */
const RoundProgress = (props) => {
  let { sqSize, strWidth, percentage, showPercentage } = props;
  if (showPercentage === undefined) showPercentage = true;
  const rad = (sqSize - strWidth) / 2;
  const viewBox = `0 0 ${sqSize} ${sqSize}`;
  const dashArray = rad * Math.PI * 2;
  const dashOffset = dashArray - (dashArray * percentage) / 100;
  return (
    <svg width={sqSize} height={sqSize} viewBox={viewBox}>
      <Background cx={sqSize / 2} cy={sqSize / 2} r={rad} strokeWidth={`${strWidth}px`} />
      <ProgCircumference
        stroke={props.fill_stroke}
        cx={sqSize / 2}
        cy={sqSize / 2}
        r={rad}
        strokeWidth={`${strWidth}px`}
        // Start progress marker at 12 O'Clock
        transform={`rotate(-90 ${sqSize / 2} ${sqSize / 2})`}
        style={{
          strokeDasharray: dashArray,
          strokeDashoffset: dashOffset,
        }}
      />
      <InnerText x="50%" y="50%" dy=".3em" textAnchor="middle">
        {showPercentage && `${percentage}%`}
      </InnerText>
      {/* {showPercentage && (
       
      )} */}
    </svg>
  );
};

export default RoundProgress;
