export function BE_AUTH(uri) {
  let host = window.location.hostname;
  let isLocal = host.indexOf("localhost") > -1;
  let result;
  if (!isLocal) {
    result = "https://auth.sohaibahmed.ca";
  } else {
    result = "http://localhost:8050";
  }
  return `${result}${uri}`;
}

export function BE_SERVER(uri) {
  let host = window.location.hostname;
  let isLocal = host.indexOf("localhost") > -1;
  let result;
  if (!isLocal) {
    result = "https://api.sohaibahmed.ca";
  } else {
    result = "http://localhost:8000";
  }
  return `${result}${uri}`;
}
