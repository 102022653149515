import React, { forwardRef, useRef, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { CloseBtn } from "../../components/layout/buttons/buttons";

const modalDiv = document.getElementById("portal_container");

export const useModal = () => {
  let [modal, setModal] = React.useState(false);
  let [modalContent, setModalContent] = React.useState("I'm the Modal Content");
  let handleModal = (content = false) => {
    setModal(!modal);
    if (content) {
      setModalContent(content);
    }
  };
  return { modal, handleModal, modalContent };
};

let ModalContext;
let { Provider } = (ModalContext = React.createContext());

let ModalProvider = ({ children }) => {
  let { modal, handleModal, modalContent } = useModal();
  return (
    <Provider value={{ modal, handleModal, modalContent }}>
      <ModalElement isOpen={modal} close={handleModal}>
        {children}
      </ModalElement>
    </Provider>
  );
};

export const ModalShit = { ModalContext, ModalProvider };

export const ModalItself = () => {
  let { modalContent, handleModal, modal } = React.useContext(ModalContext);
  if (modal) {
    return createPortal(
      <div
        className="fixed top-0 left-0 h-screen w-full flex items-center justify-center"
        style={{ background: "rgba(0,0,0,0.8)" }}
      >
        <div className="bg-white relative p-5 shadow-lg rounded flex flex-col items-start text-lg text-gray-800">
          <button
            className="absolute top-0 right-0 -mt-12 font-bold self-end rounded-full bg-red-200 mb-3 bg-white text-red-700 w-8 h-8"
            onClick={() => handleModal()}
          >
            &times;
          </button>
          <p>{modalContent}</p>
        </div>
      </div>,
      document.querySelector("#modal-root")
    );
  } else return null;
};

export function ModalDeprecated(
  { children, defaultOpened = false, fade = false },
  ref
) {
  const [isOpen, setIsOpen] = React.useState(defaultOpened);
  const close = React.useCallback(() => {
    console.log("close modal");
    return setIsOpen(false);
  }, []);

  //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~//
  // *** FEATURE 1: BE HANDLED BY THE PARENT REFS.
  React.useImperativeHandle(
    //Modal open/close is handled by parent ref.
    //This exposes the open & close functions to the parent's own ref.,
    // e.g. parent.current.open() will open the modal;
    ref,
    () => (
      {
        open: () => {
          setIsOpen(true);
        },
        close: () => {
          console.log("close");
          setIsOpen(false);
        },
      },
      [close, setIsOpen]
    )
  );
  //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~//
  // *** FEATURE 2: LEAVE MODAL @ `ESCAPE` KEY;
  const handleEscape = React.useCallback(
    (e) => {
      if (e.keycode === 27) close();
    },
    [close]
  );
  React.useEffect(() => {
    if (isOpen && typeof window !== "undefined" && window.document) {
      document.addEventListener(`keydown`, handleEscape, false);
      return () => {
        document.removeEventListener(`keydown`, handleEscape, false);
      };
    }
  }, [handleEscape, isOpen]);
  //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~//
  // *** FEATURE 3: MODAL IS CREATED IN A PORTAL OUTSIDE REACT TREE.
  //created a new root container in index.html

  //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~//
  // *** FEATURE 4: OPEN/CLOSE MODAL VIA close-button
  // ?? CREATE A BUTTON IN THE PARENT; trigger ref.current.open() & ref.current.close()
  // ?? TO OPEN/CLOSE
  // !! NOT IMPLEMENTED??
  //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~//
  // return createPortal(
  //   <ModalElement isOpen={isOpen} fade={fade} close={close}>
  //     {children}
  //   </ModalElement>,
  //   modalDiv
  // );
  return createPortal(
    isOpen ? (
      <div className={`modal ${fade ? "modal-fade" : ""}`}>
        <div className="modal-overlay" onClick={close} />
        <span
          role="button"
          className="modal-close"
          aria-label="close"
          onClick={close}
        >
          x
        </span>
        <div className="modal-body">{children}</div>
      </div>
    ) : null,
    modalDiv
  );
}
export default forwardRef(ModalDeprecated);

export function ModalElement({ isOpen, showClose, close, children }) {
  const ref = useRef(null);

  useEffect(() => {
    let body = document.querySelector("body");
    if (isOpen) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "auto";
    }
  }, [isOpen]);
  if (isOpen) {
    return (
      <div
        ref={ref}
        className={`modal ${isOpen ? "modal-fade" : ""}`}
        aria-hidden={isOpen ? "false" : "true"}
        role="dialog"
      >
        <div className={`modal-overlay `} onClick={close} />
        {showClose ? (
          <span
            role="button"
            className="modal-close"
            aria-label="close"
            onClick={close}
          >
            <CloseBtn onClick={close} />
          </span>
        ) : null}
        <div className="modal-body">{children}</div>
      </div>
    );
  }
  return null;
}

/*
  CSS



*/
