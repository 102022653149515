import { useState, useRef, Children, cloneElement } from "react";
import styled from "styled-components";
import { Box } from "../../layout/containers/box";
import uuid4 from "uuid4";
export const DropWrap = styled.div`
  box-sizing: border-box;
  color: black;
  cursor: pointer;
  padding: 8px 10px;
  width: min-content;
  text-align: center;
  font-weight: 500;
  font-size: 12px;
  margin: auto 0;
  position: relative;
  white-space: nowrap;
  &:hover {
    background-color: rgb(30, 30, 30, 0.1);
  }
  &::after {
    display: inline-block;
    content: "\\2192";
    width: 20px;
    color: black;
    padding: 5px;
    transform: ${({ isOpen }) => (isOpen ? "rotate(90deg)" : "rotate(0deg)")};
    transition: transform 0.1s ease;
    white-space: nowrap;
  }
`;
export const Row = styled.div`
  display: flex;
  flex-direction: row nowrap;
  justify-content: space-between;
  align-items: center;
  

  box-sizing: border-box;
  width: 100%;
  height: 40px;
  padding: 10px 20px;
  margin: 0;
  text-align: left;
  background: ${({ active }) => (active ? "var(--primary-color)" : "transparent")};
  color: ${({ active }) => (active ? "white" : "black")};
  cursor: pointer;
  user-select: none;
  &:hover {
    ${({ active }) => {
      if (!active) return "background: rgb(30, 30, 30, 0.1);";
    }}
  &:active {
    background: var(--primary-color);
    color: white;
  }
`;
