import {
  useCallback,
  useRef,
  useState,
  useMemo,
  useContext,
  useEffect,
} from "react";
import Elements from "./renders/elements";
import Leafs from "./renders/leafs";
import { Slate, Editable, withReact, ReactEditor } from "slate-react";
import { createEditor, Editor, Transforms } from "slate";

import { withHistory } from "slate-history";
import withHtml from "./helpers/withHtml";
import withInlines from "./helpers/withInlines";
import withEditableVoids from "./helpers/withEditableVoids";
import editable from "./helpers/methods";
import { Box } from "../layout/containers/box";
import Toolbar, { ToolbarAddsElem } from "./toolbar";
import { EditorContext, EditorHook, CommentHook } from "./hooks";
import { Checkbox } from "../layout/selection/checkbox";
import { addNewElementAfterCurrent } from "./helpers/text";

// import { withEditableVoids, withHtml, withInlines } from "../editor/plugins";

function tempValueCreatingFunc() {
  let locallyStored = localStorage.getItem("editor");
  let parsed = JSON.parse(locallyStored);
  let isArray = Array.isArray(parsed);
  let reset =
    isArray && parsed.length === 1 && parsed[0].children[0].text === "";
  if (!!locallyStored && isArray && !reset) {
    return JSON.parse(locallyStored);
  }
  return [
    {
      type: "paragraph",
      children: [
        { text: "hey bud", comment_12345: true },
        {
          text: " here's a question for you:",
          isBreakpoint: true,
          qid_wda: true,
        },
        { text: " why are you here?" },
      ],
    },
  ];
}
/**
 * @param  {String} placeholder
 * @param  {Boolean} autoFocus
 * @param  {Function} onChange  must be given with supraValue. Both need to be together!!
 * @param  {Array} supraValue must be given with supraValue. Both need to be together!!
 */
export default function Sohs({ placeholder, autoFocus, onChange, supraValue }) {
  const [readOnly, setReadOnly] = useState(false);
  const [value, setValue] = useState(supraValue || tempValueCreatingFunc());

  // const editor = useRef(null);
  const editorRef = useRef();
  let containerRef = useRef();

  const handleChange = (v) => {
    if (onChange && typeof onChange === "function") {
      onChange(v);
    } else {
      setValue(v);
    }
  };
  const renderElement = useCallback((props) => {
    return <Elements {...props} editor={editorRef.current} />;
  }, []);
  const renderLeaf = useCallback((props) => {
    return <Leafs {...props} />;
  }, []);

  return (
    <Box
      width="100%"
      type={"col left"}
      margin="0 0 80px 0"
      fwdRef={containerRef}
    >
      <Box
        type="row"
        justifyContent="space-between"
        padding="0"
        margin="0"
        background="transparent"
      >
        Editor 2
        <>
          <Checkbox
            onChange={setReadOnly}
            checked={readOnly}
            name="readOnly"
            style={{ width: "100px" }}
          >
            Read Only
          </Checkbox>
          <span
            onMouseDown={(e) => {
              e.preventDefault();
              addNewElementAfterCurrent(editorRef?.current, "paragraph");
            }}
          >
            paragraph
          </span>
        </>
      </Box>
      <hr />
      <br />
      <EditMoi
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        autoFocus={autoFocus}
        value={supraValue || value}
        setValue={handleChange || setValue}
        editorRef={editorRef}
        placeholder={placeholder}
        readOnly={readOnly}
        containerRef={containerRef}
      />
    </Box>
  );
}

const EditMoi = ({
  value,
  setValue,
  editorRef,
  renderLeaf,
  renderElement,
  autoFocus,
  placeholder,
  readOnly,
  containerRef,
}) => {
  let memoizedEditor = useMemo(
    () =>
      withEditableVoids(
        withInlines(withHtml(withHistory(withReact(createEditor()))))
      ),
    []
  );

  //Defining  editor.
  if (!editorRef.current) {
    editorRef.current = memoizedEditor;
  }
  let editor = editorRef.current;

  const handleSelect = (e) => {
    editable.saveLastRange(editor);
  };
  useEffect(() => {
    if (JSON.stringify(value) !== JSON.stringify(editor.children)) {
      if (
        Array.isArray(value) &&
        typeof value[0]?.type === "string" &&
        Array.isArray(value[0].children)
      ) {
        Transforms.select(editor, Editor.start(editor, []));
        editor.children = value;
      } else if (!Array.isArray(value) || !value.length) {
        editor.children = [{ type: "paragraph", children: [{ text: "" }] }];
      }
    }
  }, [value, editor]);

  return (
    <Slate
      editor={editor}
      value={value}
      onChange={(v) => {
        setValue(v);
        localStorage.setItem("editor", JSON.stringify(v));
        window.dispatchEvent(new Event("storage"));
      }}
    >
      <EditorHook value={readOnly} containerRef={containerRef}>
        <CommentHook>
          <Toolbar />
          <ToolbarAddsElem />

          {/* HoverToolbar Adding Questions Adding Comments */}
          <Editable
            className="editor"
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            onKeyDown={(event) => editable.handleKeyDown(event, editor)}
            onKeyUp={(event) => editable.handleKeyUp(event, editor)}
            onDOMBeforeInput={(e) => editable.handleToolbarClick(e, editor)}
            placeholder={placeholder || "Enter text here..."}
            autoFocus={autoFocus}
            onSelect={(e) => handleSelect(e)}
            readOnly={readOnly}
            onMouseDown={(e) => {
              if (!!readOnly) e.preventDefault();
            }}
            onClick={(e) => {
              if (!!readOnly) e.preventDefault();
            }}
          />
        </CommentHook>
      </EditorHook>
    </Slate>
  );
};

/**
 * [{
 *    type: "paragraph",
 *    comments: [{range: [], id: "", status: true}],
 *    questions: [{range: [], id: "", status: false}]
 *    children: []
 * }]
 *
 */
