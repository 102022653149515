import { useState, useEffect, useContext } from "react";
import useSocket from "../../../helpers/hooks/useSocket";
import { UserContext } from "../../../helpers/hooks/userContext";
import { GET } from "../../../helpers/REST/api";
import { Location, useParams } from "react-router-dom";
import Lecture from "../../lecture";
import StudentLecture from "../../lecture/studentLecture";

export default function Lec(props) {
  // const socket = useSocket();
  // const { unit_id, doc_id } = useParams(); // --> Get docBlocks in the right order.
  // const { PID, MID, UID, lecDocs } = useContext(UserContext);
  // const [docBlocks, setDocBlocks] = useState([]);
  // const [memberLecId, setMemberLecId] = useState();
  // const [setBlocks, blocks] = useState([]);
  // const [savePoint, setSavePoint] = useState({
  //   blockIndex: 0,
  //   docElementsIndex: 0,
  //   blockType: "",
  //   elementIndex: 0,
  // });

  return <StudentLecture />;
}
