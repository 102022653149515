import React, { useState } from "react";

import styled from "styled-components";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

export const DndWrap = (props) => {
  const { droppableId, grid, width } = props;
  const { data, setData, idProp, children, ...rest } = props;
  const { activeIndex, setActive } = props;
  const [lastActive, setLastActive] = useState(null);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  function onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const items = reorder(data, result.source.index, result.destination.index);
    if (result.source.index === lastActive) {
    }

    setData(items);
  }
  function onDragStart(node) {
    if (node.source.index === activeIndex) {
      setActive(null);
    }
  }
  return (
    <DragDropContext onDragEnd={onDragEnd} onBeforeDragStart={onDragStart}>
      <Droppable droppableId={droppableId}>
        {(provided, snapshot) => (
          <>
            <List {...provided.droppableProps} ref={provided.innerRef} isDraggingOver={snapshot.isDraggingOver} grid={8} width={width} {...rest}>
              {children}
              {provided.placeholder}
            </List>
          </>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export const ListItem = styled.div`
  height: auto;
  display: flex;
  flex-flow: row wrap;
  user-select: none;
  background-color: ${({ isActive }) => (isActive ? "var(--primary-color)" : "white")};
  opacity: ${({ isDragging }) => (isDragging ? "0.7" : "1")};
  width: 100%;
  margin: ${({ grid }) => `0 0 ${grid}px 0`};
  //   padding: ${({ grid }) => `${grid * 2}px`};
  border-radius: 2px;
  border-left: ${({ isActive }) => (!isActive ? "5px solid var(--primary-color)" : "5px solid  white")};
  .title,
  div {
    color: ${({ isActive }) => (isActive ? "white" : "black")};
  }
  ${({ isHidden }) => (isHidden ? "display: none; max-height: 0px; visibility: hidden; opacity: 0" : "display: block; max-height: 100px; visibility: visible; opacity: 1")};
  transition: max-height 1s, opacity 1s, visibility 1s ease, display 0.3s, padding 2s;
  &:hover {
    background: rgb(255, 255, 255, 0.7);
  }
`;
const List = styled.div`
  height: auto;
  background-color: ${({ isDraggingOver }) => (!isDraggingOver ? "transparent" : "rgb(235, 236, 240, 0.3)")};
  //   background-color: transparent;
  // padding: ${({ grid }) => `${grid}px`};
  width: ${({ width }) => (width ? `${width}px` : "100%")};
  transition: background-color 0.3s linear;
  //   min-height: 50vh;
  color: inherit;
  border-radius: 8px;
  overflow-y: auto;
  min-height: 600px;
`;
export const DndRowWrap = styled.div`
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  padding: ${({ grid }) => `${grid * 2}px`};
  width: 100%;
  align-items: center;
`;

export const DndItem = ({ dId, i, children, isActive, rest }) => {
  return (
    <Draggable draggableId={dId} index={i}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          isDragging={snapshot.isDragging}
          style={provided.draggableProps.style}
          grid={8}
          isActive={isActive}
          {...rest}
        >
          {children}
        </div>
      )}
    </Draggable>
  );
};
