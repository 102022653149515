import styled from "styled-components";
import Select from "react-select";
import { Button } from "../buttons/buttons";

export const Input = styled.input`
  width: 100%;
  height: 48px;
  position: relative;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  background-color: transparent;
  color: black;
  outline: none;
  box-shadow: 0px 4px 20px 0px transparent;
  transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out,
    0.1s padding ease-in-out;
  & button {
    background-color: primary;
  }

  &[type="file"]::file-selector-button {
    float: right;
    padding: 0.2em 0.4em;
    border-radius: 0.2em;
    background-color: var(--primary-color);
    transition: 1s;
    color: white;
  }

  &[type="file"]::file-selector-button:hover {
    background-color: var(--primary-color);
    opacity: 0.7;
    border: 2px solid #00cec9;
    color: white;
  }
`;

export const ListTextInput = styled(Input)`
  color: white !important;
  display: block;
  z-index: 4;
  cursor: pointer;
  opacity: 0.8;
  width: min-content;
  &:focus {
    width: 100%;
  }
  
  &:hover {
   
    width: min-content;
    color: #108ee9; !important;
    opacity: 1;
  }
`;

export const SelectInput = styled(Input)``;

export const UndecoratedWrap = styled.div`
  width: ${(props) => (props.width ? props.width : "100%")};
  
  & ${ListTextInput}:focus {
    min-width: min-content;
    width: 100%;
  }
  & ${ListTextInput}:hover {
    min-width: min-content;
    width: min-content;
    color: #108ee9;
    input {
      color: color: rgb(200,200, 200, 0.8);
    }
    svg {
      fill: rgb(200,200, 200, 0.8);
    }
  }
`;

export const LockedInput = styled(Input).attrs({ as: "div" })`
  display: block;
  min-width: 100%;
`;

export const Label = styled.label`
  position: absolute;
  top: 20px;
  left: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #ffffff;
  opacity: 0;
  pointer-events: none;
  transition: 0.1s all ease-in-out;
`;
export const Wrap = styled.div`
  display: block;
  width: ${({ width }) => (width ? width : "auto")};
  height: 48px;
  border-radius: var(--border-radius-med);
  background-color: white; //rgb(200, 200, 200, 0.5);
  /* transition: 0.3s ease-in; */
  position: relative;

  & ${Input} {
    padding: ${({ focussed, hasVal }) =>
      hasVal
        ? "24px 16px 8px 16px"
        : focussed
        ? "24px 16px 8px 16px"
        : "0px 16px"};
    /* display: ${({ hasVal }) => (hasVal ? "none" : "0")}; */
  }
  & ${Input}+${Label} {
    top: ${({ focussed, hasVal }) => (hasVal ? "4px" : "20px")};
    font-size: ${({ focussed, hasVal }) => (!hasVal ? "1em" : "12px")};
    opacity: ${({ focussed, hasVal }) => "1"};
    color: ${({ focussed, hasVal }) =>
      focussed ? "var(--primary-color)" : "grey"};
  }
`;

export const ReactSelectWrap = styled(Select)`
  & .Select__indicator Select__dropdown-indicator {
    width: 90%;
    height: 100%;
    position: relative;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    background-color: transparent;
    color: black;
    outline: none;
    box-shadow: 0px 4px 20px 0px transparent;
    transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out,
      0.1s padding ease-in-out;
  }
`;
