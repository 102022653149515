import { useState, useRef, useEffect } from "react";
import { GET } from "../../../helpers/REST/api";
import { QuestionView } from "../../questions/importQuestions";
import { Box } from "../../layout/containers/box";
import CreateQuestion, {ViewQuestion} from "../../questions";
import QuestionViewOnly from "../../questions/questionView";
import { Alert } from "antd";
const AdminQsOverview = () => {
  const [questions, setQuestions] = useState(null);
  useEffect(() => {
    GET("/questions?SELECT=_id").then((r) => {
      console.log(r);
      setQuestions(r);
    });
  }, []);
  if (questions === null) return null;

  return (
    <Box padding="0" background="none" type="col left">
      <Alert message="Not completed. Needs to show student results + metrics. Fix tags \n Also need to make it editable" type="warning" closable />
      {questions && questions.map((qs, qi) => <QuestionViewOnly fontSize={"14px"} key={qs._id} qid={qs._id} index={qi} readOnly={false} style={{ margin: "1em 0" }} />)}
    </Box>
  );
};

export default AdminQsOverview;
