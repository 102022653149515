import styled from "styled-components";
//~~~~~~~~~~~~~~~~~~~~~~~ MODAL-MENU >~~~~~~~~~~~~~~~~~~~~~~~~~~//
export const MenuBar = styled.div`
  display: flex;
  flex-flow: row wrap;
  /* padding: 8px 7px 6px; */
  position: absolute;
  z-index: 2;
  top: -10000px;
  left: -10000px;
  margin-top: -6px;
  opacity: 0;
  background-color: #222;
  border-radius: 4px;
  transition: opacity 0.75;
  select,
  option {
    min-width: 400px !important;
  }
`;

export const IconButton = styled.button`
  position: relative;
  cursor: pointer;
  border: none;
  min-width: 50px;
  height: 45px;
  padding: 5px;
  border-radius: 2px;
  background: ${(props) =>
    props.inline
      ? "transparent"
      : props.disabled
      ? "rgba(0,0,0,0.25)"
      : props.active
      ? "rgb(56,9,25)"
      : "rgb(255,255,255, 1)"};
  font-size: 16px;
  /* font-weight: 600; */
  stroke-width: 45;
  color: ${(props) =>
    props.inline
      ? props.active
        ? "var(--primary-color)"
        : "#C5BDC0"
      : props.active
      ? "white"
      : "black"};
  &:hover {
    background: ${(props) =>
      !!props.disabled
        ? props.inline //REMAIN UNCHANGED IF DISABLED
          ? "transparent"
          : props.disabled
          ? "rgba(0,0,0,0.25)"
          : props.active
          ? "rgb(56,9,25)"
          : "rgb(255,255,255, 1)"
        : props.inline
        ? "transparent"
        : !!props.disabled
        ? "transparent"
        : "rgb(56, 9, 25)"};
    color: white;
    stroke-width: 55;
    font-weight: 600;
    font-size: 18;
  }
`;
//~~~~~~~~~~~~~~~~~~~~~~~ QUESTION_MODAL >~~~~~~~~~~~~~~~~~~~~~~~~~~//
