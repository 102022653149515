import { Box } from "../layout/containers/box";
import { Link, useNavigate } from "react-router-dom";
import { Select, Switch, Tooltip, Tree, Spin, Tag } from 'antd';
import { CheckCircle, CautionCircle, Lock } from "../../images/icons";
import styled from "styled-components";
import { useRef, useEffect } from "react";

const Icon = ({icon_type}) => {
  const wrap_style={paddingRight: "2em", position: "relative", display: "inline-block", left: "-20px" }
  if (icon_type ==="spin") return <span style={wrap_style}><Spin color="yellow" /></span>
  if (icon_type === "ok")return <span style={wrap_style}><CheckCircle style={{left: "-10px"}}/></span>
  if (icon_type === "needs_work") return <span style={wrap_style}><CautionCircle /></span>
  if (icon_type === "protected") return <span style={{wrap_style, left: "-20px"}}><Lock color="white"/></span>
}
const Todo = ({title}) => {
  return <span>{title} <Tag color="magenta">Todo</Tag></span>
}

const nested_pages = [
    {title: "Signup", key: "/signup", icon: <Icon icon_type={"ok"} />},
    {title: "Login", key: "/login", icon: <Icon icon_type={"ok"} />},
    {title: "Verify", key: "/verify", icon: <Icon icon_type={"ok"} />},
    {title: "Users", key: "/users", icon: <Icon icon_type={"ok"} />},

    {title: "Pages", key: "/pages", icon: <Icon icon_type={"ok"} />},
    {title: "Components", key: "/components",  icon: <Icon icon_type={"spin"} />},
    {
      title: "Product Gallery", key: "/gallery-index",  children: [
        {title: "index", key: "/", icon: <Icon icon_type={"ok"} />},
      ]
    },
    {title: "Students", key: "/students-index", icon: <Icon icon_type={"protected"}/>, children: [
      {title: "index", key: "/students"},
       {title: "Lecture (Won't load: needs ID)", key: "/lecture", icon: <Icon icon_type={"needs_work"}/> },
    ]},    
    {title: "Admin", key: "/admin-index", children: [
      {title: "Admin Dashboard", key: "/admin", icon: <Icon icon_type={"needs_work"}/>},
      {title: "Documents", key: "/documents-index", icon: <Icon icon_type={"protected"}/>, children: [
        {title: "Documents Index", key: "/documents"}, 
        {title: "New Document / New Lecture ", key: "/documents/new"},
        {title: "Document (needs id)", key: "/documents/:doc_id",  icon: <Icon icon_type={"needs_work"}/>},  
      ]},
      {title: "Blocks", key: "/blocks", icon: <Icon icon_type={"protected"}/>, },
      {title: "Products", key: "/products", icon: <Icon icon_type={"protected"}/>, },
      {title: "Playlists", key: "/playlists", icon: <Icon icon_type={"protected"}/>, children: [
        {title: <Todo title="Edit" />, key: "/playlists/:playlist_id", checked:false},
        {title: <Todo title="All & New" />, key: "/playlists/"},
      ]},
      {title: "Questions", key: "/questions", icon: <Icon icon_type={"protected"}/>, children: [
        {title: <Todo title="Edit" />, key: "/questions/:playlist_id", checked:false},
        {title: <Todo title="All & New" />, key: "/questions/"},
      ]},
    ]},
   
  ]


const Pages = () => {
  const tree_ref = useRef(null);
  const nav = useNavigate();
  useEffect(() => {
    if (tree_ref.current) {
      let [list, ... rest] = tree_ref.current.getElementsByClassName("ant-tree-list");
      console.log(list)
      if (list) {
        list.style.background = "rgb(10,10,10)";
        list.style.width = "100%";
      }
    }
  }, [])
  const pages = [
    { p: "Students", l: "/students", isCurrent: false, lastWorkedOn: false },
    { p: "Products", l: "/products", isCurrent: false, lastWorkedOn: false },
    {
      p: "Components",
      l: "/components",
      isCurrent: false,
      lastWorkedOn: false,
     
    },
    { p: "Blocks", l: "/blocks", isCurrent: false, lastWorkedOn: false },
    {
      p: "Edit Blocks [needsId]",
      l: "/blocks",
      isCurrent: false,
      lastWorkedOn: false,
    },
    {
      p: "Lecture [needsId]",
      l: "/lecture",
      isCurrent: false,
      lastWorkedOn: false,
    },
    { p: "Documents", l: "/documents" },
    { p: "New Doc", l: "/documents/new" },
    { p: "Doc (news Id)", l: "/documents/:doc_id" },
    { p: "Playlists", l: "/playlists" },
  ];
  
  function onSelect([selectedKey,...rest], info) {
    //console.log('selected', selectedKey, info);
    nav(selectedKey)
  }
 
  return (
    <Box background="none" type="row" justifyContent="flex-start">
      {/* {pages &&
        pages.map(({ p, l }, i) => (
          <Box type="row" width="150px" margin="10px" key={"link_" + i} justifyContent="center" alignItems="center">
            <Link to={l}>{p}</Link>
          </Box>
        ))} */}
      <div ref={tree_ref}>
      <Tree
        showLine={false}
        showIcon={true}
        defaultExpandedKeys={['0-0-0']}
        onSelect={onSelect}
        treeData={nested_pages}    
        defaultExpandAll={true}    
      />  
      </div>  
    </Box>
  );
};
export default Pages;
