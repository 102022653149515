import { useState, useEffect, useRef } from "react";
import { MenuBar, IconButton } from "./hoverStyles";
import { useSlate } from "slate-react";
import { Editor, Transforms, Range, Element as SlateElement } from "slate";
import { useFocused, useSelected, ReactEditor } from "slate-react";
import { Tooltip } from "antd";
import { SearchDrop } from "../../forms/term";

import {
  BoldOutlined,
  ItalicOutlined,
  UnderlineOutlined,
  QuestionOutlined,
  StrikethroughOutlined,
  QuestionCircleFilled,
  PictureFilled,
  VideoCameraAddOutlined,
  AudioFilled,
  CommentOutlined,
  AlertFilled,
  SecurityScanFilled,
  BranchesOutlined,
  UnorderedListOutlined,
  LinkOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import {
  handleInline,
  isMarkActive,
  toggleMark,
} from "../helperFunctions/index.js";
import { ToolbarWrap } from "../UI/styled";

export function Menu({ forwardRef, children }) {
  return (
    <div id={"slateJs_portal_menu"}>
      <MenuBar ref={forwardRef}>{children}</MenuBar>
    </div>
  );
}

export const HoverIcons = () => (
  <div>
    <MarkIcons />
  </div>
);
export const ToolbarTopIcons = () => (
  <ToolbarWrap showToolbar={useFocused()}>
    <TextQuestionIcons onToolbar={true} />
    <FormattingIcons onToolbar={true} />
  </ToolbarWrap>
);
export const MarkIcons = () => {
  const [open, setOpen] = useState(false);
  const wrapRef = useRef(null);
  let editor = useSlate();
  let isFocused = ReactEditor.isFocused(editor);
  let isSelected = editor?.selection;

  useEffect(() => {
    if (!isFocused && !isSelected) {
      // setOpen(false);
    }
  }, [isFocused, isSelected]);
  return (
    <div ref={wrapRef}>
      <div>
        <ToolbarButton format="bold" title="Bold" />
        <ToolbarButton format="italic" title="Italicize" />
        <ToolbarButton format="underline" title="Underline" />
        <ToolbarButton format="strikethrough" title="Strike Through" />
        <ToolbarButton format="heading-two">H2</ToolbarButton>
        <ToolbarButton format="heading-three" title="Heading 3">
          H3
        </ToolbarButton>
        <ToolbarButton format="comment" title="Comment">
          <CommentOutlined />
        </ToolbarButton>

        <ToolbarButton
          format="term"
          title="Add 'Term'"
          onClick={() => setOpen(true)}
        >
          <PlusOutlined />
        </ToolbarButton>
        <ToolbarButton
          format="question"
          title="Adds question for a given line"
          onClick={() => setOpen(true)}
        >
          <QuestionOutlined />
        </ToolbarButton>
      </div>
      <SearchDrop isOpen={open} setOpen={setOpen} />
    </div>
  );
};
export const TextQuestionIcons = ({ onToolbar }) => (
  <span>
    {/* style={{ borderLeft: "3px solid black" }} */}
    <ToolbarButton
      location={!!onToolbar}
      format=""
      title="NOT SET.Check For Errors"
    >
      <SecurityScanFilled />
    </ToolbarButton>
    <ToolbarButton location={!!onToolbar} format="" title="NOT SET.Comment">
      <CommentOutlined />
    </ToolbarButton>
    <ToolbarButton location={!!onToolbar} format="" title="NOT SET.Error">
      <AlertFilled />
    </ToolbarButton>
    <ToolbarButton
      location={!!onToolbar}
      format="question"
      title="Select Text & make a question"
    />

    <ToolbarButton
      location={!!onToolbar}
      format="inline-question-void"
      title="A question within a line (must select some text)"
    />

    <ToolbarButton
      location={!!onToolbar}
      format="question"
      title="Supposed to be a reference question"
    >
      <BranchesOutlined />
    </ToolbarButton>

    <ToolbarButton
      location={!!onToolbar}
      format="bulleted-list"
      title="Bullet list"
    >
      <UnorderedListOutlined />
    </ToolbarButton>

    <ToolbarButton location={!!onToolbar} format="" title="Knowledge Check">
      <small>Kn. Check</small>
    </ToolbarButton>
  </span>
);
export const FormattingIcons = ({ onToolbar }) => (
  <span>
    {/* style={{ borderLeft: "3px solid black" }} */}
    <ToolbarButton location={!!onToolbar} format="picture" />
    <ToolbarButton location={!!onToolbar} format="video" />
    <ToolbarButton location={!!onToolbar} format="audio" />
  </span>
);

//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~//
/**
 *
 * @param {String} props.format Is either "mark", "inline", or "block";
 * @param {Boolean} props.location Determines styles: Indicates whether the button is on hover toolbar
 * @param {String} props.title Tooltip title. Defaults to "Insert Tooltip"
 * @returns
 */
export const ToolbarButton = ({
  format,
  location,
  children,
  title,
  onClick,
}) => {
  const editor = useSlate();
  if (!format) format = "unavailable";
  let formatTypes = {
    bold: ["mark", <BoldOutlined />],
    italic: ["mark", <ItalicOutlined />],
    underline: ["mark", <UnderlineOutlined />],
    strikethrough: ["mark", <StrikethroughOutlined />],
    question: ["inline", <QuestionOutlined />],
    "inline-question-void": ["inline", <QuestionCircleFilled />],
    picture: ["inline", <PictureFilled />],
    video: ["inline", <VideoCameraAddOutlined />],
    audio: ["block", <AudioFilled />],
    "heading-one": ["block", null],
    "heading-two": ["block", null],
    "heading-three": ["block", null],
    "heading-four": ["block", null],
    "bulleted-list": ["block", null],
    "list-item": ["block", null],
    "numbered-list": ["block", null],
    link: ["inline", null],
    unknown: [null, null],
    comment: ["mark", <CommentOutlined />],
    term: ["mark", <PlusOutlined />],
  };

  function toggleButton(elementType, format) {
    if (elementType === "inline") {
      if (handleInline.isActive(editor, format)) {
        handleInline.unwrap(editor, format);
      } else {
        handleInline.insertElement(editor, format);
      }
    } else if (elementType === "block") {
      toggleBlock(editor, format);
    } else if (elementType === "mark") {
      toggleMark(editor, format);
    } else {
      alert("Unknown button type");
    }
  }
  function isButtonActive(elementType) {
    if (elementType === "inline") {
      return handleInline.isActive(editor, format);
    } else if (elementType === "block") {
      return isBlockActive(editor, format);
    } else if (elementType === "mark") {
      return isMarkActive(editor, format);
    }
  }
  let formatType = formatTypes[format] ? formatTypes[format][0] : "unknown";
  let buttonIcon = formatTypes[format] ? formatTypes[format][1] : null;
  const TogglesOnClick = ({ onClick }) => {
    return (
      <Tooltip title={title || "Insert tip"} color="pink">
        <IconButton
          reversed
          inline={!!location}
          active={isButtonActive(formatType)}
          onMouseDown={(event) => {
            event.preventDefault();
            console.log(formatType);
            toggleButton(formatType, format);
            if (onClick && typeof onClick === "function") {
              onClick(event);
            }
          }}
        >
          {format && children ? (
            <span>{children}</span>
          ) : format ? (
            buttonIcon
          ) : (
            <span>{children}</span>
          )}
        </IconButton>
      </Tooltip>
    );
  };
  return <TogglesOnClick />;
};

export function MarkButton({ format, children }) {
  const editor = useSlate();
  function formatType(format) {
    switch (format) {
      case "bold":
        return <BoldOutlined />;
      case "italic":
        return <ItalicOutlined />;
      case "underline":
        return <UnderlineOutlined />;
      case "question":
        return <QuestionOutlined />;
      case "strikethrough":
        return <StrikethroughOutlined />;
      case "comment":
        return <CommentOutlined />;
      case "inline-question-void":
        return <QuestionOutlined />;
      case "link":
        return <LinkOutlined />;
      default:
        return null;
    }
  }
  return (
    <IconButton
      reversed
      active={isMarkActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      {format && children ? (
        <span>{children}</span>
      ) : format ? (
        formatType(format)
      ) : (
        <span>{children}</span>
      )}
    </IconButton>
  );
}
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~//
export function InlineButton({ format, children, text }) {
  const editor = useSlate();
  function formatType(format) {
    switch (format) {
      case "question":
        return <QuestionOutlined />;
      case "inline-question-void":
        return <QuestionCircleFilled />;
      case "picture":
        return <PictureFilled />;
      case "video":
        return <VideoCameraAddOutlined />;
      case "audio":
        return <AudioFilled />;
      default:
        return null;
    }
  }
  return (
    <IconButton
      inline={true}
      reversed
      active={handleInline.isActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        if (handleInline.isActive(editor, format)) {
          handleInline.unwrap(editor, format);
        } else {
          handleInline.insertElement(editor, format);
        }
      }}
    >
      {format ? formatType(format) : <span>{children}</span>}
    </IconButton>
  );
}
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~//
export function VoidButton({ format, children }) {
  const editor = useSlate();
  function formatType(format) {
    switch (format) {
      case "inline-question-void":
        return <QuestionCircleFilled />;
      default:
        return null;
    }
  }
  const isActive = () => {
    const [el] = Editor.nodes(editor, {
      match: (n) =>
        !Editor.isEditor(n) && SlateElement.isElement(n) && editor.isVoid(n),
    });

    return !!el;
  };
  const unWrap = () => {
    Transforms.unwrapNodes(editor, {
      match: (n) =>
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
    });
  };

  const insertEditableVoid = (editor, format) => {
    if (!isActive()) {
      const { selection } = editor;
      const isCollapsed = selection && Range.isCollapsed(selection);

      const text = { text: "ds" };
      const voidNode = {
        type: "inline-question-void",
        children: [text],
      };
    } else {
      Transforms.removeNodes(editor, { voids: true });
    }
  };
  return (
    <IconButton
      reversed
      active={isActive()}
      onMouseDown={(event) => {
        event.preventDefault();
        insertEditableVoid(editor, format);
      }}
    >
      {format ? formatType(format) : <span>{children}</span>}
    </IconButton>
  );
}
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~//
function isBlockActive(editor, format) {
  const { selection } = editor;
  if (!selection) return false;
  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) =>
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
    })
  );

  return !!match;
}
export const toggleBlock = (editor, format) => {
  const LIST_TYPES = ["numbered-list", "bulleted-list"];
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);
  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type),
    split: true,
  });
  const newProperties = {
    type: isActive ? "paragraph" : isList ? "list-item" : format,
  };
  if (format === "audio") newProperties.tracks = [];
  Transforms.setNodes(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};
