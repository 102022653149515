import styled from 'styled-components';
import { PausePlayWrap } from './icons';

const flex = styled.div`
  height: 40px;
  padding: 8px;
  display: flex;
  flex-flow: row nowrap;
  place-content: center;
  align-items: center;
  box-sizing: border-box;
`;
export const ControlWrapper = styled(flex)`
  visibility: ${(props) =>
    !props.showControls ? 'hidden' : 'visible'};
  opacity: ${(props) => (props.showControls ? '1' : '0')};
  color: black;
  z-index: ${(props) =>
    props.fullScreen ? ' 2147483648' : 'inherit'};
  width: 100%;
  position: ${(props) =>
    props.fullScreen ? 'absolute; bottom: 20px' : 'default'};
  writing-mode: horizontal-tb;
  height: 50px;
  position: relative;
  //bottom: 47px;
  transition: all 0.4s ease-in;
  background: white;
  width: min-content;
  min-width: 450px;
  border-radius: 15px;
  margin: 0 auto;
`;
export const VideoControlsWrap = styled(flex)`
  place-content: stretch flex-start;
  flex: auto;
  width: 30%;
`;
export const VideoDurationsWrap = styled(flex)`
  place-content: center;
  flex: auto;
  width: 25%;
`;
export const VideoDisplayWrap = styled(flex)`
  place-content: stretch flex-end;
  flex: auto;
  width: 30%;
`;
export const IconWrap = styled.div`
  outline: none;
  cursor: pointer;
  display: inline-flex;
  padding: 10px 8px;
  transition: color 150ms ease 0s;
  width: min-content;
  color: white;
  & ${PausePlayWrap} {
    transform: translateY(3px);
  }
`;
export const SoundIconWrap = styled(IconWrap)`
  display: flex;
  flex-flow: row nowrap;
  place-content: center space-around;
  color: rgb(184, 193, 207);
  &: hover {
    color: white;
  }
`;
export const TimeWrap = styled(IconWrap)`
  width: min-content;
  white-space: nowrap;
  color: rgb(184, 193, 207);
  color: black;
  transform: ${(props) =>
    props.soundState ? 'translateX(15px)' : 'translateX(-55px)'};
  transition: transform 0.7s cubic-bezier(0.7, 0, 0.05, 1) 0s;
`;
export const CenterIcon = styled(IconWrap)`
  color: ${(props) =>
    props.active ? '#f50057' : 'rgb(184, 193, 207)'};

  transition: all 0.4s cubic-bezier(0.7, 0, 0.05, 1) 0s;
  &:hover {
    color: #f50057;
    transform: scale(1.4);
  }
  &:active {
    transform: scale(1.2);
  }
`;

export const VolumeWrap = styled.div`
  width: 64px;
  position: relative;
  display: flex;
  align-items: center;
  // pointer-events: none;
`;
export const VolumeThumb = styled.div`
  width: 10px;
  height: 10px;
  opacity: ${(props) => (props.showVolume ? '1' : '0')};
  transform: ${(props) =>
    !props.showVolume
      ? 'translateX(-5px) scale(0.2)'
      : 'translateX(' + props.level * 56 + 'px) scale(1)'};
  transition: ${(props) =>
    props.cancelAnimations
      ? `none 0s ease 0s`
      : `opacity 1s cubic-bezier(0.7, 0, 0.05, 1) 0s,
    transform 0.3s cubic-bezier(0.7, 0, 0.05, 1) 0s`};

  border-radius: 50%;
  background-color: rgb(241, 243, 246);
  background-color: grey;
  position: absolute;
  z-index: 3;
`;
export const VolumeSlider = styled.input`
  appearance: none;
  cursor: pointer;
  padding: 4px 0px;
  width: 100%;
  background: transparent;
  height: 6px;
  position: absolute;
  z-index: 3;
  border: transparent;
  width: 100%;
  background: transparent;
  &:focus {
    outline: none;
  }
  &::-webkit-slider-thumb {
    visibility: hidden;
    background: transparent;
  }
`;
export const VolumeBarWrap = styled.div`
  width: 64px;
  height: 4px;
  position: relative;
  border-radius: 2px;
  overflow: hidden;
`;
export const SliderTrackBackground = styled.div`
  width: 64px;
  height: 4px;
  margin: auto 0;
  border-radius: 2px;
  background-color: rgb(50, 55, 69);
  background-color: black;
  transform: ${(props) =>
    props.showVolume ? 'translateX(0px)' : 'translateX(-64px)'};
  transition: transform 1.1s cubic-bezier(0.7, 0, 0.05, 1) 0s;
  position: absolute;
`;
// prettier-ignore
export const SliderTrack = styled.div`
  width: 64px;
  height: 4px;
  border-radius: 2px;
  opacity: ${props => (props.showVolume ? "1" : "0")};
  transform: ${props =>
    !!props.showVolume
      ? "translateX(" + (-56 + props.level * 56) + "px)"
      : "translateX(-64px)"};
  will-change: opacity;
  transition: opacity 0.6s cubic-bezier(0.7, 0, 0.05, 1) 0s,
    transform 0.3s cubic-bezier(0.7, 0, 0.05, 1) 0s;
  background-color: ${props =>
    props.showVolume ? "grey" : "white"};
  position: absolute;
`
