import React, { useRef, useState, useEffect } from "react";
import { useSlate, useSlateStatic } from "slate-react";
import { Range, Node, Path } from "slate";

import { qModalStyle, QuestionPopup } from "../UI/styled";

let { Container, Content, InputWrapper, Label, Input, ButtonWrapper, Select } =
  qModalStyle;

export function QuestionPopover() {
  const [data, setData] = useState(null);
  const inputRefs = useRef(null);
  const rangeInputRef = useRef(null);
  const qInputRef = useRef(null);
  const ref = useRef(null);
  const el = ref.current;
  const editor = useSlate();

  let state = useRef({ parentNode: "", inputRange: "", activeRef: null });

  function positionToolbar(el) {
    const domSelection = window.getSelection();
    const domRange = domSelection.getRangeAt(0);

    const rect = domRange.getBoundingClientRect();
    //~~ Get position~~//
    let topPos = rect.top + window.pageYOffset + 50;
    let leftPos =
      rect.left + window.pageXOffset - el.offsetWidth / 2 + rect.width / 2;

    //~~ Apply limits~~//
    let leftLimit = 150;
    let rightLimit = 930;
    leftPos =
      leftPos < leftLimit
        ? leftLimit
        : leftPos > rightLimit
        ? rightLimit
        : leftPos;
    //~~ Apply styles~~//
    el.style.opacity = "1";
    el.style.top = `${topPos}px`;
    el.style.left = `${leftPos}px`;
  }
  function removeToolbar(el) {
    el.removeAttribute("style");
  }
  function shouldShowQuestion(el, selection, editor) {
    if (selection?.focus?.path) {
      const parentPath = Path.parent(selection.focus.path);
      const parentNode = Node.get(editor, parentPath);
      return parentNode.type === "question";
    }
    return false;
  }
  function getSelectedText() {
    if (window.getSelection) {
      return window.getSelection().toString();
    } else if (document.selection && document.selection.type !== "Control") {
      return rangeInputRef.current.value;
    }
  }

  if (!!state?.current?.activeRef && typeof window !== "undefined") {
    let rangeFieldActive = state?.current?.activeRef === "range";
    let inputFieldActive = state?.current?.activeRef === "question";
    let shouldGetSelection = rangeFieldActive || inputFieldActive;

    if (shouldGetSelection && rangeFieldActive) {
      rangeInputRef.current.value = getSelectedText();
    } else if (shouldGetSelection && inputFieldActive) {
      qInputRef.current.value = getSelectedText();
    }

    if (editor?.selection) {
      let start = Range.start(editor.selection);
      let end = Range.end(editor.selection);
      let inputRange = JSON.stringify({ start, end });
      const parentPath = Path.parent(editor.selection.focus.path);
      const parentNode = Node.get(editor, parentPath);
      state.current.parentNode = parentNode;
      state.current.inputRange = inputRange;
    }
  }

  useEffect(() => {
    const { selection } = editor;
    if (!el || !shouldShowQuestion(el, selection, editor)) {
      if ((!state || !state.current.activeRef) && el) removeToolbar(el);
      return;
    }
    if (typeof window !== "undefined") {
      positionToolbar(el);
      //Store value of the selected
    }
  });

  return (
    <QuestionPopup ref={ref}>
      <>
        <Container>
          <Content>
            <InputWrapper>
              <Select>
                <option>Check Referrent</option>
                <option>True or False?</option>
                <option>One-word Answer</option>
                <option>Implication-based Question</option>
              </Select>
            </InputWrapper>
            <InputWrapper>
              <Label htmlFor="question">Question</Label>
              <Input
                type="text"
                placeholder="Reference: Don't type...select"
                name="question"
                ref={qInputRef}
                onClick={(e) => {
                  state.current.activeRef = "question";
                }}
              />
            </InputWrapper>
            <InputWrapper>
              <Label htmlFor="range">Answer</Label>
              <Input
                type="text"
                placeholder="Don't type...select"
                name="range"
                ref={rangeInputRef}
                onClick={(e) => {
                  state.current.activeRef = "range";
                }}
              />
            </InputWrapper>
            <ButtonWrapper>
              <button type="submit">Save</button>
              <button
                type="submit"
                onMouseDown={(e) => {
                  e.preventDefault();
                  removeToolbar(el);
                }}
              >
                Close
              </button>
            </ButtonWrapper>
          </Content>
        </Container>
      </>
    </QuestionPopup>
  );
}
const MultipartMap = (array, qInputRef, state) => {
  const btnRef = useRef([]);

  return (
    <>
      <InputWrapper>
        <Label htmlFor="question">Question</Label>
        <Input
          type="text"
          placeholder="Reference: Don't type...select"
          name="question"
          ref={qInputRef}
          onClick={(e) => {
            state.current.activeRef = "question";
          }}
        />
      </InputWrapper>
    </>
  );
};
