import React, { useState, useEffec, useContext } from "react";
import { MediaContext } from "../v2/masterContext.js";
import {
  ControlWrapper,
  VideoControlsWrap,
  VideoDurationsWrap,
  VideoDisplayWrap,
  IconWrap,
  SoundIconWrap,
  VolumeWrap,
  VolumeBarWrap,
  VolumeThumb,
  VolumeSlider,
  SliderTrackBackground,
  SliderTrack,
  TimeWrap,
  CenterIcon,
} from "./controlStyles.js";
import { Play, Pause, Sound, FullScreen } from "./icons";

/* 
    State: {
        1. CurrentTime. 
        2. Playing/Volume
        3/ FullScreen
        4. Speed.
        // shouldn't all player logic occur here? 
    }
    player: {
        1. tracks => refList
        2. currentTrack => ref.
        3. Questions
    }

   controls: { 
        1. Pause/Play; ==> updates ref. 
        2. Speaker ==> changes refList
        3. Volume bar (on speaker hover)
        4. fullscreen ?? ... maybe has to apply to the wrapper.
        5. Progress meter (broken down or single)
        6. Timestamp
    }
*/

export default function VideoControls(props) {
  const [soundHovered, setHover] = useState(false);

  const {
    mediaRef,
    currentTrack,
    isMute,
    setMute,
    volume,
    changeVolume,

    isPlaying,
    rwdFiveSec,
    onScrubEnd,
    fwdFiveSec,
    isFullScreen,
    setFullScreen,
    togglePlay,
  } = useContext(MediaContext);

  const onPrevClick = () => {
    rwdFiveSec();
    onScrubEnd();
  };
  const onNextClick = () => {
    fwdFiveSec();
    onScrubEnd();
  };

  //// LEGACY /////

  //Video Controls.

  const toggleSound = (newVolume) => {
    if (newVolume !== volume) {
      if (isMute && newVolume !== "0") {
        setMute(false);
      } else if (newVolume === "0") {
        setMute(true);
      } else {
        changeVolume(newVolume);
        mediaRef.current.forEach((t) => {
          t.volume = newVolume;
        });
      }
    }
  };
  const toggleMute = () => {};
  return (
    <ControlWrapper fullScreen={isFullScreen} showControls={true}>
      <VideoControlsWrap>
        <IconWrap
          className="tooltip"
          onClick={() => {
            togglePlay();
          }}
        >
          <span className="tooltiptext">
            {!isPlaying ? <Play /> : <Pause />}
          </span>
        </IconWrap>
        <SoundIconWrap
          onClick={() => {
            setMute(!isMute);
            if (volume === "0") toggleSound(0.8);
          }}
          onMouseEnter={() => {
            setHover(true);
          }}
          onMouseLeave={() => setHover(false)}
        >
          <Sound volume={volume} mute={isMute} />
        </SoundIconWrap>
        <VolumeTrack
          hoverState={soundHovered}
          level={volume}
          changeVolume={toggleSound}
          changeHover={setHover}
        />
        <Timer hoverState={soundHovered} />
        <TimeWrap soundState={soundHovered}>
          {currentTrack + 1} / {props?.tracks?.length || 1}
        </TimeWrap>
      </VideoControlsWrap>
      {/* <VideoDurationsWrap>
       
      </VideoDurationsWrap> */}
      <VideoDisplayWrap>
        <CenterIcon onClick={() => setFullScreen(!isFullScreen)}>
          <FullScreen />
        </CenterIcon>
      </VideoDisplayWrap>
    </ControlWrapper>
  );
}

const Timer = ({ hoverState }) => {
  const { playerProgress, totalTime, convertTimeIntToString } =
    useContext(MediaContext);
  const convertTime = convertTimeIntToString;
  return (
    <TimeWrap soundState={hoverState}>
      {playerProgress ? convertTime(Math.ceil(playerProgress)) : "00:00:00"} /{" "}
      {totalTime ? convertTime(Math.ceil(totalTime)) : "NaN"}
    </TimeWrap>
  );
};
const VolumeTrack = ({ hoverState, changeHover, level, changeVolume }) => {
  const [sliderClicked, setClicked] = useState(false);

  return (
    <VolumeWrap>
      <VolumeBarWrap>
        <SliderTrackBackground showVolume={hoverState} />
        <SliderTrack showVolume={hoverState} level={level} />
      </VolumeBarWrap>
      <VolumeThumb
        htmlFor="Volume Slider"
        showVolume={hoverState}
        level={level}
        cancelAnimations={sliderClicked}
      />
      <VolumeSlider
        showVolume={hoverState}
        id="Volume Slider"
        onMouseEnter={() => {
          changeHover(true);
        }}
        onMouseLeave={() => changeHover(false)}
        type="range"
        min={0}
        max={1}
        step={0.02}
        level={level}
        value={level}
        onChange={(e) => {
          changeVolume(e.target.value);
        }}
      />
    </VolumeWrap>
  );
};
