import React, { useState, useEffect, useRef } from "react";
import { AudioOutlined, AudioMutedOutlined } from "@ant-design/icons";
import styled from "styled-components";
/**
 * @param  {Boolean} props.isPhrase
 * @param {Boolean} props.runOnFirstRender
 * @param  {Array} props.vocab
 * @param  {Boolean} props.showSpeech
 * @param  {Function} props.onResult=(r
 * @param  {Function} props.onError
 * @param  {Function} props.onLowConfidence
 * @param {string} props.size "l" "m" "s" 


 */
const Mic = (props) => {
  let { isPhrase, vocab, size, showSpeech } = props;
  let { onResult, onError, onLowConfidence } = props;
  if (!onResult) onResult = (r) => console.log(r);
  if (!onError) onError = (r) => console.log(r);

  const [start, setStart] = useState(!!props.runOnFirstRender);
  const [recognition, setRecognition] = useState(null);

  let SpeechRecognition =
    window.SpeechRecognition || window.webkitSpeechRecognition;
  let SpeechGrammarList =
    window.SpeechGrammarList || window.webkitSpeechGrammarList;

  const msgRef = useRef({});

  useEffect(() => {
    function init() {
      if (vocab === undefined) vocab = ["true", "false"];
      var grammar = "#JSGF V1.0; grammar phrase; public <phrase> = ";
      vocab.forEach((word) => (grammar += word + ";"));

      let newSR = new SpeechRecognition();
      let newRecognitionList = new SpeechGrammarList();

      newRecognitionList.addFromString(grammar, 1);
      newSR.grammars = newRecognitionList;
      newSR.lang = "en-US";
      newSR.continuous = !!isPhrase; //Gives us words;
      newSR.interimResults = false; //Get results once done speaking
      newSR.maxAlternatives = 1;

      newSR.onstart = (event) => {
        //Fired when the speech recognition service has begun listening to incoming audio with intent to recognize grammars associated with the current SpeechRecognition.
        if (showSpeech) {
          msgRef.current.innerText = "Leggo";
          msgRef.current.style.backgroundColor = "var(--primary-color";
        }

        console.log("SpeechRecognition.onstart");
      };
      newSR.onaudiostart = (event) => {
        //Fired when the user agent has started to capture audio.
        if (showSpeech) {
          msgRef.current.innerText = "Speak louder please! I can't hear you";
          console.log(
            "I hear something...sounds gibberish so far. SPEAK UP BRUH"
          );
        }
      };
      newSR.onspeechstart = (event) => {
        if (showSpeech) {
          msgRef.current.innerText = "Nowww, I can hear you";
          //Fired when sound that is recognised by the speech recognition service as speech has been detected.
          console.log("Wait! yeah I'm understanding you!");
        }
      };
      newSR.onspeechend = () => {
        newSR.stop();
        console.log("K. You just stopped speaking! ");
        setStart(false);
      };
      newSR.onaudioend = (event) => {
        //Fired when the user agent has finished capturing audio.
        if (showSpeech) {
          msgRef.current.innerText = "You stopped speaking! Try again";
          console.log("You stopped speaking!");
        }
      };
      newSR.onresult = (event) => {
        if (!event) return;

        let speechResult = event.results[0][0].transcript.toLowerCase();
        let confidence = event.results[0][0].confidence || 0;
        let msg = `I'm ${Math.ceil(
          confidence * 100
        )}% you said: ${speechResult}`;

        console.log("Confidence: " + event.results[0][0].confidence);
        //update msg;
        if (showSpeech) {
          msgRef.current.innerText = msg;
        }
        if (confidence < 80 && onLowConfidence !== undefined) {
          onLowConfidence();
        }
        console.log(speechResult);
        onResult(speechResult);
      };
      newSR.onend = (event) => {};
      newSR.onerror = (event) => {
        console.log(event.errors);
      };
      newSR.onnomatch = (event) => {
        //Fired when the speech recognition service returns a final result with no significant recognition. This may involve some degree of recognition, which doesn't meet or exceed the confidence threshold.
        if (showSpeech) {
          msgRef.current.innerText = "Dafuq you just say?";
        }
        console.log("Dafuq you just say?");
      };

      setRecognition(newSR);
    }
    if (recognition === null) {
      init();
    }
  }, []);

  useEffect(() => {
    if (start && recognition !== null) {
      recognition.start();
    } else if (!start && recognition !== null) {
      recognition.stop();
    }
  }, [start, recognition]);
  if (!onResult) {
    throw new Error("Key property missing: onResult");
  }
  if (!onError) {
    throw new Error("Key property missing: onError");
  }
  function sizeFont() {
    let iconSize = { s: 16, m: 18, l: 24 };
    return { fontSize: `${iconSize[size ?? 16]}px` };
  }
  function styleBtn() {
    let btnSize = { s: 32, m: 34, l: 40 };
    let result = {
      width: `${btnSize[size]}px`,
      height: `${btnSize[size]}px`,
      cursor: "pointer",
      borderRadius: "50%",
      justifyContent: "center",
      alignItems: "center",
    };

    return result;
  }

  return (
    <Wrap>
      <button
        onMouseDown={() => {
          setStart(!start);
        }}
        style={styleBtn()}
      >
        {!start ? (
          <AudioOutlined style={sizeFont()} />
        ) : (
          <AudioMutedOutlined style={sizeFont()} />
        )}
      </button>
      {props.showSpeech && <MicBubble ref={msgRef}></MicBubble>}
    </Wrap>
  );
};
export default Mic;

const MicBubble = styled.span`
  position: relative;
  display: inline-block;
  width: 80%;
  height: 100%;
  background-color: transparent;
  color: blue;
  padding: 5px;
  margin-left: auto;
  border-radius: 15px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  transition: background-color 0.1s ease-in;
  &:before {
    position: absolute;
    display: inline-block;
    bottom: 0;
    left: 0;
    width: 50px;
    height: 50px;
    transform: rotateX(90deg);
    background-color: var(--primary-color);
  }
`;
const Wrap = styled.span`
  display: inline-flex;
  width: 100%;
  justify-content: space-around;
  stroke: var(--primary-color);
  & button {
    svg {
    }
  }
`;
