import { useState, useEffect, useRef } from "react";
import StudentProfileCourse from "./courses";
import { Box, Collapse, TabGroup } from "../../layout/containers/box";
import { useParams } from "react-router-dom";
import { GET, PUT } from "../../../helpers/REST/api";
import dateFormatter, { dateDifference, addDaysToDate } from "../../../helpers/formatDate";
import { Tooltip } from "antd";
import { SelectField } from "../../layout/containers/input";
import { toast } from "react-toastify";
import { DragWrap } from "./EditableStudyPlan";
import { Button } from "../../layout/buttons/buttons";

const StudentProfile = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [userData, setUserData] = useState({ memberships: null });
  const { userId } = useParams();

  useEffect(() => {
    const filter = "SELECT=createdAt,membershipStatus,membershipType,productType,productId,studyPlan,standing";
    GET(`/members/userId/${userId}?${filter}`).then((list) => {
      setUserData({ ...userData, memberships: list });
    });
  }, []);

  function setMembershipProps(membershipIndex, key, value) {
    if (membershipIndex > -1 && key && value && Array.isArray(userData.memberships) && typeof userData.memberships[membershipIndex] === "object") {
      let memshipsArrayCopy = [...userData.memberships];
      memshipsArrayCopy[membershipIndex][key] = value;
      setUserData({ ...userData, memberships: memshipsArrayCopy });
    }
  }

  return (
    <Box background="none" type="column">
      <TabGroup tabs={["Overview", "Courses", "History", "Financials"]} setTab={setActiveTab} activeIndex={activeTab} padding="0" width="min-content" />
      {Array.isArray(userData?.memberships) &&
        userData.memberships.map((membership, mi) => {
          return <CourseCard membership={membership} index={mi} key={"memberships" + mi} onChange={setMembershipProps} />;
        })}
    </Box>
  );
};
export default StudentProfile;

// const tempStudyPlan = [
//   {
//     layerType: "Subject",
//     childType: "unit",
//     children: [
//       {
//         layerType: "unit",
//         childType: "document",
//         children: [
//           {
//             _id: "644243b261c024fde163bc4b",
//             parentContainer: ["641e7328a19cff215fcc5ea2"],
//             parentDoc: ["63fd142653a243b49bbf0925"],
//             blocks: ["644243b361c024fde163bc4d"],
//           },
//           {
//             _id: "6446e988cbf3767e789f28a3",
//             parentContainer: ["641e7328a19cff215fcc5ea2"],
//             parentDoc: ["63fd14830a08e1aafd55a32b"],
//             blocks: ["6446e988cbf3767e789f28a7", "6446e988cbf3767e789f28a9", "6446e988cbf3767e789f28ab"],
//           },
//         ],
//         _id: "641e7328a19cff215fcc5ea2",
//       },
//       {
//         layerType: "unit",
//         childType: "document",
//         children: [
//           {
//             _id: "641e81bfeb26408113611308",
//             parentContainer: ["641e7f03a19cff215fcc5fc5"],
//             parentDoc: ["63fd14830a08e1aafd55a32b"],
//             blocks: ["641e81bfeb2640811361130a"],
//           },
//         ],
//         _id: "641e7f03a19cff215fcc5fc5",
//       },
//       {
//         layerType: "unit",
//         childType: "document",
//         children: [],
//         _id: "6420d17c5c630f3fa8929785",
//       },
//       {
//         layerType: "unit",
//         childType: "document",
//         children: [],
//         _id: "642a02c5a48bee610a5f7b2c",
//       },
//       {
//         layerType: "unit",
//         childType: "document",
//         children: [],
//         _id: "642a092ca48bee610a5f7bd2",
//       },
//       {
//         layerType: "unit",
//         childType: "document",
//         children: [],
//         _id: "642a0973a48bee610a5f7bf5",
//       },
//       {
//         layerType: "unit",
//         childType: "document",
//         children: [],
//         _id: "642a09a4a48bee610a5f7bff",
//       },
//       {
//         layerType: "unit",
//         childType: "document",
//         children: [],
//         _id: "642a09aba48bee610a5f7c09",
//       },
//       {
//         layerType: "unit",
//         childType: "document",
//         children: [],
//         _id: "642a09b4a48bee610a5f7c13",
//       },
//     ],
//     _id: "6420d17c5c630f3fa8929785",
//   },
//   {
//     layerType: "Subject",
//     childType: "unit",
//     children: [],
//     _id: "642a0973a48bee610a5f7bf5",
//   },
// ];
//Courses; services;

const CourseCard = (props) => {
  const { membership, onChange, index } = props;
  let { createdAt, membershipType, membershipStatus, _id, studyPlan, productId } = membership;

  const saveBtnRef = useRef(null);
  // Start & End Dates.
  const sDate = dateFormatter(createdAt, "d/m/y", { month: "short" });
  const eDate = addDaysToDate(createdAt, 30); //TODO: Add course end date to student membership!
  const daysRemaining = dateDifference(sDate, eDate);
  //Membership-type "Select" Config.

  let currMembershipType = -1;
  const membershipTypeOptions = [
    { label: "Is a student", value: "STUDENT" },
    { label: "Is a moderator", value: "MODERATOR" },
    { label: "Is a course admin", value: "COURSE_ADMIN" },
  ];
  membershipTypeOptions.forEach((mType, optIndex) => {
    if (mType.value.toLowerCase() === membershipType.toLowerCase()) {
      currMembershipType = optIndex;
    }
  });
  //Membership-status "Select" Config.
  let statuses = [
    "REQUESTED ACCESS",
    "PROCESSING",
    "PENDING_PAYMENT",
    "PENDING_CONTRACT",
    "CANCELLED", // Didn't complete payment/contract
    "ACTIVE",
    "INACTIVE", // e.g. someone who is taking a break before resuming.
    "ENDED", // End of term.
    "EXTENDED",
    "RESTRICTED", // End of term but can see course content;
    "PAUSED", // Based on student-instructor agreement; Implies it can be resumed.
    "SUSPENDED",
    "REVIEW", // e.g. someone is not doing their work.
    "TERMINATED", // Student ends their contract prior to completion
    "REQUESTED REFUND",
    "REQUESTED EXTENSION",
    "REQUEST PAUSE",
  ];
  let statusIndex = statuses.indexOf(membershipStatus);
  let statusesOpts = statuses.map((v) => ({ value: v, label: v[0] + v.substring(1).toLowerCase() }));

  //METHODS
  function changeStatus({ value }) {
    let confirm = prompt("This is really not recommended. Are you sure you want to proceed? (y/n)");
    if (confirm && confirm.toLowerCase() === "y") {
      if (typeof value === "string" && value.length > 0) {
        PUT(`/members/id/${_id}/membershipStatus/${value}`).then((r) => {
          onChange(index, "membershipStatus", value);
          toast.success("Status updated");
        });
      }
    } else {
      toast.info("Status change cancelled. Ignore the field: it's wrong");
    }
  }
  function changeType({ value }) {
    let confirm = prompt("This is really not recommended. Are you sure you want to proceed? (y/n)");
    if (confirm && confirm.toLowerCase() === "y") {
      if (typeof value === "string" && value.length > 0) {
        PUT(`/members/id/${_id}/membershipType/${value}`).then((r) => {
          onChange(index, "membershipType", value);
          toast.success("Status updated");
        });
      }
    } else {
      toast.info("Status change cancelled. Ignore the field: it's wrong");
    }
  }
  function reorderStudyPlanAndEnableSave(array) {
    onChange(index, "studyPlan", array);
    toast.info("Don't forget to save when you've completed the changes");
    enableSaveBtn();
  }
  function saveChangesAndDisableSave() {
    let prevPlan = [...studyPlan];
    PUT(`/members/id/${_id}/studyPlan`, { studyPlan })
      .then(() => {
        toast.success("Reordered!");
        disableSaveBtn();
      })
      .catch(() => {
        toast.warn("Unable to save new changes.");
        onChange(index, "studyPlan", prevPlan);
      });
  }
  function resetToOriginalAndDisableSave() {
    let confirm = prompt("Be super cautious! \n\n This will DELETE the current plan entirely and replace it with whatever the current product has. \n\n Are you sure you want to proceed? (y/n)");
    if (confirm && confirm.toLowerCase() === "y") {
      PUT(`/members/id/${_id}/restoreStudyPlan?productId=${productId}`).then((result) => {
        onChange(index, "studyPlan", result);
        disableSaveBtn();
      });
    } else {
      toast.info("Status change cancelled. Ignore the field: it's wrong");
    }
  }
  function disableSaveBtn() {
    if (!saveBtnRef?.current?.disabled) {
      saveBtnRef.current.disabled = true;
    }
  }
  function enableSaveBtn() {
    if (saveBtnRef?.current?.disabled) {
      saveBtnRef.current.disabled = false;
    }
  }
  //Disable Save button initially.
  useEffect(() => {
    if (saveBtnRef?.current) {
      saveBtnRef.current.disabled = true;
    }
  }, []);
  return (
    <Box type="column" title={membership.productType}>
      <div className="flex-row justify">
        <div>
          <div>
            <Tooltip title={membership.productName}>
              <strong style={{ color: "var(--primary-color)" }}>{membership.productAbbr}</strong>
            </Tooltip>
            <span> ({membership.productType})</span>
          </div>
          <div>
            <span style={{ color: "var(--primary-color)" }}> {daysRemaining} days till end</span>
            <span>
              {" "}
              (<em>{sDate} </em>
              {" - "}
            </span>
            <span>
              <em>{eDate}</em>)
            </span>
          </div>
        </div>
      </div>
      <div className="flex-row justify" style={{ margin: "1em 0" }}>
        <div style={{ marginRight: "1em" }}>
          <label style={{ color: "snow" }}>Current Status</label>
          <SelectField onChange={(v) => changeStatus(v)} options={statusesOpts} defaultValue={statusesOpts[statusIndex]} width="250px" isSearchable={false} />
        </div>
        <div style={{ marginLeft: "1em" }}>
          <label style={{ color: "snow" }}>Membership Type</label>
          <SelectField onChange={(v) => changeType(v)} options={membershipTypeOptions} defaultValue={membershipTypeOptions[currMembershipType]} width="250px" isSearchable={false} />
        </div>
      </div>
      {!!membership && !!studyPlan && Array.isArray(studyPlan) && (
        <Box type="col left" background="transparent" width="100%" padding="0">
          <Box type="row" justifyContent="space-between" alignItems="center" background="transparent" width="100%" padding="0">
            <h2>Plan</h2>
            <Box type="row" justifyContent="space-between" background="transparent" width="350px">
              <Button
                title="Caution! Only use if plan has accidentally been deleted."
                onMouseDown={() => {
                  resetToOriginalAndDisableSave();
                }}
              >
                Restore To Original
              </Button>
              <Button
                onMouseDown={() => {
                  saveChangesAndDisableSave();
                }}
                fwdRef={saveBtnRef}
              >
                Save
              </Button>
            </Box>
          </Box>
          <DragWrap data={studyPlan} setData={reorderStudyPlanAndEnableSave} mid={_id} pid={productId} />
        </Box>
      )}
    </Box>
  );
};
