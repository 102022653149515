import { v4 as uuidv4 } from "uuid";
import { Editor, Range } from "slate";
import styled from "styled-components";
import { CommentContext } from "../hooks";
import { useContext, useState, useEffect } from "react";
import { useSlateStatic } from "slate-react";
import { Drawer } from "antd";

const QUESTION_PREFIX = "qid_";

// STYLES + WRAPPER
const Wrap = styled.span`
  background-color: ${(props) =>
    props.isActive ? "#00BFA6" : "rgb(0, 191, 166, 0.1)"};
  color: white;
  border: 1px solid rgb(255, 255, 255, 0.15);
  border-style: dashed;
`;
export function QuestionText(props) {
  const { questionIds, textNode, ...otherProps } = props;
  const editor = useSlateStatic();
  const [shortestFragment, setShortestFragment] = useState(null);
  useEffect(() => {
    let shortest = getSmallestCommentThreadAtTextNode(editor, textNode);
    setShortestFragment(shortest);
  }, []);
  useEffect(() => {
    let shortest = getSmallestCommentThreadAtTextNode(editor, textNode);
    setShortestFragment(shortest);
  }, [questionIds]);

  //1. ADD CONTEXT HERE'
  const { activeComment, setActiveComment } = useContext(CommentContext);

  //2. OnClick --> setActive the smallest comment thread
  function onClickHandler(e) {
    if (activeComment === shortestFragment) {
      setActiveComment(null);
    } else {
      setActiveComment(shortestFragment);
    }
  }

  return (
    <Wrap
      {...otherProps}
      onClick={onClickHandler}
      isActive={questionIds.has(activeComment)}
    >
      {props.children}
    </Wrap>
  );
}

//HIGHLIGHT COMMENT + HELPERS
export function isMarkPropertyAComment(marks) {
  return marks.indexOf(QUESTION_PREFIX) === 0;
}
export function getAllIdsFromText(textNode) {
  return new Set(
    Object.keys(textNode)
      .filter(isMarkPropertyAComment)
      .map(decodeCommentThreadIdFromMarkProperty)
  );
}
export function createUniqueMark(threadId) {
  return `${QUESTION_PREFIX}${threadId}`;
}
export function decodeCommentThreadIdFromMarkProperty(mark) {
  if (!isMarkPropertyAComment(mark)) {
    throw new Error("Expected mark to be of a comment thread");
  }
  return mark.replace(QUESTION_PREFIX, "");
}

//ADD COMMENT
/**
 * Returns threadID
 * @param  {Object} editor
 * @param  {Function} addCommentThreadToState CommentContext ==> activateComment
 */
export function insertQuestion(editor, addCommentThreadToState) {
  const threadID = uuidv4();
  const newQuestion = {
    // comments as added would be appended to the thread here.
    comments: [],
    creationTime: new Date(),
    // Newly created comment threads are OPEN. We deal with statuses
    // later in the article.
    status: "open",
  };

  if (addCommentThreadToState) {
    addCommentThreadToState(threadID, newQuestion);
  }
  Editor.addMark(editor, createUniqueMark(threadID), true);
  Editor.addMark(editor, "isBreakpoint", true);
  return threadID;
}

//We shouldn't comment something that's already commented
export function shouldAllowNewQuestionAtSelection(editor, selection) {
  //console.log(selection, Range.isCollapsed(selection));
  if (selection == null || Range.isCollapsed(selection)) {
    return false;
  }

  const textNodeIterator = Editor.nodes(editor, {
    at: selection,
    mode: "lowest",
  });

  let nextTextNodeEntry = textNodeIterator.next().value;
  const textNodeEntriesInSelection = [];
  while (nextTextNodeEntry != null) {
    textNodeEntriesInSelection.push(nextTextNodeEntry);
    // console.log(textNodeEntriesInSelection);
    nextTextNodeEntry = textNodeIterator.next().value;
  }
  //console.log(textNodeEntriesInSelection.length, "Node entries length");
  if (textNodeEntriesInSelection.length === 0) {
    return false;
  }

  return textNodeEntriesInSelection.some(([textNode]) => {
    let result = getAllIdsFromText(textNode).size === 0;
    //console.log(result, "textNodeEntriesInSelection");
    return result;
  });
}

//The smallest selected commented-text should come first!
export function getSmallestCommentThreadAtTextNode(editor, textNode) {
  //console.log(textNode); --> e.g. {text: "", comment_uniqueID: true}
  const commentThreadsMap = getAllIdsFromText(textNode); // Get all the comment threads
  const commentThreadsAsArray = [...commentThreadsMap];
  //console.log(commentThreadsAsArray[0]);
  let shortestCommentThreadID = commentThreadsAsArray[0];

  if (commentThreadsAsArray.length > 1) {
    // The map here tracks the lengths of the comment threads.
    // We initialize the lengths with length of current text node
    // since all the comment threads span over the current text node
    // at the least.
    const commentThreadsLengthByID = new Map(
      commentThreadsAsArray.map((id) => [id, textNode.text.length])
    );

    /**
     * 1. Get prev. text node
     * 2. Traverse in the reverse direction, & update map
     */
    const reverseTextNodeIterator = (slateEditor, nodePath) =>
      Editor.previous(slateEditor, {
        at: nodePath,
        mode: "lowest",
        match: Text.isText,
      });
    //
    updateCommentThreadLengthMap(
      editor,
      commentThreadsMap,
      reverseTextNodeIterator,
      commentThreadsLengthByID
    );

    /**
     * 1. Get next. text node
     * 2. Traverse to forwards node, & update map
     */
    const forwardTextNodeIterator = (slateEditor, nodePath) =>
      Editor.next(slateEditor, {
        at: nodePath,
        mode: "lowest",
        match: Text.isText,
      });

    updateCommentThreadLengthMap(
      editor,
      commentThreadsMap,
      forwardTextNodeIterator,
      commentThreadsLengthByID
    );

    let minLength = Number.POSITIVE_INFINITY;

    /**
     *  Find the thread with the shortest length.
     */
    for (let [threadID, length] of commentThreadsLengthByID) {
      if (length < minLength) {
        shortestCommentThreadID = threadID;
        minLength = length;
      }
    }
  }

  return shortestCommentThreadID;
}
/**
 * APPLIES TO PREV FUNC. i.e. GET_SMALLEST_COMMENT_AT_TEXT_NODE FUNC ;
 * @param  {Object} editor
 * @param  {Array} commentThreads Array of ids;
 * @param  {function} nodeIterator Func that iterates backwards or forwards;
 * @param  {Map} map Result that will be updated
 */
function updateCommentThreadLengthMap(
  editor,
  commentThreads,
  nodeIterator,
  map
) {
  let nextNodeEntry = nodeIterator(editor); //Next can also be prev. depending on the iterator

  while (nextNodeEntry != null) {
    const nextNode = nextNodeEntry[0];
    const commentThreadsOnNextNode = getAllIdsFromText(nextNode);
    const intersection = [...commentThreadsOnNextNode].filter((x) =>
      commentThreads.has(x)
    );

    // All comment threads we're looking for have already ended meaning
    // reached an uncommented text node OR a commented text node which
    // has none of the comment threads we care about.
    if (intersection.length === 0) {
      break;
    }

    // update thread lengths for comment threads we did find on this
    // text node.
    for (let i = 0; i < intersection.length; i++) {
      map.set(intersection[i], map.get(intersection[i]) + nextNode.text.length);
    }

    // call the iterator to get the next text node to consider
    nextNodeEntry = nodeIterator(editor, nextNodeEntry[1]);
  }

  return map;
}

// SHOW COMMENT SIDEBAR
export function CommentDrawer() {
  const [open, setOpen] = useState(false);
  // 1. Writing the comment itself should be simple.
  // 2. Be able to click through the comments (left, right)
  return (
    <Drawer
      title={"Diction"}
      placement={"right"}
      closable={true}
      onClose={() => setOpen(false)}
      visible={open}
      key={"right"}
      keyboard={true}
      bodyStyle={{ backgroundColor: "var(--tertiary-color-darkest)" }}
    ></Drawer>
  );
}
