import { useState, useRef, useEffect, createContext, useContext } from "react";
import AddProduct from "./addProduct";
import AddLayer from "./addLayer";
import AddDocumentModal from "../../documents/addDocToProduct";
import { POST_ProductDoc } from "../../../helpers/REST/api";
import { toast } from "react-toastify";
//AddDocument from "./addDocument";

export const ProductsCtx = createContext({});
/**
 * Deals with modal logic: view/create/edit  products & layers
 */
export const ProductsCtxProvider = ({ children }) => {
  const [modalOpen, setShowModal] = useState(null);
  const [modalData, setModalData] = useState(null);
  const [edit, setEdit] = useState(false);
  const openModal = (type, ctx, edit) => {
    setShowModal(type);
    if (ctx === null) setModalData(null);
    if (ctx) setModalData(ctx);
    if (edit) setEdit(true);
  };

  const closeModal = () => {
    setShowModal(null);
    setEdit(false);
  };
  const createProductDocument = ({ _id, children }) => {
    POST_ProductDoc({
      productId: modalData.productId,
      layerId: modalData._id,
      doc_id: _id,
      children,
    }).then((d) => {
      toast.success("It work3d!! New document should be created. Don't get your hopes up though");
    });
    if (_id && children && modalData.productId && modalData._id) {
    }
  };
  return (
    <ProductsCtx.Provider value={{ modalOpen, closeModal, openModal }}>
      {children}
      <AddProduct isOpen={modalOpen === "products"} onClose={closeModal} isEdit={edit} data={modalData} />
      <AddLayer isOpen={modalOpen === "layers"} onClose={closeModal} data={modalData} isEdit={edit} />
      <AddDocumentModal isOpen={modalOpen === "add_docs"} onClose={closeModal} data={modalData} onSelect={createProductDocument} isEdit={edit} />
    </ProductsCtx.Provider>
  );
};

//NOT IMPLEMENTED: WILL DO LATER.
export const LayerLogic = createContext({});

export const LayerLogicProvider = ({ children }) => {
  const [layerDataById, setAllLayerData] = useState({});
  const [courseData, setCourseData] = useState({});
  const [activeIndex, setActive] = useState(null);
  const [layerData, setLayerData] = useState([]);
  const [hover, setHover] = useState(false);

  const setLayerDataById = (id, key, value) => {
    if (id && key && !value) {
      setAllLayerData({
        ...layerDataById,
        [id]: key,
      });
    } else {
      setAllLayerData({
        ...layerDataById,
        [id]: { ...layerDataById[id], [key]: value },
      });
    }
  };

  return <LayerLogic.Provider value={{ courseData, layerDataById, setLayerDataById, setAllLayerData }}>{children}</LayerLogic.Provider>;
};
