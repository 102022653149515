import { Box } from "../../layout/containers/box";
import Lecture from "../../lecture";
import { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

const LecturePage = () => {
  const { doc_id } = useParams();
  const [lectureData, setLectureData] = useState(null);
  const [blockMetaData, setBlockMetaData] = useState(null);
  useEffect(() => {
    let query = new URLSearchParams({ parentId: doc_id });
    axios.get(`/blocks/?${query}`).then(({ data }) => {
      let mapped = data.map((b, bi) => b.doc_elements_rtf);
      let filtered = mapped.filter((rtf) => (rtf.length > 0 ? rtf : null));

      setLectureData(filtered.flat());
      setBlockMetaData(data);
    });
  }, []);

  function getSelectedText() {
    if (window.getSelection) {
      console.log(window.getSelection());
    } else if (document.selection && document.selection.type !== "Control") {
      return console.log(document.selection);
    }
  }

  return (
    <Box
      width="100%"
      type="col left"
      background="none"
      margin="0"
      padding="0"
      // onMouseUp={() => {
      //   getSelectedText();
      // }}
    >
      {lectureData && <Lecture data={lectureData} metaData={blockMetaData} />}
    </Box>
  );
};
export default LecturePage;
