import { useRef, useState, useEffect } from "react";
import { useSelected, useFocused } from "slate-react";
import { EditorImg } from "../../editor/UI/styled";
import { PictureFilled } from "@ant-design/icons";
import { TextField } from "../../layout/containers/input";
import { Tooltip } from "antd";
import { Button } from "../../layout/buttons/buttons";
import { Box } from "../../layout/containers/box";
import { ImageUpload } from "../../layout/containers/input";
import ImagesManager from "../../media/importImage";
import { Transforms, Editor, Element } from "slate";
import { GET } from "../../../helpers/REST/api";
import { AddBtn, EditBtn } from "../../layout/buttons/buttons";

let dataUri = `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24' stroke='%23333' stroke-width='0.5' fill='none' stroke-linecap='round' stroke-linejoin='round' class='css-i6dzq1'%3E%3Crect x='3' y='3' width='18' height='18' rx='2' ry='2'%3E%3C/rect%3E%3Ccircle cx='8.5' cy='8.5' r='1.5'%3E%3C/circle%3E%3Cpolyline points='21 15 16 10 5 21'%3E%3C/polyline%3E%3C/svg%3E`;

export default function ImageElement({ attributes, children, element, editor }) {
  const [edit, setEdit] = useState(false);
  const [openGallery, setGalleryOpen] = useState(false);
  const [imgData, setImageData] = useState(null);
  const selected = useSelected();
  const focused = useFocused();
  let imgRef = useRef(null);

  useEffect(() => {
    if (element.imgId && imgData === null) {
      GET(`/media/${element.imgId}`).then((r) => {
        if (r !== null) setImageData(r);
        imgRef.current.src = r.uri;
      });
    }
  }, [element, imgData]);
  return (
    <div {...attributes}>
      <div contentEditable={false}>
        <div className="flex-row justify align_center" style={{ maxWidth: "500px", margin: "0 auto", flexFlow: "row wrap", position: "relative" }}>
          <div>
            <EditorImg
              ref={imgRef}
              src={element.url || imgData?.uri}
              onError={(e) => {
                element.url = dataUri;
              }}
              selected={selected}
              focussed={focused}
              contentEditable={false}
              alt={element?.title}
            />
            <EditBtn
              style={{ position: "absolute", right: "-3em", top: "0" }}
              onMouseDown={() => {
                setEdit(!edit);
              }}
            />
            <AddBtn
              style={{ position: "absolute", right: "-3em", top: "3em" }}
              onMouseDown={() => {
                setGalleryOpen(!openGallery);
              }}
            />
            <div style={{ position: "absolute", right: "-3em", top: "6em", width: "30px", margin: "0 auto", textAlign: "center", cursor: "pointer" }}>
              <span
                style={{
                  textDecoration: "underline dotted red",
                  color: "var(--primary-color)",
                }}
              >
                <Tooltip
                  title={
                    <p>
                      <ol>
                        <li> An image can directly be pasted; But be careful--if the link ceases to exist, the image will be broken.</li>
                        <li>[Safer & resusable method] Upload the image.</li>

                        {element?.url && typeof element?.url === "string" && !element?.url.includes("https://") && <li style={{ color: "red" }}>Warning: This image has a link in it</li>}
                      </ol>
                    </p>
                  }
                >
                  ?
                </Tooltip>
              </span>
            </div>
          </div>

          <div className="" style={{ maxWidth: "500px", margin: "0 auto", textAlign: "center" }}>
            {edit ? (
              <TextField
                label={"Image Title/Subtext"}
                isDecorated={true}
                contentEditable={false}
                styles={{ padding: "0.5em", color: "snow" }}
                wrapStyles={{ width: "400px", margin: "0.5em auto", color: "snow", textAlign: "center" }}
                placeholder="Write title here..."
                value={element?.title || imgData?.title || ""}
                onChange={(v) => {
                  if ((v && element.imgId) || element.url) {
                    Transforms.setNodes(
                      editor,
                      { title: v },
                      {
                        match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n.type === "image" && n.imgId === element.imgId,
                      }
                    );
                  }
                }}
              />
            ) : (
              <small>{element.title}</small>
            )}
          </div>
        </div>
        <ImagesManager
          open={openGallery}
          setOpen={setGalleryOpen}
          onChange={(imgObj) => {
            if (imgObj._id) {
              Transforms.setNodes(
                editor,
                { imgId: imgObj._id, title: imgObj.title || imgObj.fileName, children: [{ text: imgObj.description || "" }] },
                {
                  match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n.type === "image" && n.imgId === element.imgId && n.url === element.url,
                }
              );
            }
            imgRef.current.src = imgObj.uri;
          }}
        />
      </div>
      {children}
    </div>
  );
}
