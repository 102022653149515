import { useState, useEffect, useRef } from "react";
import { Drawer, Tooltip } from "antd";
import { TabGroup } from "../layout/containers/box";
import { Box } from "../layout/containers/box";
import { TextField } from "../layout/containers/input";
import { ImageUpload } from "../layout/containers/input";
import { SelectField } from "../layout/containers/input";
import { GET, UPLOAD } from "../../helpers/REST/api";
import { Button } from "../layout/buttons/buttons";
import styled from "styled-components";
import { toast } from "react-toastify";

let dataUri = `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24' stroke='%23333' stroke-width='0.5' fill='none' stroke-linecap='round' stroke-linejoin='round' class='css-i6dzq1'%3E%3Crect x='3' y='3' width='18' height='18' rx='2' ry='2'%3E%3C/rect%3E%3Ccircle cx='8.5' cy='8.5' r='1.5'%3E%3C/circle%3E%3Cpolyline points='21 15 16 10 5 21'%3E%3C/polyline%3E%3C/svg%3E`;

export const ImgTile = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  //height: auto;
  text-align: center;
  margin: 1em;
  cursor: pointer;
  background-color: #333;
  border: 4px solid ${({ selected }) => (selected ? "var(--primary-color)" : "transparent")};
  border-radius: 15px;
  & img {
    display: block;
    width: ${(props) => (props.wid && props.wid ? props.wid : "500px")};
    min-width: 200px;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    border-radius: 12px;
    box-shadow: ${(props) => (props.selected && props.focused ? "0 0 0 2px blue;" : "none")};
    margin-bottom: 0.5em;
    background-color: #222;
  }
`;
const ImgGallery = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  & .ImgContainer {
    box-sizing: border-box;
    display: flex;
    flex-flow: column nowrap;
    //height: auto;
    text-align: center;
    margin: 1em;
    cursor: pointer;
    background-color: #333;
    border: 4px solid ${({ selected }) => (selected ? "var(--primary-color)" : "snow")};
  }
`;

export default function ImagesManager({ onChange, open, setOpen }) {
  const [activeTab, setActiveTab] = useState(0);
  const [imgs, setImgs] = useState([]);
  const [selected, setSelected] = useState(-1);
  const handleChange = (imgObj) => {
    setOpen(false);
    onChange(imgObj);
  };
  const handleKeyPress = ({ key }) => {
    if (activeTab === 1 && selected !== -1 && key === "Enter") {
      handleChange(imgs[selected]);
    }
  };

  return (
    <>
      <Drawer
        title={"IMAGE GALLERY"}
        placement={"bottom"}
        closable={true}
        onClose={() => setOpen(false)}
        visible={open}
        key={"bottom"}
        keyboard={true}
        bodyStyle={{ backgroundColor: "var(--tertiary-color-darkest)" }}
        width={"100dvw"}
        height={"100dvh"}
        onKeyPress={(e) => handleKeyPress(e)}
      >
        <TabGroup tabs={["Upload", "Gallery"]} setTab={setActiveTab} activeIndex={activeTab} width="min-content" />
        {open && activeTab === 0 && <UploadImgForm onSave={handleChange} />}
        {open && activeTab === 1 && <ImagesView onSelect={handleChange} imgs={imgs} setImgs={setImgs} selected={selected} setSelected={setSelected} />}
      </Drawer>
    </>
  );
}

export const UploadImgForm = () => {
  const [formState, setFormState] = useState({});
  const [formData, setFormData] = useState(new FormData());
  const imgRef = useRef(null);
  const validateForm = (e) => {
    const { title, description } = formState;
    if (!typeof formData === "object" || !title || !description) {
      e.preventDefault();
      if (!title) alert("Image title is missing");
      if (!description) alert("Image description is missing");
    }
  };
  const handleUpload = async () => {
    let imgData = formData.get("file0");
    if (formState.title) imgData.title = formState.title;
    if (formState.tags) imgData.tags = formState.tags;
    if (formState.description) imgData.description = formState.description;
    let finalFormData = new FormData();
    finalFormData.append("file0", imgData);
    finalFormData.append("title", formState.title);
    finalFormData.append("description", formState.description);
    let tags = formState.tags.map((t) => t.value);
    finalFormData.append("tags", JSON.stringify(tags));

    await UPLOAD("/media/image/upload", finalFormData, {
      maxContentLength: 10000000,
      maxBodyLength: 10000000,
      headers: { "Content-Type": "multipart/form-data" },
      onUploadProgress: (progressEvent) => console.log("Upload progress: ", Math.round((progressEvent.loaded / progressEvent.total) * 100)),
    })
      .then((res) => {
        toast.success("Image uploaded!");
        console.log("RESULT", res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const setTitle = (v) => setFormState({ ...formState, title: v.trim() });
  const setDescrip = (v) => setFormState({ ...formState, description: v.trim() });
  const setTags = (v) => setFormState({ ...formState, tags: v });
  return (
    <Box type="row" background="none" width={"80%"} justifyContent="space-between" margin="0 auto">
      <Box type="column" background="none" width={"400px"} justifyContent="center" margin="0 auto">
        <TextField label="Title" isDecorated={true} wrapStyles={{ margin: "1em 0" }} onChange={(v) => setTitle(v)} placeholder="Displays under document image" />
        <TextField label="Description" isDecorated={true} wrapStyles={{ margin: "1em 0" }} onChange={(v) => setDescrip(v)} placeholder="What do you want them to see?" />
        <SelectField
          isMulti={true}
          isCreatable={true}
          placeholder="Insert tags"
          value={formState?.tags}
          style={{ margin: "1em 0", width: "100%" }}
          onChange={(v) => {
            Array.isArray(v) && setTags(v);
          }}
        />
        <div className="flex-row justify">
          <ImageUpload
            onUpload={(e) => {
              imgRef.current.src = URL.createObjectURL(e.target.files[0]);
              let newFormData = new FormData();
              [...e.target.files].forEach((file, index) => {
                newFormData.append("file" + index, file);
              });
              setFormData(newFormData);
            }}
            disabled={!formState?.title}
            onSave
            onMouseDown={(e) => {
              validateForm(e);
            }}
            title="Attach"
          ></ImageUpload>
          <Button
            disabled={!formState?.title || !imgRef?.current?.src}
            onMouseDown={() => {
              handleUpload();
            }}
          >
            Upload
          </Button>
        </div>
      </Box>
      <Box type="row" background="none" width={"300px"} justifyContent="center" margin="0 auto">
        <ImgTile wid="300px">
          <img ref={imgRef} src="" alt="" />
        </ImgTile>
      </Box>
    </Box>
  );
};

/**
 * IMAGE GALLERY / VIEW / SEARCH;
 ** -- Is Stand alone;
 * @param  {Function} props.onSelect
 * @return {Object} Media Object: fileName, size, mediaType, uri
 */
export const ImagesView = ({ onSelect, selected, setSelected, imgs, setImgs }) => {
  //query //fileName // mediaType// tags,

  useEffect(() => {
    let query = "mediaType=image";
    GET(`/media/mediaItems?${query}`).then((d) => {
      if (Array.isArray(d) && d.length) {
        let reformatted = d.map(({ fileName, title, fileSize, uri, _id, tags }) => ({ _id, tags, title: title || fileName, uri: uri, description: "Description here..." }));
        setImgs(reformatted);
      }
    });
  }, []);
  return (
    <div style={{ height: "80%" }}>
      <Box type="column" background="none" width={"100%"}>
        <h3>Saved Images ({imgs.length})</h3>
        <ImgGallery>
          {imgs &&
            imgs.map((img, imgIndex) => (
              <ImageView
                key={"img_" + imgIndex}
                wid="300px"
                selected={selected === imgIndex}
                onMouseDown={() => {
                  setSelected(imgIndex);
                }}
                img={img}
              />
            ))}
        </ImgGallery>
      </Box>
      <div style={{ display: "absolute", bottom: "0px" }}>
        <Button
          disable={selected === -1 || imgs[selected] === undefined}
          onMouseDown={() => {
            onSelect(imgs[selected]);
          }}
        >
          Select
        </Button>
      </div>
    </div>
  );
};
/**
 * @param  {string} props.img.title
 * @param  {string} props.img.description
 * @param  {string} props.img.uri
 * @param  {string} props.wid e.g. "300px"
 * @param  {object} props.fwdRef Applies to img.
 * @param  {objects} rest applies to the wrapper
 */
export const ImageView = ({ img, fwdRef, ...rest }) => {
  useEffect(() => {
    if (!img && fwdRef?.current?.src) fwdRef.current.src = dataUri;
    if (img && fwdRef?.current?.src) fwdRef.current.src = img;
  }, [img]);
  return (
    <ImgTile {...rest}>
      <img
        ref={fwdRef}
        src={img?.uri || dataUri}
        alt={img?.title}
        loading="lazy"
        onError={(e) => {
          console.log("error loading image", e);
          e.target.src = dataUri;
        }}
      />
      <Tooltip
        title={
          <>
            <h4>Description</h4>
            <span>{img?.description}</span>
          </>
        }
      ></Tooltip>
      <small>{img?.title}</small>
    </ImgTile>
  );
};
