import AdminMediaPlayer from '../editor/UI/vPlayer/v2/adminMediaPlayer';

let element = {
  tracks: [
    {
      type: 'video',
      uri: 'https://d189wr37i6dtm4.cloudfront.net/5b231faabd3fa92682ea7279-1618076360485-chemFundamentals1.mp4',
      format: 'video/mp4',
      duration: 89,
    },
    {
      type: 'video',
      uri: 'https://d189wr37i6dtm4.cloudfront.net/undefined-1557215166873-Conclusion 2.mp4',
      format: 'video/mp4',
      duration: 739,
    },
    {
      type: `audio`,
      uri: 'https://www.dl.dropboxusercontent.com/s/lupcrqo2f2fg2f2/Mutually%20Exclusive%20Collectively%20Exhaustive.m4a?dl=0',
      duration: 208,
    },
  ],
};
export default function Player(props) {
  return (
    <div style={{ marginBottom: '100px' }}>
      <AdminMediaPlayer
        mediaType="video"
        element={element}
        editor={{
          blockFragmentData: { blockId: 0, fragmentIndex: 0 },
        }}
      />
    </div>
  );
}
