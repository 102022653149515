import styled from "styled-components";
import React, { useEffect, useState, useRef } from "react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import AsyncSelect from "react-select/async";
import { Button } from "../buttons/buttons";
import { FormEdit } from "../../../images/icons";
import { UPLOAD } from "../../../helpers/REST/api";

import { ListTextInput, Input, UndecoratedWrap, LockedInput, Label, Wrap, ReactSelectWrap } from "./inputStyles";

/**
 *
 * @param {boolean} props.isDecorated Whether it has a wrapper or transparent background
 * dd @param {boolean} props.locked
 * @param {number} props.width Field width
 * @param {string} props.name Field property name
 * @param {string} props.defaultValue
 * @param {number} props.width
 * @param {string} props.placeholder

 * @param {string} props.label
 * @param {object} props.errors
 * @param {function} props.onChange = requires (value, element-ref)
 * @param {function} props.onSave This might be useless!
 * @param {function} props.detectFocus callback to detect focus. Takes isFocussed && ref as arg.
 * @param {boolean} props.readonly 
 * @returns
 */

export const TextField = ({
  isDecorated,
  hasEditBtn = true,
  width = "100%",
  name,
  defaultValue,
  placeholder,
  locked = false,
  label = "Label",
  errors = null,
  onChange,
  detectFocus,
  onSave,
  saveOnBlur,
  readonly = false,
  wrapStyles,
  value,
  ...rest
}) => {
  const fieldRef = useRef(null);
  React.useEffect(() => {
    if (fieldRef?.current?.value) {
      fieldRef.current.value = defaultValue;
    }
  }, [defaultValue]);

  const [isFocussed, setFocus] = useState(false);
  const [hasError, setError] = useState(false);
  const editRef = useRef(null);
  const handleBlurAndFocus = (e) => {
    if (!e) return;
    if (e === "blur" && !locked && isFocussed) {
      setFocus(false);
      if (saveOnBlur && typeof saveOnBlur === "function") {
        saveOnBlur();
      }
    } else if (e === "focus" && !locked && !isFocussed) {
      setFocus(true);
    }
  };
  const handleChange = (e) => {
    if (value) {
      return onChange(e.target.value);
    } else {
      fieldRef.current.value = e.target.value;
      return onChange(e.target.value, fieldRef.current);
    }
  };
  const handleSave = () => {
    onSave(fieldRef.current.value, fieldRef.current);
  };

  function handleKeyPress(field) {
    if (field.key === "Escape") {
      console.log("Esc");
      setFocus(false);
    }
  }

  useEffect(() => {
    if (isFocussed) {
      fieldRef.current.focus();
    }
    if (detectFocus && typeof detectFocus === "function") {
      detectFocus(isFocussed, fieldRef.current);
    }
  }, [isFocussed]);

  function showEdit() {
    if (fieldRef?.current?.style?.opacity) {
      fieldRef.current.style.opacity = 1;
    }
  }
  function hideEdit() {
    if (fieldRef?.current?.style?.opacity) {
      fieldRef.current.style.opacity = 0.7;
    }
    if (editRef?.current?.style?.opacity) {
      editRef.current.style.opacity = 0;
    }
  }
  if (!isDecorated) {
    return (
      <UndecoratedWrap
        width={isFocussed ? "100%" : width}
        locked={locked}
        focussed={isFocussed}
        onFocus={() => handleBlurAndFocus("focus")}
        onBlur={() => handleBlurAndFocus("blur")}
        hasVal={() => fieldRef?.current?.value}
        onClick={() => {
          fieldRef?.current?.focus();
          handleBlurAndFocus("focus");
        }}
        // onMouseEnter={() => {
        //   if (hasEditBtn) showEdit();
        // }}
        // onMouseLeave={() => {
        //   if (hasEditBtn) hideEdit();
        // }}
        style={wrapStyles}
      >
        <ListTextInput
          name={name}
          ref={fieldRef}
          type="text"
          defaultValue={value ? defaultValue : undefined}
          placeholder={placeholder || ""}
          onChange={(e) => handleChange(e)}
          onKeyDown={(e) => handleKeyPress(e)}
          onClick={() => {
            if (!isFocussed) setFocus(true);
          }}
          value={value}
          // disabled={locked ? "disabled" : undefined}
          readonly={isFocussed ? "true" : undefined}
          // style={{ color: isFocussed ? "white" : "rgb(200,200,200, 0.7)" }}
          {...rest}
        />
        {hasEditBtn && (
          <span ref={editRef} style={{ opacity: "0" }}>
            <FormEdit size="1em" color={"#ccc"} />
          </span>
        )}
      </UndecoratedWrap>
    );
  }
  return (
    <Wrap
      width={width}
      focussed={isFocussed}
      locked={locked}
      onFocus={() => handleBlurAndFocus("focus")}
      onBlur={() => handleBlurAndFocus("blur")}
      hasVal={() => fieldRef?.current?.value}
      onClick={() => {
        fieldRef?.current?.focus();
        handleBlurAndFocus("focus");
      }}
      style={{ ...wrapStyles }}
    >
      <Input
        name={name}
        ref={fieldRef}
        type="text"
        defaultValue={defaultValue || ""}
        placeholder={placeholder || ""}
        onChange={(e) => handleChange(e)}
        // disabled={locked ? "disabled" : undefined}
        readonly={readonly}
        {...rest}
      />
      <Label htmlFor={name} error={hasError}>
        {errors || label || name || "Label"}
      </Label>
    </Wrap>
  );
};
/**
 * @param  {Array} props.options [{value: "", label: ""}]
 * @param  {String} width
 * @param  {} ...rest}
 */
export const SelectField = ({ options, width, isCreatable, isAsync, ...rest }) => {
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted pink",
      color: state.isSelected ? "var(--primary-pink)" : "blue",
    }),

    control: (provided, { selectProps: { width } }) => {
      const height = "48px";
      const position = "relative";
      const border = "none";
      const borderRadius = "4px";
      return {
        ...provided,
        width: "100%",
        height,
        position,
        border,
        borderRadius,
      };
    },
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
    multiValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };

  if (isAsync) {
    return (
      <Wrap {...rest} width={width}>
        <AsyncSelect width={width} styles={customStyles} options={options} {...rest} />
      </Wrap>
    );
  }

  return (
    <Wrap {...rest} width={width}>
      {isCreatable ? <CreatableSelect width={width} styles={customStyles} options={options} {...rest} /> : <Select width={width} styles={customStyles} options={options} {...rest} />}
    </Wrap>
  );
};

export const MediaUpload = ({ ...rest }) => {
  const mediaRef = useRef(null);
  return <Input type="file" ref={mediaRef} {...rest} />;
};

/**
 * @param  {String} props.title Button Name
 * @param  {Function} props.onUpload
 * @param  {Function} props.onSave
 * @param  {Object} props.style
 * @param {Element} props.children **OPTIONAL** Pass image tag
 *
 */

export const ImageUpload = (props) => {
  const { title, children, disabled, onUpload, onSave, style, ...rest } = props;
  const mediaRef = useRef(null);
  const handleSave = async (e) => {
    let formData = new FormData();
    let data = [...e.target.files];
    data.forEach((file, index) => {
      formData.append("file" + index, file);
    });

    await UPLOAD("/media/upload/", formData, {
      maxContentLength: 10000000,
      maxBodyLength: 10000000,
      headers: { "Content-Type": "multipart/form-data" },
      onUploadProgress: (progressEvent) => console.log("Upload progress: ", Math.round((progressEvent.loaded / progressEvent.total) * 100)),
    });
  };
  const handleChange = (e) => {
    onUpload(e);
  };
  if (props.img) {
  }
  return (
    <>
      <Button
        onMouseDown={() => {
          if (!disabled) mediaRef.current.click();
        }}
        disabled={disabled}
      >
        {title}
      </Button>{" "}
      <Input type="file" accept="image/*" ref={mediaRef} onChange={handleChange} {...rest} style={{ ...style, display: "none" }} />
    </>
  );
};

const AutoComplete = () => {};
