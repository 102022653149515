import { useContext } from 'react';
import { TimeContext } from '../../editor/UI/audioPlayer/useCurrTime';
import { Box } from '../../layout/containers/box';
import { Checkbox } from '../../layout/selection/checkbox';
import { TextField } from '../../layout/containers/input';
import { Button } from '../../layout/buttons/buttons';
import QsEditor from '../../editor/questionEditor';

export default function AddQsForm({
  qsData,
  setQsData,
  playerCtx,
  createQsInDb,
}) {
  let { playerProgress } = useContext(playerCtx);
  function toggleQuestionType() {
    let newState = Object.assign({}, qsData, {
      questionType:
        qsData.questionType === 'mcq' ? 'audio_question' : 'mcq',
    });
    setQsData(newState);
  }
  function toggleAnsCorrectness(e, ansIndex) {
    let newState = Object.assign({}, qsData);
    let choice = newState.options[ansIndex];
    choice.isCorrect = !choice.isCorrect;
    setQsData(newState);
  }
  function handleEditorSave(stateKey, value, ansIndex, ansProperty) {
    let newState = Object.assign({}, qsData);
    if (stateKey === 'question') {
      newState.question = value;
      setQsData(newState);
      return;
    } else if (stateKey === 'options') {
      if (newState.options[ansIndex] === undefined) {
        let ansChoiceObj = {};
        ansChoiceObj[ansProperty] = value;
        newState.options[ansIndex] = ansChoiceObj;
      } else {
        newState.options[ansIndex][ansProperty] = value;
      }
      setQsData(newState);
      return;
    }
  }
  function addAnsChoice(e) {
    e.preventDefault();
    console.log('mouse down');
    let newState = Object.assign({ options: [] }, qsData);
    newState.options.push({ isCorrect: false });
    setQsData(newState);
    return;
  }
  function removeAnswer(i) {
    let newState = Object.assign({}, qsData);
    newState.options.splice(i, 1);
    setQsData(newState);
  }

  const qType = qsData.questionType;

  return (
    <Box type="col left" background="none">
      <h2>
        Question @ <span>{Math.ceil(playerProgress)}s</span>
      </h2>
      <Box
        type="1 row centered"
        background="none"
        padding="0"
        width="auto"
      >
        <Checkbox
          name="mcq"
          checked={!!qType === 'mcq'}
          value="mcq"
          onChange={toggleQuestionType}
        >
          Ask question orally?
        </Checkbox>
        <Box
          type="row nowrap"
          background={qType === 'mcq' ? 'transparent' : 'white'}
          opacity={qType === 'mcq' ? '0.3' : '1'}
          padding="0"
          width="auto"
        >
          <audio
            src={
              qsData.questionType === 'audio_question'
                ? qsData.question
                : null
            }
            type="media/mp4"
            controls
            style={{ transform: 'scale(0.7)', marginLeft: 'auto' }}
          />
        </Box>
      </Box>
      {/* Question Input */}
      <Box
        type="row"
        background="none"
        justifyContent="space-between"
        padding={'16px 0'}
      >
        <TextField
          name="question"
          width="100%"
          label={qType === 'mcq' ? 'Question' : 'Link'}
          defaultValue={qsData.question}
          onChange={(v, ref) => {
            handleEditorSave(ref.name, v);
          }}
        />
      </Box>

      {/* Ans. Choices */}
      {qsData &&
        qsData.options &&
        qsData.options.map((option, i) => (
          <span
            style={{ display: 'flex', flexFlow: 'row nowrap' }}
            key={`ansChoice_question_${i}`}
          >
            <span
              style={{ display: 'inline' }}
              onMouseDown={(e) => toggleAnsCorrectness(e, i)}
            >
              <Checkbox
                readOnly
                defaultChecked={!!option.isCorrect}
              />
            </span>
            <QsEditor
              content={option.choice}
              placeholder="Type Answer..."
              background={'transparent'}
              onChange={(v) =>
                handleEditorSave('options', v, i, 'choice')
              }
              width="50%"
              className={'dark'}
            />
            <button
              className="close-btn"
              style={{
                background: '#A52A2A',
                borderRadius: '50%',
                padding: '1px',
                width: '25px',
                height: '25px',
                color: 'white',
                fontSize: '12px',
                marginTop: '2px',
              }}
              onMouseDown={() => removeAnswer(i)}
            >
              🗑️
            </button>
          </span>
        ))}

      {/* Footer */}
      <Box
        type="1 row centered"
        padding="0"
        justifyContent="space-between"
      >
        {/* Button 1 */}
        <Button
          theme="white-black"
          onMouseDown={(e) => addAnsChoice(e)}
        >
          Add more answer choices
        </Button>
        {/* Button 2 */}
        <Button
          theme="pink-black"
          onMouseDown={() => {
            createQsInDb();
          }}
        >
          Save Question
        </Button>
      </Box>
    </Box>
  );
}
