import { QuestionSpan } from "../../editor/UI/styled";
import { useRef } from "react";

export function QuestionBlock({ attributes, children }) {
  const questionRef = useRef(null);

  return (
    <QuestionSpan {...attributes} ref={questionRef}>
      {/* <ChromeBugfix /> */}
      {children}
      {/* <ChromeBugfix /> */}
    </QuestionSpan>
  );
}
