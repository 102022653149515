import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Input, Select, Switch, Tree } from "antd";
import { Dropdown, Menu } from "antd";
import { Space, Card, Collapse, Typograph } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import {
  manageEntry,
  createCourse,
  createChild,
} from "../../helpers/inputs/course";
import { useMutation, useQuery } from "@apollo/client";
import {
  CREATE_COURSE,
  DELETE_COURSE,
  CREATE_COURSECHILD,
} from "../../helpers/mutations";
import { COURSES_QUERY } from "../../helpers/api";
import Title from "antd/lib/typography/Title";

const CourseManager = () => {
  const [modalVisibility, setModal] = useState(false);
  const [courseQueryData, setCourseQueryData] = useState([]);
  const { data, refetch, loading, error } = useQuery(COURSES_QUERY, {
    enabled: false,
  });
  const [deleteCourse] = useMutation(DELETE_COURSE);

  async function deleteCourseCallback(id, courseQueryDataIndex) {
    let result = await deleteCourse({ variables: { id: id } }).catch((e) => {
      console.log(e);
      alert("Error deleting Course");
    });
  }
  const getData = async () => {
    let courseData = await refetch()
      .then(({ data }) => {
        console.log(data.Courses);
        setCourseQueryData(data.Courses);
        return data.Courses;
      })

      .catch((e) => {
        alert(JSON.stringify(e));
      });
    console.log("Course Data: ", courseData);
  };
  useEffect(() => {
    getData();
  }, []);
  function closeModal() {
    setModal(false);
  }
  function handleModalOk(e) {}

  return (
    <Space direction="vertical">
      <Button
        type="primary"
        onClick={() => {
          setModal(true);
        }}
      >
        Add Course
      </Button>
      <Button type="default">Add Course</Button>

      {courseQueryData.map((course, index) => {
        return (
          <Space direction="vertical">
            <Course
              {...course}
              key={course._id}
              deleteCourse={deleteCourseCallback}
              index={index}
            />
          </Space>
        );
      })}

      <AddCourseModal
        modalVisibility={modalVisibility}
        handleModalOk={handleModalOk}
        closeModal={closeModal}
        loading={false}
      />
    </Space>
  );
};

export default CourseManager;

const Course = ({ deleteCourse, index, ...props }) => {
  const [showModal, setModalShow] = useState(false);

  function closeModal() {
    setModalShow(false);
  }
  function handleMenuClick(e) {
    e.domEvent.stopPropagation();
    console.log(e);
    if (e.key === "1") {
      setModalShow(true);
    } else if (e.key === "3") {
      deleteCourse(props._id, index);
    }
  }
  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="0">Edit Course</Menu.Item>
      <Menu.Item onClick={() => showModal} key="1">
        Add Child
      </Menu.Item>
      <Menu.Item key="2">Disable</Menu.Item>
      <Menu.Item key="3">Delete</Menu.Item>
    </Menu>
  );
  const genExtra = () => (
    <Dropdown
      onClick={(event) => {
        // If you don't want click extra trigger collapse, you can prevent this:
        event.stopPropagation();
      }}
      overlay={menu}
      placement="bottomCenter"
      // icon={}
    >
      <SettingOutlined />
    </Dropdown>
  );

  const { title, description, isPublished, isOffered, hierarchy, defaultPlan } =
    props;
  return (
    <Collapse style={{ width: 600 }}>
      <Collapse.Panel header={title} panel extra={genExtra()}>
        <Space>
          {title}-{description}
        </Space>
        <Space>
          <div>
            <span>Is published: {isPublished}</span>{" "}
            <span>Is offered: {isOffered}</span>
          </div>
        </Space>
        {hierarchy.length === 0
          ? null
          : hierarchy.map((level, index) => (
              <Collapse>
                <Collapse.Panel header={level}></Collapse.Panel>
              </Collapse>
            ))}
      </Collapse.Panel>
      {showModal ? (
        <AddChildModal
          modalVisibility={showModal}
          handleModalOk={closeModal}
          closeModal={closeModal}
          loading={false}
          courseObject={{ ...props }}
          hierarchyLevel={0}
        />
      ) : null}
    </Collapse>
  );
};

const TreeView = ({ courseData }) => {
  const [state, setState] = useState({
    expandedKeys: ["0-0", "0-0-0", "0-0-0-0"],
    gData: courseData,
  });
  function onDragEnter(info) {}
  function onDrop(info) {
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split("-");
    const dropPosition =
      info.dropPosition - Number(dropPos[dropPos.length - 1]);

    const loop = (data, key, callback) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          return callback(data[i], i, data);
        }
        if (data[i].children) {
          loop(data[i].children, key, callback);
        }
      }
    };
    const data = [...state.gData];

    // Find dragObject
    let dragObj;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });

    if (!info.dropToGap) {
      // Drop on the content
      loop(data, dropKey, (item) => {
        item.children = item.children || [];
        // where to insert
        item.children.unshift(dragObj);
      });
    } else if (
      (info.node.props.children || []).length > 0 && // Has children
      info.node.props.expanded && // Is expanded
      dropPosition === 1 // On the bottom gap
    ) {
      loop(data, dropKey, (item) => {
        item.children = item.children || [];
        // where to insert
        item.children.unshift(dragObj);
        // in previous version, we use item.children.push(dragObj) to insert the
        // item to the tail of the children
      });
    } else {
      let ar;
      let i;
      loop(data, dropKey, (item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        ar.splice(i, 0, dragObj);
      } else {
        ar.splice(i + 1, 0, dragObj);
      }
    }

    setState({
      expandedKeys: state.expandedKeys,
      gData: data,
    });
  }

  return (
    <>
      <Tree
        className="draggable-tree"
        defaultExpandedKeys={["Course"]}
        draggable
        blockNode
        showLine
        onDragEnter={onDragEnter}
        onDrop={onDrop}
        treeData={state.gData}
      />

      {JSON.stringify(state.gData) === JSON.stringify(courseData) ? null : (
        <Button type="primary">Save</Button>
      )}
    </>
  );
};

const InputSelector = (field) => {
  let props = manageEntry(field);
  switch (field.type) {
    case "Input":
      return <Input {...props} />;
    case "Select":
      return (
        <Select>
          {field.options.map((option, key) => {
            let value, title;
            if (typeof option === "string") {
              value = option;
              title = option;
            } else {
              value = option.value;
              title = option.title;
            }
            return (
              <Select.Option value={value} key={`${value}_${key}`}>
                {title}
              </Select.Option>
            );
          })}
        </Select>
      );
    case "Select_Multiple":
      return (
        <Select props mode="multiple" allowClear style={{ width: "100%" }}>
          {field.options.map((option, key) => {
            let value, title;
            if (typeof option === "string") {
              value = option;
              title = option;
            } else {
              value = option.value;
              title = option.title;
            }
            return (
              <Select.Option value={value} key={`${value}_${key}`}>
                {title}
              </Select.Option>
            );
          })}
        </Select>
      );
    case "Switch":
      return <Switch {...props} />;
    default:
      throw new Error({ error: "Unknown Input type" });
  }
};

const AddCourseModal = ({ modalVisibility, handleModalOk, closeModal }) => {
  const [addFormData, { data, loading, error }] = useMutation(CREATE_COURSE);
  async function onSubmit(values) {
    await addFormData({ variables: { ...values } }).catch((e) => {
      console.log(e);
    });
  }

  return (
    <Modal
      visible={modalVisibility}
      title="Create Course"
      onOk={handleModalOk}
      onCancel={closeModal}
      footer={null}
    >
      <Form onFinish={onSubmit} layout={"vertical"}>
        {createCourse.map((field, key) => (
          <Form.Item
            label={field.label}
            name={field.name}
            rules={field.rules}
            key={`${field.label}_${key}`}
          >
            {InputSelector(field)}
          </Form.Item>
        ))}

        <Button type="primary" htmlType="submit" loading={loading}>
          Submit
        </Button>
      </Form>
    </Modal>
  );
};

const AddChildModal = ({
  modalVisibility,
  handleModalOk,
  closeModal,
  courseObject,
  hierarchyLevel,
}) => {
  let courseId = courseObject._id;
  let parentLocation = hierarchyLevel > 0 ? ["String"] : [];

  let currentHierarchy = courseObject.hierarchy[hierarchyLevel];
  let moduleType =
    courseObject.hierarchy[hierarchyLevel + 1] === undefined
      ? "BODY"
      : courseObject.hierarchy[hierarchyLevel + 1];
  let childType = "FRAGMENTS";
  const [addFormData, { data, loading, error }] =
    useMutation(CREATE_COURSECHILD);

  async function onSubmit(values) {
    let variables = {
      courseId,
      parentLocation,
      moduleType,
      childType,
      ...values,
    };
    console.log(variables);
    await addFormData({ variables }).catch((e) => {
      console.error(e);
    });
  }
  return (
    <Modal
      visible={modalVisibility}
      title={`Create Course _${currentHierarchy[0]}${currentHierarchy
        .toLowerCase()
        .substring(1)}_`}
      onOk={handleModalOk}
      onCancel={closeModal}
      footer={null}
    >
      <Form onFinish={onSubmit} layout={"vertical"}>
        {createChild.map((field, key) => (
          <Form.Item
            label={field.label}
            name={field.name}
            rules={field.rules}
            key={`${field.label}_${key}`}
          >
            {InputSelector(field)}
          </Form.Item>
        ))}

        <Button type="primary" htmlType="submit" loading={loading}>
          Submit
        </Button>
      </Form>
    </Modal>
  );
};
