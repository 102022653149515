import { useState, useEffect, useContext } from "react";
import { QuestionCtx } from ".";
import { QsEditorLogic } from ".";
import { Box, Collapse } from "../layout/containers/box";
import { QuestionPrompt } from ".";
import { AudioRecorderWrap } from ".";
import { AnswerChoices } from ".";
import QsEditor from "../editor/questionEditor";
import { Tag } from "antd";
import { EditBtn, ExpandBtn } from "../layout/buttons/buttons";
import { GET, getDocTitle } from "../../helpers/REST/api";



export default function ViewQuestion(props) {
  const { fontSize, qid, index, readOnly, style, editable } = props;
  const [isOpen, setOpen] = useState(false);
  const toggleOpen = () => setOpen(!isOpen);
  return (
    <QsEditorLogic {...props}>
      <Box type="col left" width="100%" margin="0 auto" background="#222" style={{ maxHeight: "80vh", ...props.style }}>
        <div className="flex-row justify-end w-full">
           <Tags toggleOpen={toggleOpen} {...props} />
            <div className="flex-row justify_around">
              <EditBtn className="mx-[0.5em]" />
              <ExpandBtn
                
                onMouseDown={() => {
                  toggleOpen();
                }}
              />
            </div>
        </div>
       
        <Prompt {...props} />
        <Collapse showTitle={false} isOpen={isOpen} setOpen={setOpen} background="none" padding="none">
          <AudioRecorderWrap />

          <div style={{ overflowY: "auto" }}>
            <AnswerChoices />
          </div>
        </Collapse>
      </Box>
    </QsEditorLogic>
  );
}


export const Tags = (props) => {
  const { onQsTextChange, question, belongsToBlock, readOnly, fontSize } = useContext(QuestionCtx);
  const [belongsTo, setBelongsTo] = useState(null);
  useEffect(() => {
    async function getTitles() {
      if (belongsToBlock && Array.isArray(belongsToBlock) && belongsToBlock.length && belongsTo === null) {
        const [docId, blockId] = belongsToBlock;
        let tagString = "";
        Promise.all([docId && getDocTitle(docId), blockId && GET(`/blocks/id/${blockId}?select=title`).then((r) => (r === null ? "" : r.title))]).then((resultArray) => {
          if (resultArray[0]) tagString += "Doc: " + resultArray[0] + "/ ";
          if (resultArray[1]) tagString += "Block: " + resultArray[1];
          if (tagString) setBelongsTo(tagString);
          
        });
      }
    }
    getTitles();
  }, [belongsToBlock, belongsTo]);
  
  return (
    
      <div style={{minWidth: "90%"}}>
        {belongsTo && <Tag style={{ margin: "0 1em 0 0", color: "#777" }}>{belongsTo}</Tag>}
        {/* <Tag style={{ margin: "0 1em 0 0", color: "#777" }}>Qs. Prompt</Tag>
        <Tag style={{ margin: "0 1em 0 0", color: "#777" }}>Qs. Prompt</Tag> */}
      </div>
      
    
  );
};
export function Prompt({ index }) {
  const { onQsTextChange, question, readOnly, fontSize } = useContext(QuestionCtx);
  if (question === undefined) return null;
  return (
    <Box type="row" background="none" padding="0">
      <div style={{ padding: "0.35em", fontSize }}>{index + 1}.</div>
      <div style={{ width: "90%" }}>
        <QsEditor fontSize={fontSize} readOnly={readOnly} placeholder="Type question..." background={"transparent"} onChange={(v) => onQsTextChange(v)} content={question} className="dark" />
      </div>
    </Box>
  );
}
