import { useEffect, useState, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Box } from "../../layout/containers/box";
import Sohs from "../../editor2/editor";

import { useParams } from "react-router-dom";
import { RounderBtn } from "../../layout/buttons/buttonStyles";
import { Drawer } from "antd";
import { TextField } from "../../layout/containers/input";
import { ArrowBtn } from "../../layout/buttons/buttons";
import { toast } from "react-toastify";
import AudioRecorder from "../../media/audioRecorder";
import {
  getDocTitle,
  updateDocTitle,
  GET_DocBlocks,
  PUT_DocBlockTitle,
  POST_DocBlocks,
  PUT_DocBlocks,
} from "../../../helpers/REST/api";
const Doc = () => {
  const [value, setValue] = useState([]);
  const [docBlocks, setDocBlocks] = useState([]);
  const [isSaved, setIsSaved] = useState(true);
  const [activeBlock, setActiveBlock] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const navigate = useNavigate();

  //ActiveBlock
  const { doc_id, block_id } = useParams();

  const saveDoc = useCallback(async () => {
    let block = docBlocks[activeBlock];
    function updateState() {
      let copy = [...docBlocks];
      copy[activeBlock].doc_elements_rtf = value;
      setDocBlocks(copy);
      return copy;
    }
    return PUT_DocBlocks(block._id, value)
      .then(updateState)
      .then(() => {
        toast.success("Document saved!");
      });
  }, [activeBlock, docBlocks, value]);
  function handleChange(v) {
    setValue(v);
    let strungValue = JSON.stringify(v);
    let blockVal = docBlocks[activeBlock].doc_elements_rtf;
    let strungBlockVal = JSON.stringify(blockVal);
    //persist data in local Storage
    localStorage.setItem(`/${doc_id}/${block_id}`, strungValue);

    localStorage.setItem(
      `${doc_id}/${docBlocks[activeBlock]._id}/doc_elements_rtf`,
      strungValue
    );

    if (strungValue !== strungBlockVal && isSaved) {
      setIsSaved(false);
    }
  }
  async function handleBlockTitle(v) {
    let blockId = docBlocks[activeBlock]._id;
    if (blockId) PUT_DocBlockTitle(blockId, v);
  }
  function createBlock() {
    POST_DocBlocks(doc_id).then((d) => {
      setDocBlocks([...docBlocks, d]);
    });
  }
  function toggleDrawerOpen() {
    setDrawerOpen(!drawerOpen);
  }
  useEffect(() => {
    GET_DocBlocks(doc_id).then((d) => {
      if (Array.isArray(d) && d.length > 0) {
        navigate(`/documents/${doc_id}/${d[0]._id}`, true);
        setDocBlocks(d);
      }
    });
  }, []);
  useEffect(() => {
    if (Array.isArray(docBlocks[activeBlock]?.doc_elements_rtf)) {
      setValue(docBlocks[activeBlock].doc_elements_rtf);
    } else if (!docBlocks[activeBlock]?.doc_elements_rtf) {
      setValue([{ type: "paragraph", children: [{ text: "" }] }]);
    }
    if (docBlocks[activeBlock]?._id) {
      navigate(`/documents/${doc_id}/${docBlocks[activeBlock]?._id}`, true);
    }
  }, [activeBlock]);
  useEffect(() => {
    function saveIn1min() {
      saveDoc().then(() => {
        if (!isSaved) setIsSaved(true);
      });
    }
    const timer = setTimeout(saveIn1min, [10000]);
    if (isSaved) {
      clearInterval(timer);
    }
    return () => clearInterval(timer);
  }, [isSaved, saveDoc]);

  let renderEditor = docBlocks && docBlocks[activeBlock];

  return (
    <>
      <Drawer
        title="Document Blocks"
        closable={false}
        placement="left"
        onClose={() => {
          toggleDrawerOpen();
        }}
        key={"right"}
        keyboard={true}
        bodyStyle={{ backgroundColor: "var(--tertiary-color)", color: "white" }}
        visible={drawerOpen}
      >
        <div>
          <RounderBtn onClick={() => createBlock()}>Create Block</RounderBtn>
        </div>
        <ul>
          {docBlocks &&
            docBlocks.map((b, bi) => (
              <Box
                type="col left"
                style={{ cursor: "pointer" }}
                width="100%"
                background={
                  bi === activeBlock
                    ? "--tertiary-color-darkest"
                    : "--tertiary-color-darker"
                }
                border="1px solid grey"
                margin="5px 0"
                key={b._id + bi}
                onMouseDown={() => setActiveBlock(bi)}
              >
                <li>{bi === activeBlock ? <i>{b.title}</i> : b.title}</li>
              </Box>
            ))}
        </ul>
      </Drawer>
      <Box width="100%" minHeight="100dvh" background="none" type="col left">
        <Header
          doc_id={doc_id}
          createBlock
          saveBlock={saveDoc}
          hasBlocks={!!docBlocks.length}
          openDrawer={toggleDrawerOpen}
          isSaved={isSaved}
        />

        {renderEditor && (
          <>
            <TextField
              defaultValue={docBlocks[activeBlock].title}
              onChange={handleBlockTitle}
              placeholder="Block name"
              label="Block Name"
              isDecorated={true}
            />
            <Sohs
              onChange={handleChange}
              supraValue={docBlocks[activeBlock].doc_elements_rtf}
            />
          </>
        )}
      </Box>
      <AudioRecorder />
    </>
  );
};

const Header = ({
  doc_id,
  createBlock,
  saveBlock,
  hasBlocks,
  openDrawer,
  isSaved,
}) => {
  const [docTitle, setDocTitle] = useState(null);

  const handleTitleChange = (v) => {
    setDocTitle(v);
  };
  const handleTitleSave = async () => {
    let title = await getDocTitle(doc_id);
    if (docTitle !== title) {
      toast.promise(
        updateDocTitle(doc_id, docTitle).catch((err) => {
          toast.error(err);
        }),
        {
          success: "Title updated",
          pending: "Saving...",
          error: "Couldn't save!",
        }
      );
    }
  };
  //GET title on load
  useEffect(() => {
    getDocTitle(doc_id).then((data) => setDocTitle(data));
  }, []);

  return (
    <Box
      type="1 row cenetered"
      background="none"
      alignItems="centered"
      justifyContent="flex-start"
      textAlign="center"
      margin="50px 0 2em 0"
      padding="0 5px 1em 5px"
      style={{
        borderBottom: "1px solid var(--tertiary-color-darker)",
      }}
    >
      <Box
        type="1 row cenetered"
        background="none"
        alignItems="centered"
        justifyContent="flex-start"
        width="min-content"
        padding="0"
        margin="auto 0"
      >
        <Link to="/documents">
          <ArrowBtn direction="l" />
        </Link>
      </Box>
      <Box
        type="1 row cenetered"
        background="none"
        alignItems="centered"
        justifyContent="flex-start"
        width="min-content"
        padding="0"
        margin="auto 0"
      >
        <RounderBtn
          onClick={(e) => {
            openDrawer();
          }}
        >
          Blocks
        </RounderBtn>
      </Box>
      <TextField
        wrapStyles={{
          padding: "0",
          margin: "0",
          display: "flex",
          alignItems: "center",
          height: "100%",
          justifyContent: "flex-start",
          color: "snow",
        }}
        label={"Document title"}
        value={docTitle || "Document Page"}
        onChange={handleTitleChange}
        saveOnBlur={handleTitleSave}
      />
      <div style={{ display: "flex", alignItems: "center" }}>
        <RounderBtn
          onMouseDown={(e) => saveBlock(e)}
          disabled={!hasBlocks || !!isSaved}
        >
          Save
        </RounderBtn>
      </div>
    </Box>
  );
};

export default Doc;
