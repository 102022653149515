import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getQuestion, getBlockQuestions } from "../../../helpers/REST/api";
import { MultipleAnswers, SingleAnswerMCQ, TrueOrFalse, FindEvidence } from "../../editor/UI/questionModals/questionTypes";
import { Box } from "../../layout/containers/box";

import styled from "styled-components";
import { qModalStyle } from "../../editor/UI/styled";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Popover, Drawer } from "antd";
import { getAllIdsFromText } from "../features/question";
import { Transforms, Element, Text } from "slate";
import { toast } from "react-toastify";
import CreateQuestion from "../../questions/";

const { Label, InputWrapper, Input, Select } = qModalStyle;

const TabWrap = styled.div`
  width: 100%;
  border-bottom: 1px solid grey;
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 15px;
`;
const Tab = styled.div`
  position: relative;
  top: 1px;
  border-bottom: ${({ active }) => (active ? "2px solid var(--primary-color)" : "2px solid transparent")};
  color: ${({ active, color }) => (active ? "var(--primary-color)" : color ? color : "black")};
  font-weight: ${({ active }) => (active ? "500" : "400")};
  padding: 5px 5px;
  margin-right: 5px;
  cursor: pointer;
`;

export function QuestionVoid(props) {
  /*
    If new:
    1. we need to select a new question type,
    2.write it out,
    3. save it,
    4. and add it to db

    if old:
    1. we need to select just the right presenter -- nothing moore.
    2. What to do if it's no longer published? Do old students not seee it?
    3. How do we remove it entirely?
  */
  const { attributes, element, children, editor } = props;
  const [selectValue, setSelectValue] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalTab, setTab] = useState(0);
  const [qsData, setQsData] = useState(null);
  const [path, setPath] = useState(null);
  const [qid, setQid] = useState(null);
  const { id } = useParams();

  const showModal = () => {
    setModalOpen(!modalOpen);
  };
  const handleOk = (e) => {
    e.preventDefault();
    setModalOpen(false);
  };
  const handleCancel = (e) => {
    e.preventDefault();
    setModalOpen(false);
  };
  function handleSelect(e, value) {
    e.preventDefault();
    setSelectValue(value);
  }
  function RenderSelection(selectValue) {
    switch (selectValue) {
      case "mcq_multiple":
        return <MultipleAnswers questionId={element.questionId} mainEditor={props.editor} path={path} data={qsData} />;

      case "mcq":
        return <SingleAnswerMCQ questionId={element.questionId} mainEditor={props.editor} path={path} data={qsData} />;
      case "check_referrent":
        return <MultipleAnswers questionId={element.questionId} mainEditor={props.editor} path={path} data={qsData} />;
      case "boolean":
        return <TrueOrFalse questionId={element.questionId} mainEditor={props.editor} path={path} data={qsData} />;
      default:
        return <FindEvidence questionId={element.questionId} mainEditor={props.editor} path={path} data={qsData} />;
    }
  }
  function getPath() {
    if (editor && editor.selection && !path) {
      let newPath = [...editor.selection.anchor.path];
      newPath.pop();
      setPath(newPath);
    }
  }
  function  addIdToEditor(new_id, old_id) {
    let {type, children, ...rest} = element;
    let qidAttribute = {};
    let key = "qid_" + new_id;
    qidAttribute[key] = true;
   
    //if (old_id) qid
    const match_node_keys = (target, source) => {
        let element_keys = Object.keys(target);
        let result = true;
        element_keys.forEach((k) => {
          console.log(k)
          if (result && element[k] !== source[k]) {
            result = false
          }
        })
        if (result) {console.log("Element is", element)}
        return result;
    }
    const match_void = (n) =>   
      Element.isElement(n) && 
      !Text.isText(n) && 
      n.type === "inline-question-void" &&
      match_node_keys(element, n);
    if (Object.keys({...rest}).length) {
      Transforms.unsetNodes(editor, Object.keys({...rest}), {at:[], voids: true, match: match_void});
    }
    Transforms.setNodes(editor, qidAttribute, { 
      at: [], 
      voids: true, 
      match: match_void
    });
   
    // setQid(new_id)
    toast.success("Question saved and added");
    return new_id;
  }
  function removeEmptyIdLessQsVoid() {
    if (qid === null || getAllIdsFromText(qid).size === 0) {
      Transforms.removeNodes(editor, {
        at: path === null ? [] : path,
        voids: true,
        match: (n) => {
          let isVoid = n.type === "inline-question-void";
          let dzntHaveQid = getAllIdsFromText(n).size < 1;
          if (isVoid) console.log("dznt", dzntHaveQid);
          return isVoid && dzntHaveQid;
        },
      });
      toast.warn("Unsaved question removed!");
    }
  }

  useEffect(() => {
    if (qid === null) {
      let qids = getAllIdsFromText(element);
      console.log("qids", qids, [...qids][0]);
      if (qids.size > 0) {
        console.log("First quid", [...qids][0]);
        setQid([...qids][0]);
      }
    }
  }, [element, qid]);

  useEffect(() => {
    async function fetchQuestion(qsId) {
      let result = await getQuestion(qsId);

      setQsData(result);
      setSelectValue(result.questionType);
    }
    async function fetchAllBlockQuestions() {
      let result = await getBlockQuestions(id);
    }
    if (modalOpen && qsData === null && qid) {
      fetchQuestion(qid);
    }
    if (modalTab === 1) {
      fetchAllBlockQuestions();
    }
  }, [modalTab, modalOpen, qid, qsData]);

  return (
    <span
      {...attributes}
      style={{
        padding: "2px 10px",
        cursor: "pointer",
        color: "#F50057",
      }}
    >
      <Popover
        contentEditable={false}
        trigger="click"
        visible={modalOpen}
        placement="bottom"
        content={
          <CreateQuestion
            isOpen={modalOpen}
            onSave={addIdToEditor}
            qid={qid}
            onClose={() => {
              let prompt =
                "We're not supposed to have unsaved/empty questions in the doc.: You have one and it'll be removed from the editor.\n \n Danger⚠️⚠️\n Students will literally see empty questions && it'll be a problem.";
              if ((qid === null && window.confirm(prompt))) {
              
                removeEmptyIdLessQsVoid();
              } 
                setModalOpen(false);
            }}
          />
        }
      >
        <QuestionCircleOutlined
          style={{ color: "#F50057" }}
          onMouseDown={(e) => {
            getPath();
            e.preventDefault();
            setModalOpen(true);
            showModal();
          }}
        />
      </Popover>

      {children}
    </span>
  );
}
export function SelectQuestion({ handleSelect, ...rest }) {
  return (
    <InputWrapper style={{ width: "100%", display: "inline-block" }}>
      <Label>Select Question Type</Label>
      <Select
        style={{ width: "100%", display: "inline-block" }}
        onChange={(e) => {
          handleSelect(e, e.target.value);
        }}
      >
        <option value="mcq">MCQ: One Correct Answer</option>
        <option value="mcq_multiple">MCQ: Multiple Correct Answers</option>
        <option value="boolean">T/F: One Right Answer</option>
        <option value="cite_evidence">Cite: One Word/Sentence</option>
        <option value="cite_referrent">Cite: One Referrent</option>
        <option value="cite_multipart_map">Cite: Multipart Map</option>
        <option value="etymology">Etymology</option>
      </Select>
    </InputWrapper>
  );
}

//TODO: NOT IN USE (CAN'T REMEMBER WHAT THE USE WAS);
export function TextReplacer() {
  return (
    <span contentEditable={false}>
      <input type="text" />
    </span>
  );
}

//Created Feb 18th 2023
//TODO: NOT IN USE
export function CreateNewQuestion(props) {
  const { attributes, element, children, editor, modalTab, isVisible = true } = props;
  const [selectValue, setSelectValue] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const [qsData, setQsData] = useState(null);
  const [path, setPath] = useState(null);
  const { id, blockId } = useParams();
  const qsMap = getAllIdsFromText(element);
  const questionIds = [...qsMap];
  let questionId = element?.questionId || "test";

  const showModal = () => {
    setModalOpen(!modalOpen);
  };
  const handleOk = (e) => {
    e.preventDefault();
    setModalOpen(false);
  };
  const handleCancel = (e) => {
    e.preventDefault();
    setModalOpen(false);
  };
  function handleSelect(e, value) {
    e.preventDefault();
    setSelectValue(value);
  }
  function RenderSelectedQuestionForm(selectValue) {
    switch (selectValue) {
      case "mcq_multiple":
        return <MultipleAnswers questionId={questionIds[0]} mainEditor={props.editor} path={path} data={qsData} />;

      case "mcq":
        return <SingleAnswerMCQ questionId={questionIds[0]} mainEditor={props.editor} path={path} data={qsData} />;
      case "check_referrent":
        return <MultipleAnswers questionId={questionIds[0]} mainEditor={props.editor} path={path} data={qsData} />;
      case "boolean":
        return <TrueOrFalse questionId={questionIds[0]} mainEditor={props.editor} path={path} data={qsData} />;
      default:
        return <TrueOrFalse questionId={questionIds[0]} mainEditor={props.editor} path={path} data={qsData} />;
      // return (
      //   <FindEvidence
      //     questionId={questionIds[0]}
      //     mainEditor={props.editor}
      //     path={path}
      //     data={qsData}
      //   />
      // );
    }
  }
  function getPath() {
    if (editor && editor.selection && !path) {
      let newPath = [...editor.selection.anchor.path];
      newPath.pop();
      setPath(newPath);
    }
  }

  useEffect(() => {
    async function fetchQuestion() {
      let result = await getQuestion(element.questionId);

      setQsData(result);
      setSelectValue(result.questionType);
    }
    async function fetchAllBlockQuestions() {
      let result = await getBlockQuestions(id);
      return result;
    }
    if (modalOpen && qsData === null && element.questionId) {
      fetchQuestion(element.questionId);
    }
    // if (modalTab === 1) {
    //   fetchAllBlockQuestions();
    // }
  }, [modalTab, modalOpen]);
  return (
    <Box type={isVisible ? "col left" : "hidden"} background="transparent">
      <Box type="1 row centered" background="transparent" margin="0" padding={0}>
        <SelectQuestion handleSelect={handleSelect} />
      </Box>
      <Box type="1 row centered" background="transparent" margin="0" padding={0}>
        {RenderSelectedQuestionForm(selectValue)}
      </Box>
    </Box>
  );
}
export function ViewSavedQuestions(props) {
  const { isVisible } = props;
  const [active, isActive] = useState(false);
  const [qData, setqData] = useState([]);

  useEffect(() => {
    function fetchAllBlockQuestions() {}

    //Update data.
  }, [active, qData]);

  return (
    <Box type={isVisible ? "col left" : "hidden"} background="transparent">
      Show a list of questions. Open the question requested
    </Box>
  );
}
export function QuestionViewEditor(props) {
  const [modalTab, setTab] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  function handleTabClick(e, tabIndex) {
    e.preventDefault();
    setTab(tabIndex);
  }

  return (
    <Box type="column" width="100%" background="none">
      <TabWrap>
        <Tab active={modalTab === 0} color={"white"} onMouseDown={(e) => handleTabClick(e, 0)}>
          Add Question
        </Tab>
        <Tab
          color={"white"}
          active={modalTab === 1}
          onMouseDown={() => {
            setTab(1);
          }}
        >
          Unused Questions
        </Tab>
      </TabWrap>
      <CreateNewQuestion {...props} isVisible={modalTab === 0} modalTab={modalTab} />
      <ViewSavedQuestions {...props} isVisible={modalTab === 1} modalTab={modalTab} />
    </Box>
  );
}
