import styled, { keyframes } from "styled-components";

export const RecorderWrap = styled.div`
  position: absolute;
  width: 35px;
  height: 35px;
  bottom: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0px;
  background-color: var(--tertiary-color);
  border-radius: 50%;
  display: flex;
  cursor: pointer;
  &:hover {
    background-color: var(--primary-color);
  }
`;

export const Wrap = styled.div`
  height: 15%;
  display: flex;
  justify-content: space-evenly;
  padding: 0.5rem;
  width: 100%;
`;
export const Display = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  font-size: 2rem;
`;
export const RTime = styled.div`
  width: 80%;
  font-size: 0.8em;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BlinkAnimation = keyframes`
  0% { opacity: 0 }

 100% { opacity:1 }
`;

export const RIndicator = styled.div`
  width: 10px;
  height: 10px;
  margin-right: 0.5rem;
  border-radius: 50%;
  background-color: #099fff;
  animation-name: ${BlinkAnimation};
  animation-duration: 2s;
  animation-iteration-count: infinite;
`;
export const CancelWrap = styled.div`
  width: 20%;
  display: grid;
  place-content: center;
  animation-name: ${BlinkAnimation};
  animation-duration: 2s;

  & button {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    border: none;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;
    span {
      font-size: 1rem;
    }
  }
  & button:hover {
    color: #fd1c03;
  }
`;
export const StartWrap = styled(CancelWrap)`
  width: 40px;
  height: 40px;
  & button:hover {
    color: #41295a;
    background-color: #f2ea02;
  }
`;
export const DeleteWrap = styled(StartWrap)`
  & button:hover {
    color: #41295a;
    background-color: red;
  }
`;
export const AudioWrap = styled.audio`
  //   &::-webkit-media-controls-panel {
  //     border-radius: 10px;
  //     background-color: var();
  //   }
  //   &::-webkit-media-controls-mute-button {
  //     background-color: rgb(200, 200, 200);
  //     border-radius: 50%;
  //     &:hover {
  //       background-color: white;
  //     }
  //   }
  //   &::-webkit-media-controls-play-button {
  //     background-color: rgb(200, 200, 200);
  //     border-radius: 50%;
  //     &:hover {
  //       background-color: white;
  //     }
  //   }
  //   &::-webkit-media-controls-timeline-container {
  //     background-color: rgb(200, 200, 200);
  //     border-radius: 50%;
  //     &:hover {
  //       background-color: white;
  //     }
  //   }
  //   &::-webkit-media-controls-current-time-display {
  //     color: white;
  //   }
  //   &::-webkit-media-controls-time-remaining-display {
  //     color: white;
  //   }
  //   &::-webkit-media-controls-timeline {
  //     color: white;
  //   }
  //   &::-webkit-media-controls-volume-slider-container {
  //     color: rgb(200, 200, 200);
  //   }
  //   &::-webkit-media-controls-volume-slider {
  //     background-color: rgb(200, 200, 200);
  //   }
  //   &::-webkit-media-controls-seek-back-button {
  //   }
  //   &::-webkit-media-controls-seek-forward-button {
  //   }
  //   &::-webkit-media-controls-fullscreen-button {
  //   }
  //   &::-webkit-media-controls-rewind-button {
  //   }
  //   &::-webkit-media-controls-return-to-realtime-button {
  //   }
  //   &::-webkit-media-controls-toggle-closed-captions-button {
  //   }
`;
