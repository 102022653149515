import isHotkey from "is-hotkey";
import Elements from "../renders/elements";
import Leafs from "../renders/leafs";
import {
  toggleMark,
  toggleBlock,
  toggleFormat,
  removeAllMarks,
} from "./format.js";
import { toggleButton } from "./toolbarHandlers";
import { formatTypes } from "../toolbar/definitions";
import { Range, Transforms, Editor } from "slate";

let editable = {};

editable.renderElement = (props) => {
  return <Elements />;
};
editable.renderLeaf = (props) => {
  return <Leafs />;
};
// editable.handleKeyDown = (event, editor) => {
//   const HOTKEYS = {
//     "mod+b": "b",
//     "mod+i": "i",
//     "mod+u": "u",
//     "mod+`": "c",
//     "mod+s": "s",
//     "mod+1": "inline-question-Void",
//   };
// };
editable.handleToolbarClick = (event, editor) => {
  switch (event.inputType) {
    case "bold":
      return toggleFormat(editor, "bold");
    case "italic":
      return toggleFormat(editor, "italic");
    case "underline":
      return toggleFormat(editor, "underlined");
    case "question":
      return toggleFormat(editor, "question");
    case "inline-question-void":
      return toggleFormat(editor, "inline-question-void");
    case "link":
      return toggleFormat(editor, "link");
    default:
      return null;
  }
};

let keysPressed = {}; // used of keydown and up; detects multi strokes
editable.handleKeyDown = (event, editor) => {
  editable.saveLastRange(editor);
  const HOTKEYS = {
    "mod+b": "bold",
    "mod+i": "italic",
    "mod+u": "underline",
    "mod+`": "code",
    "mod+s": "strikethrough",
  };
  for (const hotkey in HOTKEYS) {
    if (isHotkey(hotkey, event)) {
      event.preventDefault();
      const mark = HOTKEYS[hotkey];
      toggleMark(editor, mark);
    }
  }
  // Command/Control + Esc --> Removes all mark formatting.
  keysPressed[event.key] = true;

  if (keysPressed["Meta"] && event.key === "Escape") {
    removeAllMarks(editor);
  }
};
editable.handleKeyUp = (event, editor) => {
  delete keysPressed[event.key];
};

editable.saveLastRange = (editor) => {
  if (
    editor !== null &&
    !!editor.selection &&
    !Range.isCollapsed(editor.selection)
  ) {
    editor.lastRange = editor.selection;
  }
};
const TYPE_TAGS = {
  block: {
    A: (el) => ({ type: "link", url: el.getAttribute("href") }),
    BLOCKQUOTE: () => ({ type: "quote" }),
    H1: () => ({ type: "heading-one", split: true }),
    H2: () => ({ type: "heading-two", split: true }),
    H3: () => ({ type: "heading-three", split: true }),
    H4: () => ({ type: "heading-four" }),
    H5: () => ({ type: "heading-five" }),
    H6: () => ({ type: "heading-six" }),
    IMG: (el) => ({ type: "image", url: el.getAttribute("src") }),
    LI: () => ({ type: "list-item" }),
    OL: () => ({ type: "numbered-list" }),
    P: () => ({ type: "paragraph" }),
    "`": () => ({ type: "code" }),
    UL: () => ({ type: "bulleted-list" }),
  },
  inline: {
    inlineQuestion: () => ({ type: "inline-question" }),
    link: () => ({ type: "link" }),
  },
  void: {
    inlineQuestionVoid: () => ({
      type: "inline-question-void",
      children: [{ text: "" }],
    }),
  },
};

const TEXT_TAGS = {
  s: "strikethrough",
  i: "italic",
  b: "bold",
  u: "underline",
};
export function onEditorPress(editor, key) {
  let isTag = typeof TEXT_TAGS[key] !== "undefined";
  let isBlock = typeof TYPE_TAGS.block[key] !== "undefined";
  let isInline = typeof TYPE_TAGS.inline[key] !== "undefined";
  let isVoid = typeof TYPE_TAGS.void[key] !== "undefined";
  console.log(isTag, isBlock, isInline, isVoid, TEXT_TAGS[key]);
  if (isTag) {
    toggleMark(editor, TEXT_TAGS[key]);
  } else if (isBlock) {
    toggleBlock(TYPE_TAGS.block[key]);
  } else if (isInline) {
    toggleBlock(TYPE_TAGS.inline[key]);
  } else if (isVoid) {
  }
}

export default editable;
