import React, { useRef, useState, useCallback, useEffect } from "react";
import { Slate, Editable, withReact, ReactEditor } from "slate-react";

import { createEditor, Transforms } from "slate";
import isHotkey from "is-hotkey";
import { withHistory } from "slate-history";

import { withHtml, withInlines, withEditableVoids } from "./plugins/index.js";
import { Wrapper, EditorWrap } from "./UI/styled";
import { QuestionPopover } from "./tools/inlineModal";
import { HoveringToolbar } from "./tools/hoverToolbar";
import { ToolbarTopIcons } from "./tools/toolbarMenu";

import { Element, SaveButton } from "./elements";
import { Leaf } from "./leafs";
import { toggleFormat, onEditorPress } from "./helperFunctions/index.js";

export default function QsEditor({ content, onChange, placeholder, autoFocus, className = "light", readOnly = false, background, wrapStyles, width, fontSize, ...rest }) {
  const editorRef = useRef();
  const [value, setValue] = useState(() => {
    let isArray = Array.isArray(content);
    let hasLen = isArray && content.length > 0;
    let hasStringType = hasLen && typeof content[0].type === "string";
    let arrayedChildren = hasStringType && Array.isArray(content[0].children);
    let hasAtlOneChild = arrayedChildren && content[0].children.length > 0;

    if (hasAtlOneChild) {
      return content;
    }
    return [{ type: "paragraph", children: [{ text: "" }] }];
  });
  if (!editorRef.current) {
    //init editor if doesn't exist
    editorRef.current = withEditableVoids(withInlines(withHtml(withReact(withHistory(createEditor())))));
  }
  //ease of access
  const editor = editorRef.current;
  const renderElement = useCallback((props) => {
    return <Element {...props} />;
  }, []);

  const renderLeaf = useCallback((props) => {
    return <Leaf {...props} />;
  }, []);

  function handleToolbarClick(event) {
    switch (event.inputType) {
      case "bold":
        return toggleFormat(editor, "bold");
      case "italic":
        return toggleFormat(editor, "italic");
      case "underline":
        return toggleFormat(editor, "underlined");
      case "question":
        return toggleFormat(editor, "question");
      case "inline-question-void":
        return toggleFormat(editor, "inline-question-void");
      case "link":
        return toggleFormat(editor, "link");
      default:
        return null;
    }
  }
  function handleKeyDown(event) {
    const HOTKEYS = {
      "mod+b": "b",
      "mod+i": "i",
      "mod+u": "u",
      "mod+`": "`",
      "mod+s": "s",
      "mod+1": "inlineQuestionVoid",
    };
    for (const hotkey in HOTKEYS) {
      if (isHotkey(hotkey, event)) {
        event.preventDefault();
        const mark = HOTKEYS[hotkey];
        onEditorPress(editor, mark);
      }
    }
  }
  return (
    <EditorWrap background={background || "transparent"} className={className} isFocused={true} style={wrapStyles} width={width} fontSize={fontSize} {...rest}>
      <Slate editor={editor} value={value} onChange={(newValue) => onChange && onChange(newValue)}>
        {/* {showToolbar ? <ToolbarTopIcons /> : null} */}
        {/* {showHoverToolbar ? <HoveringToolbar /> : null} */}
        {/* {allowAddQuestions ? <QuestionPopover editor={editor} /> : null} */}

        <Editable
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          onKeyDown={handleKeyDown}
          onDOMBeforeInput={handleToolbarClick}
          placeholder={placeholder || "Enter text here..."}
          autoFocus={autoFocus}
          readOnly={readOnly}
        />
      </Slate>
    </EditorWrap>
  );
}
