import { useContext, createContext, useState, useEffect, useCallback } from "react";
import ElementInteraction from "./interactions";
import { UserContext } from "../../helpers/hooks/userContext";
import { useParams, useNavigate } from "react-router-dom";
import { GET, POST, PUT } from "../../helpers/REST/api";
import { toast } from "react-toastify";
import useSocket from "../../helpers/hooks/useSocket";

/*
    Keep states separate: 
    1. Blocks && current block
    2. Elements && current Element
    3. isTriggerShown? 
    4. isQuestionShown?
*/
const LectureContext = createContext({});

export const States = ({ children, ...props }) => {
  const navigate = useNavigate();
  const { MID, UID, PID } = useContext(UserContext);
  const { doc_id, unit_id } = useParams();

  const socket = useSocket();

  const [blocks, setBlocks] = useState([]);
  const [docElems, setDocElems] = useState([]); //Render.
  const [metaData, setMetaData] = useState([]); // Nav.
  const [progress, setProgress] = useState(0);
  const [isComplete, setIsComplete] = useState(false);

  const [memLecData, setMemberData] = useState(null); //Member lecture.

  const [saved, setSaved] = useState(null); // Save state: will be updating member lecture.
  const [isLive, setIsLive] = useState(props?.isLive || window.location.pathname.includes("/students/lecture/"));

  //ASSOCIATED CALLBACKS.
  const updateBlocks = useCallback((newBlocks) => {
    setBlocks([...blocks, ...newBlocks.map((b) => b.doc_elements_rtf)]);
  }, []);
  const updateSaved = useCallback((save) => {
    //console.log("Check point loaded: ", save);
    setCurrentBlockIndex(save.docElementsIndex);
    setElementIndex(save.elementsIndex);
    setSaved(save);
  }, []);
  const updateMember = useCallback(({ _id, blocks, title }) => {
    setMemberData({ _id, blocks, title });
  }, []);

  const saveIt = useCallback(
    (obj) => {
      if (obj && isLive) {
        let totalElements = blocks.flat().reduce((total, current) => total + current.children.length, 0);
        let totalDocElems = docElems.flat().reduce((total, current) => total + current?.children.length || 0, 0);
        let progress = totalDocElems / totalElements;
        setProgress(progress);
        PUT(`/members/lecture/${memLecData._id}/savedPoint`, { savedPoint: obj, progress }).then(() => {
          toast.success("Checkpoint saved");
          setSaved(obj);
        });
      }
    },
    [memLecData, blocks, docElems]
  );

  // Lecture related states;
  const [isInlineElseBlock, setInlineElseBlock] = useState(false); // fasle = block;
  const [breakPoints, setBreakPoints] = useState([]); //? i = undefined: means no more breakpoints. Otherwise, that's the point we're at.
  const [interactElement, setElementInteraction] = useState(false); //Element is on breakpoint;
  const [currentBlockIndex, setCurrentBlockIndex] = useState(0);
  const [elementIndex, setElementIndex] = useState(-1); //!TODO:

  /**
   * SEMANTICS:
   * 1. One active block at a time, indicated by "currentBlockIndex";
   * 2. Am I at the block level or inline? See: isInlineElseBlock val.
   *     2.1. This determines _the type_ of trigger that'll show up.
   *     2.2. This determines _where_ the trigger will show up.
   *     2.3. If inline has no breakpoints, then it'll revert to the block (value: false);
   *     2.4. A
   */

  const onInline = useCallback(function onInline() {
    setInlineElseBlock(true);
  }, []);
  const onBlock = useCallback(function onBlock() {
    setInlineElseBlock(false);
  }, []);
  const startInteraction = useCallback(
    function startInteraction(interactionCtx) {
      // {CompletionTrigger, questionIds}
      if (!interactElement) {
        setElementInteraction(interactionCtx);

        if (isLive) saveIt({ ...saved, elementIndex: breakPoints[0]?.i }); //!Checked. Shows that' we're done uptil this point.
      }
    },
    [interactElement, breakPoints, saved, saveIt]
  );
  const endInteraction = useCallback(
    function endInteraction() {
      if (interactElement !== false) setElementInteraction(false);

      if (isLive) {
        let elemIndx = breakPoints[0].i;
        let nextElem = docElems[currentBlockIndex].children[elemIndx + 1];
        // console.log("breakPoints", docElems[currentBlockIndex].children, nextElem, breakPoints);
        saveIt({ ...saved, elementIndex: nextElem === undefined ? -1 : elemIndx + 1 }); //!Checked. Shows that' we're done uptil this point.
      }
    },
    [interactElement, docElems, currentBlockIndex, saved, saveIt, breakPoints]
  );

  const fetchNextLiveBlock = useCallback(() => {
    const { blockIndex, docElementsIndex } = saved;
    let blockOfInterest = blocks[blockIndex];
    let hasNextDocEl = blockOfInterest && blockOfInterest[docElementsIndex + 1] !== undefined;
    let hasAnotherBlock = blocks[blockIndex + 1] !== undefined;
    let hasUnfetchedBlocks = memLecData.blocks[blockIndex + 1] !== undefined;
    if (hasNextDocEl) {
      console.log("has next doc el");
      saveIt({ ...saved, docElementsIndex: docElementsIndex + 1, elementIndex: 0 });
      setCurrentBlockIndex(currentBlockIndex + 1);
      stageDocElements();
    } else if (hasAnotherBlock) {
      console.log("has another block");
      saveIt({ ...saved, blockIndex: saved.blockIndex + 1, docElementsIndex: 0, elementsIndex: 0 });
      setCurrentBlockIndex(0);
      stageDocElements();
    } else if (hasUnfetchedBlocks && memLecData.blocks[saved.blockIndex + 1] !== undefined) {
      saveIt({ ...saved, blockIndex: saved.blockIndex + 1, docElementsIndex: 0, elementsIndex: 0 });
      loadSecond();
    } else if (!isComplete) {
      saveIt({ ...saved, blockIndex: saved.blockIndex + 1, docElementsIndex: 0, elementsIndex: 0 });
      // setIsComplete(true);
      let query = `mid=${MID}&pid=${PID}&targetId=${doc_id}`;
      console.log(unit_id);
      PUT(`/members/lecture/${memLecData._id}/completed?${query}`).then((d) => {
        // navigate("/students" + d);
        console.log("okaaay");
        socket.emit("next lecture");
      });
      // mark this document as complete. mark gate as complete? open next document/else next gates
    }
  }, [saved, blocks, currentBlockIndex, docElems, unit_id]);

  useEffect(() => {
    if (props.isLive && !isLive) setIsLive(true);
  }, [props.isLive, isLive]); //1., setIsLive: determines next steps; otherwise it's a demo.

  const loadFirst = useCallback(async () => {
    let q = `mid=${MID}&pid=${PID}&o_did=${doc_id}&p_cid=${unit_id}&select=savedPoint,blocks,title,progress,isComplete`;
    await GET(`/members/lecture/forceCreate?${q}`).then((lec_doc) => {
      //console.log("Step-1: member lec doc: ", lec_doc);
      if (lec_doc !== null) {
        const { _id, savedPoint, blocks, title, progress, isComplete } = lec_doc;

        updateSaved(savedPoint);
        updateMember({ _id, blocks, title });
        setProgress(progress);
        setIsComplete(isComplete);
      }
    });
    await GET();
  }, [MID, PID, doc_id, unit_id, updateMember, updateSaved]);
  useEffect(() => {
    const shudFetchLiveData = !!isLive && !!MID && !!PID && !!UID && !!doc_id && !!unit_id && memLecData === null;
    if (shudFetchLiveData) loadFirst();
  }, [isLive, MID, PID, UID, doc_id, unit_id, memLecData, updateMember, updateSaved, loadFirst]); // 2. If Live lecture: Get memberLecture.

  const loadSecond = useCallback(() => {
    //console.log("loading second");
    let ids = "";

    memLecData.blocks.forEach((i) => {
      ids += i + ",";
    });
    //console.log(ids);
    let select = "select=savedPoint,blocks,doc_elements_rtf,title,tags";
    GET(`/blocks/list?${select}&ids=${ids}`).then((r) => {
      //console.log("RESULT", r);
      let at = 0;
      let metaObject = {};
      let m = r.forEach((block, bi) => {
        metaObject[bi] = {
          _id: block._id,
          title: block.title,
          length: block?.doc_elements_rtf?.length,
        };
        at = at + block?.doc_elements_rtf?.length;
      });

      setMetaData(metaObject);
      updateBlocks(r);
    });
  }, [memLecData, saved, updateBlocks, metaData]);
  useEffect(() => {
    const shudFetchDocElems = isLive && memLecData !== null && memLecData.blocks && saved !== null && blocks.length === 0;
    if (shudFetchDocElems) loadSecond();
  }, [isLive, memLecData, saved, updateBlocks, loadSecond, blocks]); // 3. Once memberLecture, usedSavedPoint to update lecture states

  useEffect(() => {
    if (saved !== undefined && saved !== null && blocks.length === 0) {
      const docElIndex = saved?.docElementsIndex;
      const elIndex = saved?.elementIndex;
      if (docElIndex && currentBlockIndex !== docElIndex) setCurrentBlockIndex(saved.docElementsIndex);
      if (elIndex && elementIndex !== elIndex) setElementIndex(saved.elementIndex);
    }
  }, [isLive, saved, currentBlockIndex, elementIndex, blocks]); //2. sync saved to state.

  const stageDocElements = () => {
    let hasBlocks = blocks.length;
    let totalBlockEls = blocks.reduce((t, b) => b.length + t, 0);
    if (saved !== null && hasBlocks && totalBlockEls >= docElems.length) {
      let dElems = [];
      let { blockIndex, docElementsIndex } = saved;
      let b = 0,
        d = 0;
      while (b < blockIndex + 1 && blocks[b] !== undefined && blocks[b][d] !== undefined) {
        while (b < blockIndex && d < blocks[b].length) {
          dElems = [...dElems, blocks[b][d]];
          d++;
        }
        while (b === blockIndex && d < docElementsIndex + 1 && blocks[b][d] !== undefined) {
          dElems = [...dElems, blocks[b][d]];
          d++;
        }
        d = 0;
        b++;
      }
      //console.log("D_elems: ", dElems);
      setDocElems(dElems);
    }
  };
  useEffect(() => {
    let hasBlocks = blocks.length;
    let totalBlockEls = blocks.reduce((t, b) => b.length + t, 0);
    if (saved !== null && hasBlocks && totalBlockEls >= docElems.length) {
      stageDocElements();
    }
  }, [updateBlocks, blocks, currentBlockIndex, saved]);
  useEffect(() => {
    if (breakPoints && breakPoints[0] && breakPoints[0].i !== elementIndex) setElementIndex(breakPoints[0].i);
  }, [breakPoints, currentBlockIndex, elementIndex, docElems, saved, blocks]);

  return (
    <LectureContext.Provider
      value={{
        isLive, //!For live lecture only
        isInlineElseBlock,
        breakPoints, // breakPoints[0]: current one we're on. Goes to Element Render
        setBreakPoints, // Goes toElement Render;
        onInline, //Callback: Goes to ElementRender
        onBlock, //Callback : Goes to ElementRender;
        currentBlockIndex, //Comes from the block itself;
        setCurrentBlockIndex, //Comes from the block itself;
        startInteraction, // --> Comes from ElementRender;
        interactElement, // --> Goes to "Element Interaction"
        setElementInteraction,
        endInteraction,
        fetchNextLiveBlock, //!For live lecture only
        docElems, //!For live lecture only
        metaData, //!For live lecture only

        elementIndex,
        memLecData,
        blockId: memLecData?.blocks[saved?.blockIndex],
        blocks,
        saved,
        saveIt,
        isComplete,
      }}
    >
      {children}
    </LectureContext.Provider>
  );
};

export default LectureContext;
