import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { Readable } from "../../slateReader";
import { Node } from "slate";
import ContentEditor from "../../editor/slateEditor";
import {
  getBlocks,
  getBlock,
  createBlock,
  updateBlock,
} from "../../../helpers/REST/api";
import { Wrapper } from "../../editor/UI/styled";
import { FontContext } from "../../../helpers/hooks/useFontSize";
import { Slider, InputNumber, Row, Col } from "antd";
import { Spin } from "antd";
import { Checkbox } from "../../layout/selection/checkbox";

export default function EditBlock() {
  let { id } = useParams(); //contentBlockId; //* Necessary to fetch;
  const [block, setBlock] = useState(null);
  const [isPreview, setIsPreview] = useState(true);

  async function createNewBlock() {
    let data = Object.assign({}, block);
    await createBlock(data);
  }
  async function loadBlock() {
    let response = await getBlock(id);
    setBlock(response);
  }
  const serialize = (nodes) => {
    return nodes.map((n) => Node.string(n)).join("\n");
  };

  function updateTitleRTF(content) {
    let blockCopy = Object.assign({}, block);
    blockCopy.title_rtf = content;

    if (Array.isArray(content)) {
      blockCopy.title = serialize(content);
    }

    setBlock(blockCopy);
    const saveContent = JSON.stringify(blockCopy);
    localStorage.setItem("content", saveContent);
  }

  function updateFragment(index, content) {
    let blockCopy = Object.assign({}, block);
    blockCopy.fragments[index] = content;
    setBlock(blockCopy);
    const saveContent = JSON.stringify(blockCopy);
    localStorage.setItem("content", saveContent);
  }
  async function saveBlockToDb() {
    let data = Object.assign({}, block);
    if (!!id && !block._id) data._id = id;
    await updateBlock(data);
  }
  function addFragment(nodeType) {
    let newFragment = [{ type: nodeType, children: [{ text: "" }] }];
    let blockCopy = Object.assign({}, block);
    blockCopy.fragments.push(newFragment);
    setBlock(blockCopy);
  }

  useEffect(() => {
    if (id) loadBlock();
  }, [id]);

  return (
    <div style={{ marginBottom: "250px" }}>
      <FontSizeRange />

      <h1>Block</h1>
      <Checkbox
        checked={isPreview}
        name="Preview"
        onChange={() => setIsPreview(!isPreview)}
      >
        Preview
      </Checkbox>
      <br />
      {!block ? (
        <div
          style={{
            margin: "auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spin tip="Loading content..." />
        </div>
      ) : (
        <>
          <label>Title</label>
          {block && block.title_rtf && !isPreview ? (
            <ContentEditor
              placeholder="Block Title..."
              content={block.title_rtf}
              save={updateTitleRTF}
            />
          ) : (
            <Readable content={block.title_rtf} />
          )}
          <Wrapper>
            {block && block.fragments && isPreview ? (
              <Readable content={block.fragments[0]} />
            ) : (
              block.fragments.map((fragment, index) => (
                <ContentEditor
                  placeholder="This is a testable section..."
                  fragmentIndex={{ blockId: id, fragmentIndex: index }}
                  content={fragment}
                  save={(c) => updateFragment(index, c)}
                  key={`slateEditor${index}`}
                />
              ))
            )}
          </Wrapper>
        </>
      )}
      <button
        onClick={() => {
          saveBlockToDb();
        }}
      >
        Save block to Db
      </button>
      <button
        className="btn-right"
        onClick={() => {
          addFragment("audio");
        }}
      >
        Add Video block
      </button>
      <button
        className="btn-right"
        onClick={() => {
          addFragment("audio");
        }}
      >
        Add new audio block
      </button>
      <button
        className="btn-right"
        onClick={() => {
          addFragment("paragraph");
        }}
      >
        Add new paragraph block
      </button>
    </div>
  );
}

const FontSizeRange = () => {
  const { fontSize, updateFont } = useContext(FontContext);
  return (
    <div style={{ background: "transparent", padding: "15px" }}>
      <h3>Change Font Globally :D</h3>

      <Row>
        {/* <Col span={12}>
          <Slider
            min={10}q
            max={30}
            onChange={updateFont}
            value={typeof fontSize === "number" ? fontSize : 20}
          />
        </Col> */}
        <Col span={4}>
          <InputNumber
            min={10}
            max={30}
            style={{ margin: "0 16px" }}
            value={fontSize || 20}
            onChange={updateFont}
          />
        </Col>
      </Row>
    </div>
  );
};
