import React, { useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../helpers/auth";
import { UserContext } from "../../helpers/hooks/userContext";
import styled from "styled-components";
import { Box } from "../layout/containers/box";
import { TextField } from "../layout/containers/input";
import { Button } from "../layout/buttons/buttons";

const TextButton = styled(Button)`
  background: #222;

  color: #666;
  font-size: 13px;
  margin: 0;
  border: 1px solid #333;
  padding: 5px 8px;
  &:hover {
    color: snow;
  }
  &:hover.teal {
    background: var(--spring-green);
  }
`;
const Splitter = styled.div`
  width: 33%;
  margin: 1em auto;
  height: 1px;
  background: #333;
`;

export default function LoginPage() {
  let navigate = useNavigate();
  let location = useLocation();
  let auth = useAuth();

  let from = location.state?.from?.pathname || "/";

  function handleNavToSignup(e) {
    navigate("/signup");
  }
  function handleNavToForgotPass() {
    navigate("/forgot-password");
  }
  async function handleSubmit(event) {
    event.preventDefault();

    let formData = new FormData(event.currentTarget);
    let email = formData.get("email");
    let password = formData.get("password");
    let url = window.location.hostname.indexOf("localhost") > -1 ? "http://localhost:8050/users/login" : "https://auth.sohaibahmed.ca/users/login";

    return await auth
      .signin(url, { email, password })
      .then((decodedToken) => {
        // typeof decodedToken === "object" && {id, email, name, role, iat} (all strngs for now)
        navigate(from);
      })
      .catch((err) => {
        alert("Error! Couldn't log in");
      });
  }

  return (
    <Box type="column" width="min-content" margin="0 auto auto auto" minHeight="500px" justifyContent="space-between">
      <h2 style={{ fontWeight: "300", color: "#888" }}>
        Sign in to <span style={{ color: "#999" }}>99point9</span>
      </h2>

      {from !== "/" ? <small>You must log in to view the page at {from}</small> : null}
      <form onSubmit={handleSubmit}>
        <Box type="column" width="min-content" background="none" justifyContent="space-between" margin="auto 0 0 0">
          <Splitter />
          <div>
            <TextField name="email" label="Email" isDecorated={true} wrapStyles={{ margin: "1em 0", minWidth: "350px" }} onChange={() => {}} />

            <TextField name="password" type="password" label="Password" isDecorated={true} wrapStyles={{ margin: "1em 0", minWidth: "350px" }} onChange={() => {}} />
          </div>
          <Button type="submit" style={{ width: "100%" }}>
            Login
          </Button>
          <Splitter />
        </Box>
      </form>

      <Box type="row" background="none" justifyContent="space-between" padding="0">
        <TextButton type="button" onMouseDown={(e) => handleNavToSignup(e)}>
          No account, eh? Sign-up here!
        </TextButton>
        <TextButton type="button" className="teal" onMouseDown={(e) => handleNavToForgotPass(e)}>
          Recover password
        </TextButton>
      </Box>
    </Box>
  );
}
