import { useState, useEffect } from "react";
import { Box } from "../layout/containers/box";
import { RounderBtn } from "../layout/buttons/buttonStyles";
import { CloseBtn, CopyToClipboard } from "../layout/buttons/buttons";
import { TextField } from "../layout/containers/input";
// import {AsyncSelect}

import { GET_Docs, GET_DocCount, GET_nonLectureDocs, GET_SearchDocTitles } from "../../helpers/REST/api";
import { Tooltip } from "antd";
import { ModalElement } from "../../helpers/hooks/useModal";
import { SelectField } from "../layout/containers/input";
import { GET_DocBlocks } from "../../helpers/REST/api";

import { toast } from "react-toastify";

const AddDocumentModal = ({ isOpen, onSelect, onClose }) => {
  return (
    <ModalElement isOpen={isOpen} close={onClose} fade={isOpen} showClose={false}>
      <DocList isOpen={isOpen} onClose={onClose} onSelect={onSelect} />
    </ModalElement>
  );
};
export default AddDocumentModal;

const DocList = ({ headings = ["Title", "Owner", "Audience", "Modified", "Created"], onSelect, onClose }) => {
  const [activeRow, setActive] = useState(-1);
  const [field, setField] = useState("");
  const [filtered, setFiltered] = useState([]);
  const [data, setData] = useState([]);
  const [fetchedBlocks, setFetchedBlocks] = useState([]);
  const [selectedDocBlocks, setSelectedBlocks] = useState([]);

  //METHODS

  function fetchSearched(f) {
    console.log(f);
    GET_SearchDocTitles(f).then((result) => {
      setFiltered(result);
    });
  }
  function loadAll() {
    GET_nonLectureDocs().then((d) => setData(d));
  }

  function handleSelect() {
    if (activeRow === -1) {
      toast.warn("You haven't selected a document to replicate, bud");
    } else if (selectedDocBlocks.length === 0) {
      toast.warn("You haven't selected any blocks to go with that document, bud");
    } else {
      onSelect({ _id: data[activeRow]._id, children: [...selectedDocBlocks] });
    }
  }
  useEffect(() => {
    if (activeRow > -1) {
      toast.warn("Select the order of blocks you want in this lecture document!", { duration: 1500 });
      GET_DocBlocks(data[activeRow]._id).then((blocks) => {
        let result = blocks.map((b) => {
          return {
            label: `_${b.title}_ (w. ${b.doc_elements_rtf.length} elems)`,
            value: b._id,
            key: b._id,
          };
        });

        setFetchedBlocks(result);
      });
    }
  }, [activeRow, data]);
  useEffect(() => {
    toast.success("Block selected. Make sure the correct document is selected before saving");
  }, [selectedDocBlocks]);
  //USE EFFECT: (2)
  useEffect(() => {
    const filterCb = (row, ri) => {
      if (row.title.toLowerCase().includes(field.toLowerCase())) {
        return row;
      }
      return null;
    };
    const handleFilter = (data) => {
      let result = data.filter(filterCb);
      setFiltered(result);
    };
    if (field.length !== 0) {
      handleFilter(data);
    } else if (field.length === 0) {
      setFiltered([]);
    }
  }, [field, data]);

  return (
    <Box type="column" padding="none" style={{ borderRadius: "var(--border-radius-large)", height: "80dvh" }} role="table" width="60vw">
      <Heading onClose={onClose} />
      <Search onSearch={fetchSearched} onFilter={setField} field={field} />
      {fetchedBlocks && (
        <div
          style={{
            width: "75%",
            visibility: fetchedBlocks.length ? "visible" : "hidden",
          }}
        >
          <SelectField
            isMulti
            options={fetchedBlocks}
            placeholder={activeRow > -1 ? `Blocks for: ${data[activeRow].title}` : "Select a document first"}
            noOptionsMessage={() => "name not found"}
            onChange={(e) => {
              if (Array.isArray(e)) {
                setSelectedBlocks(e.map((i) => i.value));
              }
            }}
          />
        </div>
      )}

      <HeadRow headings={headings} />
      <div style={{ overflowY: "auto", width: "100%", height: "auto" }}>
        {filtered.length > 0
          ? filtered.map((row, ri) => (
              <>
                <Row onMouseDown={() => setActive(ri)} isActive={activeRow === ri} key={"User_data_row" + ri}>
                  <Cell isActive={activeRow === ri} content={row.title} index={0} />
                  <Cell isActive={activeRow === ri} content={row.status} />
                  <Cell isActive={activeRow === ri} content={row.isVerified} />
                  <Cell isActive={activeRow === ri} content={row.audience} />
                  <Cell isActive={activeRow === ri} content={row.cell} />
                </Row>
              </>
            ))
          : data &&
            data.map((row, ri) => (
              <div key={"User_data_row" + ri}>
                <Row onMouseDown={() => setActive(ri)} isActive={activeRow === ri}>
                  <Cell isActive={activeRow === ri} content={row.title} index={0} />
                  <Cell isActive={activeRow === ri} content={row.au} />
                  <Cell isActive={activeRow === ri} content={row.audience} />
                  <DateCell isActive={activeRow === ri} content={row.updatedAt} />
                  <DateCell isActive={activeRow === ri} content={row.createdAt} />
                </Row>
              </div>
            ))}
      </div>
      <Footer fetchCount={data && data.length} loadAll={loadAll} active={data && data[activeRow]} onSelect={handleSelect} onClose={onClose} />
    </Box>
  );
};

const Heading = ({ onClose }) => {
  return (
    <Box type="row" background="none" padding="1em 1em 0 1em" justifyContent="space-between">
      <h3>Add Document (Personal)</h3>
      <CloseBtn
        tooltip="Close Window"
        type={1}
        onMouseDown={() => {
          if (onClose) onClose();
        }}
      />
    </Box>
  );
};
const Search = ({ onSearch, onFilter, onClose, field }) => {
  const [text, setText] = useState("");
  const reset = () => {
    setText("");
    onFilter("");
  };
  const handleKeydown = ({ key }) => {
    if (key === "Enter" && text !== "") onFilter(text);
    if ((key === "Esc" || key === "Escape") && text !== "") reset();
  };
  return (
    <Box justifyContent="space-between" background={"none"} type="row" width="100%" alignItems="center" onKeyDown={(e) => handleKeydown(e)}>
      <CloseBtn disabled={text === ""} onMouseDown={() => reset()} />
      <div style={{ width: "70%" }}>
        <TextField
          placeholder="Search..."
          hasEditBtn={false}
          onChange={(v) => {
            setText(v);
          }}
          value={text}
          // isDecorated="true"
          width="90%"
        />
      </div>

      <RounderBtn
        disabled={text === ""}
        onMouseDown={() => {
          if (text.length) onFilter(text);
        }}
      >
        Filter
      </RounderBtn>
      <RounderBtn tooltip={"Searches exact text"} disabled={text === ""} onMouseDown={() => onSearch(text)}>
        Search
      </RounderBtn>
    </Box>
  );
};
const HeadRow = ({ headings, isActive, ...rest }) => {
  const [active, setActive] = useState(-1);
  return (
    <Box padding="0" background={"none"} type="row" width="100%" {...rest} style={{ cursor: "pointer" }}>
      {headings &&
        headings.map((h, hi) => (
          <HeaderCell
            index={hi}
            content={h}
            isActive={active === hi}
            key={"heading_" + hi + h}
            // onMouseDown={() => {
            //   setActive(hi);
            // }}
          />
        ))}
    </Box>
  );
};
const HeaderCell = ({ content, index, isActive, ...rest }) => {
  let dynaFlex = index === 0 ? "1 1 auto" : "1 1 auto";
  return (
    <Box
      type="row"
      background={isActive ? "#333" : "none"}
      width={index === 0 ? "20%" : "auto"}
      justifyContent="flex-start"
      style={{
        flex: dynaFlex,
        textAlign: "center",
        color: "grey",
        cursor: "pointer",
      }}
      {...rest}
    >
      {content}
    </Box>
  );
};
const Row = ({ children, isActive, ...rest }) => {
  const [bg, setBg] = useState("none");
  const mO = () => {
    if (!isActive && bg !== "var(--spring-green-hover)") {
      setBg("var(--spring-green-hover)");
    }
  };
  const mL = () => {
    if (!isActive && bg !== "none") {
      setBg("none");
    }
  };

  return (
    <Box
      padding="0"
      background={isActive ? "var(--spring-green)" : bg}
      type="row"
      width="100%"
      {...rest}
      style={{
        cursor: "pointer",
        color: bg !== "none" ? "snow" : "grey",
        backgroundOpacity: bg !== "none" ? "0.7" : "0",
      }}
      onMouseOver={() => mO()}
      onMouseLeave={() => mL()}
    >
      {children}
    </Box>
  );
};

const Cell = ({ content, index, isActive }) => {
  const [hover, sethover] = useState("none");
  let dynaFlex = index === 0 ? "1 1 auto" : "1 1 auto";

  const mO = () => {
    sethover(true);
  };
  const mL = () => {
    sethover(false);
  };
  return (
    <Box
      type="row"
      background="none"
      width="auto"
      justifyContent="center"
      style={{
        flex: dynaFlex,
        textAlign: "center",

        opacity: hover ? "0.8" : "1",
        color: isActive ? "snow" : "grey",
        maxWidth: "30%",
      }}
      onMouseOver={() => mO()}
      onMouseLeave={() => mL()}
    >
      {index === 0 ? (
        <span
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "200px",
            marginRight: "auto",
          }}
        >
          <CopyToClipboard
            style={{
              justifyContent: "flex-start",
              // flex: "row nowrap",
              width: "100%",
              whiteSpace: "nowrap",
            }}
          >
            <Tooltip title={content}>{content}</Tooltip>
          </CopyToClipboard>
        </span>
      ) : (
        <CopyToClipboard
          style={{
            justifyContent: "center",
            flex: "row nowrap",
            width: "min-content",
            whiteSpace: "nowrap",
          }}
        >
          {content}
        </CopyToClipboard>
      )}
    </Box>
  );
};

const Footer = ({ fetchCount, loadAll, fetch10More, active, onSelect, ...rest }) => {
  const [count, setCount] = useState("NA");
  useEffect(() => {
    if (count === "NA") GET_DocCount().then((d) => setCount(d?.count));
    //
  }, []);

  return (
    <Box justifyContent="space-between" background={"none"} type="row" width="100%" margin="auto 0 0 0" {...rest}>
      <RounderBtn
        // disabled={true}
        disabled={count <= fetchCount}
        onMouseDown={() => loadAll()}
      >
        Load All
      </RounderBtn>
      <small style={{ fontSize: "16px", color: "grey" }}>
        Showing: {fetchCount}/{count}
      </small>
      <Tooltip title="Make sure the document you want to select is highlighted">
        <RounderBtn disabled={!active} onMouseDown={() => onSelect(active)}>
          Select
        </RounderBtn>
      </Tooltip>
    </Box>
  );
};
const DateCell = ({ content, index, isActive }) => {
  const [hover, sethover] = useState("none");

  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",

      hour: "numeric",
      minute: "numeric",
    };
    const formattedDate = date.toLocaleDateString(undefined, options);
    return formattedDate;
  }
  let dynaFlex = index === 0 ? "2 1 auto" : "1 1 auto";

  const mO = () => {
    sethover(true);
  };
  const mL = () => {
    sethover(false);
  };
  return (
    <Box
      type="row nowrap"
      background="none"
      width="auto"
      justifyContent="flex-start"
      style={{
        flex: dynaFlex,
        textAlign: "center",
        overFlow: "hidden",
        opacity: hover ? "0.8" : "1",
        color: isActive ? "snow" : "grey",
        maxWidth: "20%",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        //fontSize: "10px",
      }}
      onMouseOver={() => mO()}
      onMouseLeave={() => mL()}
    >
      <Tooltip title={formatDate(content)}>
        <span
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "150px",
          }}
        >
          {formatDate(content)}
        </span>
      </Tooltip>
    </Box>
  );
};

// const Actions
