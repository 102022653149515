import Code from "./codeBlock";
import { QuestionSpan } from "../../editor/UI/styled";
import { Tooltip } from "antd";
import styled from "styled-components";
import { useSlate, useReadOnly } from "slate-react";
import { getAllCommentIdsFromText, CommentedText } from "../features/comment";
import { getAllIdsFromText, QuestionText } from "../features/question";

export default function Leafs(props) {
  let { attributes, children, leaf } = props;
  const commentThreads = getAllCommentIdsFromText(leaf);
  const questionIds = getAllIdsFromText(leaf);

  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }
  if (leaf.code) {
    children = <Code {...attributes}>{children}</Code>;
  }
  if (leaf.italic) {
    children = <em>{children}</em>;
  }
  if (leaf.underline) {
    children = <u>{children}</u>;
  }
  if (leaf.strikethrough) {
    children = <del>{children}</del>;
  }
  if (leaf.question) {
    children = <QuestionSpan>{children}</QuestionSpan>;
  }

  if (commentThreads.size > 0) {
    children = (
      <CommentedText
        {...attributes}
        commentThreads={commentThreads}
        textNode={leaf}
      >
        {children}
      </CommentedText>
    );
  }
  if (questionIds.size > 0) {
    children = (
      <QuestionSpan>
        <QuestionText {...attributes} questionIds={questionIds} textNode={leaf}>
          {children}
        </QuestionText>
      </QuestionSpan>
    );
  }

  return <span {...attributes}>{children}</span>;
}

// if (leaf.comment) {
//   children = (
//     <Tooltip contentEditable={false} title={leaf.comment}>
//       <Commented>{children}</Commented>
//     </Tooltip>
//   );
// }
