import React from "react";
import { Routes, Route } from "react-router-dom";

import LoginPage from "./components/pages/login";
import Signup from "./components/pages/signup";
//import Home from "./components/pages/home";
//import About from "./components/pages/about";
import Dashboard from "./components/pages/student/dashboard2.js";
import NoMatch from "./components/pages/404-notFound";
import CourseManager from "./components/pages/courseManager";
import MainLayout from "./components/layout/wrapper";
import CreateBlock from "./components/pages/concept";
import EditBlock from "./components/pages/blocks/edit_block.js";
import Blocks from "./components/pages/blocks/blocks.js";
import Products from "./components/pages/products/courses2";
import Gallery from "./components/pages/student/gallery";
import Lecture from "./components/pages/student/lecture";
import { AuthProvider, RequireAuth } from "./helpers/auth";
import { UserContextProvider } from "./helpers/hooks/userContext.js";
import AllComponents from "./components/pages/components";
import Player from "./components/pages/video";
import Pages from "./components/pages/index.js";
import Document from "./components/pages/documentSpace";
import Documents from "./components/pages/documentSpace/documents";
import CreateDoc from "./components/pages/documentSpace/createDoc";
import LecturePage from "./components/pages/lectures";
import Playlists from "./components/mediaTracks";
import VerifySignup from "./components/pages/verify";
import ProductGallery from "./components/pages/student/components/ProductGallery";
import StudentProfile from "./components/pages/student_profile";
import AdminQsOverview from "./components/pages/questions/adminQsOverview";
import UserList from "./components/UNUSED_users/list";

export default function App() {
  return (
    <AuthProvider>
      <UserContextProvider>
        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route
              index
              // element={
              //   <RequireAuth>
              //     <ProductGallery />
              //   </RequireAuth>
              // }
              element={<ProductGallery />}
            />
            <Route path="login" index element={<LoginPage />} />
            <Route path="signup" element={<Signup />}></Route>

            <Route path="/verify" element={<VerifySignup />} />
            <Route path="/verify/:token" element={<VerifySignup />} />

            <Route path="m" element={<Dashboard />}>
              <Route path=":settings" element={<Dashboard />} />
              <Route path=":home" element={<Dashboard />} />
            </Route>
            <Route path="gallery/:id" element={<Gallery />} />
            <Route path="learn/:id" element={<Lecture />} />
            <Route path="course_manager" element={<CourseManager />} />
            <Route path="pages" element={<Pages />} />
            <Route path="/students" element={<Dashboard />} />
            <Route path="/students/lecture/:unit_id/:doc_id" element={<Lecture />} />

            <Route path="blocks" element={<Blocks />} />
            <Route path="products" element={<Products />} />
            <Route path="edit_block/:id" element={<EditBlock />} />
            <Route path="create_block" element={<CreateBlock />} />
            <Route path="components" element={<AllComponents />} />
            <Route path="video" element={<Player />} />
            <Route
              path="arena"
              element={
                <RequireAuth>
                  <Dashboard />
                </RequireAuth>
              }
            />
            <Route
              path="hall_way"
              element={
                <RequireAuth>
                  <Dashboard />
                </RequireAuth>
              }
            />
            <Route path="/lessons/:doc_id" element={<LecturePage />} />
            <Route path="/documents" element={<Documents />} />
            <Route path="/documents/new" element={<CreateDoc />} />
            <Route path="/documents/:doc_id" element={<Document />} />
            <Route path="/documents/:doc_id/:block_id" element={<Document />} />
            <Route path="/playlists" title="Playlists" element={<Playlists />} />
            <Route path="/student-profile/:userId" element={<StudentProfile />} />
            <Route path="/questions" element={<AdminQsOverview />} />
            <Route path="/users" element={<UserList />} />
            <Route path="*" element={<NoMatch />} />
          </Route>
        </Routes>
      </UserContextProvider>
    </AuthProvider>
  );
}
