import { Box } from "../layout/containers/box";
import { useRef, useState } from "react";
import { TextField } from "../layout/containers/input";
import { POST } from "../../helpers/REST/api";
import { useNavigate, Link } from "react-router-dom";
import { Button } from "../layout/buttons/buttons";
import { toast } from "react-toastify";
import { Popover } from "antd";
import styled from "styled-components";
import { useAuth } from "../../helpers/auth";
const Splitter = styled.div`
  width: 33%;
  margin: 1em auto;
  height: 1px;
  background: #333;
`;
const TextButton = styled(Button)`
  background: none;
  color: #666;
  font-size: 13px;
  margin: 0;
  // border: 1px solid #333;
  padding: 5px;
  .highlight {
    color: var(--primary-color);
  }
  &:hover {
    color: snow;
    .highlight {
      color: snow;
    }
  }
`;

const hasAtleastTwoWords = (val) => val?.includes(" ");
const noDigitsOrSymbols = (val) => !!/^[a-zA-Z]+ [a-zA-Z]+$/.test(val);
const validEmail = (v) => !!/\S+@\S+\.\S+/.test(v);
const noParantheses = (val) => !val?.includes("(") && !val?.includes(")");
const noDashes = (val) => !val?.includes("-");
const noSymbols = (val) => /^[0-9]+$/.test(val);

const atLeastXChars = (val, x) => val?.length >= x;
const atLeastOneUppercase = (val) => /[A-Z]/?.test(val);
const atLeastOneLowercase = (val) => val && /[a-z]/?.test(val);
const atLeastOneDigit = (val) => /[0-9]/?.test(val);
const passMatch = (val, otherVal) => val && val === otherVal;

const Signup = () => {
  const formRef = useRef({});
  const [formVal, setFormVal] = useState({});
  const nav = useNavigate();
  const auth = useAuth();

  async function post() {
    let hasName = formRef.current.name;
    let hasEmail = formRef.current.email;
    let hasCont = formRef.current.contact;
    let hasPass = formRef.current.password;
    let hasConf = formRef.current.confirmPassword;
    if (hasName && hasEmail && hasCont && hasPass && hasConf) {
      let host = window.location.hostname;
      let url = host.indexOf("localhost") > -1 ? "http://localhost:8050/users/signup" : process.env.BE_AUTH ?? "https://auth.sohaibahmed.ca/users/signup";

      await auth
        .signup(url, { ...formRef.current })
        .then(() => {
          nav("/verify");
        })
        .catch((e) => {
          toast.warn("Uh oh! That didn't go through. Please try again, or try reaching out to us at info@99point9prep.com");
        });
    } else {
      if (!hasName) toast.warn("Can't submit: Name field looks empty");
      if (!hasEmail) toast.warn("Can't submit: Email field seems empty");
      if (!hasCont) toast.warn("We need your cell no.; We'll need it to send you a verification code");
      if (!hasPass) toast.warn("Password field is empty");
      if (!hasConf) toast.warn("Password-confirmation field is empty");

      // console.log({ ...formRef.current }, hasName, hasEmail, hasCont, hasPass, hasConf);
    }
  }
  function handleVal(key, val) {
    formRef.current[key] = val;
    setFormVal({ ...formVal, [key]: val.trim() });
  }
  const NamePopoverContent = () => {
    let val = formVal?.name;
    return (
      <Box type="col left" background="#444" width="300px" padding={"5px"}>
        <h3>Name</h3>
        <span>{atLeastXChars(val, 3) ? "✅" : "❌"} Is more than three characters</span>
        <span>{hasAtleastTwoWords(val) ? "✅" : "❌"} Has first and last name at least</span>
        <span>{noDigitsOrSymbols(val) ? "✅" : "❌"} Doesn't have any digits or symbols</span>
      </Box>
    );
  };
  const EmailPopoverContent = () => {
    let val = formVal?.email;
    return (
      <Box type="col left" background="#444" width="300px" padding={"5px"}>
        <h3>Email</h3>
        <span>{validEmail(val) ? "✅" : "❌"} Is a real email address</span>
      </Box>
    );
  };
  const ContactPopoverContent = () => {
    let val = formVal?.contact;
    return (
      <Box type="col left" background="#444" width="300px" padding={"5px"}>
        <h3>Cell # (To contact & send a verification code)</h3>
        <span>{noParantheses(val) ? "✅" : "❌"} Doesn't include parentheses</span>
        <span>{noDashes(val) ? "✅" : "❌"} Doesn't include dashes</span>
        <span>{noSymbols(val) ? "✅" : "❌"} Only has digits (e.g., don't include "+")</span>
        <span>{atLeastXChars(val, 10) ? "✅" : "❌"} Has at least 10 digits</span>
        <span>{atLeastXChars(val, 11) ? "✅" : "❌"} Includes Area Code (e.g. "1...")</span>
        <br />
        <small>Note: If you've entered the area code and you're receiving an error, you may have missed a digit (check that you've added 11 digits)</small>
      </Box>
    );
  };
  const PasswordPopoverContent = () => {
    let val = formVal?.password;
    return (
      <Box type="col left" background="#444" width="300px" padding={"5px"}>
        <h3>Password</h3>
        <span>{atLeastXChars(val, 8) ? "✅" : "❌"} Password is at least 8 characters</span>
        <span>{atLeastOneUppercase(val) ? "✅" : "❌"} Password is at least one capital letter</span>
        <span>{atLeastOneLowercase(val) ? "✅" : "❌"} Password is at least one lowercase letter</span>
        <span>{atLeastOneDigit(val) ? "✅" : "❌"} Password has at least one digit in it</span>
        <br />
        <small>
          <strong>Pro-tip</strong>
          <br />
          1) How do you come up with strong passwords that you don't forget? Don't use words as passwords; use phrases!
          <br />
          2) Keep the phrase quirky and specific to that website ;)
        </small>
        <br />
        <small>
          <em>Example</em>: "1+1 is Eleven! Duh doi"
        </small>

        <small>
          <em>Example</em>: "-_- looks like 1 annoyed person!"
        </small>
      </Box>
    );
  };
  const PassMatchPopoverContent = () => {
    let val = formVal?.confirmPassword;
    return (
      <Box type="col left" background="#444" width="300px" padding={"5px"}>
        <h3>Confirm Password</h3>
        <span>{passMatch(val, formVal?.password) ? "✅" : "❌"} Password match</span>
        <span>{atLeastXChars(val, 8) ? "✅" : "❌"} Password is at least 8 characters</span>
        <span>{atLeastOneUppercase(val) ? "✅" : "❌"} Password is at least one capital letter</span>
        <span>{atLeastOneLowercase(val) ? "✅" : "❌"} Password is at least one lowercase letter</span>
        <span>{atLeastOneDigit(val) ? "✅" : "❌"} Password has at least one digit in it</span>
      </Box>
    );
  };

  return (
    <Box type="column" margin="0 auto auto auto" minHeight="200px" width="min-content" justifyContent="space-between">
      <h2 style={{ fontWeight: "300", color: "#888" }}>
        Create your <span style={{ color: "#999" }}>99point9</span> account
      </h2>

      <Box type="column" width="min-content" background="none" justifyContent="space-between" margin="auto 0 0 0">
        <Splitter />
        <Popover content={<NamePopoverContent />} placement="right" style={{ position: "absolute" }}>
          <TextField
            isDecorated={true}
            wrapStyles={{ margin: "1em 0", minWidth: "350px" }}
            name="name"
            label="Full Name"
            onChange={(v) => {
              handleVal("name", v);
            }}
          />
        </Popover>
        <Box type="row" justifyContent="space-between" background="none" padding="0" minWidth="375px">
          <Popover content={<EmailPopoverContent />} placement="right" style={{ position: "absolute" }}>
            <TextField
              isDecorated={true}
              wrapStyles={{ margin: "1em 0", minWidth: "350px" }}
              name="email"
              onChange={(v) => {
                handleVal("email", v);
              }}
              label="Email (@)"
            />
          </Popover>
          <Popover content={<ContactPopoverContent />} placement="right" style={{ position: "absolute" }}>
            <TextField isDecorated={true} wrapStyles={{ margin: "1em 0", minWidth: "350px" }} name="contact" onChange={(v) => handleVal("contact", v)} label={"Cell Number"} />
          </Popover>
        </Box>
        <Box type="row" justifyContent="space-between" background="none" minWidth="375px" padding="0">
          <Popover content={<PasswordPopoverContent />} placement="right" style={{ position: "absolute" }}>
            <TextField
              isDecorated={true}
              wrapStyles={{ margin: "1em 0", minWidth: "350px" }}
              placeholder="Needs to be at least 8 characters"
              name="password"
              onChange={(v) => {
                handleVal("password", v);
              }}
              label={"Password"}
              type="password"
            />
          </Popover>
          <Popover content={<PassMatchPopoverContent />} placement="right" style={{ position: "absolute" }}>
            <TextField
              isDecorated={true}
              wrapStyles={{ margin: "1em 0", minWidth: "350px" }}
              placeholder="Needs to be at least 8 characters"
              name="confirmPassword"
              onChange={(v) => handleVal("confirmPassword", v)}
              type="password"
              label={"Confirm Password"}
            />
          </Popover>
        </Box>
        <Button
          onMouseDown={() => {
            post();
          }}
          style={{ width: "100%" }}
        >
          Sign up
        </Button>
        <Splitter />
      </Box>

      <Link to={"/login"}>
        <TextButton>
          Already have an account? You can{" "}
          <span className="highlight">
            <strong>log-in </strong>
          </span>
          here
        </TextButton>
      </Link>
    </Box>
  );
};
export default Signup;
