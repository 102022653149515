import { removeToolbar, centerToolbar } from "./toolbarMethods";
import { useRef, useContext, useEffect } from "react";
import { useSlate } from "slate-react";
import Portal, { Menubar } from "./portal";
import { EditorContext } from "../hooks";
import { Tooltip } from "antd";
import uuid4 from "uuid4";
import { CommentContext } from "../hooks";

import { IconButton } from "../../editor/tools/hoverStyles";
import { customList, formatTypes, elementalBlockList } from "./definitions";
import { Box } from "../../layout/containers/box";
import { BlockSelect } from "./blockDropdown";

function IconRow({ toolbarOpen, iconList }) {
  //Hooks
  const editor = useSlate();
  const { addCommentIdAndDataToContext } = useContext(CommentContext);

  return iconList.map((format, index) => {
    const { type, Icon, title, isActive, toggle, customToggle, disabled } =
      formatTypes[format];

    let isActivated = isActive(editor, type, format); // used for majority of icons

    let isDisabled; // For comments
    if (disabled) {
      isDisabled = !!disabled(editor) || undefined;
      // console.log(!!disabled(editor));
    }
    function manageTitle() {
      if (title === "Comment" && !!isDisabled) {
        return `Max comments in selection; ${title} disabled`;
      }
      return title;
    }
    function handleToggle(event) {
      event.preventDefault();
      if (title === "Comment" || title === "Add Question" || !toggle) {
        customToggle(editor, addCommentIdAndDataToContext);
      } else {
        toggle(editor, type, format, event);
      }
    }

    //Prevent unnecessary renders
    if (!toolbarOpen) {
      return null;
    }
    return (
      <Tooltip title={manageTitle()} color="pink" key={uuid4()}>
        <IconButton
          active={isActivated}
          onMouseDown={(event) => {
            handleToggle(event);
          }}
          disabled={isDisabled}
        >
          <Icon />
        </IconButton>
      </Tooltip>
    );
  });
}

export default function Toolbar({ fontSize, width }) {
  //!: This renders the toolbar once, and hence it doesn't update as you keep selecting.
  //TODO: Later, make it so it updates with each selection focus change or that it's at the bottom center of a page like a dock.
  const editor = useSlate();
  const { toolbarOpen } = useContext(EditorContext);

  const toolbarRef = useRef(null);

  useEffect(() => {
    if (!toolbarRef?.current) return;
    if (!editor) return;
    if (!toolbarOpen) {
      removeToolbar(toolbarRef.current);
      return;
    } else {
      if (typeof window !== "undefined") {
        // positionToolbar(toolbarRef.current);
        centerToolbar(toolbarRef.current);
        return;
      }
    }
  }, [toolbarOpen]);

  return (
    <Portal>
      <Menubar ref={toolbarRef}>
        <Box type="row nowrap" background="white" padding="3px" width="100%">
          <BlockSelect />
          <IconRow toolbarOpen={toolbarOpen} iconList={customList} />
        </Box>
      </Menubar>
    </Portal>
  );
}

//Aka "BlockToolbar"
export const ToolbarAddsElem = function ({ fontSize, width }) {
  const editor = useSlate();
  const { blockToolbarOpen } = useContext(EditorContext);

  const toolbarRef = useRef(null);

  useEffect(() => {
    if (!toolbarRef?.current) return;
    if (!editor) return;
    if (!blockToolbarOpen) {
      removeToolbar(toolbarRef.current);
      return;
    } else {
      if (typeof window !== "undefined") {
        // positionToolbar(toolbarRef.current);
        centerToolbar(toolbarRef.current);
        return;
      }
    }
  }, [blockToolbarOpen]);
  return (
    <Portal>
      <Menubar ref={toolbarRef} style={{ position: "fixed", bottom: "2em" }}>
        <Box type="row nowrap" background="white" padding="3px" width="100%">
          <IconRow
            toolbarOpen={blockToolbarOpen}
            iconList={elementalBlockList}
          />
        </Box>
      </Menubar>
    </Portal>
  );
};
