import { DropWrap, Row } from "../styles/dropdown";
import { useState, useRef, useEffect } from "react";
import { blockList, formatTypes } from "./definitions";
import { Box } from "../../layout/containers/box";
import { useSlate } from "slate-react";
import uuid4 from "uuid4";

const Drop = ({ rows, title }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropRef = useRef(null);
  useEffect(() => {
    if (isOpen && dropRef?.current) {
      let rect = dropRef.current.getBoundingClientRect();
      let bottom = rect.bottom;
      if (window.innerHeight < bottom) {
        let height = rect.height;
        dropRef.current.style.top = -1 * height - 25 + "px";
      }
    }
  }, [isOpen]);
  const editor = useSlate();
  function RowItem(format) {
    const { type, title, Icon, isActive, toggle } = formatTypes[format];

    return (
      <Row
        key={uuid4()}
        onMouseDown={(e) => {
          e.preventDefault();
          toggle(editor, type, format);
          setIsOpen(!isOpen);
        }}
        active={isActive(editor, type, format)}
      >
        <>{title} </>
        <Icon />
      </Row>
    );
  }
  return (
    <DropWrap
      isOpen={isOpen}
      onMouseDown={(e) => {
        e.preventDefault();
        setIsOpen(!isOpen);
      }}
      // onClick={(e) => {
      //   e.preventDefault();
      //   setIsOpen(!isOpen);
      // }}
    >
      {title || "Select..."}
      {isOpen && rows?.length && (
        <Box
          fwdRef={dropRef}
          style={{
            position: "absolute",
            top: "75px",

            overflowY: "scroll",
          }}
          padding="0"
          margin="0"
          background="white"
          width="150px"
          type="col left"
          overflowY="scroll"
          height="250px"
        >
          {rows.map((type, i) => RowItem(type))}
        </Box>
      )}
    </DropWrap>
  );
};

export const BlockSelect = () => {
  return <Drop rows={blockList} title={"Block Type"} />;
};
