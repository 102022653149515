import { handleInline } from "./withInlines";
import { isBlockActive, toggleBlock, toggleMark, isMarkActive } from "./format";
import { Editor } from "slate";

/**
 *
 * @param {*} editor Editor ref or useSlate from slate.
 * @param {String} elementType e.g. "mark"
 * @param {String} format e.g. "bold"
 * @returns
 */
export function toggleButton(editor, elementType, format) {
  const textIsSelected = editor && editor.selection !== null;
  // let prevSelection = editor.selection;
  // Editor.nodes()
  if (elementType === "inline" && textIsSelected) {
    if (handleInline.isActive(editor, format)) {
      handleInline.unwrap(editor, format);
    } else {
      handleInline.insertElement(editor, format);
    }
  } else if (elementType === "block") {
    toggleBlock(editor, format);
  } else if (elementType === "mark") {
    toggleMark(editor, format);
  }
}
/**
 *
 * @param {*} editor Editor ref or useSlate from slate.
 * @param {String} elementType e.g. "mark"
 * @param {String} format e.g. "bold"
 * @returns
 */
export function isButtonActive(editor, elementType, format) {
  if (elementType === "inline") {
    return handleInline.isActive(editor, format);
  } else if (elementType === "block") {
    return isBlockActive(editor, format);
  } else if (elementType === "mark") {
    return isMarkActive(editor, format);
  }
}

// !NOT NEEDED ATM.

// export const markMouseEvent = (event, editor, format) => {};
// export const blockMouseEvent = (event, editor, format) => {};
// export const formatMouseEvent = (event, editor, format) => {};
