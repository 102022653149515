import React, { useState, useEffect } from "react";
import { TrackWrap, MediaInputWrap } from "./styled";
import { AudioQuestions } from "./addQs";
import { Collapse, Box } from "../../../layout/containers/box";
import { TextField, SelectField } from "../../../layout/containers/input";
import { Button } from "../../../layout/buttons/buttons";
import { update } from "react-spring";
export default function AddAudioTrackAndQuestions({
  tracks = [],
  addTrack,
  updateTrack,
  deleteTrack,
  transposeTrack,
  element,
}) {
  const template = {
    uri: "",
    fileName: "",
    duration: "",
    location: "",
    fileType: "",
  };
  const [activeTrack, setActiveTrack] = useState(0);
  const [showAddMedia, setShowAddMedia] = useState(false);
  const [showAddQuestion, setShowAddQuestion] = useState(false);
  const [newAudio, setNewAudio] = useState(
    Array.isArray(tracks) && tracks.length > 0 ? tracks[0] : template
  );

  let audioRef = React.useRef(null);
  const closeCollapse = () => {
    setShowAddMedia(false);
    setActiveTrack(null);
    setNewAudio(template);
  };
  useEffect(() => {
    if (tracks.length === 0) {
      setActiveTrack(null);
      setNewAudio(template);
    } else {
      setActiveTrack(tracks.length - 1);
      setNewAudio(tracks[tracks.length - 1]);
    }
  }, []);

  return (
    <>
      <TrackWrap contentEditable={false}>
        <ScrollableTrack
          activeTrack={activeTrack}
          tracks={tracks}
          setActiveTrack={setActiveTrack}
          setShowAddMedia={setShowAddMedia}
          setShowAddQuestion={setShowAddQuestion}
          showAddQuestion={showAddQuestion}
          setNewAudio={setNewAudio}
          template={template}
        />
      </TrackWrap>

      <Collapse
        isOpen={showAddMedia}
        onClose={closeCollapse}
        contentEditable={false}
        height="500px"
        title={activeTrack !== null ? "Update Track" : "Completely New Track"}
      >
        <Box type={"column"} alignItems={"left"} background="none">
          <AddOrEditNewTrack
            audioRef={audioRef}
            newAudio={newAudio}
            setNewAudio={setNewAudio}
          />
          <EditTrackButtons
            newAudio={newAudio}
            tracks={tracks}
            setActiveTrack={setActiveTrack}
            activeTrack={activeTrack}
            deleteTrack={deleteTrack}
            updateTrack={updateTrack}
            addTrack={addTrack}
            transposeTrack={transposeTrack}
          />
        </Box>
      </Collapse>
      <AudioQuestions
        setShow={setShowAddQuestion}
        show={showAddQuestion}
        element={element}
        handleSelect={(e, v) => {
          console.log(e, v);
        }}
      />
    </>
  );
}

const ScrollableTrack = ({
  activeTrack,
  tracks,
  setActiveTrack,
  setShowAddMedia,
  setShowAddQuestion,
  showAddQuestion,
  setNewAudio,
  template,
}) => (
  <>
    <span
      className="track"
      contentEditable={false}
      onMouseDown={() => {
        setActiveTrack(null);
        setShowAddMedia(true);
        setNewAudio(template);
      }}
      style={{
        background: "rgb(30,30,30)",
        fontSize: "15px",
        whiteSpace: "nowrap",
      }}
    >
      <>&#x2b; Track</>
    </span>
    <span className="overflow">
      {Array.isArray(tracks) &&
        tracks.map((track, i) => (
          <span
            className={`track ${i === activeTrack ? "track_active" : ""}`}
            key={`track_${track.fileName}_${i}`}
            onMouseDown={() => {
              setActiveTrack(i);
              setShowAddMedia(true);
              setNewAudio(track);
            }}
            contentEditable={false}
          >
            <span className="text bolder">
              {track.mediaType} Track {i + 1}
            </span>
            <span className="text">{track.fileName}</span>
          </span>
        ))}
    </span>
    <span
      className="track gradient-btn"
      // id="gradient-btn"
      contentEditable={false}
      onMouseDown={() => {
        setActiveTrack(null);
        setShowAddMedia(false);
        setShowAddQuestion(!showAddQuestion);
        setNewAudio(template);
      }}
      style={{
        // background: "rgb(30,30,30)",
        fontSize: "15px",
        whiteSpace: "nowrap",
      }}
    >
      <>&#x2b; Questions.</>
    </span>
  </>
);
const AddOrEditNewTrack = ({ audioRef, newAudio, setNewAudio }) => {
  function handleInput(value, key) {
    let updatedNewAudio;
    if (typeof value === "object" && key === undefined) {
      updatedNewAudio = Object.assign({}, newAudio, value);
    } else {
      updatedNewAudio = Object.assign({}, newAudio);
      updatedNewAudio[key] = value;
    }
    setNewAudio(updatedNewAudio);
  }
  function createTitle(text) {
    if (!text) return text;
    let title = text.split("/");
    title = title[title.length - 1]; //last item gets title
    title = title.split(".")[0]; // remove formatting at the end.
    title = title.replaceAll("%20", " "); // replace space
    title = title //Capitalize words
      .split(" ")
      .map((w) => w[0].toUpperCase() + w.slice(1))
      .join(" ");
    return title;
  }
  function editDropboxLink(v, ref) {
    let result = {};
    let text = v;
    let isDropboxLink =
      text.includes("dropbox.com") || text.includes("dropboxusercontent.com");
    if (v.indexOf(".com") > -1) {
      if (text.indexOf("www.") === -1) text = "www." + text;
      if (text.indexOf("https://") === -1) text = "https://" + text;
    }
    result.location = isDropboxLink ? "dropbox" : "other";
    text = text.replace("dropbox.com", "dl.dropboxusercontent.com");
    ref.value = text;
    result[ref.name] = text;
    handleInput(result);
  }
  function selectMediaType(option) {
    console.log(option);
    if (option?.value?.includes("link")) {
      console.log(option.fileType, option.mediaType);
      const { fileType, mediaType } = option;
      handleInput({ fileType, mediaType });
    }
  }
  if (!newAudio) return null;
  return (
    <>
      <Box
        type=" row"
        background="none"
        flexFlow="row nowrap"
        justifyContent="space-between"
      >
        <TextField
          name="uri"
          width="100%"
          label="Dropbox Link"
          defaultValue={newAudio.uri}
          onChange={(v, ref) => {
            editDropboxLink(v, ref);
          }}
        />
      </Box>
      <Box
        type=" row"
        background="none"
        flexFlow="row nowrap"
        justifyContent="space-between"
      >
        <SelectField
          classNamePrefix={"Select"}
          name="selectMediaType"
          width="45%"
          placeholder={"Select Media Type: Audio/Video"}
          onChange={(o) => selectMediaType(o)}
          options={[
            {
              label: "Add Video link",
              value: "link_video",
              fileType: "video/mp4",
              mediaType: "video",
            },
            {
              label: "Add Audio link",
              value: "link_audio",
              fileType: "audio/mp4",
              mediaType: "audio",
            },
            {
              label: "Upload New Audio file; Not Available",
              value: "upload_audio",
              isDisabled: true,
            },
            {
              label: "Upload New Video file; Not Available",
              value: "upload_video",
              isDisabled: true,
            },
          ]}
        />
        <TextField
          width="45%"
          style={{ fontSize: "14" }}
          name="fileName"
          placeholder="What do you want to call it?"
          defaultValue={newAudio.fileName}
          onChange={(v, ref) => {
            handleInput(v, ref.name);
          }}
          onMouseDown={(e) => {
            let format = createTitle(newAudio.uri);
            e.target.value = format;
            handleInput(format, e.target.name);
          }}
        />
      </Box>
      <Box type="row" background="none" justifyContent="space-between">
        <Box type="1 row centered" background="white" width="45%" padding="0">
          <audio ref={audioRef} controls src={newAudio.uri} />
        </Box>
        <TextField
          width="45%"
          name="duration"
          label="Click to get duration"
          type="number"
          defaultValue={newAudio.duration}
          onMouseDown={(e) => {
            if (!audioRef?.current?.duration) {
              alert("Something is wrong w. the link");
            } else {
              e.target.value = audioRef.current.duration;
              handleInput(audioRef.current.duration, e.target.name);
            }
          }}
          onChange={(v, ref) => handleInput(v, ref.name)}
        />
      </Box>
    </>
  );
};
const EditTrackButtons = ({
  activeTrack,
  tracks,
  deleteTrack,
  setActiveTrack,
  updateTrack,
  newAudio,
  addTrack,
  transposeTrack,
}) => {
  function moveRight() {
    let nextActive = activeTrack + 1;
    if (tracks.length - 1 <= [nextActive]) {
      setActiveTrack(nextActive);
      transposeTrack(nextActive - 1, nextActive);
    }
  }
  function moveLeft() {
    let nextActive = activeTrack - 1;
    if (nextActive !== -1) {
      setActiveTrack(nextActive);
      transposeTrack(nextActive + 1, nextActive);
    }
  }

  if (activeTrack === null) {
    return (
      <Button
        theme="primary"
        onMouseDown={() => {
          console.log("Save new track clicked");
          addTrack(newAudio, tracks.length);
        }}
      >
        Save New Track
      </Button>
    );
  } else {
    return (
      <Box
        type="1 row centered"
        background="none"
        padding="0"
        margin={"50px 0 0 0"}
        justifyContent="space-between"
      >
        <Button
          theme="primary"
          // className="edit-track-btn "
          onMouseDown={() => {
            let saveActive = activeTrack;
            if (!tracks) return;
            else if (tracks.length === 1) {
              setActiveTrack(null);
            } else if (tracks.length - 1 === activeTrack) {
              setActiveTrack(activeTrack - 1);
            }

            deleteTrack(saveActive);
          }}
        >
          Delete 🗑️
        </Button>
        <Button
          theme="white-black"
          onMouseDown={() => {
            moveLeft(activeTrack);
          }}
          disabled={activeTrack === 0 ? "disabled" : undefined}
        >
          &#8592; Move
        </Button>
        <Button
          theme="white-black"
          onMouseDown={() => {
            moveRight(activeTrack);
          }}
          disabled={
            tracks && activeTrack === tracks.length - 1 ? "disabled" : undefined
          }
        >
          Move &#8594;
        </Button>
        <Button
          theme="primary"
          onMouseDown={() => {
            updateTrack(activeTrack, newAudio);
          }}
        >
          Save 💾
        </Button>
      </Box>
    );
  }
};

{
  /*
        <h3 style={{ marginLeft: "50px" }}>
          {" "}
          {activeTrack !== null ? "Update Track" : "Completely New Track"}
        </h3>
        <span className="flex-row">
          <span className="media-input-wrap">
            <label htmlFor="uri">Dropbox Link</label>
            <input
              name="uri"
              type="text"
              placeholder="..."
              value={newAudio.uri}
              onChange={(e) => {
                let text = e.target.value;
                if (e.target.value.indexOf(".com") > -1) {
                  if (text.indexOf("www.") === -1) text = "www." + text;
                  if (text.indexOf("https://") === -1) text = "https://" + text;
                }
                text = text.replace("dropbox.com", "dl.dropboxusercontent.com");
                e.target.value = text;
                handleInput(text, e.target.name);
              }}
            />
          </span>
          <span className="media-input-wrap">
            <label htmlFor="fileName">Audio Title</label>
            <input
              name="fileName"
              type="text"
              placeholder="What do you want to call it?"
              value={newAudio.fileName}
              onChange={(e) => {
                handleInput(e.target.value, e.target.name);
              }}
              onMouseDown={(e) => {
                let format = createTitle(newAudio.uri);
                e.target.value = format;
                handleInput(format, e.target.name);
              }}
            />
          </span>
          <span className="media-input-wrap">
            <label>Test Meh...</label>
            <audio
              ref={audioRef}
              controls
              src={newAudio.uri}
              style={{ transform: "scale(0.7)" }}
            />
          </span>
          <span className="media-input-wrap">
            <label>Audio duration</label>
            <input
              name="duration"
              type="number"
              value={newAudio.duration}
              onMouseDown={(e) => {
                if (!audioRef?.current?.duration) {
                  alert("Something is wrong w. the link");
                } else {
                  e.target.value = audioRef.current.duration;
                  handleInput(audioRef.current.duration, e.target.name);
                }
              }}
              placeholder="Click me"
            />
          </span>
        </span>
        {activeTrack === null ? (
          <button
            className="edit-track-btn "
            onMouseDown={() => {
              addTrack(newAudio);
            }}
          >
            Save New Track
          </button>
        ) : (
          <span className="flex-row" style={{ marginTop: "15px" }}>
            <button
              className="edit-track-btn "
              onMouseDown={() => {
                let saveActive = activeTrack;
                if (!tracks) return;
                else if (tracks.length === 1) {
                  setActiveTrack(null);
                } else if (tracks.length - 1 === activeTrack) {
                  setActiveTrack(activeTrack - 1);
                }

                deleteTrack(saveActive);
              }}
            >
              Delete 😵 🗑️ 🗑️ 🗑️
            </button>
            <button
              onMouseDown={() => {
                moveLeft(activeTrack);
              }}
              disabled={activeTrack === 0 ? "true" : undefined}
            >
              &#8592; Move
            </button>
            <button
              onMouseDown={() => {
                moveRight(activeTrack);
              }}
              disabled={activeTrack === tracks.length - 1 ? "true" : undefined}
            >
              Move &#8594;
            </button>
            <button
              className="edit-track-btn "
              onMouseDown={() => {
                updateTrack(activeTrack, newAudio);
              }}
            >
              Save 😀 💾 💾 💾
            </button>
          </span>
        )} */
}
