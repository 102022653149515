import { Tooltip } from "antd";
import React from "react";
import styled, { keyframes } from "styled-components";

let iconHeight = "24";
let iconWidth = "24";
// let sidebarColor = "rgb(245, 0, 87, 0.1)"
let primaryPink = "rgb(20,20,20)"; //"rgb(20,20,20)" //"#f50057"
let iconColor = "black";
let pink = "#F50057";

const Blink = keyframes`
  0% {opacity: 0}
  50% {opacity: 1}
  100% {opacity: 0}
`;
const SVG = styled.svg`
  &:active {
    fill: #f50057;
  }
`;
const RecordAnimated = styled(SVG)`
  opacity: 1;
  transition: opacity 0.5s linear;
  animation: ${Blink} 2s linear 1s infinite ${({ animate }) => (animate ? "running" : "pause")};
`;
const SvgWrap = styled.div`
  box-sizing: border-box;
  padding: 8px;
  float: left;
  border: none;
  display: block;
  outline: 0;
  color: white;
  text-align: center;
  background-color: ${(props) => (props.active ? "blue" : "none")};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  flex-flow: column nowrap;
  cursor: pointer;
  margin: 2px;
  border: 1px solid transparent;
  & span {
    color: ${(props) => (props.stroke ? props.stroke : iconColor)};
    margin: 2px 0px;
  }
  & svg path {
    stroke: ${(props) => (props.stroke ? props.stroke : iconColor)};
  }
  &:hover {
    background-color: transparent;
    border: 1px solid ${pink};
    & span {
      color: ${(props) => (props.hoverColor ? props.hoverColor : primaryPink)};
    }
    & svg path {
      stroke: ${(props) => (props.hoverColor ? props.hoverColor : primaryPink)};
    }
  }
`;

const IconWrap = (props) => (
  <SvgWrap stroke={props.stroke} hoverColor={props.hoverColor}>
    {props.children}
  </SvgWrap>
);

export const Cross = (props) => (
  <SVG width={iconWidth} height={iconWidth} viewBox="0 0 24 24" fill="black" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="3" y="3" width="18" height="18">
      <path d="M0 16L8 8M16 0L8 8M8 8L0 0M8 8L16 16" transform="translate(4 4)" stroke="#F50A0A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </mask>
    <g mask="url(#mask0)">
      <rect width="24" height="24" />
    </g>
  </SVG>
);
export const Info = ({ size, color, round, ...rest }) => {
  if (round) {
    return (
      <SVG viewBox="64 64 896 896" focusable="false" data-icon="info-circle" width={size || "1em"} height={size || "1em"} fill={color} aria-hidden="true" {...rest}>
        <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
        <path d="M464 336a48 48 0 1096 0 48 48 0 10-96 0zm72 112h-48c-4.4 0-8 3.6-8 8v272c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V456c0-4.4-3.6-8-8-8z"></path>
      </SVG>
    );
  }
  return (
    <svg viewBox="64 64 896 896" focusable="false" data-icon="info" width={size || "1em"} height={size || "1em"} fill={color} aria-hidden="true" {...rest}>
      <path d="M448 224a64 64 0 10128 0 64 64 0 10-128 0zm96 168h-64c-4.4 0-8 3.6-8 8v464c0 4.4 3.6 8 8 8h64c4.4 0 8-3.6 8-8V400c0-4.4-3.6-8-8-8z"></path>
    </svg>
  );
};

export const Maximize = ({ size, color, round, ...rest }) => {
  return (
    <svg
      {...rest}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      width={size || "1em"}
      height={size || "1em"}
      fill="none"
      stroke={color || "currentColor"}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M15 3h6v6M9 21H3v-6M21 3l-7 7M3 21l7-7" />
    </svg>
  );
};
export const Plus = ({ size, color, round, ...rest }) => {
  if (round) {
    return (
      <svg viewBox="64 64 896 896" focusable="false" data-icon="plus-circle" width={size || "1em"} height={size || "1em"} fill={color} aria-hidden="true" {...rest}>
        <path d="M696 480H544V328c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v152H328c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h152v152c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V544h152c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8z"></path>
        <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
      </svg>
    );
  }

  return (
    <svg viewBox="64 64 896 896" focusable="false" data-icon="plus" width={size || "1em"} height={size || "1em"} fill={color} aria-hidden="true" {...rest}>
      <defs>
        <style></style>
      </defs>
      <path d="M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8z"></path>
      <path d="M176 474h672q8 0 8 8v60q0 8-8 8H176q-8 0-8-8v-60q0-8 8-8z"></path>
    </svg>
  );
};
export const BackArrow = ({ size, color, ...rest }) => {
  return (
    <SVG viewBox="64 64 896 896" focusable="false" data-icon="arrow-left" width={size || "24"} height={size || "24"} fill={color || "black"} aria-hidden="true" {...rest}>
      <path d="M872 474H286.9l350.2-304c5.6-4.9 2.2-14-5.2-14h-88.5c-3.9 0-7.6 1.4-10.5 3.9L155 487.8a31.96 31.96 0 000 48.3L535.1 866c1.5 1.3 3.3 2 5.2 2h91.5c7.4 0 10.8-9.2 5.2-14L286.9 550H872c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8z"></path>
    </SVG>
  );
};
export const Hamburger = ({ color, ...rest }) => (
  <SVG viewBox="0 0 100 80" width={iconWidth} height={iconWidth} {...rest}>
    <rect width="100" height="20" rx="8" fill={color} />
    <rect y="30" width="100" height="20" rx="8" fill={color} />
    <rect y="60" width="100" height="20" rx="8" fill={color} />
  </SVG>
);
export const Home = (props) => (
  <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" height={(props && props.height) || "48"} width={(props && props.height) || "48"}>
    <g transform="matrix(3.4285714285714284,0,0,3.4285714285714284,0,0)">
      <path fill="none" stroke={(props && props.stroke) || "#000000"} strokeLinecap="round" strokeLinejoin="round" d="M.5 7 7 .5 13.5 7m-11 1.5v5h9v-5"></path>
    </g>
  </SVG>
);
export const SettingSliderHorizontal = (props) => {
  return (
    <SVG viewBox="0 0 48 48" height={(props && props.height) || "48"} width={(props && props.width) || "48"} xmlns="http://www.w3.org/2000/svg">
      <g transform="matrix(3.4285714285714284,0,0,3.4285714285714284,0,0)">
        <path
          d="M0.500 2.000 A1.500 1.500 0 1 0 3.500 2.000 A1.500 1.500 0 1 0 0.500 2.000 Z"
          fill="none"
          stroke={(props && props.stroke) || "#000000"}
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
        <path d="M3.5 2L13.5 2" fill="none" stroke={(props && props.stroke) || "#000000"} strokeLinecap="round" strokeLinejoin="round"></path>
        <path
          d="M5.500 7.000 A1.500 1.500 0 1 0 8.500 7.000 A1.500 1.500 0 1 0 5.500 7.000 Z"
          fill="none"
          stroke={(props && props.stroke) || "#000000"}
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
        <path d="M0.5 7L5.5 7" fill="none" stroke={(props && props.stroke) || "#000000"} strokeLinecap="round" strokeLinejoin="round"></path>
        <path d="M8.5 7L13.5 7" fill="none" stroke={(props && props.stroke) || "#000000"} strokeLinecap="round" strokeLinejoin="round"></path>
        <path
          d="M10.500 12.000 A1.500 1.500 0 1 0 13.500 12.000 A1.500 1.500 0 1 0 10.500 12.000 Z"
          fill="none"
          stroke={(props && props.stroke) || "#000000"}
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
        <path d="M10.5 12L0.5 12" fill="none" stroke={(props && props.stroke) || "#000000"} strokeLinecap="round" strokeLinejoin="round"></path>
      </g>
    </SVG>
  );
};
export const ContentBookEdit = (props) => (
  <SVG viewBox="0 0 48 48" height={(props && props.height) || "48"} width={(props && props.height) || "48"} xmlns="http://www.w3.org/2000/svg">
    <g transform="matrix(3.4285714285714284,0,0,3.4285714285714284,0,0)">
      <path d="M8.5,13.5h-7a1,1,0,0,1-1-1V1.5a1,1,0,0,1,1-1h8" fill="none" stroke={(props && props.stroke) || "#000000"} strokeLinecap="round" strokeLinejoin="round"></path>
      <path d="M10.5,3.5,12,.5l1.5,3V12a1.5,1.5,0,0,1-3,0Z" fill="none" stroke={(props && props.stroke) || "#000000"} strokeLinecap="round" strokeLinejoin="round"></path>
      <path d="M10.5 9.5L13.5 9.5" fill="none" stroke={(props && props.stroke) || "#000000"} strokeLinecap="round" strokeLinejoin="round"></path>
      <path d="M3.5 0.5L3.5 13.5" fill="none" stroke={(props && props.stroke) || "#000000"} strokeLinecap="round" strokeLinejoin="round"></path>
      <path d="M5.5 3.5L8.5 3.5" fill="none" stroke={(props && props.stroke) || "#000000"} strokeLinecap="round" strokeLinejoin="round"></path>
    </g>
  </SVG>
);
export const AlignVerticalBottom = (props) => (
  <SVG viewBox="0 0 48 48" height={(props && props.height) || "48"} width={(props && props.height) || "48"} xmlns="http://www.w3.org/2000/svg">
    <g transform="matrix(3.4285714285714284,0,0,3.4285714285714284,0,0)">
      <path d="M13.5 13.5L0.5 13.5" fill="none" stroke={(props && props.stroke) || "#000000"} strokeLinecap="round" strokeLinejoin="round"></path>
      <path
        d="M7.250 5.750 L13.750 5.750 L13.750 9.750 L7.250 9.750 Z"
        fill="none"
        stroke={(props && props.stroke) || "#000000"}
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="translate(2.75 18.25) rotate(-90)"
      ></path>
      <path
        d="M-1.750 3.750 L8.750 3.750 L8.750 7.750 L-1.750 7.750 Z"
        fill="none"
        stroke={(props && props.stroke) || "#000000"}
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="translate(-2.25 9.25) rotate(-90)"
      ></path>
    </g>
  </SVG>
);
export const ContentBook = (props) => (
  <SVG viewBox="0 0 48 48" height={(props && props.height) || "48"} width={(props && props.height) || "48"} xmlns="http://www.w3.org/2000/svg">
    <g transform="matrix(3.4285714285714284,0,0,3.4285714285714284,0,0)">
      <path
        d="M12.5,13.5H3a1.5,1.5,0,0,1,0-3h8.5a1,1,0,0,0,1-1v-8a1,1,0,0,0-1-1H3A1.5,1.5,0,0,0,1.5,2V12"
        fill="none"
        stroke={(props && props.stroke) || "#000000"}
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path d="M11.5 10.5L11.5 13.5" fill="none" stroke={(props && props.stroke) || "#000000"} strokeLinecap="round" strokeLinejoin="round"></path>
    </g>
  </SVG>
);

export const OptionsVertical = ({ round, ...props }) => {
  if (round) {
    return (
      <SVG viewBox="0 0 48 48" focusable="false" data-icon="options-circle" height={props.size || "1em"} width={props.size || "1em"} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g transform="matrix(3.4285714285714284,0,0,3.4285714285714284,0,0)">
          <path
            d="M5.500 2.000 A1.500 1.500 0 1 0 8.500 2.000 A1.500 1.500 0 1 0 5.500 2.000 Z"
            fill={props.stroke ? props.stroke : "#000000"}
            stroke={props.stroke ? props.stroke : "#000000"}
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
          <path
            d="M5.500 7.000 A1.500 1.500 0 1 0 8.500 7.000 A1.500 1.500 0 1 0 5.500 7.000 Z"
            fill={props.stroke ? props.stroke : "#000000"}
            stroke={props.stroke ? props.stroke : "#000000"}
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
          <path
            d="M5.500 12.000 A1.500 1.500 0 1 0 8.500 12.000 A1.500 1.500 0 1 0 5.500 12.000 Z"
            fill={props.stroke ? props.stroke : "#000000"}
            stroke={props.stroke ? props.stroke : "#000000"}
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
        </g>
      </SVG>
    );
  }
  return (
    <IconWrap stroke={props.stroke ? props.stroke : "#000000"} hoverColor={props.hoverColor ? props.hoverColor : "#000000"}>
      <SVG viewBox="0 0 48 48" focusable="false" height={iconHeight} width={iconWidth} xmlns="http://www.w3.org/2000/svg">
        <g transform="matrix(3.4285714285714284,0,0,3.4285714285714284,0,0)">
          <path
            d="M5.500 2.000 A1.500 1.500 0 1 0 8.500 2.000 A1.500 1.500 0 1 0 5.500 2.000 Z"
            fill="none"
            stroke={props.stroke ? props.stroke : "#000000"}
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
          <path
            d="M5.500 7.000 A1.500 1.500 0 1 0 8.500 7.000 A1.500 1.500 0 1 0 5.500 7.000 Z"
            fill="none"
            stroke={props.stroke ? props.stroke : "#000000"}
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
          <path
            d="M5.500 12.000 A1.500 1.500 0 1 0 8.500 12.000 A1.500 1.500 0 1 0 5.500 12.000 Z"
            fill="none"
            stroke={props.stroke ? props.stroke : "#000000"}
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
        </g>
      </SVG>
    </IconWrap>
  );
};
export const OptionsHorizontal = (props) => {
  const { stroke, hoverColor, ...rest } = props;
  return (
    <IconWrap stroke={props.stroke ? props.stroke : "#000000"} hoverColor={props.hoverColor ? props.hoverColor : "#000000"} {...rest}>
      <SVG viewBox="0 0 48 48" height={iconHeight} width={iconWidth} xmlns="http://www.w3.org/2000/svg">
        <g transform="matrix(3.4285714285714284,0,0,3.4285714285714284,0,0)">
          <path
            d="M10.500 7.000 A1.500 1.500 0 1 0 13.500 7.000 A1.500 1.500 0 1 0 10.500 7.000 Z"
            fill="none"
            stroke={props.stroke ? props.stroke : "#000000"}
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
          <path
            d="M5.500 7.000 A1.500 1.500 0 1 0 8.500 7.000 A1.500 1.500 0 1 0 5.500 7.000 Z"
            fill="none"
            stroke={props.stroke ? props.stroke : "#000000"}
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
          <path
            d="M0.500 7.000 A1.500 1.500 0 1 0 3.500 7.000 A1.500 1.500 0 1 0 0.500 7.000 Z"
            fill="none"
            stroke={props.stroke ? props.stroke : "#000000"}
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
        </g>
      </SVG>
    </IconWrap>
  );
};

export const Grid = (props) => {
  const { stroke, hoverColor, ...rest } = props;
  return (
    <SVG viewBox="0 0 24 24" height={iconHeight} width={iconWidth} xmlns="http://www.w3.org/2000/svg" {...rest}>
      <rect x="3" y="3" width="7" height="7"></rect>
      <rect x="14" y="3" width="7" height="7"></rect>
      <rect x="14" y="14" width="7" height="7"></rect>
      <rect x="3" y="14" width="7" height="7"></rect>
    </SVG>
  );
};

export const CalendarDone = (props) => {
  const { stroke, hoverColor, ...rest } = props;
  return (
    <IconWrap stroke={props.stroke ? props.stroke : "#000000"} hoverColor={props.hoverColor ? props.hoverColor : "#000000"} {...rest}>
      <SVG viewBox="0 0 48 48" height={iconHeight} width={iconWidth} xmlns="http://www.w3.org/2000/svg">
        <g transform="matrix(3.4285714285714284,0,0,3.4285714285714284,0,0)">
          <path
            d="M1.5,2.5a1,1,0,0,0-1,1v9a1,1,0,0,0,1,1h11a1,1,0,0,0,1-1v-9a1,1,0,0,0-1-1h-2"
            fill="none"
            stroke={props.stroke ? props.stroke : "#000000"}
            strokeLinecap="round"
            strongLinejoin="round"
          ></path>
          <path d="M3.5 0.5L3.5 4.5" fill="none" stroke={props.stroke ? props.stroke : "#000000"} strokeLinecap="round" strongLinejoin="round"></path>
          <path d="M10.5 0.5L10.5 4.5" fill="none" stroke={props.stroke ? props.stroke : "#000000"} strokeLinecap="round" strongLinejoin="round"></path>
          <path d="M3.5 2.5L8.5 2.5" fill="none" stroke={props.stroke ? props.stroke : "#000000"} strokeLinecap="round" strongLinejoin="round"></path>
          <path d="M4 9L6 10.5 9.5 6.5" fill="none" stroke={props.stroke ? props.stroke : "#000000"} strokeLinecap="round" strongLinejoin="round"></path>
        </g>
      </SVG>
    </IconWrap>
  );
};

export const CalendarPending = (props) => {
  const { stroke, hoverColor, ...rest } = props;
  return (
    <IconWrap stroke={props.stroke ? props.stroke : "#000000"} hoverColor={props.hoverColor ? props.hoverColor : "#000000"} {...rest}>
      <SVG viewBox="0 0 48 48" height={iconHeight} width={iconWidth} xmlns="http://www.w3.org/2000/svg">
        {props.done ? (
          <g transform="matrix(3.4285714285714284,0,0,3.4285714285714284,0,0)">
            <path
              d="M1.5,2.5a1,1,0,0,0-1,1v9a1,1,0,0,0,1,1h11a1,1,0,0,0,1-1v-9a1,1,0,0,0-1-1h-2"
              fill="none"
              stroke={props.stroke ? props.stroke : "#000000"}
              strokeLinecap="round"
              strongLinejoin="round"
            ></path>
            <path d="M3.5 0.5L3.5 4.5" fill="none" stroke={props.stroke ? props.stroke : "#000000"} strokeLinecap="round" strongLinejoin="round"></path>
            <path d="M10.5 0.5L10.5 4.5" fill="none" stroke={props.stroke ? props.stroke : "#000000"} strokeLinecap="round" strongLinejoin="round"></path>
            <path d="M3.5 2.5L8.5 2.5" fill="none" stroke={props.stroke ? props.stroke : "#000000"} strokeLinecap="round" strongLinejoin="round"></path>
            <path d="M4 9L6 10.5 9.5 6.5" fill="none" stroke={props.stroke ? props.stroke : "#000000"} strokeLinecap="round" strongLinejoin="round"></path>
          </g>
        ) : (
          <g transform="matrix(3.4285714285714284,0,0,3.4285714285714284,0,0)">
            <path
              d="M1.5,2.5a1,1,0,0,0-1,1v9a1,1,0,0,0,1,1h11a1,1,0,0,0,1-1v-9a1,1,0,0,0-1-1h-2"
              fill="none"
              stroke={props.stroke ? props.stroke : "#000000"}
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path d="M3.5 0.5L3.5 4.5" fill="none" stroke={props.stroke ? props.stroke : "#000000"} strokeLinecap="round" strokeLinejoin="round"></path>
            <path d="M10.5 0.5L10.5 4.5" fill="none" stroke={props.stroke ? props.stroke : "#000000"} strokeLinecap="round" strokeLinejoin="round"></path>
            <path d="M3.5 2.5L8.5 2.5" fill="none" stroke={props.stroke ? props.stroke : "#000000"} strokeLinecap="round" strokeLinejoin="round"></path>
            <path d="M8.768 9.768L5.232 6.232" fill="none" stroke={props.stroke ? props.stroke : "#000000"} strokeLinecap="round" strokeLinejoin="round"></path>
            <path d="M8.768 6.232L5.232 9.768" fill="none" stroke={props.stroke ? props.stroke : "#000000"} strokeLinecap="round" strokeLinejoin="round"></path>
          </g>
        )}
      </SVG>
    </IconWrap>
  );
};
export const ArrowRight = (props) => {
  const { stroke, hoverColor, ...rest } = props;
  if (props.goodOne) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <line x1="5" y1="12" x2="19" y2="12"></line>
        <polyline points="12 5 19 12 12 19"></polyline>
      </svg>
    );
  }
  return (
    <IconWrap stroke={props.stroke ? props.stroke : "#000000"} hoverColor={props.hoverColor ? props.hoverColor : "#000000"} {...rest}>
      <SVG viewBox="0 0 48 48" height={iconHeight} width={iconWidth} xmlns="http://www.w3.org/2000/svg">
        <g transform="matrix(3.4285714285714284,0,0,3.4285714285714284,0,0)">
          <path d="M3.854.5,10,6.646a.5.5,0,0,1,0,.708L3.854,13.5" fill="none" stroke={props.stroke ? props.stroke : "#000000"} strokeLinecap="round" strokeLinejoin="round"></path>
        </g>
      </SVG>
    </IconWrap>
  );
};
export const Lock = ({ size, color, ...rest }) => {
  return (
    <svg viewBox="0 0 24 24" width={size || "1em"} height={size || "1em"} fill="none" stroke={color || "black"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" {...rest}>
      <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
      <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
    </svg>
  );
};

export const FullScreen = ({ stroke, color, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke={stroke || color || "black"}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...rest}
  >
    <polyline points="15 3 21 3 21 9"></polyline>
    <polyline points="9 21 3 21 3 15"></polyline>
    <line x1="21" y1="3" x2="14" y2="10"></line>
    <line x1="3" y1="21" x2="10" y2="14"></line>
  </svg>
);
/**
  Can be animated!
  @param {Boolean} animate Blinks the button
 */
export const Record = ({ animate, stroke, color, ...rest }) => {
  return (
    <RecordAnimated
      animate={animate}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={stroke || "red"}
      stroke={stroke || "red"}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...rest}
    >
      <circle cx="12" cy="12" r="10" />
    </RecordAnimated>
  );
};
export const Stop = ({ stroke, color, ...rest }) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={stroke || "black"}
    stroke={stroke || "black"}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...rest}
  >
    <rect x="3" y="3" width="18" height="18" rx="2" ry="2" />
  </SVG>
);
export const Play = ({ stroke, color, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="black" stroke={stroke || "black"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" {...rest}>
    <polygon points="5 3 19 12 5 21 5 3"></polygon>
  </svg>
);
export const Pause = ({ stroke, color, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke={stroke || color || "black"}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...rest}
  >
    <rect x="6" y="4" width="4" height="16"></rect>
    <rect x="14" y="4" width="4" height="16"></rect>
  </svg>
);
export const Sound = ({ volume, mute, stroke, color, ...rest }) => {
  let level = parseFloat(volume);
  let none = level === 0;
  let low = level < 0.3;
  let med = level < 0.6;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke={stroke || color || "black"}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...rest}
    >
      <polygon points="11 5 6 9 2 9 2 15 6 15 11 19 11 5"></polygon>
      {mute || none ? (
        <>
          <line x1="23" y1="9" x2="17" y2="15"></line>
          <line x1="17" y1="9" x2="23" y2="15"></line>
        </>
      ) : low ? null : med ? (
        <path d="M15.54 8.46a5 5 0 0 1 0 7.07"></path>
      ) : (
        <path d="M19.07 4.93a10 10 0 0 1 0 14.14M15.54 8.46a5 5 0 0 1 0 7.07"></path>
      )}
    </svg>
  );
  if (!level) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={stroke || color || "black"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <polygon points="11 5 6 9 2 9 2 15 6 15 11 19 11 5"></polygon>
        <line x1="23" y1="9" x2="17" y2="15"></line>
        <line x1="17" y1="9" x2="23" y2="15"></line>
      </svg>
    );
  } else if (level > 0.3 && level < 0.5) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        class="feather feather-volume-1"
      >
        <polygon points="11 5 6 9 2 9 2 15 6 15 11 19 11 5"></polygon>
        <path d="M15.54 8.46a5 5 0 0 1 0 7.07"></path>
      </svg>
    );
  } else if (parseFloat(volume) > 0.5 && level < 0.8) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={stroke || color || "black"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <polygon points="11 5 6 9 2 9 2 15 6 15 11 19 11 5"></polygon>
        <path d="M19.07 4.93a10 10 0 0 1 0 14.14M15.54 8.46a5 5 0 0 1 0 7.07"></path>
      </svg>
    );
  }
  {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={stroke || color || "black"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <polygon points="11 5 6 9 2 9 2 15 6 15 11 19 11 5"></polygon>
      </svg>
    );
  }
};
export const Audio = ({ stroke, color, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke={stroke || color || "currentColor"}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...rest}
    >
      <path d="M9 18V5l12-2v13"></path>
      <circle cx="6" cy="18" r="3"></circle>
      <circle cx="18" cy="16" r="3"></circle>
    </svg>
  );
};
export const Film = ({ stroke, color, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke={stroke || "currentColor"}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...rest}
    >
      <rect x="2" y="2" width="20" height="20" rx="2.18" ry="2.18"></rect>
      <line x1="7" y1="2" x2="7" y2="22"></line>
      <line x1="17" y1="2" x2="17" y2="22"></line>
      <line x1="2" y1="12" x2="22" y2="12"></line>
      <line x1="2" y1="7" x2="7" y2="7"></line>
      <line x1="2" y1="17" x2="7" y2="17"></line>
      <line x1="17" y1="17" x2="22" y2="17"></line>
      <line x1="17" y1="7" x2="22" y2="7"></line>
    </svg>
  );
};

export const FormEdit = ({ size, color, ...rest }) => (
  <SVG viewBox="64 64 896 896" width={size || iconHeight} height={size || iconHeight} fill={color || "inherit"} {...rest}>
    <path d="M904 512h-56c-4.4 0-8 3.6-8 8v320H184V184h320c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V520c0-4.4-3.6-8-8-8z"></path>
    <path d="M355.9 534.9L354 653.8c-.1 8.9 7.1 16.2 16 16.2h.4l118-2.9c2-.1 4-.9 5.4-2.3l415.9-415c3.1-3.1 3.1-8.2 0-11.3L785.4 114.3c-1.6-1.6-3.6-2.3-5.7-2.3s-4.1.8-5.7 2.3l-415.8 415a8.3 8.3 0 00-2.3 5.6zm63.5 23.6L779.7 199l45.2 45.1-360.5 359.7-45.7 1.1.7-46.4z"></path>
  </SVG>
);
export const Edit2 = ({ width, fill, stroke, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "24"}
      height={width || "24"}
      viewBox="0 0 24 24"
      fill={fill || "none"}
      stroke={stroke || "currentColor"}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...rest}
    >
      <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
    </svg>
  );
};
export const ExternalLink = ({ size, color, ...rest }) => (
  <svg viewBox="0 0 24 24" width={size || "1em"} height={size || "1em"} fill="none" stroke={color || "black"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" {...rest}>
    <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
    <polyline points="15 3 21 3 21 9"></polyline>
    <line x1="10" y1="14" x2="21" y2="3"></line>
  </svg>
);
export const CheckCircle = ({ stroke, color, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="transparent"
      stroke={stroke || "green"}
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...rest}
    >
      <path stroke={stroke || "grey"} d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
      <polyline points="22 4 12 14.01 9 11.01"></polyline>
    </svg>
  );
};
export const CautionCircle = ({ stroke, color, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="transparent"
      stroke={stroke || "orange"}
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...rest}
    >
      <circle cx="12" cy="12" r="10"></circle>
      <line x1="12" y1="8" x2="12" y2="12"></line>
      <line x1="12" y1="16" x2="12.01" y2="16"></line>
    </svg>
  );
};
export const Check = ({ stroke, color, style, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="5 0 20 20"
      fill="none"
      stroke={stroke || "green"}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      style={{ left: "0px", ...style }}
      {...rest}
    >
      <polyline points="22 4 12 14.01 9 11.01"></polyline>
    </svg>
  );
};
export const Help = ({ title, stroke, color, style, ...rest }) => {
  return (
    <Tooltip title={title}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="15"
        viewBox="0 0 24 24"
        fill="none"
        stroke={stroke || "#555"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        style={{ margin: "0 0.5em", position: "relative", top: "2px", ...style }}
        {...rest}
      >
        <circle cx="12" cy="12" r="10"></circle>
        <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
        <line x1="12" y1="17" x2="12.01" y2="17"></line>
      </svg>
    </Tooltip>
  );
};
const Shake = keyframes`
  0%{ transform: translateY(0px)}
  25% { transform: translateY(-0.5px)}
   50% { transform: translateY(-0px)}
    75% { transform: translateY(0.5px)}
  100% { transform: translateY(0px)};
`;
const UserWrap = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  color: #ccc;
  padding: 1px;
  border-radius: 50%;
  // border: 1px solid #ccc;
  // background: #222;
  transition: all 0.3s linear;
  user-select: none;
  margin: 0 5px;
  &:hover {
    border-color: white;
    transform: scale(1.01);
    circle {
      animation: ${Shake} 1s ease-in-out 1s infinite;
    }
  }
`;

export const User = ({ stroke, color, src, alt, width, fwdRef, ...rest }) => {
  return (
    <UserWrap ref={fwdRef}>
      {src ? (
        <img src={src} alt={alt} {...rest} />
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke={stroke || "currentColor"}
          strokeWidth="1"
          strokeLinecap="round"
          strokeLinejoin="round"
          {...rest}
        >
          <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" fill="#fff"></path>
          <circle cx="12" cy="8" r="4" fill="#fff"></circle>
        </svg>
      )}
    </UserWrap>
  );
};

export const NotifAlert = ({ active, fwdRef, ...rest }) => {
  return (
    <UserWrap ref={fwdRef} style={{ position: "relative", top: "2px" }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="22"
        viewBox="0 0 24 24"
        fill={active ? "currentColor" : "none"}
        stroke="currentColor"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...rest}
      >
        <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
        <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
      </svg>
    </UserWrap>
  );
};
export function Clipboard({ stroke, color, src, alt, width, ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "24"}
      height={width || "24"}
      viewBox="0 0 24 24"
      fill="none"
      stroke={stroke || "currentColor"}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
      <rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect>
    </svg>
  );
}
