import styled, { keyframes, css } from "styled-components";

export const PlayerWrap = styled.div`
  width: 100%;
  border-radius: 20px;
  padding: 15px;
  box-shadow: 0 28px 28px rgba(0, 0, 0, 0.2);
  margin: auto;
  color: white;
  &:focus {
    outline: 0;
  }
`;
export const TrackInfo = styled.div`
  text-align: center;
  z-index: 1;
  position: relative;
`;
export const Title = styled.h2`
  font-weight: 700;
  margin-bottom: 4px;
`;
export const Desc = styled.h3`
  font-weight: 300;
  margin-top: 0;
`;
export const Range = styled.input.attrs({ type: "range" })`
  height: 2px;
	-webkit-appearance: none;
	width: 100%;
	margin-bottom: 10px
	border-radius: 8px;
	background: #3b7677;
	transition: background 0.2s ease;
	cursor: pointer;
  -webkit-gradient: ${(props) =>
    `linear, 0% 25%, 50% 100%, color-stop(${props.currentPercentage}, #fff), color-stop(${props.currentPercentage}, #f50057)`};
  :focus {outline:0;}
`;
export const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  width: 75%;
  margin: 0 auto 15px;
  &:focus {
    outline: 0;
  }
  button {
    background-color: transparent;
    border: none;
    outline: none;
    :focus {
      outline: 0;
    }
  }
  .back-forth {
    transform: scale(0.9);

    svg {
      font-size: 50px;
      stroke-width: 60px;
      stroke: var(--body-background);
      color: var(--primary-color);
    }
    :active svg {
      stroke-width: 50px;
      transform: scale(0.95);
    }
    .button-text {
      font-size: 25px;
      font-weight: 100;
      color: white;
      position: relative;
      top: -13px;
      left: 33px;
      color: var(--primary-color);
    }
  }
  .pause-play {
    border: 1px solid rgb(15, 15, 15);
    padding: 4px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    margin-left: 10px;

    svg {
      font-size: 25px;
      color: var(--primary-color);
      path {
        stroke-width: 1px;
        stroke: var(--primary-color);
        stroke-width: 5;
      }
    }
  }
  .play svg {
    position: relative;
    left: 2px;
  }
  .play-wrap {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    border: 1px solid grey;
    width: 250px;
    border-radius: 50px;
    transform: scale(0.7);
    padding: 0 5px;
    justify-content: space-between;
    background: rgb(20, 20, 20);
    div {
      display: flex;
      flex-flow: row nowrap;
    }
  }
`;

const colorChange = keyframes`
  from {
    filter: hue-rotate(0deg);
  }
	to {
    filter: hue-rotate(360deg);
  }
`;
export const BackdropWrap = styled.div`
  background: linear-gradient(45deg, #00aeb0, 20%, transparent 100%) no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  animation: ${(props) =>
    props.isPlaying
      ? css`
          ${colorChange} 20s alternate infinite
        `
      : "undefined"};
`;

export const SrcWrap = styled.div`
  width: 100%;
  .content {
    padding: 0 18px;
    display: ${(props) => (props.collapsed ? "none" : "block")};
    overflow: hidden;
    background-color: #f1f1f1;
    max-height: ${(props) => (props.collapsed ? "0px" : "null")};
    background: ${(props) => (props.collapsed ? "grey" : "#fff")};
    cursor: pointer;
    outline: none;
    transition: max-height 0.2s ease-out;
  }
`;
export const TrackWrap = styled.span`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  max-height: 100px;
  box-sizing: content-box;
  height: 50px;
  background: rgb(40, 40, 40, 0.5);
  align-items: center;
  //pattern
  background-color: #000;
  opacity: 1;
  background-image: repeating-radial-gradient(
      circle at 0 0,
      transparent 0,
      #000 6px
    ),
    repeating-linear-gradient(#26262655, #262626);
  .track {
    background: grey;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: auto;
    padding: 0px 12px;
    border: 1px solid black;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 10px;
    margin-right: 10px;
    whitespace: nowrap;

    .text {
      display: inline-block;
      color: black;
      width: 125px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 12px;
      line-height: 16px;
    }
    .bolder {
      font-weight: 600;
    }
  }
  .track_active {
    background: white;
  }
  .overflow {
    height: 100%;
    overflow-x: auto;
    display: flex;
    flex-flow: row nowrap;
    width: 90%;
    /* background: rgb(245, 0, 87, 0.1); */
    /* margin: auto 0px; */
  }
  .overflow.track {
    white-space: normal;
    height: auto;
    margin-top: auto;
  }
  .overflow::-webkit-scrollbar {
    display: none;
  }
  .gradient-btn {
    background: -webkit-linear-gradient(right, #ab003c, #f50057);
  }
`;
export const MediaInputWrap = styled.div`
  width: 100%;
  height: auto;
  min-height: 300px;
  max-height: ${(props) => (props.show ? "800px" : "0px")};
  display: ${(props) => (props.show ? "flex" : "none")};
  flex-flow: column nowrap;
  background: rgb(40, 40, 40);
  padding: 10px;

  span {
    display: inline-block;
  }
  .gradient-btn {
    background: -webkit-linear-gradient(right, #ab003c, #f50057);
  }
  .close-btn {
    margin-left: auto;
    cursor: pointer;
    padding: 5px 13px;
    border: 1px solid black;
    background: black;
    box-sizing: border-box;
  }
  .flex-row {
    width: 90%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin: 0 auto;
  }
  .media-input-wrap {
    display: flex;
    flex-direction: column;
    margin: 3px 5px;

    label {
      display: inline-block;
      font-weight: 500;
      color: white;
    }
    input[type="text"] {
      display: inline-block;
      padding: 5px;
      width: 300px;
    }
  }
  .edit-track-btn {
    background: rgb(56, 9, 25, 0.5);
    /* background: #e50914; */
    font-weight: 500;
    max-width: 200px;
    padding: 5px 10px;
    color: white;
    margin: 0 auto;
    border-radius: 5px;
    cursor: pointer;
  }
  .edit-track-btn:hover {
    background: rgb(56, 9, 25);
    background: rgb(40, 0, 1);
  }
`;
