import styled from "styled-components";
import { HolderOutlined } from "@ant-design/icons";
import { BackArrow } from "../../../images/icons";
import { DropBtnWrap } from "../../layout/buttons/buttonStyles";

export const DropdownWrap = styled.div`
  width: auto;
  height: auto;
  max-height: ${({ isOpen }) => (isOpen ? "500px" : "0px")};
  overflowy: ${({ isOpen }) => (isOpen ? "scroll" : "hidden")};
  visibility: ${({ isOpen }) => (isOpen ? "visible" : "hidden")};
  opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
  transition: max-height 1s, opacity 1s, visibility 2s ease;
`;
export const IconBorder = styled.div`
  border-radius: 50%;
  transition: transform 0.2s linear;
  padding: 0;
  align-self: center;
  & svg {
    display: block;
  }
  & ${DropBtnWrap} {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
  & button svg {
    transform: scale(1) !important;
    stroke: ${(props) => props.color};
  }
  & button svg:hover {
    transform: scale(1.5);
  }
`;

//ROW (AKA the rectangle)
export const RowIconWrap = styled.div`
  position: relative;
  //left: -30px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 100px;
  cursor: pointer;
  align-items: center;
  & svg {
    // display: inline-block;
    // width: 20px;
    // height: 20px;
    margin: 0 5px;
    // padding: 5px;
  }
  & svg {
    &:hover {
      transform: scale(1.2);
    }
  }
`;

export const RowName = styled.div`
  min-width: 300px;
  align-self: start;
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  color: inherit;
  .type {
    width: min-content;
    font-size: 8px;
    padding: 3px;
    padding
    background: white;
    color: black;
    font-weight: 600;
  }
`;
export const RowLabel = ({ title, type, description }) => (
  <RowName>
    <div className="type">{type.toUpperCase()}</div>
    <div>{title}</div>
    <small>{description}</small>
  </RowName>
);
export const DndRowDragIcon = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: 30px;
  padding: 10px 10px;
  margin-right: 10px;
  cursor: ${({ isSelected }) => (isSelected ? "pointer" : "row-resize")};
  z-index: 2;
  &:hover {
    background: rgb(0, 0, 0, 0.2);
  }
`;

export const DndRowDragOrBackIcon = ({ isSelected, activate }) => {
  return (
    <DndRowDragIcon isSelected={isSelected} onClick={() => activate()}>
      {!isSelected ? <HolderOutlined /> : <BackArrow size="1em" color="white" />}
    </DndRowDragIcon>
  );
};
export const BackIcon = ({ isActive, activate }) => {
  if (!isActive) return null;
  return (
    <DndRowDragIcon isSelected={isActive}>
      <BackArrow size="1em" color="white" onClick={() => activate()} />
    </DndRowDragIcon>
  );
};
