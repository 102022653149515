export default function withEditableVoids(editor, fragmentIndex) {
  const { isVoid } = editor;
  editor.isVoid = (element) => {
    switch (element.type) {
      case "inline-question-void":
        return true;
      case "replace-input":
        return true;
      // case "audio":
      //   return true;
      default:
        return isVoid(element);
    }
  };
  editor.blockFragmentData = fragmentIndex;
  //current word.
  //current path

  return editor;
}
