import React, { useState, createContext, useEffect } from "react";
import { PUT, GET } from "../REST/api";
import jwt_decode from "jwt-decode";
import useSocket from "./useSocket";
import { useAuth } from "../auth";

export const UserContext = createContext({});

export function UserContextProvider({ children }) {
  const { user } = useAuth();
  const UID = user?.id;
  const socket = useSocket();
  const [PID, setPID] = useState("");

  const [MID, setMID] = useState("");
  const [standing, setStanding] = useState();
  const [studyPlan, setStudyPlan] = useState([]);
  const [studyTime, setStudyTime] = useState("");
  const [units, setUnits] = useState([]);
  const [lecDocs, setLecDocs] = useState([]);

  function setOverview(object) {
    setStanding(object.standing);
    // setStudyPlan(object.studyPlan);
  }

  function unlockIfActive(isActive, gateId, layerType) {
    if (isActive) {
      PUT(`/members/${MID}/gate/open`, { params: { gateId, layerType } });
    }
  }
  function getValidTicket() {
    let ticket = localStorage.getItem("ticket");
    let decoded = !!ticket && ticket !== "undefined" && jwt_decode(ticket);
    let ticketIsObject = typeof decoded === "object";
    let exp = typeof decoded.exp === "number" && decoded.exp;
    let isValid = Date.now() / 1000 < exp;
    console.log("Ticket validity: " + isValid);
    console.log("decoded", decoded, ticketIsObject, isValid);
    if (isValid && ticketIsObject) return decoded;
    return null;
  }

  // useEffect(() => {
  //   async function getMemberships() {
  //     return;
  //   }
  //   GET("/members/memberships").then((r) => {
  //     if (r && r.length === 1) {
  //       setMID(r[0]._id);
  //       setPID(r[0].productId);
  //     }
  //   });
  //   getMemberships();
  // }, []);
  // useEffect(() => {
  //   async function getPlan() {
  //     return GET(`/members/plan?memberId=${MID}&productId=${PID}`).then((r) => {
  //       console.log(r);
  //       setStudyPlan(r.studyPlan);
  //       setStanding(r.standing);
  //     });
  //   }

  //   if (MID && PID && studyPlan.length === 0) {
  //     getPlan();
  //   }
  // }, [MID, PID, studyPlan]);
  // useEffect(() => {
  //   if (socket !== null) {
  //     socket.emit("changeId", { UID });
  //   }
  // }, [UID]);
  return (
    <UserContext.Provider
      value={{
        PID,
        setPID,
        UID,
        MID,
        setMID,
        standing,
        setStanding,
        studyPlan,
        setStudyPlan,
        studyTime,
        setStudyTime,
        //Helpers
        setOverview,
        unlockIfActive,
        units,
        setUnits,
        lecDocs,
        setLecDocs,
        getValidTicket,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
