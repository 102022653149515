import styled from "styled-components";

//Video styles
export const VidWrap = styled.div`
  display: ${(props) => (props.isActive ? "block" : "none")};
  height: auto;
  width: auto;
  cursor: pointer;
`;
export const MediaWrap = styled.div`
  height: auto;
  width: auto;
  outline: none;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
`;

export const TrackWrap = styled.div`

  visibility: ${(props) => (!props.showControls ? "hidden" : "visible")};
  opacity: ${(props) => (props.showControls ? "1" : "0")};
  box-sizing: border-box;
  width: 100%;
  margin: 0px auto;
  height: auto;
  z-index: ${(props) => (props.fullScreen ? " 2147483648" : "inherit")};
  position: ${(props) =>
    props.fullScreen ? "absolute; bottom: 20px" : "default"};
  position: relative;
  //bottom: 42px;
  z-index: 1;
  cursor: pointer;
  opacity: 0.5;
  transition: all 1s ease-in-out;
   display: flex;
  flex-flow: column wrap;
  progress[value] {
    height: 10px;
    width: 100%;
    appearance: none;
    border-radius: 20px;
    background-color: transparent;
    position: absolute;
  }
  progress[value]::-webkit-progress-bar {
    height: 4px;
    width: 100%;
    border-radius: 20px;
    background-color: #ccc;
     transition: all 0.5s ease-in-out;
  }

    progress[value]::-webkit-progress-value {
      height: 4px;
      background: #F50057;
      transition: all 0.5s ease-in-out;
    }

    progress[value] ::-moz-progress-bar {
      background-image: linear-gradient(to right, #ff8a00, #e52e71);
      background transparent;
      transition: all 0.5s ease-in-out;
    }
    &:hover {
       opacity: 1;
       transform: translateY(-4px);

      progress[value]::-webkit-progress-value {
      height: 8px;

      }
      progress[value]::-webkit-progress-bar {
        height: 8px;

      }
    }
`;
export const Range = styled.input.attrs({ type: "range" })`
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 8.4px;
  background: transparent;
  cursor: pointer;
  width: 100%;
  z-index: 1;
  &:focus {
    outline: none;
  }
  &::-webkit-slider-runnable-track {
    width: 100%;
    cursor: pointer;
    border-radius: 1.3px;
    -webkit-appearance: none;
    transition: all 0.4s ease;
  }
  &::-webkit-slider-thumb {
    height: 16px;
    width: 16px;
    border-radius: 16px;
    background: var(--youtube-red);
    cursor: pointer;
    -webkit-appearance: none;
    margin-left: -1px;
  }

  &:focus::-webkit-slider-runnable-track {
    background: transparent;
  }
  & .volume {
    height: 5px;
    background-color: #fff;
  }

  .volume::-webkit-slider-runnable-track {
    background-color: transparent;
  }
  .volume::-webkit-slider-thumb {
    margin-left: 0;
    height: 14px;
    width: 14px;
    background: #fff;
  }

  &::-moz-range-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    border: 1px solid transparent;
    background: transparent;
    border-radius: 1.3px;
  }

  &::-moz-range-thumb {
    height: 14px;
    width: 14px;
    border-radius: 50px;
    border: 1px solid var(--youtube-red);
    background: var(--youtube-red);
    cursor: pointer;
    margin-top: 5px;
  }

  &:focus::-moz-range-track {
    outline: none;
  }

  &.volume::-moz-range-thumb {
    border: 1px solid #fff;
    background: #fff;
  }
`;
