import { useEffect, useState, useRef, useLayoutEffect, useCallback } from "react";
import styled from "styled-components";

const Marking = styled.li`
  --ruler-unit: 1px;
  --ruler2-space: ${(props) => props.space || "50"};
  --ruler-num-pi: 0.75ch;
  align-self: flex-end;
  counter-increment: d var(--ruler2-space);
  flex: 0 0 calc(var(--ruler-unit) * var(--ruler2-space));

  &::before {
    content: "${(props) => props.index} ${(props) => props.unit}";
    position: absolute;
    width: min-content;
    padding: 5px;
    background-color: white;
    border-radius: 10px;
    opacity: 0;
    transition: opacity 0.1s &near;
  }
  &:hover::before {
    opacity: 1;
  }
  &::after {
    content: "${(props) => props.index}  ${(props) => props.unit}";
    line-height: 1;
    padding-inline-start: var(--ruler-num-pi);
  }
`;
const RulerWrap = styled.div`
  box-sizing: border-box;

  --ruler-num-c: #888;
  --ruler-num-fz: 10px;
  --ruler-num-pi: 0.75ch;
  --ruler-unit: 1px;
  --ruler-x: 1;
  --ruler-y: 1;
  --ruler1-bdw: 1px;
  --ruler1-c: #bbb;
  --ruler1-h: 8px;
  --ruler1-space: 5;
  --ruler2-bdw: 1px;
  --ruler2-c: #bbb;
  --ruler2-h: 25px;
  --ruler2-space: ${(props) => props.space || "50"};
  height: 50px;
  width: 100%;
  background-image: linear-gradient(90deg, var(--ruler1-c) 0 var(--ruler1-bdw), transparent 0), linear-gradient(90deg, var(--ruler2-c) 0 var(--ruler2-bdw), transparent 0);
  background-size: calc(var(--ruler-unit) * var(--ruler1-space)) var(--ruler1-h), calc(var(--ruler-unit) * var(--ruler2-space)) var(--ruler2-h);
  background-position: 0 0;
  background-repeat: repeat-x, repeat-x;
  background-size: calc(var(--ruler-unit) * var(--ruler1-space) * var(--ruler-x)) var(--ruler1-h), calc(var(--ruler-unit) * var(--ruler2-space) * var(--ruler-x)) var(--ruler2-h),
    var(--ruler1-h) calc(var(--ruler-unit) * var(--ruler1-space) * var(--ruler-y)), var(--ruler2-h) calc(var(--ruler-unit) * var(--ruler2-space) * var(--ruler-y));
  & small {
    display: inline-block;
    color: #333;
    text-align: center;
    margin: 0 auto;
    width: min-content;
    white-space: nowrap;
  }
`;
const RulerX = styled.ul`
  box-sizing: border-box;
  --ruler-num-c: #888;
  --ruler-num-fz: 10px;
  --ruler-num-pi: 0.75ch;
  --ruler-unit: 1px;
  --ruler-x: 1;
  // --ruler-y: 1;
  // --ruler1-bdw: 1px;
  // --ruler1-c: #bbb;
  // --ruler1-h: 8px;
  // --ruler1-space: 5;
  // --ruler2-bdw: 1px;
  // --ruler2-c: #bbb;
  --ruler2-h: 25px;
  --ruler2-space: ${(props) => props.space || "50"};

  color: var(--ruler-num-c);
  counter-reset: d -${(props) => props.space || "50"};
  display: flex;
  font-size: var(--ruler-num-fz);
  line-height: 1;
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
  //position: fixed;
  height: var(--ruler2-h);
  inset-block-start: 0;
  inset-inline-start: calc(var(--ruler-unit) * var(--ruler2-space));
  opacity: var(--ruler-x);
  width: 100%;
`;

const Ruler = ({ totalDuration }) => {
  const [markingSpace, setMarkingSpace] = useState(0);
  let [space, setSpace] = useState(0);
  const rulerRef = useRef();

  useEffect(() => {
    let min = Math.ceil(parseInt(totalDuration) / 60);
    min <= 1 ? setSpace(1) : setSpace(60);
  }, [totalDuration]);
  useEffect(() => {
    if (rulerRef?.current?.clientWidth) {
      setMarkingSpace((rulerRef?.current?.clientWidth?.toPrecision(2) / totalDuration) * space);
    }
  }, [space]);

  useLayoutEffect(() => {
    const resetMarking = () => {
      if (rulerRef?.current?.clientWidth) {
        setMarkingSpace((rulerRef?.current?.clientWidth?.toPrecision(2) / totalDuration) * space);
      }
    };
    window.addEventListener("resize", resetMarking);
    return () => window.removeEventListener("resize", resetMarking);
  }, []);

  if (!totalDuration) return null;
  let min, array;
  min = Math.ceil(parseInt(totalDuration) / 60);
  if (isNaN(min)) return null;

  if (min <= 1) {
    array = Array.from(Array(totalDuration).keys());
  } else {
    array = Array.from(Array(min).keys());
  }
  //! Important Calc

  return (
    <RulerWrap space={markingSpace}>
      <RulerX space={markingSpace} id="ruled" ref={rulerRef}>
        {array.map((_, i) => (
          <Marking key={"ruler_marking" + i} index={i} space={markingSpace} unit={min <= 1 ? "s" : "min"} />
        ))}
      </RulerX>
      <small>
        <strong>Question Marker</strong> (Click on an arrow to open the relevant question)
      </small>
    </RulerWrap>
  );
};

export default Ruler;
