import { Box } from "../../../../layout/containers/box";
import { useState, useEffect } from "react";
import { GET } from "../../../../../helpers/REST/api";

export function ProductPricing({ product, active, prices, activePriceIndex, setActivePriceIndex }) {
  const price = prices && prices[activePriceIndex];
  if (!price) return null;
  const nickname = price && price.nickname;
  const amt = price && price.unit_amount;
  const curr = price && price.currency.toUpperCase();

  return (
    <div className={!!active ? "visible" : "hidden"} style={{ borderRadius: "var(--border-radius-med)" }}>
      <Box type="row" minHeight="50dvh" width="100%" justifyContent="space-between">
        <PricingList props={{ product, prices, activePriceIndex, setActivePriceIndex }} />
        <Box background="none" type="col left" minHeight="100%" padding="0 1em" width="70%">
          <h2>
            {nickname}: ${amt / 100} {curr}
          </h2>
        </Box>
      </Box>
    </div>
  );
}

const PricingList = ({ props }) => {
  const { product, prices, activePriceIndex, setActivePriceIndex } = props;
  const activate = (i) => setActivePriceIndex(i);
  let title = product?.productMetaData?.type || "";
  title = title[0]?.toUpperCase() + title.slice(1);

  const Row = ({ nickname, isActive, index, ...rest }) => (
    <Box background={isActive ? "var(--primary-color-active)" : "transparent"} margin="0.5em 0" width="100%" padding="10px" className={"hover_primary"} style={{ cursor: "pointer" }} {...rest}>
      {index + 1}. {nickname}
    </Box>
  );
  return (
    <Box type="column" height="100%" padding="0" width="200px" background="none" style={{ overflowY: "auto" }}>
      <Box type="column" background="none" padding="0.6em 0 0 0 " width="100%">
        <h3>{title} Packages</h3>
      </Box>
      {!!prices &&
        prices?.map((pr, index) => (
          <Row
            index={index}
            key={pr.id}
            isActive={activePriceIndex === index}
            nickname={pr.nickname || pr.description || "Price"}
            onMouseDown={() => {
              activate(index);
            }}
          />
        ))}
    </Box>
  );
};
