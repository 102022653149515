import { useState, useEffect, useContext, useCallback } from "react";
import { useParams } from "react-router-dom";
import { TabGroup } from "../layout/containers/box";
import { QsViewAnswerChoices, QuestionCtx } from ".";
import { GET, POST } from "../../helpers/REST/api";
import QsEditor from "../editor/questionEditor";
import { Box, Collapse } from "../layout/containers/box";
import { ArrowBtn, CheckButton, Button } from "../layout/buttons/buttons";
import { AnswerChoices } from ".";
import { Checkbox } from "../layout/selection/checkbox";
import { Tag } from "antd";
import { toast } from "react-toastify";

//!Works in question modal.
const ImportDocQuestions = () => {
  const [tabs, setTabs] = useState(["This Block", "This Doc (All blocks)", "Unclaimed Questions", "From Elsewhere"]);
  const [questions, setQuestions] = useState([]);
  const [active, setActive] = useState(0);

  const { pasteImportedIdToDocument, importQs } = useContext(QuestionCtx);
  const { doc_id, block_id } = useParams();

  const containerStyles = { minWidth: "400px", width: "50dvw", maxHeight: "70dvh", minHeight: "50dvh" };
  const bodyStyle = { overflowY: "scroll", maxHeight: "50dvh", height: "auto" };

  //Effects.
  useEffect(() => {
    if (importQs) {
      let q = "";
      if (active === 0) q = `?belongsToBlock=${doc_id},${block_id}`;
      if (active === 1) q = `?belongsToBlock=${doc_id}`;

      GET(`/questions${q}`).then((r) => {
        setQuestions(r);
      });
    }
  }, [importQs, active]);

  //Methods
  const handleQsSelect = (qs, action) => {
    // use = attach;
    // duplicate; --> NewId; Same object
    // Unclaim to doc;
    // unclaim

    // import to block;
    // import to document;

    let unclaimed = qs.belongsToBlock === undefined || qs.belongsToBlock?.length === 0;
    let docMatch = Array.isArray(qs.belongsToBlock) && qs.belongsToBlock[0] === doc_id && qs.belongsToBlock[1] === block_id;
    let otherDocDocument = !unclaimed && !docMatch;
    let replicate = action === "replicate"; //* else ATTACH
    if (replicate) {
      let qsData = { ...qs, belongsToBlock: [doc_id, block_id] };
      if (docMatch) qsData.belongsToBlock = [doc_id];
      delete qsData._id;
      POST("/questions/replicate", { data: qsData }).then((replicatedQsWthId) => {
        setQuestions([...questions, replicatedQsWthId]);
        if (docMatch) toast.warn("Question replicated, but _not_ attached to current block.");
      });

      //scenario: question is replicated X times in a document. How do we know where it's at? What if we change it? It changes every where in that document and block?
    } else {
      if (unclaimed) {
        console.log("question was unclaimed. Do nothing")
        // ...hmmm. nothing here.
        //should claim it.
      } else if (docMatch) {
        console.log("question was already in the document. Going to paste importedIdToDocument")
        // send Id.
        pasteImportedIdToDocument(qs._id);
      } else if (otherDocDocument) {
        console.log("Question was in other document. Do nothing.")
        // create copy.
      }
    }
  };

  //Err boundary;
  if (!importQs) return null;

  //Components;
  const NoData = () => (
    <div style={{ overflowY: "scroll", maxHeight: "50dvh", height: "auto" }}>
      <div>No item found</div>
    </div>
  );
  const WithQuestions = () => questions.map((qs, qi) => <QuestionView qs={qs} index={qi} tabIndex={active} onSelect={handleQsSelect} key={qs._id + qi} />);

  return (
    <div style={containerStyles}>
      <small>Note to self: Dont call all data at once</small>
      <TabGroup tabs={tabs} activeIndex={active} setTab={setActive} />
      <div style={bodyStyle}>{!questions.length ? <NoData /> : <WithQuestions disableEdit={true} />}</div>
    </div>
  );
};
export default ImportDocQuestions;

export const QuestionView = ({ qs, options, index, tabIndex = 0, onSelect, disableEdit = true }) => {
  const [isOpen, setOpen] = useState(false);

  //Styles
  const openStyle = { cursor: "auto", padding: "1em", height: "auto", maxHeight: "100%", overflowY: "hidden", fontSize: "12px" };
  const closedStyle = { ...openStyle, maxHeight: "90px", cursor: "pointer" };

  //Sections
  const Heading = useCallback(
    () => (
      <div className="flex-row">
        <div className="flex-row">
          <Tag bordered={"false"} color="magenta">
            Question Type: {qs.questionType?.toUpperCase()}
          </Tag>
        </div>
        <div className="flex-row" style={{ width: "100px", justifyContent: "flex-end" }}>
          <ArrowBtn
            style={{ marginLeft: "1em", marginBottom: "auto" }}
            size={25}
            direction={isOpen ? "d" : "r"}
            onMouseDown={() => {
              setOpen(!isOpen);
            }}
          />
        </div>
      </div>
    ),
    [isOpen, qs]
  );
  const QsRow = useCallback(
    () => (
      <>
        <h4 style={{ margin: "0.5em auto 0 0" }}>Question {index + 1}</h4>
        <div className="flex-row justify_around">
          <QsEditor
            background={"transparent"}
            readOnly={true}
            onMouseDown={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            content={qs.question}
            className="dark"
            placeholder="No question text"
            wrapStyles={{ margin: "0 auto 0 0" }}
            fontSize="12px"
            width="100%"
            disabled
          />
        </div>
      </>
    ),
    [qs, index]
  );
  const Footer = () => {
    const SelectBtn = ({ action, children }) => <Button onMouseDown={() => onSelect(qs, action)}>{children}</Button>;

    return (
      <div className="flex-row">
        <div className="flex-row">
          <Checkbox defaultChecked={qs.isPublished} disabled name={"isPublished"}>
            Published
          </Checkbox>

          <Checkbox defaultChecked={qs.isPublished} disabled name={"isPublished"} style={{ marginLeft: "1em" }}>
            Archived
          </Checkbox>
        </div>

        <div className="flex-row" style={{ width: "100px", justifyContent: "flex-end" }}>
          {tabIndex <= 1 ? (
            <>
              <SelectBtn action={"attach"}>Attach</SelectBtn>
              <SelectBtn action={"replicate"}>Copy</SelectBtn>
            </>
          ) : tabIndex === 2 ? (
            <>
              <SelectBtn action={"replicate"}>Import</SelectBtn>
              <SelectBtn action={"attach"}>Attach</SelectBtn>
            </>
          ) : (
            <SelectBtn action={"attach"}>Import</SelectBtn>
          )}
        </div>
      </div>
    );
  };

  return (
    <Box background="#444" type="column" margin="0.5em 0" style={isOpen ? openStyle : closedStyle}>
      <Heading />
      <QsRow />
      <QsViewAnswerChoices {...qs} isDisabled={disableEdit} fontSize="13px" />
      <hr style={{ width: "100%" }} />
      <Footer />
    </Box>
  );
};
