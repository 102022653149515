//!TODO: 1) Save name ==> Store to AWS; 2) Move to "dock" on the bottom right side of the page
import React, { useState, useRef, useEffect } from "react";
import { Box } from "../layout/containers/box";
import uuid4 from "uuid4";
import { CloseOutlined, AudioOutlined, DeleteOutlined, SaveOutlined } from "@ant-design/icons";
import { Wrap, Display, RTime, RIndicator, CancelWrap, StartWrap, DeleteWrap, AudioWrap, RecorderWrap } from "./recorderStyles";
import { AudioFilled } from "@ant-design/icons";
import { UPLOAD, GET } from "../../helpers/REST/api";
import { toast } from "react-toastify";
import { ShinyRoundMediaBtn } from "../layout/buttons/buttons";

const initialState = {
  recordingMinutes: 0,
  recordingSeconds: 0,
  initRecording: false,
  mediaStream: null,
  mediaRecorder: null, //This is the recorder && has onStart && onStop methods + data.
  audio: null,
  audio_blob: null,
};
const useRecorder = () => {
  const [recorderState, setRecorderState] = useState(initialState);
  useEffect(() => {
    const MAX_RECORDER_TIME = 10;
    let recordingInterval = null;
    let mediaRecorder = recorderState.mediaRecorder;
    const incrementTimer = (prevState) => {
      if (prevState.recordingMinutes === MAX_RECORDER_TIME && prevState.recordingSeconds === 0) {
        clearInterval(recordingInterval);
        return prevState;
      }

      if (prevState.recordingSeconds >= 0 && prevState.recordingSeconds < 59)
        return {
          ...prevState,
          recordingSeconds: prevState.recordingSeconds + 1,
        };

      if (prevState.recordingSeconds === 59)
        return {
          ...prevState,
          recordingMinutes: prevState.recordingMinutes + 1,
          recordingSeconds: 0,
        };
    };

    if (recorderState.initRecording || mediaRecorder?.state === "recording") {
      // Start timer coundown
      recordingInterval = setInterval(() => {
        setRecorderState(incrementTimer(recorderState));
      }, 1000);
    } else clearInterval(recordingInterval);

    return () => clearInterval(recordingInterval);
  });
  useEffect(() => {
    if (recorderState.mediaStream)
      setRecorderState((prevState) => {
        return {
          ...prevState,
          mediaRecorder: new MediaRecorder(prevState.mediaStream),
        };
      });
  }, [recorderState.mediaStream]);

  useEffect(() => {
    const recorder = recorderState.mediaRecorder;
    let isInactive = recorder && recorder?.state === "inactive";
    let isRecording = recorder && recorder?.state === "recording";
    let chunks = [];

    if (isInactive || isRecording) {
      recorder.start();

      recorder.ondataavailable = (e) => {
        chunks.push(e.data);
      };

      recorder.onstop = () => {
        const blob = new Blob(chunks, { type: "audio/webm; codecs=opus" });
        chunks = [];
        // Blob Object --> Used to create an audio file to send to bbackend.
        // will be added to "Audio_blob" prop in state.
        let blobFileObject = {
          fileName: new Date(Date.now()),
          mimeType: "audio/webm; codecs=opus",
          size: blob.size,
          blob: blob,
        };
        setRecorderState((prevState) => {
          //Update duration;
          blobFileObject.duration = prevState.recordingMinutes * 60 + prevState.recordingSeconds;

          if (prevState.mediaRecorder) {
            let newValue = { ...initialState };

            if (Array.isArray(prevState.audio)) {
              newValue.audio = [...prevState.audio, window.URL.createObjectURL(blob)];
              newValue.audio_blob = [...prevState.audio_blob, blobFileObject];
            } else {
              newValue.audio = [window.URL.createObjectURL(blob)];
              newValue.audio_blob = [blobFileObject];
            }
            return newValue;
          } else {
            return initialState;
          }
        });
      };
    }

    return () => {
      if (recorder) recorder.stream.getAudioTracks().forEach((track) => track.stop());
    };
  }, [recorderState.mediaRecorder]);
  return {
    recorderState,
    startRecording: () => startRecording(setRecorderState),
    cancelRecording: () => setRecorderState(initialState),
    pauseRecording: () => pauseRecording(recorderState.mediaRecorder),
    resumeRecording: () => resumeRecording(recorderState.mediaRecorder),
    saveRecording: () => saveRecording(recorderState.mediaRecorder),
    recordingStatus: recorderState?.mediaRecorder?.state,
    uploadAudio: (blobObject) => uploadRecording(blobObject),
  };
};

// HANDLERS
async function startRecording(setRecorderState) {
  // Step 1: Button click sets "initRecording": true, mediaStream: stream.
  // init recording --> Changes UI + starts timer.
  // mediaStream (null --> stream): Sets mediaRecorder (null --> New MediaRecorder);

  // Step 2: Everysecond/render (useEffect),
  try {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

    setRecorderState((prevState) => {
      return {
        ...prevState,
        initRecording: true,
        mediaStream: stream,
      };
    });
  } catch (err) {
    console.log(err);
  }
}

function saveRecording(recorder) {
  if (recorder.state !== "inactive") recorder.stop();
}
function pauseRecording(recorder) {
  if (recorder.state === "recording") recorder.pause();
}
function resumeRecording(recorder) {
  if (recorder.state === "paused") recorder.resume();
}
//UTILITY
function deleteAudio(audioKey, setRecordings) {
  setRecordings((prevState) => prevState.filter((record) => record.key !== audioKey));
}
function generateKey() {
  return uuid4();
}
function formatMinutes(minutes) {
  return minutes < 10 ? `0${minutes}` : minutes;
}

function formatSeconds(seconds) {
  return seconds < 10 ? `0${seconds}` : seconds;
}

async function uploadRecording(blobFileObject) {
  console.log("In uploadRecording", blobFileObject !== undefined);
  if (blobFileObject !== undefined) {
    const { fileName, mimeType, duration, blob } = blobFileObject;
    const opts = { type: blob.type || mimeType, duration };
    // new File (bits, name, options)
    let audioFile = new File([blob], fileName, opts);
    let formData = new FormData();
    formData.append("file", audioFile, fileName);
    return await UPLOAD("/media/upload", formData).catch((e) => {
      toast.error(e);
    });
  }
}

function RecorderControls({ recorderState, handlers }) {
  const { recordingMinutes, recordingSeconds, initRecording } = recorderState;
  const { startRecording, saveRecording, cancelRecording } = handlers;

  return (
    <Wrap>
      <Display>
        <RTime>
          {initRecording && <RIndicator></RIndicator>}
          <span>{formatMinutes(recordingMinutes)}</span>
          <span>:</span>
          <span>{formatSeconds(recordingSeconds)}</span>
        </RTime>
        {initRecording && (
          <CancelWrap>
            <button title="Cancel recording" onClick={cancelRecording}>
              <CloseOutlined />
            </button>
          </CancelWrap>
        )}
      </Display>
      <StartWrap>
        {initRecording ? (
          <button title="Save recording" disabled={recordingSeconds === 0} onClick={saveRecording}>
            <SaveOutlined />
          </button>
        ) : (
          <button className="start-button" title="Start recording" onClick={startRecording}>
            <AudioOutlined />
          </button>
        )}
      </StartWrap>
    </Wrap>
  );
}

function useRecordingsList(audio) {
  const [recordings, setRecordings] = useState([]);

  useEffect(() => {
    if (audio)
      setRecordings((prevState) => {
        return [...prevState, { key: generateKey(), audio }];
      });
  }, [audio]);

  return {
    recordings, //Array
    deleteAudio: (audioKey) => deleteAudio(audioKey, setRecordings),
  };
}

function RecordingsList({ audio, audio_blob }) {
  const { recordings, deleteAudio } = useRecordingsList(audio);
  console.log(recordings);
  if (recordings.length > 0) {
    return (
      <Box width="100%" background="transparent" type="row" margin="0" padding="0" height="300px" style={{ overflowY: "auto" }}>
        {recordings.map((record, index) => (
          <Box key={generateKey()} background="transparent" type="1 row centered" justifyContent="space-around" margin="5px" padding="0" style={{ transform: "scale(0.75)" }}>
            <AudioWrap controls src={audio[index]} />
            <DeleteWrap>
              <button onClick={() => deleteAudio(record.key)}>
                <DeleteOutlined />
              </button>
            </DeleteWrap>
            <DeleteWrap>
              <button title="Upload recording" onClick={() => uploadRecording(audio_blob[index])}>
                <SaveOutlined />
              </button>
            </DeleteWrap>
          </Box>
        ))}
      </Box>
    );
  }
  return <div style={{ minHeight: "300px" }}>Nothing to show for.</div>;
}
function SavedAudios() {
  const [audios, setAudios] = useState([]);
  useEffect(() => {
    const fetchAudios = async () => {
      await GET("/media/mediaType/audio").then((data) => {
        console.log(data);
        setAudios(data);
      });
    };
    fetchAudios();
  }, []);
  return null;
}

const AudioRecorder = () => {
  const { recorderState, ...handlers } = useRecorder();
  const [open, isOpen] = useState(false);
  const { audio, audio_blob } = recorderState;
  return (
    <>
      <RecorderWrap onClick={() => isOpen(!open)}>
        <AudioFilled />
      </RecorderWrap>
      {open && (
        <Box width="min-content" type={"column"} style={{ position: "fixed", bottom: "75px", right: "100px" }}>
          Audio Recorder
          <Box type={"column"} background="transparent" width="200px" margin="0" padding="0">
            <RecorderControls recorderState={recorderState} handlers={handlers} />
            <RecordingsList audio={audio} audio_blob={audio_blob} />
            <SavedAudios />
          </Box>
        </Box>
      )}
    </>
  );
};

export default AudioRecorder;
/**
 * @param {Callback} props.uploadCallback returns saved audio_media object;
 * @param {Object} props.recordFor //TODO: Set this up later if needed.
 */
export const QuestionAudioRecorder = (props) => {
  const recordFor = props.recordFor; // e.g. playlist, question, quiz etc.
  // has to save the recording to media anyway --> get id.
  //That id can go to questions.question, question.questionAudio, playlist

  return <ShinyRoundMediaBtn {...useRecorder()} handleMediaId={props.uploadCallback} />;
};
