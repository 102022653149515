import { useEffect, useState, useCallback, useRef } from "react";
import { AudioOutlined, AudioMutedOutlined } from "@ant-design/icons";
import { Box } from "../../layout/containers/box";
import { RounderBtn as SubmitBtn } from "../../layout/buttons/buttonStyles";
import Mic from "../../webSpeechAPI";

import {
  RoundBtnWrap,
  ShinySphere,
  ShinySphereInnerBtn,
  Spoke,
  AnimatedMic,
} from "../../layout/buttons/buttons";
import { Pause, Play } from "../../../images/icons";

const ServeAudioQuestion = (props) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [hasEnded, setHasEnded] = useState(false);
  const [hasStarted, setHasStarted] = useState(false);
  const { qsData, handleSelect, selected, onSubmit } = props;
  const { correctAns } = qsData;
  const audioRef = useRef(null);
  const handleAudioPlay = () => {
    setIsPlaying(true);
  };
  const handleAudioPause = () => {
    setIsPlaying(false);
  };
  const manageAudioRef = (ref) => {
    audioRef.current = ref;

    return ref;
  };

  const toggleQuestionPlay = () => {
    if (isPlaying) {
      setIsPlaying(false);
    } else {
      setIsPlaying(true);
    }
  };
  const handleMicError = () => {
    alert("Uh oh! Your mic glitched!");
  };
  const evaluateMicResult = (response) => {
    let isCorrect = undefined;
    //Correcting for multiple word recognition
    if (response.includes(" ")) {
      response.split(" ").forEach((res) => {
        if (answerHash[res] === false) isCorrect = false;
        if (answerHash[res]) isCorrect = true;
      });
    } else {
      isCorrect = answerHash[response];
    }
    if (isCorrect === undefined) {
      alert("That's not the answer I was looking for. Say one word pls.");
    } else if (isCorrect) {
      handleSelect(0, "mic");
    } else {
      handleSelect(1, "mic");
    }
  };
  const answerHash = {
    yes: true,
    yay: true,
    "of course": true,
    ofcourse: true,
    "for sure": true,
    sure: true,
    true: true,
    totally: true,
    ya: true,
    false: false,
    no: false,
    nahi: false,
    never: false,
    wrong: false,
    correct: true,
    yep: true,
  };

  const evalAudioResponse = (response) => {
    handleSelect(response);
  };

  const recordColor = "salmon, maroon, darkred, darkred";
  const playColor = "lightgreen, darkgreen, green, green";
  const normalColor = "#bb0d4b, #ff005a, #ba0042, #da6890"; //4th color for spoke

  useEffect(() => {
    if (isPlaying) {
      if (audioRef.current.ended) audioRef.current.currentTime = 0;
      if (audioRef.current.paused) audioRef.current.play();
    } else if (!isPlaying) {
      audioRef.current.pause();
    }
  }, [isPlaying, audioRef]);
  return (
    <Box
      type="column"
      width="500px"
      margin="1em auto"
      background={"var(--tertiary-color)"}
    >
      <h3>Audio Question</h3>
      <Box
        type="1 row centered"
        width="85%"
        margin="1em auto"
        background={"var(--tertiary-color)"}
      >
        <RoundBtnWrap
          expand={hasEnded}
          onClick={() => {}}
          tabIndex="0"
          color={!isPlaying ? playColor : normalColor}
          style={{
            minWidth: hasEnded ? "400px" : "0px",
          }}
        >
          <audio
            ref={(r) => manageAudioRef(r)}
            style={{ opacity: "0", display: "hidden" }}
            src={props.qsData.questionAudio?.uri}
            autoPlay
            onEnded={() => {
              audioRef.current.currentTime = 0;
              setIsPlaying(false);
              if (!hasEnded) setHasEnded(true);
            }}
            onPlay={() => {
              if (!hasStarted) {
                setHasStarted(true);
                setIsPlaying(true);
              }
            }}
          />
          <ShinySphere
            style={{ width: "60px", left: !hasEnded ? "0px" : "-10px" }}
            color={!isPlaying ? playColor : normalColor}
            onClick={(e) => {
              e.stopPropagation();
              toggleQuestionPlay();
            }}
            isRecording={true}
          >
            <Spoke
              state={!isPlaying ? 0 : 1}
              color={!isPlaying ? playColor : normalColor}
              isRecording={true}
            />
            <ShinySphereInnerBtn color={!isPlaying ? playColor : normalColor}>
              <MainIcon isPlaying={isPlaying} />
            </ShinySphereInnerBtn>
          </ShinySphere>
          {hasEnded ? props.children : null}
        </RoundBtnWrap>
      </Box>
      <div style={{ margin: "0 auto 3em auto" }}>
        {hasEnded ? (
          <Mic
            vocab={correctAns}
            onError={handleMicError}
            onResult={evalAudioResponse}
            size="l"
            showSpeech={false}
            runOnFirstRender={true}
          />
        ) : null}
      </div>
      <SubmitBtn
        onMouseDown={() => {
          onSubmit();
        }}
        disabled={selected === null}
      >
        Submit
      </SubmitBtn>
    </Box>
  );
};

const MainIcon = ({ isPlaying }) => {
  if (isPlaying) return <Pause />;
  return <Play />;
};
export default ServeAudioQuestion;
