import { confirmEmail, resendConfEmail } from "../../helpers/REST/api";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TextField } from "../layout/containers/input";
import { toast } from "react-toastify";

import { Button } from "../layout/buttons/buttons";
import { Box } from "../layout/containers/box";
import styled from "styled-components";

const Splitter = styled.div`
  width: 33%;
  margin: 1em auto;
  height: 1px;
  background: #333;
`;

//TODO 1. Shouldn't be able to go on this page if already verified.
//TODO 2. What happens if the code is expired?
//TODO 3. What happens if we're requesting a new password (you're technically validated. How do you come here, if that's the crit?);
//TODO 4. Error codes are bogus.
const VerifySignup = () => {
  const nav = useNavigate();
  let { token } = useParams();
  let [code, setCode] = useState("");
  function sendCode(code) {
    let host = window.location.hostname;
    let url = host.indexOf("localhost") > -1 ? `http://localhost:8050/users/signup/verify/${code}` : `https://auth.sohaibahmed.ca/users/signup/verify/${code}`;
    if (code.length !== 6) toast.warn("The code must be 6 digits long; You likely haven't entered it correctly");
    if (code.length === 6) {
      confirmEmail(url, code)
        .then(({ status, data }) => {
          if (status >= 400) toast.warn(data);
          if (status < 400) toast.success(data);
          if (status === 200) {
            window &&
              setTimeout(() => {
                nav("/");
              }, 1000);
          }
        })
        .catch((e) => {
          toast.success("That didn't work. Your code may have expired. If you're having trouble, please reach out to us at info@99point9prep.com");
          console.log(e);
        });
    }
  }
  function resendEmail() {
    let host = window.location.hostname;
    let url = host.indexOf("localhost") > -1 ? `http://localhost:8050/users/signup/resend-confirmation` : process.env.BE_AUTH ?? `https://auth.sohaibahmed.ca/users/signup/resend-confirmation`;
    resendConfEmail(url)
      .then(({ status, data }) => {
        if (status >= 400) toast.warn(data);
        if (status < 400) toast.success(data);
        if (status === 200) {
          window &&
            setTimeout(() => {
              nav("/");
            }, 1000);
        }
      })
      .catch((e) => {
        toast.warn(e?.response?.data || "Uh-oh That didn't work. Speak to the admin pls!");
      });
  }

  useEffect(() => {
    if (token && token.length === 6) {
      sendCode(token);
    }
  }, []);
  return (
    <Box type="column" width="min-content" margin="0px auto auto auto" minHeight="300px" background="none" padding="0">
      <Box type="column" width="min-content" justifyContent="space-between">
        <Box type="column" background="none" padding="0">
          <h2 style={{ fontWeight: "300", color: "#888" }}>Enter your verification code</h2>
          <Splitter />
        </Box>

        <TextField
          name="Two Factor Code"
          label="Six-Digit Code"
          placeholder={`e.g., "123456"`}
          onChange={(v) => {
            setCode(v.trim());
          }}
          wrapStyles={{ margin: "1em auto", minWidth: "375px" }}
          isDecorated={true}
        />

        <Box type="row" background="none" justifyContent="space-between" padding="0">
          <Button style={{ minWidth: "125px" }} onClick={() => resendEmail()}>
            Resend Email
          </Button>
          <Button style={{ minWidth: "125px" }} onClick={() => sendCode(code)}>
            Submit
          </Button>
        </Box>
      </Box>

      <span style={{ fontSize: "13px", color: "#444", textAlign: "center", marginTop: "1em" }}>
        Check your email: we just sent you a verification code! <br /> <br />
        If you don't see it, then check your junk/spam folders; though sometimes it does take a few minutes for it to show up.
      </span>
    </Box>
  );
};
export default VerifySignup;
