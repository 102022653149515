import React, { useRef, useState, useCallback, useEffect } from "react";

import { Para, QuestionSpan, Pre } from "../editor/UI/styled";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Popover } from "antd";
import QuestionHandler from "./questionHandler";
import { PanelHeader } from "../layout/containers/box";
import { getQuestion, checkQuestionAnswer } from "../../helpers/REST/api";

export const Readable = ({ content, currentBlock }) => {
  const [stopPoints, setStopPoints] = useState([]);
  const [contentCopy, setContentCopy] = useState([]);
  const treeRef = useRef({});
  function findStops(input) {
    //runs only once
    if (!input) return;
    const stops = ["question", "inline-question-void"];
    let stopPaths = [];
    function traverseForStops(array, parentPath) {
      if (!Array.isArray(array)) return;
      array.forEach((element, elId) => {
        const { type, children, ...rest } = element;
        stops.indexOf(type) > -1
          ? stopPaths.push(
              parentPath === undefined
                ? { path: [elId], passed: false }
                : { path: [...parentPath, elId], passed: false }
            )
          : traverseForStops(
              children,
              parentPath === undefined ? [elId] : [...parentPath, elId]
            );
      });
    }
    traverseForStops(input);
    return stopPaths;
  }
  function findNextStop(stopPointState) {
    if (!stopPointState) return [];
    let result = stopPointState.find(({ passed }, index) => !passed);

    return result;
  }
  function nextStop(currentStops) {
    let newStops = [...stopPoints];
    let passIndex = newStops.findIndex((item, i) => !item.passed);
    if (passIndex > -1) {
      newStops[passIndex].passed = true;
      setStopPoints(newStops);
    } else {
      alert("Ended");
    }
  }
  function contentProcessor() {
    let nextStop = findNextStop(stopPoints);
    if (stopPoints && stopPoints.length > 0 && nextStop) {
      let nextPath = nextStop.path;
      return spliceContent(content, nextPath);
    }
    return content;
  }
  function spliceContent(array, stopPath) {
    // Splicee is like "mentee"; to be spliced.
    if (!array) return ["Uh oh! There's no content here."];
    // console.log("Splice content", !stopPath, stopPath);
    if (!stopPath) return array;

    let nextStop = stopPath; //findNextStop().path;

    // console.log(nextStop);
    let copy = JSON.parse(JSON.stringify(array));

    function splicer(spliceeArray) {
      //Don't splice if not array;
      if (!Array.isArray(spliceeArray)) return;
      //splice everything past this level
      spliceeArray.splice(nextStop[0] + 1);
      let parent = spliceeArray[nextStop[0]].children;
      //sploce deeper
      nextStop.forEach((stop, ind) => {
        if (ind === 0) return; //Already done;
        if (parent[stop + 1]) parent.splice(stop + 1);
        if (ind !== nextStop.length && parent?.children)
          parent = parent[stop].children;
      });
    }
    splicer(copy);

    return copy;
  }
  function Wrapper({ contentArray }) {
    function ElementWrap(props) {
      const { type, children, ...attributes } = props;
      function QuestionBlock({
        children,
        type,
        path,
        questionId,
        fwdRef,
        ...attributes
      }) {
        const questionRef = useRef(null);
        return (
          <QuestionSpan {...attributes} ref={fwdRef}>
            {children}
          </QuestionSpan>
        );
      }
      function VoidQuestion({ questionId, type, path, fwdRef, ...rest }) {
        const [isOpen, setIsOpen] = useState(false);
        const [passed, setPassed] = useState(false);
        const [output, setOutput] = useState(null);
        const questionRef = useRef({});
        //question needs to be called at this level;
        useEffect(() => {
          (async () => {
            let { ...qsDeets } = await getQuestion(questionId);
            questionRef.current = { ...qsDeets };
          })();
        }, []);
        const handleSubmit = (isCorrect) => {
          setPassed(isCorrect);
          if (isCorrect) {
            setIsOpen(false);
            nextStop();
          } else {
            alert("That's not the right answer!");
          }
        };
        return (
          <span
            style={{
              padding: "2px 10px",
              cursor: "pointer",
              color: "#F50057",
            }}
            key={type + questionId}
            onMouseDown={() => setIsOpen(true)}
            ref={fwdRef}
          >
            <Popover
              title={
                <PanelHeader
                  isOpen={true}
                  onClose={() => {
                    setIsOpen(false);
                  }}
                  title="You should know this"
                  padding="0"
                  margin="0"
                  width="50vw"
                  style={{ color: "black" }}
                />
              }
              trigger="click"
              visible={isOpen}
              placement="bottom"
              content={
                <QuestionHandler
                  onSubmit={handleSubmit}
                  onClose={() => {
                    setIsOpen(false);
                  }}
                  questionObject={questionRef.current}
                  questionId={questionId}
                  setPassed={setPassed}
                />
              }
            >
              <QuestionCircleOutlined
                style={{ color: "#F50057" }}
                onMouseDown={(e) => {
                  e.preventDefault();
                }}
              />
            </Popover>
          </span>
        );
      }
      switch (type) {
        default:
          return <span>{children}</span>;
        case "paragraph":
          return (
            <Para ref={(el) => refHandler(el, props.path)}>
              <span>{children}</span>
            </Para>
          );

        case "question":
          if (props.questionId) {
            return (
              <QuestionBlock
                {...attributes}
                fwdRef={(el) => refHandler(el, props.path)}
              >
                {children}
              </QuestionBlock>
            );
          }
          return <span>{children}</span>;
        case "inline-question-void":
          if (props.questionId) {
            return (
              <VoidQuestion
                type={type}
                {...attributes}
                fwdRef={(el) => refHandler(el, props.path)}
              />
            );
          }
          return <span>{children}</span>;
        case "bulleted-list":
          return (
            <ul {...attributes} type={type}>
              {children}
            </ul>
          );
        case "heading-one":
          return (
            <h1 {...attributes} type={type}>
              {children}
            </h1>
          );
        case "heading-two":
          return (
            <h2 {...attributes} type={type}>
              {children}
            </h2>
          );
        case "heading-three":
          return (
            <h3 {...attributes} type={type}>
              {children}
            </h3>
          );
        case "heading-four":
          return (
            <h4 {...attributes} type={type}>
              {children}
            </h4>
          );
        case "heading-five":
          return (
            <h5 {...attributes} type={type}>
              {children}
            </h5>
          );
        case "heading-six":
          return (
            <h6 {...attributes} type={type}>
              {children}
            </h6>
          );
        case "list-item":
          return (
            <li {...attributes} type={type}>
              {children}
            </li>
          );
        case "numbered-list":
      }
    }
    function LeafWrap({ item, fwdRef, ...rest }) {
      let children = item.text;
      if (item.bold) children = <strong>{children}</strong>;
      if (item.italic) children = <em>{children}</em>;
      if (item.underline) children = <u>{children}</u>;
      if (item.strikethrough) children = <del>{children}</del>;
      if (item.question) children = <QuestionSpan>{children}</QuestionSpan>;

      return <span ref={fwdRef}>{children}</span>;
    }
    function Recurse(item, itemIndex) {
      if (item.text) {
        return <LeafWrap item={item} key={item + itemIndex} path={itemIndex} />;
      }
      if (item.type && !item.text) {
        let { children, ...rest } = item;

        return (
          <ElementWrap {...rest} key={item.type + itemIndex} path={itemIndex}>
            {Array.isArray(children) && children.length
              ? children.map((c, index) => {
                  return Recurse(c, [itemIndex, index].flat());
                })
              : null}
          </ElementWrap>
        );
      }
    }
    let result = contentArray.map((c, i) => Recurse(c, i));
    return result;
  }
  function refHandler(el, path) {
    treeRef.current[path.toString()] = el;
  }
  useEffect(() => {
    setStopPoints(findStops(content));
  }, []);

  return <Wrapper contentArray={contentProcessor()} />;
};
