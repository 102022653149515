import { useEffect, useState, useContext, useCallback, useRef } from "react";
import { Box } from "../layout/containers/box";
import JSONElementsToHTML from "./elementRender";
import MonitorProvider from "./monitor";
import { States } from "./context";
import { useParams } from "react-router-dom";
import { UserContext } from "../../helpers/hooks/userContext";
import { GET } from "../../helpers/REST/api";
import LectureContext from "./context";

import ElementInteraction, { NextBlockTrigger } from "./interactions";

//? displayFirstBlock; done
//? triggerBlockCompletion done
//? blockCompleteNextStep
//? addNextBlock done
//? failedBlock not done
//? EvalBreakpoint kinda done
//? async function registerBlockCompletion() {} kinda done
export default function StudentLecture() {
  return (
    <States isLive={true}>
      <MonitorProvider>
        <StudentLectureLogic />
      </MonitorProvider>
    </States>
  );
}
/**
 * @param  {Array} props.data Supposedly this is a list of all blocks to be rendered.

 */
function StudentLectureLogic(props) {
  const { docElems, blocks, metaData, fetchNextLiveBlock, memLecData } = useContext(LectureContext);
  useEffect(() => {}, []);

  const JSONElementsToHTMLCallback = useCallback(({ b, i, isCurrentBlock, fetchNextLiveBlock }) => {
    return (
      <JSONElementsToHTML
        key={i + "block"}
        block={b} // e.g. this might be a para, that has text elements--> we're passing this content to "JSONElementsToHTML"
        blockIndex={i} // i.e. current index;
        isCurrentBlock={isCurrentBlock} // i.e. current index is the last block;
        nextStepTrigger={fetchNextLiveBlock}
        fwdRef={(r) => {
          console.log("fwd", r);
        }}
      />
    );
  }, []);
  const PrevDocElems = useCallback((props) => {
    return (
      <Box type="col left" background="none" margin="0 0 auto 0" padding="0 0 5em 0" minHeight="100vh">
        <JSONElementsToHTMLCallback {...props} />
      </Box>
    );
  }, []);

  if (!docElems?.length) return null;
  /*
    1. We need memLec._id, blocks, and saved points into the States. 
    2. We need saved points to show up in the JSONElementsToHTML effect: they should start working when the block has ended. 
    3.
  */

  return (
    <Box type="col left" background="none" margin="0 0 auto 0" padding="0 0 5em 0" minHeight="100vh">
      <h1 style={{ color: "#444" }}>{memLecData?.title ? <span className="shinyText">{memLecData.title}</span> : null}</h1>
      {docElems.map((b, i) =>
        b === undefined ? null : (
          <Box type="col left" background="none" margin="1em 0" padding="0" key={i + "block"} style={{ height: "75px" }}>
            <h4 style={{ color: "#444" }}>{metaData ? metaData[i]?.title : null}</h4>
            <PrevDocElems
              key={i + "block"}
              b={b}
              i={i}
              isCurrentBlock={i === docElems.length - 1}
              // i.e. current index is the last block;
              fetchNextLiveBlock={fetchNextLiveBlock}
            />
          </Box>
        )
      )}

      <div>
        <ElementInteraction />
        <NextBlockTrigger trigger={fetchNextLiveBlock} blocks={docElems} data={!blocks ? [] : blocks.flat()} />
        {/* <QuestionView_Editor /> */}
        {/* Last_block Completion Trigger--ADD LATER*/}
        {/* Lecture Finished Trigger--ADD LATER*/}
        {/* QuizView: can be overlay or component--ADD LATER*/}
      </div>
    </Box>
  );
}

//  <div>
//    <ElementInteraction />
//    {/* //TODO Fix data */}
//    <NextBlockTrigger trigger={fetchNextLiveBlock} data={(blocks && blocks[currentBlockIndex]) || []} blocks={docElems} />
//  </div>;
