import React, { useRef, useState, useCallback, useEffect } from "react";
import {
  Slate,
  Editable,
  withReact,
  ReactEditor,
  useFocused,
} from "slate-react";

import { createEditor, Text, Editor, Transforms } from "slate";
import isHotkey from "is-hotkey";
import { withHistory } from "slate-history";

import { withHtml, withInlines, withEditableVoids } from "./plugins/index.js";
import { Wrapper, EditorWrap } from "./UI/styled";
import { QuestionPopover } from "./tools/inlineModal";
import { HoveringToolbar } from "./tools/hoverToolbar";
import { ToolbarTopIcons } from "./tools/toolbarMenu";

import { Element, SaveButton } from "./elements";
import { Leaf } from "./leafs";
import {
  toggleFormat,
  onEditorPress,
  toggleMark,
  handleInline,
} from "./helperFunctions/index.js";
import {
  EditorRange,
  replaceSelectedText,
  getUniqueWords,
  markWordInEditor,
} from "./helperFunctions/text.js";
import MenuDivider from "antd/lib/menu/MenuDivider.js";
// Define a React component to render leaves with bold text.

export const EditorSample = ({ onChange }) => {
  const [value, setValue] = useState([
    {
      type: "paragraph",
      children: [{ text: "" }],
    },
  ]);

  return (
    <>
      Editor Sample
      <ContentEditor
        content={value}
        save={setValue}
        style={{ background: "rgb(255,255,255, 0.1)" }}
      />
    </>
  );
};

/**
 * Used to create editable blocks. 
 * @param {Number} props.fragmentIndex 
 * @param {Array} props.content Sample: [{ type: "paragraph", children: [{ text: "" }] }]
 * @param {String} props.placeholder 
 * @param {Function} props.save 
 * @param {Boolean} props.showToolbar 
 * @param {Boolean} props.showHoverToolbar  
 * @param {Boolean} props.allowAddQuestions 
 * @param {Object} props.style 

 * @returns 
 */

export default function ContentEditor({
  fragmentIndex,
  content,
  placeholder,
  save,

  showToolbar = true,
  showHoverToolbar = true,
  allowAddQuestions = true,
  plainText,
  ...style
}) {
  const editorRef = useRef();

  return (
    <Wrapper>
      <EditorWrap isFocused={true} {...style}>
        <SlateEditor
          value={content}
          setValue={save}
          editorRef={editorRef}
          autoFocus={true}
          placeholder={placeholder}
          showToolbar={showToolbar}
          showHoverToolbar={showHoverToolbar}
          allowAddQuestions={allowAddQuestions}
          fragmentIndex={fragmentIndex}
        />
      </EditorWrap>
    </Wrapper>
  );
}

export function SlateEditor({
  value,
  setValue,
  editorRef,
  showToolbar,
  showHoverToolbar,
  allowAddQuestions,
  autoFocus,
  placeholder,
  fragmentIndex,
}) {
  const isFocused = useFocused();
  let memoized = React.useMemo(
    () =>
      withEditableVoids(
        withInlines(withHtml(withReact(withHistory(createEditor())))),
        fragmentIndex
      ),
    []
  );

  if (!editorRef.current) {
    editorRef.current = memoized;
  }
  let slateWrapRef = useRef(null);
  let editor = editorRef.current;

  const renderElement = useCallback((props) => {
    return <Element {...props} editor={editorRef.current} />;
  }, []);

  const renderLeaf = useCallback((props) => {
    return <Leaf {...props} />;
  }, []);

  function handleToolbarClick(event) {
    console.log(event.inputType);
    switch (event.inputType) {
      case "bold":
        return toggleFormat(editor, "bold");
      case "italic":
        return toggleFormat(editor, "italic");
      case "underline":
        return toggleFormat(editor, "underlined");
      case "question":
        return toggleFormat(editor, "question");
      case "inline-question-void":
        return toggleFormat(editor, "inline-question-void");
      case "link":
        return toggleFormat(editor, "link");
      default:
        return null;
    }
  }
  function handleKeyDown(event) {
    const HOTKEYS = {
      "mod+b": "b",
      "mod+i": "i",
      "mod+u": "u",
      "mod+`": "`",
      "mod+s": "s",
      "mod+1": "inlineQuestionVoid",
    };
    for (const hotkey in HOTKEYS) {
      if (isHotkey(hotkey, event)) {
        event.preventDefault();
        const mark = HOTKEYS[hotkey];
        onEditorPress(editor, mark);
      }
    }
    // if (slateWrapRef?.current) {
    //   getUniqueWords(slateWrapRef.current.innerText, editor);
    // }
  }

  return (
    <Slate
      editor={editor}
      value={value}
      onChange={(newValue) => {
        // markWordInEditor(editor, "cheat", "comment");
        setValue(newValue);
      }}
    >
      {showToolbar ? <ToolbarTopIcons /> : null}
      {showHoverToolbar ? <HoveringToolbar /> : null}
      {allowAddQuestions ? <QuestionPopover editor={editor} /> : null}
      <div ref={slateWrapRef}>
        <Editable
          className="editor"
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          onKeyDown={handleKeyDown}
          onDOMBeforeInput={handleToolbarClick}
          placeholder={placeholder || "Enter text here..."}
          autoFocus={autoFocus}
        />
      </div>
    </Slate>
  );
}
