import { Box } from "../containers/box";
import React from "react";
import styled from "styled-components";

const RadioBtn = styled.input`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;
const RadioLabel = styled.label`
  color: inherit;
`;
const Wrap = styled.div`
  margin: 0 0;
  /* display: flex;
  justify-content: center; */
  cursor: pointer;
  user-select: none;
`;
const OuterCircle = styled.div`
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  transition: all 0.1s linear;
  border: ${({ selected }) => (selected ? "2px solid var(--primary-color)" : " 2px solid rgb(200,200,200)")};
`;
const InnerCircle = styled.div`
  width: ${({ selected }) => (selected ? "8px" : "0px")};
  height: ${({ selected }) => (selected ? "8px" : "0px")};
  border-radius: 50%;
  background: ${({ selected }) => (selected ? "var(--primary-color)" : "rgb(200,200,200)")};
  transition: all 0.1s linear;
`;
/**
 * @param  {String} name Component name
 * @param  {String} value Works in conjunction with name
 * @param  {Boolean} checked
 * @param  {Number} index
 * @param  {Callback} onChange If no index, returns boolean
 * @param  {String} children Component to go under.
 */
export function Radio({ name, onChange, value, checked, index, children, defaultChecked, disabled, ...rest }) {
  const [isSelected, selectRadio] = React.useState(checked || defaultChecked || false);

  React.useEffect(() => {
    selectRadio(!!checked);
  }, [checked, isSelected]);

  return (
    <Box type="row nowrap" padding="0" background="none" width="min-content" {...rest}>
      <RadioLabel
        htmlFor={name}
        onMouseDown={() => {
          selectRadio(!isSelected);
          onChange(index !== undefined ? index : isSelected);
        }}
      >
        <RadioBtn type="radio" name={name} value={value} checked={isSelected} disabled={disabled} />
        <Wrap>
          <OuterCircle selected={isSelected}>
            <InnerCircle selected={isSelected} />
          </OuterCircle>
        </Wrap>
      </RadioLabel>
      {children ? (
        <Box type="row" padding="0" background="none">
          {children}
        </Box>
      ) : null}
    </Box>
  );
}
