import { useState, useEffect, useContext, useCallback } from "react";
import { Button } from "../layout/buttons/buttons";
import LectureContext from "./context";
import { QuestionView } from "./question/questionView";
import QuizView from "./question";
import { RounderBtn } from "../layout/buttons/buttonStyles";
import { toast } from "react-toastify";
import { getAllIdsFromText } from "../editor2/features/question";
import { useReward } from "react-rewards";

export const ElementInteraction = ({ onFinish }) => {
  const { interactElement, breakPoints, setElementInteraction } = useContext(LectureContext);
  let currentStop = breakPoints[0];
  let hasQids = currentStop?.questionIds;
  let isQuestion = hasQids?.length === 1;
  let isQuiz = hasQids?.length > 1;
  const handleQuizFinish = useCallback(
    function handleQuizFinish() {
      interactElement.completionCallback();
      setElementInteraction();
    },
    [interactElement, setElementInteraction]
  );
  const manageInteractionTypes = useCallback(
    function manageInteractionTypes(typeString) {
      switch (typeString) {
        default:
          interactElement?.completionCallback();
      }
    },
    [interactElement]
  );
  const Question = useCallback((props) => <QuestionView {...props} />, []);
  const Quiz = useCallback((props) => <QuizView {...props} />, []);
  if (!interactElement) return null;

  // function triggerInteraction() {
  //   let interactionUndefined = !interactionList;
  //   let noInteractions =
  //     Array.isArray(interactionList) && !interactionList.length;
  //   let currentInteraction = !noInteractions && interactionList[0];

  //   if (interactionUndefined || noInteractions) {
  //     return completionCallback();
  //   }
  //   manageInteractionTypes();
  // }

  if (isQuestion) {
    return <Question handleFinish={handleQuizFinish} data={hasQids[0]} />;
  }
  if (isQuiz) {
    return <Quiz handleFinish={handleQuizFinish} data={hasQids} />;
  }
  return (
    <span
      style={{ display: "inline-block", width: "100%" }}
      onMouseDown={() => {
        handleQuizFinish();
      }}
    >
      <Button theme="grey-white">Next</Button>
    </span>
  );
  /**
   * 1. There can be a list of interactions.
   * 2. All interactions must conclude in a callback.
   * 3. Interaction types can be different: e.g. answering a question etc.
   * 4. Interactions may be differrent for different blocks. e.g. para =/= video.
   * 5.
   **/
};

export default ElementInteraction;

export const NextBlockTrigger = ({ trigger, data, blocks }) => {
  const { isInlineElseBlock, isLive, isComplete } = useContext(LectureContext);
  const [lectureEnded, setEnd] = useState(false);
  const { reward, isAnimating } = useReward("rewardId", "confetti");
  const [hasFinalQuiz, setFinalQuiz] = useState(false);

  useEffect(() => {
    if (lectureEnded) {
      toast.success("Lecture Complete!", { hideProgressBar: true });
    }
  }, [lectureEnded]);

  if (isInlineElseBlock) return null;
  if (!data) return null;

  const handleTrigger = () => {
    let dataLen = data?.length;
    let blocksLen = blocks?.length;
    let onLastBlock = dataLen > 0 && blocksLen > 0 && dataLen === blocksLen;
    if (onLastBlock && !isInlineElseBlock && !lectureEnded) {
      setEnd(true);
    }
    trigger();
  };

  if (isLive && data.length - blocks.length > 0) {
    return (
      <RounderBtn theme="grey-white" onClick={() => handleTrigger()} style={{ margin: "2em auto" }} disabled={lectureEnded}>
        I internalized it. Gimme next!
      </RounderBtn>
    );
  }
  if (isLive && data.length - blocks.length <= 0) {
    return (
      <RounderBtn
        theme="grey-white"
        disabled={(lectureEnded && isAnimating) || isComplete}
        onClick={() => {
          reward();
          handleTrigger();
        }}
        style={{ margin: "2em auto" }}
        // disabled={lectureEnded}
      >
        <span id="rewardId">Finito</span>
      </RounderBtn>
    );
  }

  return (
    <RounderBtn theme="grey-white" onClick={() => handleTrigger()} style={{ margin: "2em auto" }} disabled={lectureEnded}>
      {!data.leng ? "Fetch Content" : "Internalized!"}
    </RounderBtn>
  );
};
