import React, {
  useState,
  useRef,
  useEffect,
  useContext,
} from 'react';
import { useSlate } from 'slate-react';
import { MediaStateProvider, MediaContext } from './masterContext';
import { Editor, Transforms, Element } from 'slate';
import AddAudioTrackAndQuestions from '../addSrc';
import MediaElement from './audVidElement';
import { AudioQuestions } from '../addQs';
import { Para } from '../../styled';

import {
  createFragmentMedia,
  updateFragmentAudio,
  getFragmentAudio,
} from '../../../../../helpers/REST/api';

export default function EditableMediaPlayer({ element, children }) {
  const mediaRef = useRef([]);
  const [tracks, setTracks] = useState(() => {
    let trackResult = Array.isArray(element.tracks)
      ? element.tracks
      : [];

    return trackResult;
  });

  //Block data is saved to db; inherited from editor.
  let editor = useSlate();
  let { blockId } = editor?.blockFragmentData;
  let { fragmentIndex } = editor?.blockFragmentData;
  useEffect(() => {
    async function fetchTracksOnLoad() {
      let fetched = await (async () => {
        let promised = await Promise.all(
          element.mediaId.map(async (mediaId) => {
            let t = await getFragmentAudio(mediaId);

            return t;
          })
        );

        return promised;
      })();

      let strungedProp = JSON.stringify(element.tracks);
      let strungedState = JSON.stringify(fetched.tracks);
      let shouldUpdateState = strungedProp !== strungedState;
      if (shouldUpdateState) setTracks(fetched);
    }
    if (Array.isArray(element.mediaId) && element.mediaId.length) {
      fetchTracksOnLoad();
    }
  }, []);
  async function addTrack(obj, i) {
    if (i < 0 || i > tracks.length) {
      console.log('index falls outside the limit');
      //Edge case; i = index;
      return;
    } else if (
      element.mediaId &&
      element.mediaId.length &&
      i === undefined
    ) {
      //No index? add obj at the end.
      console.log('No index given; we have mediaId: updating track');
      update(tracks.length, obj);
    } else if (blockId !== undefined && fragmentIndex !== undefined) {
      //Create new.
      console.log('index given; ctreating new track');
      let newTracks = Object.assign({}, obj, {
        blockId: [blockId, fragmentIndex],
      });
      let created = await createFragmentMedia(newTracks);
      let newElemTracks = [...tracks, newTracks];
      let newMediaIds = element.mediaId
        ? [element.mediaId, created._id]
        : [created._id];

      setTracks(newElemTracks);
      addToNode({
        tracks: [...tracks, newTracks],
        mediaId: newMediaIds,
      });
    }
  }
  function deleteTrack(i) {
    let newTracks = [...tracks];
    let newMediaIds = [...element.mediaId];
    newTracks.splice(i, 1);
    newMediaIds.splice(i, 1);
    addToNode({
      tracks: newTracks,
      mediaId: newMediaIds,
    });
    setTracks(newTracks);

    // alert("yeah");
  }
  function transposeTrack(initial, final) {
    console.log(tracks);
    let newTracks = [...tracks];
    let removed = newTracks.splice(initial, 1);

    newTracks.splice(final, 0, removed[0]);
    console.log(initial, final, removed);
    setTracks(newTracks);
    let newMediaId = [...element.mediaId];
    removed = newMediaId.splice(initial, 1);
    newMediaId.splice(final, 0, removed[0]);
    console.log(removed);
    addToNode({ tracks: newTracks, mediaId: newMediaId });
  }
  function update(i, obj) {
    let update = [...tracks];
    alert(i);
    if (i !== undefined) {
      const updatedProperty = {};
      Object.keys(obj).forEach((key) => {
        if (tracks[i][key] !== obj[key]) {
          updatedProperty[key] = obj[key];
        }
      });
      console.log(updatedProperty);
      updateFragmentAudio(element.mediaId[i], obj);
      update[i] = obj;
    } else {
      update.push(obj);
    }
    setTracks(update);
    addToNode({ tracks: update });
  }
  function addToNode(obj) {
    Transforms.setNodes(editor, obj, {
      match: (n) =>
        !Editor.isEditor(n) &&
        Element.isElement(n) &&
        n.type === 'audio',
    });
  }

  return (
    <div style={{ position: 'relative' }}>
      <MediaStateProvider
        value={{
          editor,
          tracks: element.tracks,
          mediaRef,
        }}
      >
        <div contentEditable={false}>
          <MediaElement tracks={element.tracks} />
          <AudioQuestions
            tracks={
              Array.isArray(tracks) && tracks.length ? tracks : []
            }
            addTracks={addTrack}
            element={element}
          />
          <AddAudioTrackAndQuestions
            tracks={element.tracks}
            addTrack={addTrack}
            updateTrack={update}
            deleteTrack={deleteTrack}
            transposeTrack={transposeTrack}
          />
        </div>
        {children ? <Para>{children}</Para> : null}
      </MediaStateProvider>
    </div>
  );
}
