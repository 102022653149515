import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useParams, Link } from "react-router-dom";
import { UserContext } from "../../../helpers/hooks/userContext";
import { getCourses, POST, GET } from "../../../helpers/REST/api";
import { Info, Lock } from "../../../images/icons";
import { Button } from "../../layout/buttons/buttons";
import RoundProgress from "../../progressBar/round";
import { Icon, Title, ButtonWrap } from "./components/styled";
import { NonDeadlinedLayer } from "./components/modules";

const Gallery = () => {
  const { standing, studyPlan } = useContext(UserContext);
  let { id } = useParams();

  function ModuleList() {
    if (studyPlan && Array.isArray(studyPlan)) {
      return studyPlan.map((item, index) =>
        item._id === id ? (
          <div key={item._id + index}>
            <h2>{item.childType}</h2>
            {item.children.map((child, chIndex) => {
              let hierarchIndex = standing.hierarchy.indexOf(child.childType);
              let activeIndex = standing.currentIndex[hierarchIndex];
              let isActive = chIndex === activeIndex;
              return (
                <NonDeadlinedLayer
                  {...child}
                  // mod={child}
                  index={chIndex}
                  key={child._id}
                  isActive={isActive}
                >
                  {isActive
                    ? child.children.map((child, chIndex) => (
                        <Unit
                          unit={child}
                          key={child._id + chIndex}
                          layerType={item.childType}
                          index={chIndex}
                        />
                      ))
                    : null}
                </NonDeadlinedLayer>
              );
            })}
          </div>
        ) : null
      );
    }
    return null;
  }
  return (
    <>
      <ModuleList />
    </>
  );
};

export default Gallery;

const Module = (props) => {
  const {
    index,
    isActive,
    mod: {
      title,
      description,
      layerType,
      isGated,
      startDate,
      children,
      _id,
      progress,
      childType,
    },
  } = props;
  const [status, setStatus] = useState();

  return (
    <>
      <ModuleWrap isActive={isActive}>
        <Icon className="start">
          {!isGated ? (
            <RoundProgress
              sqSize={"50"}
              strWidth={"1"}
              percentage={progress || 0}
            />
          ) : (
            <Lock color="white" size="20px" />
          )}
        </Icon>

        <Title className="middle">
          <div>
            {title}{" "}
            <Info round={true} color={"white"} style={{ margin: "auto 0" }} />
          </div>
          <div>
            <span className="desc">
              {layerType ? layerType.toUpperCase() : ""}
            </span>{" "}
            | <span className="desc">{description}</span>
          </div>
          <div className="desc"></div>
        </Title>

        <ButtonWrap className="end">
          <>
            {isActive ? (
              <Link to={`/lecture/${_id}`}>
                <Button
                  style={{ height: "35px", fontWeight: 400, color: "white" }}
                >
                  Expand
                </Button>
              </Link>
            ) : null}
          </>
        </ButtonWrap>
      </ModuleWrap>
      {isActive
        ? children.map((child, chIndex) => (
            <Unit
              unit={child}
              key={child._id}
              layerType={childType}
              index={chIndex}
            />
          ))
        : null}
    </>
  );
};

const ModuleWrap = styled.div`
  margin: 0 auto;
  width: 600px;

  border-radius: var(--border-radius-med);
  display: grid;
  grid-template-columns: 0.3fr 2.3fr 0.7fr;
  grid-template-rows: 75px;
  gap: 0px 0px;
  grid-template-areas: "start middle end";
  background: linear-gradient(
    -25deg,
    rgba(30, 30, 30, 1) 0%,
    rgba(50, 50, 50, 0.7) 100%
  );
  align-items: center;
  padding: 8px;
  opacity: ${({ isActive }) => (isActive ? "1" : "0.5")};
  margin-bottom: 16px;
`;

const UnitWrap = styled(ModuleWrap)`
  padding: 5px 8px;
  color: white;
  position: relative;
  left: 25px;
  border-left: 5px solid white;
`;
const Unit = ({ unit, layerType, index }) => {
  const { isGated, title, progress, description, _id } = unit;
  const { UID, MID, PID, standing, studyPlan } = useContext(UserContext);
  const [unitData, setUnitData] = useState(null);
  let hierarchIndex = standing.hierarchy.indexOf("unit");
  let activeIndex = standing.currentIndex[hierarchIndex];
  return (
    <UnitWrap isActive={index === activeIndex}>
      <Icon className="start">
        {!unit.isGated ? (
          <RoundProgress
            sqSize={"50"}
            strWidth={"1"}
            percentage={unit.progress || 0}
          />
        ) : (
          <Lock color="white" size="20px" />
        )}
      </Icon>

      <Title className="middle">
        <div>
          {title}{" "}
          <Info round={true} color={"white"} style={{ margin: "auto 0" }} />
        </div>
        <div>
          <span className="desc">
            {layerType ? layerType.toUpperCase() : ""}
          </span>{" "}
          | <span className="desc">{description}</span>
        </div>
        <div className="desc"></div>
      </Title>

      <ButtonWrap className="end">
        <>
          <Button style={{ height: "35px", fontWeight: 400 }}>Progress</Button>
          <Link to={`/learn/${_id}`}>
            <Button style={{ height: "35px", fontWeight: 400 }}>Resume</Button>
          </Link>
        </>
      </ButtonWrap>
    </UnitWrap>
  );
};
