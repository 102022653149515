import { Para } from "../../editor/UI/styled";
export const ParaBlock = ({ attributes, children, element }) => {
  return (
    <Para {...attributes}>
      <span>{children}</span>
    </Para>
  );
};

export default ParaBlock;
