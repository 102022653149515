import { useEffect } from "react";
import { Editor, Element, Transforms } from "slate";
import { Indic } from "../../editor/UI/styled";
import EditableMediaPlayer from "../renders/multiMediaPlayer/MediaEditor";
import { toast } from "react-toastify";
export function AudioBlock({ attributes, children, element, editor }) {
  const fetchMediaIds = () => {};
  const addMediaIdToElement = () => {};
  const addMediaId = (mediaId, i) => {
    let mediaIds = element.mediaId;
  };
  const addTrack = () => {};

  useEffect(() => {
    console.log(element);
    if (element && !element.mediaId) {
      Transforms.setNodes(editor, {
        mediaId: ["6405431bfb511fe61228091b", "6405431bfb511fe61228091b"],
      });
    }
  }, []);

  return (
    <div {...attributes}>
      <Indic contentEditable={false}>Audio Section Started</Indic>
      <EditableMediaPlayer element={element}>{children}</EditableMediaPlayer>
      <Indic contentEditable={false}>Audio Section Ended</Indic>
    </div>
  );
}
