import { useEffect, useState, useContext, useCallback } from "react";
import { Link } from "react-router-dom";
import { Draggable } from "react-beautiful-dnd";
import { Tooltip } from "antd";
import { ProductsCtx, ProductsCtxProvider } from "./modalHook.js";
import { createProductLayer, getCourses } from "../../../helpers/REST/api";
import { DndWrap, DndRowWrap, ListItem } from "../../../components/dragDrop/index";
import { SelectInput } from "../../layout/containers/inputStyles.js";
import { ReactSelectWrap } from "../../layout/containers/inputStyles.js";
import { RounderBtn } from "../../layout/buttons/buttonStyles.js";

import { Box } from "../../layout/containers/box";
import { Button, DropBtn } from "../../layout/buttons/buttons";
import { ExternalLink, Info, Plus } from "../../../images/icons";
import { DropdownWrap, IconBorder, RowIconWrap, DndRowDragOrBackIcon, RowLabel, BackIcon } from "./styled";
import { LayerLogicProvider, LayerLogic } from "./modalHook";
import { TabGroup, TabBtn } from "../../layout/containers/box";

function decryptLayer(item) {
  if (item.category) return "product";
  if (item.layerType && item.childType) return item.layerType;
  if (item.blocks && item.parentContainer) return "document";
  if (item.doc_elements_rtf && item.replicatedFrom) return "blocks";
}

const AddProductBtn = () => {
  const { openModal, data } = useContext(ProductsCtx);

  return (
    <div style={{ height: "auto", width: "min-content", marginBottom: "25px" }}>
      <RounderBtn
        onMouseDown={() => {
          openModal("products", null, false);
        }}
        style={{ border: "1px solid grey", background: "transparent", color: "snow" }}
      >
        <Plus color="white" size="1.25em" />
        <span style={{ marginLeft: "1em" }}>Course Product</span>
      </RounderBtn>
    </div>
  );
};
const Products = () => {
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [];
  return (
    <ProductsCtxProvider>
      <LayerLogicProvider>
        <Box type="col left" background="none" padding="0" minHeight={"100dvh"}>
          <TabGroup width="min-content" tabs={["Courses", "Services"]} alignTabs={"left"} setTab={setActiveTab} activeIndex={activeTab} />

          {activeTab === 0 ? <CourseProducts /> : null}
        </Box>
      </LayerLogicProvider>
    </ProductsCtxProvider>
  );
};

const CourseProducts = ({ children, ...props }) => {
  const [activeTab, setActiveTab] = useState(0);
  const AddProductBtnCallback = useCallback(() => <AddProductBtn />, []);
  const ProductList = useCallback(() => <Layer layer={"product"} layerIndex={-1} isSelected={true} />, []);
  return (
    <Box type="col left" background="none" padding="0" minHeight={"100dvh"}>
      <TabGroup width="min-content" tabs={["Layout", "Course Docs", "Questions", "Media"]} alignTabs={"left"} setTab={setActiveTab} activeIndex={activeTab} />
      <div>
        <AddProductBtnCallback />
      </div>
      <ProductList />
    </Box>
  );
};

/**
 * @ Layer
 * A recurisively used component that has drag-drop capability.
 * Only allows same-layer drag-drop.
 */
const Layer = (props) => {
  const { layer, layerIndex, isSelected } = props;
  const { courseId, parentId, hierarchy } = props;

  const [activeIndex, setActive] = useState(null);
  const [layerData, setLayerData] = useState([]);

  const selectGetAPI = useCallback(
    function selectGetAPI(layerType, courseId) {
      let uri;
      let params = {};
      switch (layerType) {
        case "product":
          uri = "/products/";
          break;
        case "block":
          uri = `/blocks/?parentId=${parentId}`;
          params = { parentId };
          break;

        case "document":
          uri = `/documents/?parentContainer=${parentId}&audience=course`;
          params = { parentContainer: parentId };
          break;
        default: // Never used?
          uri = "/modules/nodes";
          params = {
            params: { layerType: layerType, productId: courseId, parentId },
          };
        // case "concept":
        //   uri = `/modules/${parentId}/childConcept`;
        //   break;
      }
      return { uri, params };
    },
    [parentId]
  );
  const handleReorder = useCallback(
    (newList) => {
      console.log(newList);
      setLayerData(newList);
    },
    [selectGetAPI]
  );

  useEffect(() => {
    async function fetchChildren(childType, courseId) {
      const { uri, params } = selectGetAPI(childType, courseId);
      let result = await getCourses(uri, params);
      setLayerData(result);
    }
    if (isSelected) {
      fetchChildren(layer, courseId);
    }
  }, [isSelected]);

  let listStyle = useCallback((layerIndex, isSelected) => {
    let result = {
      marginLeft: isSelected ? `${layerIndex * 16 + 20}px` : `${layerIndex * 16}px`,
      padding: "0px",
    };
    return result;
  }, []);

  return (
    <DndWrap droppableId={`${layer}_Droppable_${layerIndex}`} grid={8} width={500} data={layerData} setData={handleReorder} setActive={setActive} activeIndex={activeIndex}>
      {layerData &&
        Array.isArray(layerData) &&
        layerData.map((item, itemIndex) => {
          // REFERENCES
          // let uId = item["title"] + itemIndex;
          let uId = item._id;
          let isSelected = itemIndex === activeIndex;
          let hide = activeIndex !== null && itemIndex !== activeIndex;
          function activate(e) {
            isSelected ? setActive(null) : setActive(itemIndex);
          }
          function dndRow_mouseDown(e) {
            e.stopPropagation();
            if (!isSelected) activate(e);
          }

          return (
            <Draggable draggableId={uId} index={itemIndex} key={uId} isDragDisabled={isSelected}>
              {(provided, { isDragging }) => (
                <div>
                  <ListItem
                    isActive={isSelected}
                    isHidden={hide}
                    grid={8}
                    isDragging={isDragging}
                    style={{
                      ...listStyle(layerIndex, isSelected),
                      ...provided.draggableProps.style,
                    }}
                    {...provided.dragHandleProps}
                    {...provided.draggableProps}
                    ref={provided.innerRef}
                  >
                    <DndRowWrap grid={8} onClick={(e) => dndRow_mouseDown(e)}>
                      <DndRowDragOrBackIcon isSelected={isSelected} activate={activate} />

                      <RowLabel title={item.abbr || item.title} type={layer} description={null} />

                      <OptionsIcons active={isSelected} layer={layer} item_id={item._id} dropClick={() => activate()} item={item} />
                    </DndRowWrap>
                  </ListItem>

                  <NestedChildrenLayers
                    {...{
                      hierarchy,
                      item,
                      isSelected,
                      layer,
                      layerIndex,
                      activeIndex,
                      courseId,
                    }}
                  />

                  {provided.placeholder}
                </div>
              )}
            </Draggable>
          );
        })}
    </DndWrap>
  );
};

const NestedChildrenLayers = (props) => {
  const { hierarchy, item, itemIndex, activeIndex, isSelected, layer, layerIndex, courseId, hover } = props;
  let uId = item._id || item["title"] + itemIndex;
  let hovered = itemIndex === hover;
  let hide = activeIndex !== null && itemIndex !== activeIndex;
  let hasChildren = item.children && item.children.length;

  let hasHierarc = item.hierarchy && item.hierarchy.length;
  let hasBlocks = item.blocks && item.blocks.length;
  if (hasChildren && layer === "product") {
    return (
      <DropdownWrap isOpen={isSelected}>
        <Layer hierarchy={hierarchy ? hierarchy : item.hierarchy} layer={item.hierarchy[0]} layerIndex={layerIndex + 1} courseId={item._id} parentId={undefined} isSelected={isSelected} />
      </DropdownWrap>
    );
  }
  if (hasChildren && layer !== "product") {
    return (
      <DropdownWrap isOpen={isSelected}>
        <Layer hierarchy={hierarchy ? hierarchy : item.hierarchy} layer={item.childType} layerIndex={layerIndex + 1} courseId={courseId} parentId={item._id} isSelected={isSelected} />
      </DropdownWrap>
    );
  }
  if (hasBlocks && layer !== "product") {
    return (
      <DropdownWrap isOpen={isSelected}>
        <Layer hierarchy={hierarchy ? hierarchy : item.hierarchy} layer={"block"} layerIndex={layerIndex + 1} courseId={courseId} parentId={item._id} isSelected={isSelected} />
      </DropdownWrap>
    );
  }

  if (hasHierarc) {
    return (
      <DropdownWrap isOpen={isSelected}>
        <Layer hierarchy={item.hierarchy} layer={item.hierarchy[0]} layerIndex={0} courseId={item._id} isSelected={isSelected} />
      </DropdownWrap>
    );
  }
  return null;
};

const OptionsIcons = ({ item_id, item, ...props }) => {
  const { openModal } = useContext(ProductsCtx);
  const layer = decryptLayer(item);
  const onAddMd = (t) => openModal(t);
  function onInfoMd() {}
  const color = !props.active ? "black" : "white";
  const activeRow = props.active ? "black" : "var(--primary-color)";

  const AddIcon = useCallback(() => {
    const color = !props.active ? "black" : "white";
    const activeRow = props.active ? "black" : "var(--primary-color)";
    let modal = "layers";
    let title = "Course";
    if (layer === "product") {
      // modal = "layers";
      title = item.hierarchy[0];
    } else if (item.layerType === "unit") {
      modal = "add_docs";
      title = "Document";
    } else {
      title = item.childType;
    }

    return (
      <Tooltip title={`Add ${title}`}>
        <IconBorder onMouseDown={() => openModal(modal, item)}>
          <Plus color={color} active={activeRow} size={"20px"} round={true} />
        </IconBorder>
      </Tooltip>
    );
  }, [props.active, layer, item, openModal]);
  const InfoIcon = useCallback(() => {
    const color = !props.active ? "black" : "white";
    const activeRow = props.active ? "black" : "var(--primary-color)";
    let modal = "layers";
    if (layer === "product") {
      modal = "products";
    } else if (layer === "unit") {
      modal = "add_docs";
    }
    return (
      <Tooltip title="Show details & edit">
        <Info
          color={color}
          active={activeRow}
          size={"20px"}
          round={true}
          onMouseDown={(e) => {
            e.preventDefault();
            openModal(modal, item, "edit");
          }}
        />
      </Tooltip>
    );
  }, [props.active, layer, item, openModal]);
  const DropIcon = useCallback(() => {
    const color = !props.active ? "black" : "white";
    return (
      <IconBorder color={color}>
        <DropBtn width={20} pad={0} bg={"transparent"} onClick={(e) => props.dropClick()} isOpen={props.active} />
      </IconBorder>
    );
  }, [props]);
  let layerLowerCase = layer?.toLowerCase();
  if (layerLowerCase === "product") {
    return (
      <RowIconWrap onClick={(e) => e.stopPropagation()}>
        <AddIcon active={props.active} layer={layerLowerCase} />
        <InfoIcon active={props.active} layer={layerLowerCase} item={props.item} />
      </RowIconWrap>
    );
  }
  if (layerLowerCase === "subject" || layerLowerCase === "chapter" || layerLowerCase === "unit") {
    return (
      <RowIconWrap onClick={(e) => e.stopPropagation()}>
        <AddIcon active={props.active} layer={layerLowerCase} />
        <InfoIcon active={props.active} layer={layerLowerCase} item={props.item} />
        <DropIcon active={props.active} layer={layerLowerCase} />
      </RowIconWrap>
    );
  }
  if (layerLowerCase === "document") {
    return (
      <RowIconWrap>
        <Link to={{ pathname: `/documents/${item_id}` }} target="_blank">
          <ExternalLink color={color} size={"20px"} />
        </Link>

        {/* <FormEdit color={color} size={"20px"} /> */}
      </RowIconWrap>
    );
  }
  return <RowIconWrap>{item.doc_elements_rtf.length} Element(s)</RowIconWrap>;
};

const subSample = {
  layerType: "subject",
  childType: "chapter",
  title: "CARS subject 1",
  description: "subject 1",
};
const chapSample = {
  layerType: "chapter",
  childType: "unit",
  title: "Chapter 1",
  description: "CARS",
};
const unitSample = {
  layerType: "unit",
  childType: "document",
  title: "Unit 1",
  description: "CARS",
};

export default Products;
