import { Editor } from "slate";
import { toggleMark, toggleBlock } from "./index.js";

export const TYPE_TAGS = {
  block: {
    A: (el) => ({ type: "link", url: el.getAttribute("href") }),
    BLOCKQUOTE: () => ({ type: "quote" }),
    H1: () => ({ type: "heading-one", split: true }),
    H2: () => ({ type: "heading-two", split: true }),
    H3: () => ({ type: "heading-three", split: true }),
    H4: () => ({ type: "heading-four" }),
    H5: () => ({ type: "heading-five" }),
    H6: () => ({ type: "heading-six" }),
    IMG: (el) => ({ type: "image", url: el.getAttribute("src") }),
    LI: () => ({ type: "list-item" }),
    OL: () => ({ type: "numbered-list" }),
    P: () => ({ type: "paragraph" }),
    "`": () => ({ type: "code" }),
    UL: () => ({ type: "bulleted-list" }),
  },
  inline: {
    inlineQuestion: () => ({ type: "inline-question" }),
    link: () => ({ type: "link" }),
  },
  void: {
    inlineQuestionVoid: () => ({
      type: "inline-question-void",
      children: [{ text: "" }],
    }),
  },
};

export const TEXT_TAGS = {
  s: "strikethrough",
  i: "italic",
  b: "bold",
  u: "underline",
};

// function isMarkActive(editor, format) {
//   const marks = Editor.marks(editor);
//   return marks ? marks[format] === true : false;
// }

function toggleVoid(editor, format) {
  let selectionIsVoid = isSelectionVoid(editor);
}
function isSelectionVoid(editor) {
  const { selection } = editor;
  if (!selection) return false;
  const [match] = Array.from(
    Editor.nodes(editor, {
      match: (n) => !Editor.isEditor(n) && editor.isVoid(n),
    })
  );
  return !!match;
}

export function onEditorPress(editor, key) {
  let isTag = typeof TEXT_TAGS[key] !== "undefined";
  let isBlock = typeof TYPE_TAGS.block[key] !== "undefined";
  let isInline = typeof TYPE_TAGS.inline[key] !== "undefined";
  let isVoid = typeof TYPE_TAGS.void[key] !== "undefined";

  if (isTag) {
    toggleMark(editor, TEXT_TAGS[key]);
  } else if (isBlock) {
    toggleBlock(TYPE_TAGS.block[key]);
  } else if (isInline) {
    toggleBlock(TYPE_TAGS.inline[key]);
  } else if (isVoid) {
  }
}
// export function toggleMark(editor, format) {
//   const isActive = isMarkActive(editor, format);
//   if (isActive) {
//     Editor.removeMark(editor, format);
//   } else {
//     Editor.addMark(editor, format, true);
//   }
// }
