import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { useAuth } from "../../helpers/auth";
import { POST } from "../../helpers/REST/api";

import Monitor from "../monitor";

import { Button, NotifAlertBtn, ProfileBtn } from "./buttons/buttons";
import { Link } from "react-router-dom";

const Header = styled.div`
  @media (max-width: 767px) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background-color: transparent;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 25px;
    z-index: 5;
    cursor: pointer;
    background: var(--tertiary-color-darkest-opaque);
  }

  /* For screens larger than 768px (web) */
  @media (min-width: 768px) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background-color: transparent;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 25px;
    z-index: 9;
    cursor: pointer;
    background: var(--tertiary-color-darkest-opaque);
  }
`;

const Pic = () => (
  <div>
    <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
      <circle cx="12" cy="7" r="4"></circle>
    </svg>
  </div>
);

export default function AppHeader({ page }) {
  const { signout, user } = useAuth();

  const [billingPortalLink, setLink] = useState(null);
  function getBillingLink() {
    let customer;

    let currLink = window.location.href;
    POST("/payments/stripe/billingPortal", { customer: user?.stripeCustomerId });
  }

  if (user === null) {
    return (
      <Header>
        <a target="_blank" rel="noreferrer" href={"https://99point9.ca"}>
          <h2>99point9</h2>
        </a>
        {/* <User onMouseDown={() => setOpen(!dropdownOpen)} /> */}
        {/* {dropdownOpen && <Monitor />} */}
        <div className="flex-row center" style={{ width: "min-content" }}>
          <Link to={page === "" ? "/login" : "/"} style={{ color: "snow" }}>
            <Button>{page === "" ? "Login" : "Gallery"}</Button>
          </Link>
        </div>
      </Header>
    );
  }
  return (
    <Header>
      <h3>99point9</h3>
      {/* <User onMouseDown={() => setOpen(!dropdownOpen)} /> */}
      {/* {dropdownOpen && <Monitor />} */}
      <div className="flex-row center" style={{ width: "min-content" }}>
        <NotifAlertBtn>
          <div style={{ minHeight: "50px" }}>No notifications</div>
        </NotifAlertBtn>
        <ProfileBtn>
          <div style={{ width: "100%", height: "100%" }}>Billing</div>
          <div style={{ width: "100%", height: "100%" }}>Settings</div>
          <div onMouseDown={() => signout()} style={{ width: "100%", height: "100%" }}>
            Signout
          </div>
        </ProfileBtn>
      </div>
    </Header>
  );
}
