import React from "react";
import { Editor, Transforms, Element, Text, Node } from "slate";
import { ReactEditor } from "slate-react";
/**
 * Returns an Array: [matchedElement, itsPath] => Each is also an array.
 * @param  {} editor
 */
export function addNewElementAfterCurrent(editor, elementType) {
  if (!editor) {
    return;
  }
  let nodesAtAllLevel = elementAtSelection(editor);
  if (nodesAtAllLevel && nodesAtAllLevel[0]) {
    let [topElem, topPath] = nodesAtAllLevel[0];
    let currentElIndex = topPath[0];
    Transforms.insertNodes(
      editor,
      {
        type: typeof elementType !== "string" ? "paragraph" : elementType,
        children: [{ text: "" }],
      },
      { at: [currentElIndex + 1] }
    );
    Transforms.select(editor, {
      anchor: { offset: 0, path: [currentElIndex + 1, 0] },
      focus: { offset: 0, path: [currentElIndex + 1, 0] },
    });

    Transforms.move(editor, {
      distance: 1,
      unit: "offset",
      edge: "focus",
    });
    Transforms.select(editor, {
      anchor: { offset: 0, path: [currentElIndex + 1, 0] },
      focus: { offset: 0, path: [currentElIndex + 1, 0] },
    });
  }
}
export function elementAtSelection(editor) {
  if (editor && editor.selection !== null) {
    return Array.from(
      Editor.nodes(editor, {
        at: editor.selection,
        match: (n) => Element.isElement(n),
      })
    );
  }
  return null;
}
export function lastWord(editor) {
  let Location = editor.selection.focus.path;
  let currentNode = Editor.node(editor, { at: Location });
  let text = Editor.string(editor, currentNode[1].at);
  text = text.split(" ");
  return text[text.length - 1];
}
export function replaceSelectedText(editor, prevWord, newWord, range) {
  let { anchor, focus } = editor.selection;
  let anchorPath = anchor.path;
  let anchorOffset = anchor.offset;
  let focusPath = focus.path;
  let focusOffset = focus.offset;
  console.log("selection", editor.selection);

  let nodeString = Editor.string(editor, focusPath);
  let ranges = [];
  console.log(nodeString);
  // find where the words are located.
  const parts = nodeString.split(prevWord);
  let offset = 0;

  parts.forEach((part, i) => {
    if (i !== 0) {
      ranges.push({
        anchor: { path: anchorPath, offset: offset - prevWord.length },
        focus: { path: focusPath, offset },
      });
    }

    offset = offset + part.length + prevWord.length;
  });
  console.log(ranges);

  // If they're different nodes, then what?
  // If same node: we'll know if their paths are the same, so we can just replace the text.
  if (anchorPath.length === focusPath.length) {
    let areTheSame = true;
    for (var i = 0; i < anchorPath.length; i++) {
      if (anchorPath[i] !== focusPath[i]) {
        areTheSame = false;
      }
    }
    if (areTheSame) {
      // replace the text
      ranges.forEach((item) => {
        if (item.anchor)
          Transforms.insertText(editor, newWord, {
            at: item,
          });
      });
    }
  }
}
export function EditorRange(editor) {
  console.log("ok");
  let start = {
    anchor: { path: [0, 0], offset: 0 },
    focus: { path: [0, 0], offset: 0 },
  };
  let end;

  let y = firstNode(editor);
  console.log(y);
}

/**
 * @param {Object} editor
 * @returns {Object} [point, point]; point = {path: [], offset: Number}
 */
function fullRange(editor) {
  return Editor.edges(editor, []);
}
/**
 * @param {Object} editor
 * @returns {Object} Last location (i.e. a point); point = {path: [], offset: Number}
 */
function endLocation(editor) {
  return Editor.end(editor, []);
}
/**
 * @param {Object} editor
 * @returns {Array} [node, path];
 * Node e.g., {text: ""}
 * path e.g. [0,0]
 */
function firstNode(editor) {
  return Editor.first(editor, []);
}

function moveCursorToXthWord(editor, index, unit) {
  return Transforms.move(editor, { distance: index, unit: "word" });
}
function allNodes(editor) {
  let [from, to] = fullRange(editor);
  let result = Node.leaf(editor, [0]);
  //   //   console.log(firstPoint);
  //   console.log(result);
  //console.log(firstPoint);
  let [match] = Editor.nodes(editor, {
    at: from,
    match: (n) => {
      return Text.isText(n);
    },
  });

  return match;
}

export function getUniqueWords(text, editor) {
  let result = { list: [] };
  //NOTE: Not including "-" punctions because words can be linked that way "e.g."
  //   var punctuationless = text.replace(/[.,\/#!$%\^&\*;:{}=\_`~()]/g, "");
  //   var finalString = punctuationless.replace(/\s{2,}/g, " ").trim();

  //   let array = finalString.split(" ").map((word, i) => {
  //     if (result[word] !== 1) {
  //       result[word] = 1;
  //       result.list.push(word);
  //     }
  //     return word.trim();
  //   });
  //   result.originalTextWithoutPunctuation = array;
  //   console.log(result);
  //   let nodes = allNodes(editor);
  //   console.log(nodes);
  //   nodes.forEach((n) => {
  //     let text = Node.string(n);
  //     console.log(text);
  //   });
  //   console.log(allNodes(editor));
}
export function nodeAtPath(editor, atPath) {
  return Editor.node(editor, { at: atPath }); // returns array []
}
export function stringAtNode(editor, atPath) {
  return Editor.string(editor, atPath);
}
export function currentString(editor) {
  //Depends on selection.
  if (editor?.selection.anchor) {
    return Editor.string(editor, editor.selection.anchor.path[0]);
  }
  return null;
}

/**
 *
 * @param {*} editor
 * @param {String} search Every instance of a word that you want marked.
 * @param {String} mark The sorta mark you want on that word. E.g. bold, italic, underline, or custom.
 */
export function markWordInEditor(editor, search, mark) {
  // We need to get the last word first!
  const lastNodeLocation = endLocation(editor); // Get last text node's location: it has the last text node; Get it's path.
  const [lastNode, lastNodeAt] = nodeAtPath(editor, lastNodeLocation.path); // @returns [node, {at: [<path>]}]

  // Get the entire last node's string; Get last word.
  let lastText = stringAtNode(editor, lastNodeAt.at);

  let lastTextArray = lastText.split(" ");
  let lastWord = lastTextArray[lastTextArray.length - 1];

  // We need to match the searched word against all the nodes
  function matchTextNodeAgainstSearch(node, path) {
    if (Text.isText(node) && node.text.includes(search) && !node[mark]) {
      return node;
    }
    return false;
  }

  // This gives us an array of all the matches.
  // Note: Each "node" has a path and the text string.
  //:: e.g. [{text: ""}, []]
  let allMatchedNodes = Array.from(
    Editor.nodes(editor, {
      at: {
        anchor: { path: [0, 0], offset: 0 },
        focus: lastNodeLocation,
      },
      match: matchTextNodeAgainstSearch,
      lowest: "true",
    })
  );

  if (
    lastWord === search &&
    Array.isArray(allMatchedNodes) &&
    allMatchedNodes.length &&
    !allMatchedNodes[0][0][mark] // i.e. the text node (which is an object) has the "mark" field e.g. a comment or bold
  ) {
    let matched = allMatchedNodes; //For brevity;
    matched.forEach((m) => {
      let [nodeTextObject, nodeTextPath] = matched[0];
      let { text } = nodeTextObject;
      let searchStartIndex = text.indexOf(search);
      let searchEndIndex = text.indexOf(search) + search.length;

      let searchLocation = {};
      searchLocation.anchor = {
        path: nodeTextPath,
        offset: searchStartIndex,
      };
      searchLocation.focus = {
        path: nodeTextPath,
        offset: searchEndIndex,
      };

      Transforms.select(editor, searchLocation);
      Editor.addMark(editor, mark, "No! This is a bad word");
      if (editor.selection) {
        Transforms.deselect(editor);
      }

      let newPath = nodeTextPath;
      newPath = newPath[newPath.length];
      Transforms.insertNodes(editor, { text: "" }, { at: newPath });
      Editor.removeMark(editor, mark);
      // Transforms.deselect(editor);
      // Transforms.move(editor, { distance: 1, unit: "offset" });
      Transforms.select(editor, Editor.end(editor, []));
    });
  }
}

//cursor implies not selected;
export function hasCursorButNotSelected(editor) {
  const { selection } = editor;
  let unselected = selection === null;
  let notInaWeirdState = !unselected && selection?.anchor && selection?.focus;
  let isCollapsed = notInaWeirdState && Range.isCollapsed(selection);
  return isCollapsed;
}

function getCommonBlock(editor) {
  //useful with a selection probably;
  const range = Editor.unhangRange(editor, editor.selection, { voids: true });
  let [common, path] = Node.common(editor, range.anchor.path, range.focus.path);
  if (Editor.isBlock(editor, common) || Editor.isEditor(common)) {
    return [common, path];
  } else {
    return Editor.above(editor, {
      at: path,
      match: (n) => Editor.isBlock(editor, n) || Editor.isEditor(n),
    });
  }
}
export function isOnANewElem(editor) {
  // let isCursor = hasCursorButNotSelected(editor);

  // let el = Node.first(editor, editor.selection);
  let matchedElemList = elementAtSelection(editor);

  if (matchedElemList && matchedElemList[0]) {
    let [topElem, topPath] = matchedElemList[0];
    const acceptableElemBlocks = {
      paragraph: true,
      video: true,
      audio: true,
      image: true,
      "numbered-list": true,
      "bulleted-list": true,
    };

    let isAcceptable = acceptableElemBlocks[topElem?.type];
    let isEmpty = isAcceptable && topElem.children.length === 1;
    if (topElem.type === "bulleted-list" || topElem.type === "numbered-list") {
      let lastKid = topElem?.children.length - 1;
      let nestedKids = topElem?.children[lastKid].children;

      isEmpty = nestedKids && nestedKids.length === 1 && nestedKids[0].text.trim() === "";
    } else {
      isEmpty = isEmpty && topElem?.children[0].text?.trim() === "";
    }

    if (isEmpty) return topPath;
  }
  return false;
}
