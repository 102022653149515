export function positionToolbar(el) {
  const domSelection = window.getSelection();
  const domRange = domSelection.getRangeAt(0);
  const rect = domRange.getBoundingClientRect();
  //~~ Get position~~//
  let topPos = rect.top + window.pageYOffset - el.offsetHeight - 10;
  let leftPos =
    rect.left + window.pageXOffset - el.offsetWidth / 2 + rect.width / 2;
  //~~ Apply limits~~//
  let leftLimit = 120;
  let rightLimit = 800;
  leftPos =
    leftPos < leftLimit
      ? leftLimit
      : leftPos > rightLimit
      ? rightLimit
      : leftPos;
  //~~ Apply styles~~//
  el.style.width = "min-content";
  //el.style.transform = "scale(0.85)";
  el.style.opacity = "1";
  el.style.top = `${topPos}px`;
  el.style.left = `${leftPos}px`;
}

export const clientWidth = () => {
  return Math.max(
    document.body.scrollWidth,
    document.documentElement.scrollWidth,
    document.body.offsetWidth,
    document.documentElement.offsetWidth,
    document.documentElement.clientWidth
  );
};
export const clientHeight = () => {
  return Math.max(
    document.body.scrollHeight,
    document.documentElement.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.offsetHeight,
    document.documentElement.clientHeight
  );
};

export function removeToolbar(el) {
  if (el.style !== undefined) el.removeAttribute("style");
}

export function refocusOnClick(e) {}
export function centerToolbar(el) {
  const y = window.innerHeight - 70;

  el.style.width = "min-content";
  el.style.position = "fixed";
  el.style.top = `${y}px`;
  el.style.left = `50%`;

  el.style.transform = `translate(${-50}%, 0%)`;
  el.style.transition = "opacity 0.2s linear";
  el.style.opacity = "1";
}
