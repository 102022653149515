import { useState, useRef, useEffect } from "react";
import { Box, Card1 } from "../../layout/containers/box";
import { AddBtn, CloseBtn, Button } from "../../layout/buttons/buttons";
import { TextField } from "../../layout/containers/input";
import { Checkbox } from "../../layout/selection/checkbox";
import { SelectField } from "../../layout/containers/input";
import { createProductLayer } from "../../../helpers/REST/api";
import { toast } from "react-toastify";
import { ModalElement } from "../../../helpers/hooks/useModal";

const AddLayer = ({ isOpen, onClose, data }) => {
  const [fields, setFields] = useState({
    title: "",
    description: "",
    isGated: true,
    isPublished: true,
  });

  useEffect(() => {
    const isCourse = data?.category === "course";
    if (data !== null) {
      if (isCourse) {
        const childType = data.hierarchy[0];
        const parentId = data._id;
        setFields({ ...fields, childType, parentId, productId: parentId });
      } else {
        const parentId = data._id;
        const { productId, childType, layerType } = data;
        setFields({ ...fields, childType, parentId, productId, layerType });
      }
    }
  }, [data]);

  const handleCreate = () => {
    let handleResp = ({ data }) => {
      toast.success("New course created. Refresh page.");
      onClose();
    };
    let handleErr = (e) => {
      if (e.status && e.status === 500) {
        toast.warn("You broke the server, thanks ");
      } else {
        toast.warn("It didn't save. You should've hired a real developer");
      }
    };

    createProductLayer(fields.layerType, fields).then(handleResp).catch(handleErr);
  };
  const handleChange = (key, val) => {
    setFields({ ...fields, [key]: val });
  };
  return (
    <ModalElement isOpen={isOpen} close={onClose} showClose={false}>
      <Card1 width="auto" height="70dvh">
        <Head onClose={onClose} childType={fields.childType} layerType={fields.layerType || "Course"} />
        {data === null ? "Can't detect hierarchial layer" : null}
        <Fields onChange={handleChange} fields={fields} />
        <Foot onCreate={handleCreate} onClose={onClose} onUpdate={() => {}} />
      </Card1>
    </ModalElement>
  );
};
export default AddLayer;

export function Fields({ fields, onChange }) {
  return (
    <div style={{ overflowY: "auto", margin: "1em 0 2em 0" }}>
      <Box type="row" padding="0" background="none" justifyContent="center">
        <TextField isDecorated={true} label="Title" name="title" onChange={(v) => onChange("title", v)} value={fields.title} wrapStyles={{ flex: "1 1 200px", margin: "1em" }} />
        <TextField
          isDecorated={true}
          label="Description"
          name="description"
          onChange={(v) => onChange("description", v)}
          value={fields.description}
          wrapStyles={{ flex: "1 1 200px", margin: "1em" }}
        />
        <Box type="row" padding="0" background="none" justifyContent="center">
          <Checkbox value={fields.isPublished} onChange={(v) => onChange("isPublished", v)} style={{ whiteSpace: "nowrap", flex: "1 1 150px", margin: "1em" }}>
            Publish (vs. being internal)
          </Checkbox>
          <Checkbox value={fields.isGated} onChange={(v) => onChange("isGated", v)} style={{ whiteSpace: "nowrap", flex: "1 1 150px", margin: "1em" }}>
            Should lock
          </Checkbox>
        </Box>
      </Box>
    </div>
  );
}
export function Head({ onClose, onAdd, layerType, childType }) {
  return (
    <Box type="1 row centered" background="none" padding={"0"} justifyContent={"space-between"} alignItems="center">
      <h3>
        Create a(n) <em>{childType}</em> in a {layerType}
      </h3>
      <div className="flex-row" style={{ width: "min-content" }}>
        <AddBtn tooltip="Import Question (Not yet implemented)" size="18px" style={{ margin: "0 1em" }} onMouseDown={() => {}} />
        <CloseBtn isOpen={true} type={1} style={{ margin: "0 1em" }} onClick={() => onClose()} />
      </div>
    </Box>
  );
}
export function Foot({ onClose, onCreate, onUpdate, isCreating }) {
  return (
    <Box type="row" justifyContent="space-between" background="none" padding="2em 0 1em 0">
      <Box background="none" padding="0" width="45%" justifyContent="flex-start">
        <Button theme="secondary" onClick={() => onClose()}>
          Close
        </Button>
      </Box>
      <Box background="none" padding="0" width="45%" justifyContent="flex-end">
        {!isCreating ? <Button onClick={() => onCreate()}>Create Layer</Button> : <Button onClick={() => onUpdate()}>Create Course</Button>}
      </Box>
    </Box>
  );
}
