import React, { useState } from "react";
import styled from "styled-components";
import { Collapse, Box } from "../layout/containers/box";
import uuid4 from "uuid4";
import { TextField } from "../layout/containers/input";

export const FieldRow = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  font-size: 15px;
  padding: 15px;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: flex-start;
`;

/**
 * Idea is to either add a term, or find old ones. Attaches term ID to a given span, so that hovering can yield results.
 * @param {*} param0
 * @returns
 */
export function SearchDrop({ children, isOpen, setOpen }) {
  const [data, setData] = useState(["Antony", "Jessica"]);
  const [inputVal, setInputVal] = useState("");

  return (
    <Box padding="0" margin="0" type={"column"}>
      {isOpen ? (
        <>
          <TextField
            isDecorated={false}
            placeholder="XYZ..."
            width="95%"
            onChange={(v) => {
              setInputVal(v);
            }}
            defaultValue={inputVal}
            tabIndex="-1"
          />
          {data && data.length && inputVal.length
            ? data.map((c, i) =>
                c.includes(inputVal) ? (
                  <FieldRow key={uuid4()}>{c}</FieldRow>
                ) : null
              )
            : data.map((c, i) => <FieldRow key={uuid4()}>{c}</FieldRow>)}
        </>
      ) : null}
    </Box>
  );
}
