import { Box } from "./box";
import { ShowOnHover } from "./boxStyles";

import { TextField } from "./input";
import { Button } from "../buttons/buttons";
import { useState } from "react";
import { GET } from "../../../helpers/REST/api";
import { Tooltip } from "antd";
import {
  VideoCameraOutlined,
  AudioOutlined,
  PictureOutlined,
  FileImageOutlined,
  FilePdfOutlined,
  EditOutlined,
  TagsOutlined,
  DownloadOutlined,
} from "@ant-design/icons";

/**
 * @param {string} props.fileName
 * @param {string} props.fileType e.g. "img/jpeg png"
 * @param {string} props.mediatype e.g. "image", "video", "audio", "pdf", "doc"
 * @param {string} props.location e.g. Dropbox, Google Drive, AWS
 * @param {string} props.uri e.g. "http://example.com/
 * @param {number} props.duration e.g. 34000 (in seconds)
 * @param {string} props.thumbnail e.g. "http://example.com/thumbnail.jpg"
 * @param {string} props.xsThumbnail e.g. "http://example.com/xsThumbnail.jpg"
 * @param {string} props.label Form label (If different from file name)
 * @param {string} props.placeholder
 * @param {string} props.isDecorated
 * @param {string} props.onChange
 * @param {string} props.onSave
 * @returns
 */
export default function ListItem({
  fileName,
  fileType,
  location,
  uri,
  duration,
  thumbnail,
  xsThumbnail,
  label,
  placeholder,
  isDecorated,
  onChange,
  mediaType,
}) {
  const [isLocked, setLocked] = useState(false);
  //const [data, setData] = useState([]);
  const [showSave, setShowSave] = useState(false);

  const iconhandler = () => {
    switch (mediaType) {
      case "video":
        return <VideoCameraOutlined />;
      case "audio":
        return <AudioOutlined />;
      case "image":
        return <PictureOutlined />;
      case "pdf":
        return <FilePdfOutlined />;
      default:
        return <FileImageOutlined />;
    }
  };
  async function getSignedUrl(loc) {
    let result = await GET(`/media/${loc}/sign`);
    console.log(result);
    return result;
  }
  return (
    <Box
      type="1 row centered"
      background="rgb(30,30,30)"
      width="100%"
      border={`1px solid ${showSave ? "pink" : "transparent"}`}
      margin="0 0 5px 0"
      padding="5px "
      justifyContent="space-between"
      className="hoverParent"
    >
      <Box
        padding="0 15px"
        margin="0"
        type="1 row centered"
        width={"100%"}
        justifyContent="flex-start"
        background="transparent"
      >
        <Box
          padding="0"
          margin="0 5px 0 0"
          width="min-content"
          onClick={() => {
            let result = getSignedUrl(fileName);
            console.log(result);
          }}
        >
          {iconhandler()}
        </Box>
        <TextField
          hasEditBtn={false}
          width="auto"
          name={"Text Field"}
          placeholder={"Text Field"}
          label="Label"
          onChange={(e, field) => {
            console.log(e, field);
          }}
          isDecorated={false}
          locked={isLocked}
          detectFocus={(isFocussed) => {
            setShowSave(isFocussed);
          }}
          defaultValue={fileName}
        />
        <Box
          margin="0 0 0 10px"
          padding="2px 5px"
          width="70px"
          justifyContent="space-around"
          className="showOnHover"
          background="transparent"
        >
          <Tooltip placement="top" title="Rename" color="#108ee9">
            <ShowOnHover
              margin="0"
              padding="0"
              background="transparent"
              alignItems="center"
            >
              <EditOutlined />
            </ShowOnHover>
          </Tooltip>
          <ShowOnHover
            margin="0"
            padding="0"
            background="transparent"
            alignItems="center"
          >
            <Tooltip placement="top" title="Add Tag" color="#108ee9">
              <TagsOutlined />
            </Tooltip>
          </ShowOnHover>
          <ShowOnHover
            margin="0"
            padding="0"
            background="transparent"
            alignItems="center"
          >
            <Tooltip placement="top" title="Download" color="#108ee9">
              <DownloadOutlined />
            </Tooltip>
          </ShowOnHover>
        </Box>
      </Box>
      {showSave ? (
        <Button use="small" onClick={() => {}}>
          Save
        </Button>
      ) : null}
    </Box>
  );
}
