import { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { RounderBtn } from "../../../layout/buttons/buttonStyles";
import { ArrowRight } from "../../../../images/icons";

import { GET, POST } from "../../../../helpers/REST/api";
import { Button } from "../../../layout/buttons/buttons";
import { Box, TabGroup } from "../../../layout/containers/box";
import { Drawer } from "antd";
// import Checkout from "./checkout/customPaymentFlow";
import { ProductPricing } from "./checkout/productDetails";
import { useAuth } from "../../../../helpers/auth";
import { useNavigate, Link } from "react-router-dom";

const Wrap = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  height: auto;
  min-height: 100dvh;
`;
const Tag = styled.div`
  width: auto;
  height: 30px;
  color: white;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1px 10px;
  margin: 0 5px;
  font-size: 14px;
  user-select: none;

  z-index: 10;
  background: var(--button-color);
  transition: all 0.3s linear;
  // position: absolute;
  // ${(props) => (props.right ? "right: 5%;" : "left: 5%;")}
  //top: 25px;

  &:hover {
    background: var(--button-color-hover);
  }
`;
const PanImage = keyframes`
  // 0% {
  //   background-position: 36% 42%;
  //   background-size: 150%;
  // }

  
  20% {
    background-position: 30% 85%;
    background-size: 150%;
  }
  
  20.0001% { /* -- View 2 -- */
    background-position: 60% 85%;
    background-size: 500%;
  }
  
  40% {
    background-position: 49% 81%;
    background-size: 500%;
  }
  
  40.0001% { /* -- View 3 -- */
    background-position: 80% 42%;
    background-size: 300%;
  }
  
  60% {
    background-position: 84% 33%;
    background-size: 300%;
  }
  
  60.0001% { /* -- View 4 -- */
    background-position: 0% 0%;
    background-size: 300%;
  }
  
  80% {
    background-position: 15% 4%;
    background-size: 300%;
  }
  
  80.0001% { /* -- View 5 -- */
    background-position: 80% 10%;
    background-size: 300%;
  }
  
  100% {
    background-position: 72% 14%;
    background-size: 300%;
  }
`;

const Img = styled.div`
  background-image: url("https://images.unsplash.com/photo-1515266591878-f93e32bc5937?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2574&q=80");
  background-image: url("https://images.unsplash.com/photo-1589829545856-d10d557cf95f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80");
  //background-image: url("https://images.unsplash.com/photo-1579684453423-f84349ef60b0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1491&q=80");

  //background-image: url("https://images.unsplash.com/photo-1590099543482-3b3d3083a474?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8dGluZ2V5JTIwaW5qdXJ5JTIwbGF3JTIwZmlybXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60");
  //background-image: url("https://images.unsplash.com/photo-1581056771107-24ca5f033842?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80");
  background-image: url(${(props) => props.uri});
  //background-color: var(--primary-color-gradient);
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
  left: 0px;
  top: 0px;
  background-size: 300%;
  background-position: 0% 0%;
  opacity: 0.5;
  animation: ${PanImage} 15s linear infinite;

  animation-play-state: ${({ isEnrolled }) => (isEnrolled ? "running" : "paused")};
  animation-play-state: running;
  border-bottom-left-radius: var(--border-radius-large);
  border-bottom-right-radius: var(--border-radius-large);

  &:hover {
    animation-play-state: running;
  }
`;
const ShmexyProductCard = styled.div`
  cursor: default;
  user-select: none;
  width: 300px;
  //height: 500px;
  display: flex;
  flex: 0 0 25%;
  //aspect-ratio: 10 / 16;
  aspect-ratio: ${({ isEnrolled }) => (isEnrolled ? "1/1" : "1/1.75")};
  border-radius: var(--border-radius-large);
  background-color: var(--primary-color-gradient-opaque);
  overflow: hidden;
  position: relative;
  z-index: 10;
  transition: all 0.2s ease-in-out;
  ${({ isEnrolled }) => !isEnrolled && "background: transparent;"}
  margin: 1em;

  & ${Img} {
    transition: filter 0.3s linear;
    ${({ isEnrolled }) => !isEnrolled && "filter: blur(0px) grayscale(1); opacity: 1; "}
    z-index: 3;
  }
  .filter {
    position: absolute;
    width: 100%;
    height: 100%;
    background: var(--primary-color-gradient-opaque);
    filter: grayscale(0);
  }
  &:hover ${Img} {
    // animation: ${PanImage} 15s linear infinite;
    //animation-play-state: ${({ isEnrolled }) => (isEnrolled ? "running" : "paused")};
    ${({ isEnrolled }) => !isEnrolled && "filter: blur(3px) grayscale(1);"}
    animation-play-state: paused;
  }
  &:hover {
    transform: scale(1.1);
  }
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  // margin-top: 67%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  z-index: 3;
  position: relative;
  // background: rgb(200, 200, 200, 0.1);
  transition: background 0.3s ease-in;
  padding: 10% 15px;
  ${({ isEnrolled }) => !isEnrolled && "background: rgb(0,0,0,0.3);"}
  & ${RounderBtn} {
    margin-left: auto;
    //transform: scale(1.1);
    font-weight: 400;
    font-size: 13px;
    opacity: 0.5;
    border-radius: var(--border-radius-med);
    ${(props) => !props.isEnrolled && "background: var(--primary-color); color: white; "};
    ${(props) => props.isEnrolled && "background: var(--spring-green-hover); color: white; opacity: 1; "};
  }
  .tag {
    transition: color 0.3s ease-in;
    color: white;
    text-align: left;
    background: #111;
    padding: 5px 6px;
    border-radius: var(--border-radius-med);
    position: relative;
    font-size: 11px;
    margin: 5px 0;
  }
  .title {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    transition: color 0.3s ease-in;
    font-size: 20px;
    color: white;
    opacity: 0.85;
    text-align: left;
  }

  .tags {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
  }
  &:hover {
    background: rgb(0, 0, 0, 0.3);
    ${({ isEnrolled }) => !isEnrolled && "background: rgb(0, 0, 0, 0.5);"}
    ${RounderBtn} {
      opacity: 0.9;
    }
  }
  & ${RounderBtn}:hover {
    opacity: 1;
  }
`;
export const ProductDisplay = ({ product }) => {
  const [imgUri, setImg] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { title, abbr, description, productMetaData, mid, membershipStatus } = product;
  const { duration, durationType, isOffered, enrollable, imgId, endDate } = productMetaData;
  const isEnrolled = membershipStatus === "ACTIVE" || membershipStatus === "PROCESSING" || membershipStatus === "PENDING";
  useEffect(() => {
    if (imgId) {
      GET(`/media/public/?id=${imgId}`).then((r) => setImg(r?.uri));
    }
  }, []);

  const ResumeBtn = () => (
    <Link to="/students">
      <RounderBtn style={{ height: "35px", width: "35px", padding: "5px" }}>
        <ArrowRight goodOne={true} />
      </RounderBtn>
    </Link>
  );
  const MoreDetails = () => (
    <Box type="row" background="none" padding="0" width="100%" justifyContent="center">
      {!isEnrolled && enrollable && (
        <RounderBtn
          onMouseDown={() => {
            setDrawerOpen(!drawerOpen);
          }}
        >
          More Details
        </RounderBtn>
      )}
    </Box>
  );
  const SignUpTag = () => {
    if (enrollable)
      return (
        <small className="tag" style={{ background: "#F9A826", cursor: "default" }}>
          Available Now
        </small>
      );
    return (
      <small className="tag" style={{ background: "#333", cursor: "default" }}>
        Full or Unavailable
      </small>
    );
  };
  const WaitlistBtn = () => {
    return (
      <RounderBtn
        style={{ background: "grey" }}
        onMouseDown={() => {
          setDrawerOpen(!drawerOpen);
        }}
      >
        Waitlist Sign-up
      </RounderBtn>
    );
  };
  return (
    <ShmexyProductCard isEnrolled={isEnrolled}>
      <Img isEnrolled={isEnrolled} uri={imgUri} />
      <div className="filter"></div>
      <Content isEnrolled={isEnrolled}>
        <div style={{ width: "100%" }}>
          <div className="tags">
            <small className="tag">{abbr}</small>
            {isEnrolled && membershipStatus && (
              <small className="tag" style={{ background: "var(--spring-green-darker)" }}>
                {membershipStatus[0] + membershipStatus.slice(1).toLowerCase()}
              </small>
            )}

            {isEnrolled && endDate && <Tag right={true}>Ends on 24th March</Tag>}
            {!isEnrolled && <SignUpTag />}

            {/* <Tag right={true}>Enrolled</Tag> */}
          </div>
          <div className="title">{title}</div>
          {!isEnrolled && <small>{description}</small>}
        </div>
        {isEnrolled && <ResumeBtn />}
        {!isEnrolled && enrollable && <MoreDetails />}
        {!isEnrolled && !enrollable && <WaitlistBtn />}
      </Content>
      <Drawer
        title={title}
        placement={"bottom"}
        closable={true}
        onClose={() => setDrawerOpen(false)}
        visible={drawerOpen}
        key={"right"}
        keyboard={true}
        bodyStyle={{ backgroundColor: "var(--tertiary-color-darkest)" }}
        width={"100dvw"}
        height={"100dvh"}
      >
        <ProductDetails product={product} />
      </Drawer>
    </ShmexyProductCard>
  );
};
const ProductGallery = () => {
  //TODO Re work the start screen, and move it to the product when member signs up for the first time.
  const [products, setProducts] = useState([]);
  const [startScreen, setStartScree] = useState(false);
  useEffect(() => {
    GET("/products/gallery").then((r) => {
      setProducts(r);
    });
  }, []);

  const MyProducts = () => {
    const totalProducts = products?.my?.length;
    if (!products?.my?.length) return null;
    return (
      <Box type="col left" background="none">
        <h2>My Courses ({totalProducts || 0})</h2>
        <Box type="row" background="none" padding="0" style={{ overFlowX: "scroll", flexFlow: "row nowrap" }}>
          {products.my.map((memberProduct, i) => (
            <ProductDisplay product={memberProduct} isEnrolled={true} key={"myC" + i} />
          ))}
        </Box>
      </Box>
    );
  };
  return (
    <Wrap
      tabIndex="-1"
      onKeyPress={() => {
        if (startScreen) setStartScree(false);
      }}
    >
      {!startScreen && (
        <>
          <MyProducts />
          <Box type="col left" background="none">
            <h2>Available Courses ({(products && products?.others?.length) || 0})</h2>
            <div style={{ overflow: "auto", display: "flex", minWidth: "0px", width: "100%" }}>
              {products && products?.others?.map((product, i) => <ProductDisplay product={product} key={"c" + i} />)}
            </div>
          </Box>
        </>
      )}
      {/* {startScreen && <SlideShow next={() => setStartScree(false)} />} */}
    </Wrap>
  );
};

const fadeIn = keyframes`  0% {opacity:0;} 100% {opacity:1;}  `;
const typed = keyframes`from { width: 0 } to { width: 100% }
 `;
const Frame = styled.img`
  display: block;
  //background-image: url("${(props) => props.url}");
  // background-repeat: no-repeat;
  background-size: auto;
  width: 500px;
  height: auto;
  // min-height: 300px;
  // position: relative;
  z-index: 0;
  margin-right: auto;
  border-radius: 50%;
  opacity: 1;
  animation: ${fadeIn} 10s;

  &::after {
    content: "${(props) => props.title}";
    // position: absolute;
    // bottom: 0px;
    // left: 50%;
    color: white;
  }
`;

const float1 = keyframes`
  0% {transform: translatey(0px);}
  50% { transform: translatey(-20px);}
  100% { transform: translatey(0px); }
`;
const float2 = keyframes`
  0% {line-height: 30px;transform: translatey(0px); }
  55% {transform: translatey(-20px);}
  60% {  line-height: 10px;}
 100% {line-height: 30px;transform: translatey(0px);}
`;
const t = keyframes`from {background-size:0 200%}`;
const b = keyframes` 50% {background-position:0 -100%,0 0}`;

const BubbleWrap = styled.div`
  position: relative;
  // left: 65%;
  // top: 30%;
  max-width: 400px;
  display: flex;
  flex-flow: column;
  opacity: 0;
  animation-name: ${fadeIn};
  animation-duration: 4s;
  animation-delay: 2s;
  animation-fill-mode: forwards;

  animation-iteration-count: 1;
  & button {
    opacity: 0;
    animation-delay: 5s;
    animation-name: ${fadeIn};
    animation-duration: 10s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
`;
const Bubble = styled.span`
  display: inline-block;
  display: inline-block;
  --color-blue: #f50057; //#83af9b;
  --color-green: #c8c8a9;
  --color-brown: #774f38;
  --color-beige: #201b1b; //hsl(339deg, 100%, 88%); //#ece5ce;
  --color-yellow: #f9cdad;
  --color-pink: #fe4365;
  --color-beige: #e2c5a3;
  --color-blue: #6b3a2e;
  //max-height: 200px;
  transform: translatey(0px);
  animation: ${float1} 5s ease-in-out infinite;
  mix-blend-mode: multiply;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 3px;
  font-size: 35px;
  color: var(--color-brown);
  background-color: var(--color-beige);
  padding: 35px;
  border-radius: 25px;
  position: relative;
  box-shadow: 15px 15px var(--color-blue);
  font-family: "Kalam", cursive;

  // animation-timing-function: ease;
  opacity: 1;
  margin-bottom: 5em;

  &:after {
    transform: translatey(0px);
    transform: translatey(0px);
    animation: ${float2} 5s ease-in-out infinite;
    content: ".";
    font-weight: bold;
    transform: translatey(0px);
    animation: ${float2} 5s ease-in-out infinite;
    content: ".";
    font-weight: bold;
    // -webkit-text-stroke: 0.5px var(--color-green);
    -webkit-text-fill-color: var(--color-beige);
    // border: 1px solid var(--color-green);
    text-shadow: 22px 22px var(--color-blue);
    text-align: left;
    font-size: 55px;
    width: 55px;
    height: 11px;
    line-height: 30px;
    border-radius: 11px;
    background-color: var(--color-beige);
    position: absolute;
    display: block;
    bottom: -30px;
    left: 0;
    box-shadow: 22px 22px var(--color-blue);
    z-index: -2;
  }

  .typed {
  }
`;

const SlideShow = ({ next }) => {
  const [linkIndex, setLinkIndex] = useState(0);
  const [dialogueIndex, setDialogueIndex] = useState(0);
  const [links, setLinks] = useState([
    "https://cdn.discordapp.com/attachments/976997351472381973/1096619505741987851/Coybench_a_realistic_Albert_Einstein_pixar_disney_black_backgro_010336af-7245-4b9e-8615-d200d0037729.png",
    "https://cdn.midjourney.com/2b3183dc-0201-449e-afc5-e93d0399272f/grid_0.png",
    "https://cdn.discordapp.com/attachments/976997351472381973/1096630149425938513/Coybench_keep_background_pitch_black_and_make_albert_Einstein_f_723bdd1b-7535-4c1c-aed9-66620a1a7bae.png",

    "https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/UUID-cl9cmkt333046qioxfz69x0of/public",
    "https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/UUID-cl90hlvhs1977859tqy7nv5gg3n/public",
  ]);
  const convo = [
    {
      link: links[2],
      name: "Albert Einstein",
      d: [
        {
          msg: " ... What was so cruucial to mien success?   ",
          resp: "What was it?",
        },
        { msg: <span>My dear... I focussed on understanding concepts, or rather...</span>, resp: "Rather what...?" },
        {
          msg: (
            <span>
              I spent a lot of time <em>proooving things were nein</em> rather than things being right...
              <br />
            </span>
          ),
          resp: "Nein?",
        },
        {
          msg: (
            <span>
              Oh ho ho! Iz my German! I mean I tried to prove what was wrong.
              <br />
              <br />
              So, for exaaample, if I was trying to find a 🐱, I ruled out places where it couldn't be, and I'd be left only with the place where it actualllay was.
              <br />
              <br />
              Iz very simple reaaally, isn't it?
            </span>
          ),
          resp: "It is, but I find that hard...",
        },
        {
          msg: (
            <span>
              You're right m'dear! That's cz you must understand the "whyyyy" first; and you must not move on before you do that
              <br /> <br />
              Kids these days; sigh. All they think about is "success" and what comes after that. They don't look at what's in front of them.
              <br /> <br />
              Did you know I never wanted "success" or the Nobel Prize -- I mean it waz a great honor, but that's not what I <em>wanted</em>.
              <br /> <br />
              You have to be better than them!
            </span>
          ),
          resp: "Is that how this course will be taught?",
        },
        {
          msg: (
            <span>
              You're a smart one, aren't you m'dear? Goodness me -- you're going to do just alright!
              <br />
              <br />
              Remember! You must look at what's in front of you and maaaster it. Forget about everything else, and good luck!
            </span>
          ),
          resp: "... GG Albi",
        },
      ],
    },
  ];
  const [active, setActive] = useState(0);
  return (
    <Box type="column" background="none" padding="0">
      <h1 style={{ color: "#444" }}>The beginning...</h1>
      <Box type="1 row centered" background="none" padding="0" margin="0" justifyContent="space-between" width="100%">
        {/* onClick={() => setActive(active + 1)} onKeydown={() => setActive(active - 1)} */}
        <Box type="column" padding="0" background="none" width="auto">
          <Frame url={links[active]} src={convo[linkIndex]?.link} alt="Albert Einstein"></Frame>
          <small style={{ marginTop: "1em", color: "#333" }}>{convo[linkIndex]?.name}</small>
        </Box>

        <BubbleWrap>
          <Bubble length={convo[linkIndex]?.d[dialogueIndex]?.msg.toString().length}>
            <span className="typed">{convo[linkIndex]?.d[dialogueIndex]?.msg}</span>
          </Bubble>
          <Button
            onClick={() => {
              if (convo[linkIndex]?.d[dialogueIndex + 1]) {
                setDialogueIndex(dialogueIndex + 1);
              } else if (convo[linkIndex + 1] && convo[linkIndex + 1].d[0]) {
                setLinkIndex(linkIndex + 1);
                setDialogueIndex(0);
              } else {
                next();
              }
            }}
          >
            {convo[linkIndex]?.d[dialogueIndex].resp}
          </Button>
        </BubbleWrap>
      </Box>
      <Button style={{ margin: "5em auto 0 auto", background: "#111" }} onClick={() => next()}>
        Skip
      </Button>
    </Box>
  );
};

export default ProductGallery;

const ProductDetails = ({ product }) => {
  const auth = useAuth();
  const nav = useNavigate();
  const { isAuthenticated, user } = auth;
  const [tab, setTab] = useState(0);
  const [prices, setPrice] = useState(null);
  const [checkout, setCheckout] = useState(null);
  const [activePriceIndex, setActivePriceIndex] = useState(0);

  const pid = product._id;

  useEffect(() => {
    if (tab > 0 && !isAuthenticated) {
      const dialogue = "Error: You're not logged in. Want us to take you to the login page?";
      if (window.confirm(dialogue)) nav("/login");
    }
  }, [tab, isAuthenticated, nav]);

  useEffect(() => {
    if (window && window.location) {
      let local = "http://localhost:8000/payments/stripe/prices";
      let live = "https://api.sohaibahmed.ca/payments/stripe/prices";
      let getPricesUrl = window.location.hostname.indexOf("localhost") > -1 ? local : live;
      GET(`${getPricesUrl}/?pid=${pid}`).then((r) => {
        console.log(r);
        setPrice(r);
      });
    }
  }, [pid]);

  useEffect(() => {
    if (tab >= 2 && prices !== null && checkout === null) {
      let local = "http://localhost:8050/users";
      let live = "https://auth.sohaibahmed.ca/users";
      let BE_AUTH = window.location.hostname.indexOf("localhost") > -1 ? local : live;
      let confirmCheckout = window.confirm("Wanna checkout?");
      console.log("Confirm checkout");
      if (confirmCheckout) {
        GET(`${BE_AUTH}/${user.id}?SELECT=stripeCustomerId`).then(async (data) => {
          const stripeCustomerId = data.stripeCustomerId;
          console.log(data);
          if (stripeCustomerId) {
            const line_items = [{ price: prices[activePriceIndex].id, quantity: 1 }];
            const checkoutObj = {
              success_url: "https://99point9.ca?success=ok",
              cancel_url: "https://99point9.ca?success=cancelled",
              line_items,
              customer: stripeCustomerId,
              metadata: { uid: user?.id, pid: product?._id },
              mode: "payment",
            };
            POST("/payments/stripe/checkout-session", checkoutObj).then((url) => {
              setCheckout(url);
            });
          }
        });
      }
    }
  }, [tab, prices, activePriceIndex, checkout]);
  return (
    <Box background="none" padding="0" margin="0 auto" type="column" width="95dvw" height="100dvh" alignItems="stretch">
      <TabGroup tabs={["Deets", "Contract", "Preview"]} setTab={setTab} activeIndex={tab} />
      <ProductPricing product={product} active={tab === 0} prices={prices} activePriceIndex={activePriceIndex} setActivePriceIndex={setActivePriceIndex} />
      <div>
        <Button
          style={{ float: "right", position: "fixed", bottom: "5em", opacity: tab < 2 ? "0.3" : "1" }}
          disabled={tab < 2 || checkout === null}
          onMouseDown={() => {
            window.location.href = checkout;
            return null;
          }}
        >
          Checkout
        </Button>
      </div>
    </Box>
  );
};
