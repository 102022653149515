import { useState, useRef, useEffect } from "react";
import uuid4 from "uuid4";
import { Tag, AutoComplete } from "antd";
import { TagsOutlined } from "@ant-design/icons";
import { Box } from "./containers/box";
import { ShowOnHover } from "./containers/boxStyles";
import { toast } from "react-toastify";

const Tags = ({ tagArray, autoCompleteStyle, onSearch, onSave }) => {
  const [tags, setTags] = useState([]);

  const [showAutoComplete, setAutoComplete] = useState(true);
  const [search, setSearch] = useState({ value: "", label: "" });
  const [autoCompleteOptions, setOptions] = useState([
    { value: "hello", label: "dafuq", color: "green" },
    { value: "world", label: "dafuq2" },
  ]);

  const nonCaseSensitiveFilter = (input, option) =>
    option?.value.toUpperCase().indexOf(input.toUpperCase()) !== -1;

  const handleInpChange = (val) => {
    setSearch({ value: val, label: val });
  };
  const handleOnPress = (e) => {
    if (e.key === ",") {
      e.preventDefault();
      addToTags();
    }

    if (e.key === "Enter") {
      let filtered = autoCompleteOptions.filter((w) =>
        w.value.includes(search.value)
      );

      let noneFound = filtered.length === 0 || autoCompleteOptions.length === 0;
      if (noneFound && search.value.length > 0) {
        addToTags();
      }
      if (noneFound && search.value === "") {
        setAutoComplete(false);
      }
    }
  };
  const resetAutoComplete = () => {
    setSearch({ value: "", label: "" });
    setOptions([]);
  };
  const addToTags = (e) => {
    if (e) {
      setTags([...tags, e]);
    } else {
      let copy = [...tags];
      let isUnique = true;
      copy.forEach((t) => {
        if (t.value === search.value) {
          isUnique = false;
        }
      });
      if (isUnique) {
        setTags([...tags, search]);
        toast.success("Tag added", { hideProgressBar: true });
      } else {
        toast.warn("Tag already exists", { hideProgressBar: true });
      }
    }
    if (onSave && typeof onSave === "function") {
      onSave(tags);
    }
    resetAutoComplete();
  };
  const handleInpSelect = (val, e) => {
    handleInpChange(val);
    addToTags(e);
  };
  const removeTag = (index) => {
    let duplicate = [...tags];
    duplicate.splice(index, 1);
    setTags(duplicate);
  };
  const handleSearch = (val) => {
    if (onSearch && typeof handleSearch === "function") onSearch(val);
  };
  useEffect(() => {
    if (!showAutoComplete) resetAutoComplete();
  }, [showAutoComplete]);
  return (
    <Box
      type="row"
      margin="0"
      width="100%"
      alignItems="center"
      minHeight="40px"
      padding="5px"
      borderRadius="0px"
      background="transparent"
      className="showOnHover"
    >
      {tags.map((t, ti) => (
        <Tag
          closable={true}
          key={uuid4()}
          onClose={() => removeTag(ti)}
          color={t.color ?? undefined}
        >
          {t.value}
        </Tag>
      ))}
      {showAutoComplete ? (
        <AutoComplete
          autoFocus={true}
          options={autoCompleteOptions}
          value={search.value}
          filterOption={(input, op) => nonCaseSensitiveFilter(input, op)}
          placeholder="Search or create a new tag..."
          onSelect={(v, e) => handleInpSelect(v, e)}
          onSearch={(v) => handleSearch(v)}
          style={{ width: "100%" }}
          onBlur={() => {
            setAutoComplete(false);
          }}
          onChange={(v) => handleInpChange(v)}
          onKeyDown={(e) => handleOnPress(e)}
          notFoundContent={"Press 'Enter' or ',' to save selection "}
        />
      ) : (
        <TagsOutlined
          onClick={() => {
            setAutoComplete(true);
          }}
        />
      )}
    </Box>
  );
};
export default Tags;
