import React, { useState, useContext } from 'react';
import { useSlate } from 'slate-react';
import AddQsForm from '../../../forms/questions/addQuestion';
import { MediaStateProvider, MediaContext } from './v2/masterContext';
import {
  SelectQuestion,
  MultipleAnswers,
  TrueOrFalse,
  FindEvidence,
} from '../questionModals/questionTypes';
import { Check } from '../questionModals/styles';
import QsEditor from '../../questionEditor';

import {
  createQuestion,
  getBlockQuestions,
} from '../../../../helpers/REST/api';
import { Transforms, Element, Editor } from 'slate';
import { Checkbox } from '../../../layout/selection/checkbox.js';
import {
  Collapse,
  Dropdown,
  Box,
  TabGroup,
} from '../../../layout/containers/box';
import { TextField } from '../../../layout/containers/input';
import { Button } from '../../../layout/buttons/buttons';
import { Radio } from '../../../layout/selection/radio';

const audioQsTemplate = {
  questionType: 'mcq',
  isPublished: false,
  isArchived: false,
  question: '',
  options: [
    {
      isCorrect: false,
      choice: [{ type: 'paragraph', children: [{ text: '' }] }],
      refs: [],
      explanation: '',
      implications: [''],
    },
  ],
};

export const AudioQuestions = ({ show, setShow, data, element }) => {
  const [qsData, setQsData] = useState(data || audioQsTemplate);
  const [tab, setTab] = useState(0);

  let editor = useSlate();
  let { playerProgress } = useContext(MediaContext);

  function toggleAnsCorrectness(e, ansIndex) {
    let newState = Object.assign({}, qsData);
    let choice = newState.options[ansIndex];
    choice.isCorrect = !choice.isCorrect;
    setQsData(newState);
  }
  function toggleQuestionType() {
    let newState = Object.assign({}, qsData, {
      questionType:
        qsData.questionType === 'mcq' ? 'audio_question' : 'mcq',
    });
    setQsData(newState);
  }
  function handleEditorSave(stateKey, value, ansIndex, ansProperty) {
    let newState = Object.assign({}, qsData);
    if (stateKey === 'question') {
      newState.question = value;
      setQsData(newState);
      return;
    } else if (stateKey === 'options') {
      if (newState.options[ansIndex] === undefined) {
        let ansChoiceObj = {};
        ansChoiceObj[ansProperty] = value;
        newState.options[ansIndex] = ansChoiceObj;
      } else {
        newState.options[ansIndex][ansProperty] = value;
      }
      setQsData(newState);
      return;
    }
  }
  function addAnsChoice(e) {
    e.preventDefault();
    console.log('mouse down');
    let newState = Object.assign({ options: [] }, qsData);
    newState.options.push({ isCorrect: false });
    setQsData(newState);
    return;
  }
  function removeAnswer(i) {
    let newState = Object.assign({}, qsData);
    newState.options.splice(i, 1);
    setQsData(newState);
  }
  async function createQsInDb() {
    let time = Math.ceil(playerProgress);
    const {
      blockFragmentData: { blockId, fragmentIndex },
    } = editor;
    let question = Object.assign({}, qsData);
    question.belongsToBlock = [blockId, fragmentIndex];
    let savedId = await createQuestion(question);

    saveIdInSlateNode(savedId);
    function saveIdInSlateNode(savedId) {
      let idObjectInNode = { seconds: time, _id: savedId };
      let hasMediaQsArray =
        element?.mediaQs !== undefined &&
        Array.isArray(element?.mediaQs);
      alert(hasMediaQsArray);
      if (!!savedId) {
        if (!hasMediaQsArray) {
          Transforms.setNodes(
            editor,
            { mediaQs: [idObjectInNode] },
            {
              match: (n) =>
                !Editor.isEditor(n) &&
                Element.isElement(n) &&
                n.type === 'audio',
            }
          );
        } else {
          let splicePoint = 0;
          element.mediaQs.forEach((item, i) => {
            if (item && item.seconds < time) {
              splicePoint = i;
            }
          });
          element.mediaQs.splice(splicePoint, 0, idObjectInNode);
        }
        alert('should be done?');
      }
    }
  }

  const showAllQsCallback = React.useCallback(() => {
    const {
      blockFragmentData: { blockId, fragmentIndex },
    } = editor;
    if (tab === 1)
      return (
        <ShowAllQuestions
          blockId={blockId}
          fragmentIndex={fragmentIndex}
        />
      );
  }, [tab]);
  const ShowNodeQsCallback = React.useCallback(
    () => <ShowNodeQuestions />,
    [tab]
  );
  return (
    <Collapse
      isOpen={show}
      onClose={() => {
        setShow(false);
      }}
      contentEditable={false}
      title={'Questions'}
    >
      <TabGroup
        tabs={['Add Questions', 'Show All Questions', 'Current Qs']}
        setTab={setTab}
        activeIndex={tab}
        padding="0"
      >
        {tab === 0 ? (
          <AddQsForm
            qsData={qsData}
            createQsInDb={createQsInDb}
            setQsData={setQsData}
            playerCtx={MediaContext}
          />
        ) : tab === 1 ? (
          showAllQsCallback()
        ) : (
          ShowNodeQsCallback()
        )}
      </TabGroup>
    </Collapse>
  );
};

function ShowAllQuestions({ blockId, fragmentIndex }) {
  const [blockFragQuestions, setQuestions] = useState([]);
  const [currentQs, setCurrQs] = useState(0);
  React.useEffect(() => {
    (async () => {
      let questions = await getBlockQuestions(blockId, fragmentIndex);
      console.log(questions);
      setQuestions(questions);
    })();
  }, []);

  return (
    <Box
      background="none"
      overFlowY="auto"
      height="400px"
      type="column"
      padding="0"
    >
      {blockFragQuestions &&
        blockFragQuestions.map((qs, i) => (
          <Dropdown
            title={`Question ${i + 1}: ${qs.questionType}`}
            key={`showAllQuestions_${i}`}
          >
            <Box type="col left" background="none" padding="0">
              <Box
                type="row"
                background="none"
                justifyContent="space-between"
              >
                <Checkbox>Archived</Checkbox>
                <Checkbox>Published</Checkbox>
              </Box>
              {qs.questionType === 'audio_question' ? (
                <TextField
                  name="question"
                  width="100%"
                  label={'Audio Question'}
                  defaultValue={qs.question}
                  readOnly
                  disabled
                />
              ) : (
                // <QsEditor
                // content={qs.question}
                // onChange={(v) => {}}
                // width="100%"
                // className={'dark'}
                // readOnly={true}
                // />
                qs.question
              )}
            </Box>

            {qs.options.map((o, oI) => {
              return (
                <Box
                  type="row"
                  background="none"
                  justifyContent="space-between"
                >
                  <Checkbox value={o.isCorrect} />
                  <QsEditor
                    key={`qs${i}_option${oI} `}
                    content={o.choice}
                    onChange={(v) => {}}
                    width="90%"
                    className={'dark'}
                    readOnly={true}
                  />
                </Box>
              );
            })}
          </Dropdown>
        ))}
    </Box>
  );
}
function ShowNodeQuestions() {
  const editor = useSlate();
  const { children } = editor;
  const mediaQs = editor.children[0].mediaQs;
  console.log(mediaQs);
  return (
    <Box
      background="none"
      overFlowY="auto"
      height="400px"
      type="column"
      padding="0"
    >
      {mediaQs &&
        mediaQs.map((qs, i) => (
          <Dropdown
            title={`Question ${i + 1}: ${qs.questionType}`}
            key={`showAllQuestions_${i}`}
          >
            <Box type="col left" background="none" padding="0">
              <Box
                type="row"
                background="none"
                justifyContent="space-between"
              >
                <Checkbox>Archived</Checkbox>
                <Checkbox>Published</Checkbox>
              </Box>
              {qs.questionType === 'audio_question' ? (
                <TextField
                  name="question"
                  width="100%"
                  label={'Audio Question'}
                  defaultValue={qs.question}
                  readOnly
                  disabled
                />
              ) : (
                <QsEditor
                  content={qs.question}
                  onChange={(v) => {}}
                  width="100%"
                  className={'dark'}
                  readOnly={true}
                />
              )}
            </Box>
          </Dropdown>
        ))}
    </Box>
  );
}

// function addQsForm(qsData, setQsData, audioCtx) {
//   function toggleQuestionType() {
//     let newState = Object.assign({}, qsData, {
//       questionType: qsData.questionType === "mcq" ? "audio_question" : "mcq",
//     });
//     setQsData(newState);
//   }
//   function handleEditorSave(stateKey, value, ansIndex, ansProperty) {
//     let newState = Object.assign({}, qsData);
//     if (stateKey === "question") {
//       newState.question = value;
//       setQsData(newState);
//       return;
//     } else if (stateKey === "options") {
//       if (newState.options[ansIndex] === undefined) {
//         let ansChoiceObj = {};
//         ansChoiceObj[ansProperty] = value;
//         newState.options[ansIndex] = ansChoiceObj;
//       } else {
//         newState.options[ansIndex][ansProperty] = value;
//       }
//       setQsData(newState);
//       return;
//     }
//   }
//   const qType = qsData.questionType;
//   return (
//     <Box type="col left" background="none">
//       <h2>
//         Question @ <span>{Math.ceil(context.playerProgress)}s</span>
//       </h2>
//       <Box type="1 row centered" background="none" padding="0" width="auto">
//         <Checkbox
//           name="mcq"
//           checked={!!qType === "mcq"}
//           value="mcq"
//           onChange={toggleQuestionType}
//         >
//           Ask question orally?
//         </Checkbox>
//         <Box
//           type="row nowrap"
//           background={qType === "mcq" ? "transparent" : "white"}
//           opacity={qType === "mcq" ? "0.3" : "1"}
//           padding="0"
//           width="auto"
//         >
//           <audio
//             src={qsData.question}
//             type="media/mp4"
//             controls
//             style={{ transform: "scale(0.7)", marginLeft: "auto" }}
//           />
//         </Box>
//       </Box>
//       {/* Question Input */}
//       <Box
//         type="row"
//         background="none"
//         justifyContent="space-between"
//         padding={"16px 0"}
//       >
//         <TextField
//           name="question"
//           width="100%"
//           label={qType === "mcq" ? "Question" : "Link"}
//           defaultValue={qsData.question}
//           onChange={(v, ref) => {
//             handleEditorSave(ref.name, v);
//           }}
//         />
//       </Box>

//       {/* Ans. Choices */}
//       {qsData &&
//         qsData.options &&
//         qsData.options.map((option, i) => (
//           <span
//             style={{ display: "flex", flexFlow: "row nowrap" }}
//             key={`ansChoice_question_${i}`}
//           >
//             <span
//               style={{ display: "inline" }}
//               onMouseDown={(e) => toggleAnsCorrectness(e, i)}
//             >
//               <Check readOnly defaultChecked={!!option.isCorrect} />
//             </span>
//             <QsEditor
//               content={option.choice}
//               placeholder="Type Answer..."
//               background={"transparent"}
//               onChange={(v) => handleEditorSave("options", v, i, "choice")}
//               width="50%"
//               className={"dark"}
//             />
//             <button
//               className="close-btn"
//               style={{
//                 background: "#A52A2A",
//                 borderRadius: "50%",
//                 padding: "1px",
//                 width: "25px",
//                 height: "25px",
//                 color: "white",
//                 fontSize: "12px",
//                 marginTop: "2px",
//               }}
//               onMouseDown={() => removeAnswer(i)}
//             >
//               🗑️
//             </button>
//           </span>
//         ))}

//       {/* Footer */}
//       <Box type="1 row centered" padding="0" justifyContent="space-between">
//         {/* Button 1 */}
//         <Button theme="white-black" onMouseDown={(e) => addAnsChoice(e)}>
//           Add more answer choices
//         </Button>
//         {/* Button 2 */}
//         <Button
//           theme="pink-black"
//           onMouseDown={() => {
//             createQsInDb();
//           }}
//         >
//           Save Question
//         </Button>
//       </Box>
//     </Box>
//   );
// }

// <div style={{ display: "flex", flexFlow: "column noWrap", width: "90%" }}>
//   {blockFragQuestions &&
//     blockFragQuestions.length &&
//     blockFragQuestions.map((qs, index) => (
//       <div
//         style={{
//           display: "flex",
//           flexFlow: "row noWrap",
//           width: "90%",
//         }}
//         key={`block${blockId}_ind_${fragmentIndex}_${index}`}
//       >
//         {/* {console.log(qs.question)} */}

//         {qs && typeof qs.question === "string"
//           ? qs
//           : qs.question.map((item, index) => {
//               if (typeof item === "string") return item;
//               if (item.type === "paragraph" && item?.children[0]?.text) {
//                 return (
//                   <div style={{ width: "100%" }}>
//                     <QsEditor
//                       content={item}
//                       editable={false}
//                       placeholder={"Untitled"}
//                     />
//                   </div>
//                 );
//               }
//               return null;
//             })}
//         {/* {qs.options.map((ans, ind) => (
//                 <QsEditor
//                   content={ans}
//                   editable={false}
//                   placeholder={"Untitled"}
//                 />
//               ))} */}

//          <button style={{ marginLeft: "auto" }}>Add to node</button>
//       </div>
//     ))}
// </div>;
