import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { Box } from "../layout/containers/box";
import { Button } from "../layout/buttons/buttons";
import ListItem from "../layout/containers/listItem";
import { MediaUpload } from "../layout/containers/input";
import { GET, PUT, POST, UPLOAD } from "../../helpers/REST/api";
import { TabGroup } from "../layout/containers/box";
import { RounderBtn } from "../layout/buttons/buttonStyles";
import { ExternalLink } from "../../images/icons";
import { toast } from "react-toastify";
const MediaView = () => {
  const [data, setData] = useState([]);
  const [newMedia, setNewMedia] = useState([]);
  const [showInputField, setInputDisplay] = useState(false);

  useEffect(() => {
    let result = async () => {
      await GET("/media").then((data) => {
        console.log(data);
        setData(data);
        console.log("here");
        return data;
      });
    };
    result();
  }, []);

  const handleChange = async (e) => {
    let formData = new FormData();
    let data = [...e.target.files];
    data.forEach((file, index) => {
      formData.append("file" + index, file);
    });
    // formData.append("file", e.target.files[0]);
    await UPLOAD("/media/upload/", formData, {
      maxContentLength: 10000000,
      maxBodyLength: 10000000,
      headers: { "Content-Type": "multipart/form-data" },
      onUploadProgress: (progressEvent) => console.log("Upload progress: ", Math.round((progressEvent.loaded / progressEvent.total) * 100)),
    });
    // if (e.target.files.length > 0) {
    //   console.log(e.target.files[0]);
    //   let formData = new FormData();
    //   formData.append("data", e.target.files[0]);
    //   // PUT("media/buckets/upload", { data: formData });
    //   let result = await GET("media/buckets/itemInfo", {
    //     params: {
    //       fileName: e.target.files[0].name,
    //       fileSize: e.target.files[0].size,
    //       fileType: e.target.files[0].type,
    //     },
    //   });
    //   if (result.length === 0) {
    //     console.log("Result was 0");
    //     await UPLOAD("/media/upload", formData, {
    //       onUploadProgress: (progressEvent) =>
    //         console.log(
    //           "Upload progress: ",
    //           Math.round((progressEvent.loaded / progressEvent.total) * 100)
    //         ),
    //     });
    //   }
    // }
  };
  return (
    <Box type="col left" background="transparent" padding="0">
      <div>
        <h3>Media Uploads</h3>
      </div>

      {Array.isArray(data) && data.length > 0 ? data.map((item, index) => <ListItem key={index} {...item} />) : null}

      {showInputField ? (
        <Box type="1 row centered" margin="10px 0px" style={{ color: "white" }}>
          <MediaUpload accept="image/*, video/*, audio/*,.pdf" onChange={(e) => handleChange(e)} multiple style={{ height: "auto", textAlign: "center", color: "white" }} />
        </Box>
      ) : (
        <Box background="none" padding="0">
          <Button
            use="small"
            onClick={() => {
              setInputDisplay(true);
            }}
          >
            Upload File
          </Button>
        </Box>
      )}
    </Box>
  );
};

export const useMedia = (queryObject) => {
  const [query, setQuery] = useState("");
  const [media, setMedia] = useState([]);
  useEffect(() => {
    setQuery(queryObject);
  }, [queryObject]);

  function get() {
    let urlSearchParams;
    urlSearchParams = query === null ? { mediaType: ["audio", "video"] } : query;
    let q = new URLSearchParams(urlSearchParams);

    GET(`/media/?${q}`).then((data) => {
      console.log(data);
      setMedia(data);
    });
  }
  return { media, get };
};
/**
 * @param  {} {onSelect
 * @param  {Array} props.allowedMedia ["a"]
 */
export const GetMedias = ({ onSelect, optionsArray }) => {
  const [tab, setTab] = useState(0);
  const [media, setMedia] = useState([]);
  const [mediaType, setMediaType] = useState([]);
  const [activeMediaIndex, setActiveMediaIndex] = useState(null);
  const [tabOptions, setTabOptions] = useState(optionsArray || ["Audio", "Video", "PDF"]);

  const getMedia = useCallback(
    function getMedia() {
      let q = new URLSearchParams({ mediaType });
      GET(`/media/?${q}`).then((data) => {
        setMedia(data);
      });
    },
    [mediaType]
  );

  useEffect(() => {
    getMedia();
  }, [getMedia]);

  useEffect(() => {
    setMediaType(tabOptions[tab].toLowerCase());
    setActiveMediaIndex(null);
  }, [tab]);
  const handleSelect = () => {
    onSelect && onSelect(media[activeMediaIndex]);
  };
  useEffect(() => {
    if (Array.isArray(optionsArray) && optionsArray.length) {
      setTabOptions(optionsArray);
    }
  }, [optionsArray]);
  return (
    <Box type="column" padding="0" background="none">
      <Box type="row" padding="0" background="none">
        <h3>Select a media type</h3>
        <TabGroup tabs={tabOptions} setTab={setTab} activeIndex={tab} />
      </Box>
      <Box type="column" background="none" padding="0 0 2em 0" height="50vh" overFlowY="scroll" style={{ cursor: "pointer" }}>
        {media &&
          media.map((m, mi) => (
            <Box
              key={m._id + mi}
              type="1 row centered"
              background={mi === activeMediaIndex ? "var(--primary-color)" : "var(--tertiary-color-darker)"}
              justifyContent="space-between"
              alignItems="center"
              padding="0"
              margin="10px 5px"
              width="100%"
              onMouseDown={() => setActiveMediaIndex(mi)}
            >
              <div style={{ width: "15%", textAlign: "center" }}>
                <ExternalLink color="white" size="16px" />
              </div>

              {[
                { key: "fileName", h: "Name" },
                { key: "createdAt", h: "Created On" },
                { key: "duration", h: "Length (in Seconds)" },
              ].map(({ key, h }, i) => (
                <Box type="column" background="none" justifyContent="space-between" alignItems="center" padding="8px" height="5em">
                  <span>{m[key] || "NA"}</span>
                  <small
                    style={{
                      color: "#ccc",
                    }}
                  >
                    {h}
                  </small>
                </Box>
              ))}
            </Box>
          ))}
      </Box>
      {activeMediaIndex !== null && (
        <Box type="row" background="none" padding="0" justifyContent="flex-end" style={{ position: "absolute", bottom: "1em", right: "1em" }}>
          <RounderBtn onMouseDown={handleSelect}>Select</RounderBtn>
        </Box>
      )}
    </Box>
  );
};

export default MediaView;
