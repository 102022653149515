import { useState, useRef, useEffect, useContext } from "react";
import { Box, Card1 } from "../../layout/containers/box";
import { AddBtn, CloseBtn, Button } from "../../layout/buttons/buttons";
import { TextField } from "../../layout/containers/input";
import { Checkbox } from "../../layout/selection/checkbox";
import { SelectField } from "../../layout/containers/input";
import { POST, createCourse, updateCourse } from "../../../helpers/REST/api";
import { toast } from "react-toastify";
import { ModalElement } from "../../../helpers/hooks/useModal";
import { TabGroup } from "../../layout/containers/box";
import ImagesManager, { ImageView } from "../../media/importImage";
import { ProductDisplay } from "../student/components/ProductGallery";
import { RounderBtn } from "../../layout/buttons/buttonStyles";
import { Help } from "../../../images/icons";
import { GET, PUT } from "../../../helpers/REST/api";

import PricingPage from "../student/components/checkout/pricingTable";
import { useAuth } from "../../../helpers/auth";

//TODO: Create new course and see how it looks.
//TODO: Edit course details and save.
//TODO: Update products schema
//TODO: make sure image works (both saved in the backend & links); Actually -- remove links.
//TODO: Plug in stripe
//TODO: plug in contracts (create a document for it)
//TODO: Make document for intro.
//TODO: Add option to require a quiz/questionnaire.
//TODO: Allow call scheduling / speaking to an instructor.

//TODO: Avoid nested data structures.
//TODO: Check how netflix displays its shit. Vibe with that.

//TODO: Make sure the gallery looks fine.
//TODO: Make maybe put this in a drawer or different page
//TODO: Remove "my courses" if you have none.
//TODO: Add a sidebar to the product gallery?

let productTemplate = {
  category: "course",
  title: "",
  abbr: "",
  description: "",
  hierarchy: [],
  tags: [],
  productMetaData: {
    type: "product", // e.g. productType. course | package | project | dyi
    when: "one-time", //ustom || on demand || every month
    where: "online",
    package: "", // e.g. what?
    imgId: "",
    isPriced: false,
    priceType: "subscription/flat/metered",
    priceCurrency: "CAD",
    price: 0,
    pricedWhen: "Before",
    pricing: { isPriced: false, type: "subscription/flat/metered", currency: "", amount: "", when: "before | one week | after first subject" },
    isPublished: false,
    isOffered: false,
    enrollable: false,
    inviteOnly: true,
    classSize: 0,
    stream: [], // e.g. "Medicine"
    duration: 1,
    durationType: "months",
    startDate: "",
    allowDemo: false,
    status: "available",
    seatsRemaining: -1,
    contract: "",
  },
  about: {
    isStandardizedTest: false,
    isOfficiallyMarked: true,
    officialScoreRange: [118, 132],
    sections: [
      {
        title: "Critical and Reasoning Skills",
        abbr: "CARS",
        scoreRange: [472, 528],
      },
      { title: "Physical Sciences", abbr: "C/P", scoreRange: [118, 132] },
      { title: "Biological Sciences", abbr: "B/B", scoreRange: [118, 132] },
      {
        title: "Psychological and Social Sciences",
        abbr: "P/S, or Psych./Soc.",
        scoreRange: [118, 132],
      },
    ],
    testDates: [],
  },
};
const AddProduct = ({ isOpen, onClose, isEdit, data }) => {
  const [fields, setFields] = useState(productTemplate);
  const [tabs, setTabs] = useState(0);
  const handleDescriptors = (k, v) => {
    setFields({ ...fields, [k]: v });
  };
  const handleMetaData = (k, v) => {
    let meta = { ...fields.productMetaData, [k]: v };
    setFields({ ...fields, productMetaData: meta });
  };
  const handleCreate = () => {
    createCourse(fields)
      .then((response) => {
        if (response) {
          toast.success("New course created. Refresh page.");
          setFields(response.data);
        }
      })
      .catch((err) => {
        if (err.status && err.status === 500) {
          toast.warn("You broke the server, thanks ");
        } else {
          toast.warn("It didn't save. You should've hired a real developer");
        }
      });
  };
  const handleSave = async () => {
    updateCourse(data?._id, { data: fields }).then((response) => {
      toast.success(response);
    });
  };

  useEffect(() => {
    if (isEdit) {
      setFields(data);
    }
  }, [data, isEdit]);

  useEffect(() => {
    if (typeof data === "object" && data?._id && data?.category === "course") {
      setFields(data);
    } else if (data === null) setFields(productTemplate);
  }, [data]);

  const editMode = data?._id || fields?._id;

  return (
    <ModalElement isOpen={isOpen} fade={isOpen} close={onClose} showClose={false}>
      <Card1 height="70dvh" width="80dvw" justifyContent={"space-between"}>
        <Head onClose={onClose} tabs={tabs} setTabs={setTabs} editMode={editMode} data={data} onCreate={handleCreate} onSave={handleSave} />
        <Box style={{ overflowY: "auto" }} background="none" type="col left">
          {tabs === 0 && <Fields handleDescription={handleDescriptors} handleMeta={handleMetaData} data={fields} />}
          {tabs === 1 && <MetaFields handleMeta={handleMetaData} data={fields} />}
          {tabs === 2 && <PublicFacingInfo handleMeta={handleMetaData} data={fields} />}
          {tabs === 3 && <StripeProductPricing pid={data?._id || fields?._id} data={data} />}
        </Box>
      </Card1>
    </ModalElement>
  );
};
export default AddProduct;
//Select field: "category" => Course/Program/etc. || Type: Online, inperson
//Text field: title, abbrev., description,
//Tags: Select add
//Range

export function Head(props) {
  const { tabs, setTabs, onClose, onCreate, editMode, onSave, data } = props;
  const heading = !!editMode ? `Edit "${data?.abbr || data?.title}" Product` : "Create Product";
  const saveOrEdit = () => (editMode ? onSave() : onCreate());
  const actionBtnTitle = editMode ? "Save" : "Create";
  const CTA = () => (
    <div className="flex-row align_center" style={{ width: "min-content" }}>
      <RounderBtn onClick={() => saveOrEdit()} style={{ padding: "5px 25px" }}>
        {actionBtnTitle}
      </RounderBtn>
      <CloseBtn type={1} style={{ margin: "0 1em" }} onClick={() => onClose()} />
    </div>
  );
  return (
    <Box type="column" background="none" padding={"0"} justifyContent={"space-between"} alignItems="center">
      <Box type="row" background="none" padding="0" justifyContent={"space-between"}>
        <h2>{heading}</h2>
        <CTA />
      </Box>
      <TabGroup activeIndex={tabs} setTab={setTabs} tabs={["Required Info.", "Meta Attributes", "About", "Payment"]} padding="0" width={"auto"} />
    </Box>
  );
}

export function Fields({ data, handleDescription, handleMeta }) {
  //Describe: OnChange function that describes the course;
  //Meta: onChange function that updates the course's metadata (e.g. isArchived, isOffered, isEnrollable)

  const hierarchyOptions = [
    { label: "Subject", value: "Subject" },
    { label: "Chapter", value: "Chapter" },
    { label: "Unit", value: "Unit" },
  ];

  const titleDefault = data?.title || "";
  const abbrDefault = data?.abbr || "";
  const descriptionDefault = data?.description || "";
  const hierarchyDefault = data?.hierarchy?.map((layerType) => (typeof layerType === "string" ? { label: layerType[0]?.toUpperCase() + layerType?.slice(1), value: layerType } : layerType));
  const imgId = data?.productMetaData?.imgId;
  return (
    <Box background="none" type="row" padding="0" justifyContent="space-between">
      <Box background="none" style={{ height: "min-content", flex: "1 1 350px" }} type="col left" padding="0" width="45%">
        <TextField
          label="TITLE"
          defaultValue={titleDefault}
          placeholder='e.g., "Medical College Admissions Test"'
          onChange={(v) => handleDescription("title", v)}
          wrapStyles={{ margin: "1em auto" }}
          isDecorated={true}
        />
        <TextField defaultValue={abbrDefault} label="ABBREV." placeholder='e.g., "MCAT"' onChange={(v) => handleDescription("abbr", v)} wrapStyles={{ margin: "1em auto" }} isDecorated={true} />
        <TextField
          label="DESCRIPTION"
          defaultValue={descriptionDefault}
          onChange={(v) => handleDescription("description", v)}
          placeholder={'e.g., "This is a course you want to take when you\'re ready to die"'}
          isDecorated={true}
          wrapStyles={{ margin: "1em auto" }}
        />
        <Box type="col left" padding="0" background="none" justifyContent="center" margin="1em auto">
          <small style={{ color: "grey" }}>Module Hierarchy (Make sure there are no duplicates, pls.)</small>
          <div>
            <SelectField
              placeholder={`Highest container first"`}
              value={hierarchyDefault}
              isMulti
              options={hierarchyOptions}
              isCreatable={false}
              onChange={(v) => {
                if (Array.isArray(v))
                  handleDescription(
                    "hierarchy",
                    v.map((item) => item.value?.toLowerCase())
                  );
              }}
            />
          </div>
        </Box>
      </Box>
      <ProductImage canEdit={true} imgId={imgId} handleChange={(v) => handleMeta("imgId", v)} />
    </Box>
  );
}

export function MetaFields({ data, handleMeta }) {
  const { isPublished, isOffered, enrollable, type, inviteOnly, duration, durationType, allowDemo } = data?.productMetaData;
  const periodOptions = [
    { label: "Day(s)", value: "d" },
    { label: "Week(s)", value: "w" },
    { label: "Month(s)", value: "m" },
    { label: "Year(s)", value: "y" },
  ];
  const typeOptions = [
    { label: "Course", value: "course" },
    { label: "Application", value: "app." },
    { label: "Service", value: "service" },
    { label: "Editing", value: "editing" },
  ];

  const durationDefault = duration && { label: duration, value: duration };
  const typeDefault = type && { label: type, value: type };
  const periodDefault = (durationType && periodOptions.filter((item) => (item.value === durationType ? item : null))[0]) || undefined;
  const durationOptions = [{ label: "1", value: "1" }];
  const checkStyles = { whiteSpace: "nowrap", margin: "1em" };

  return (
    <Box type="row" padding="0" background="none" margin="0" justifyContent="space-between">
      <Box type="col left" padding="0" background="none" width="45%">
        <div>
          <small style={{ color: "grey" }}>Offering Type</small>
          <SelectField placeholder={`Type e.g. course, app., freebie, service, mentoring`} value={typeDefault} options={typeOptions} isCreatable={true} onChange={(v) => handleMeta("type", v.value)} />
        </div>
        <div>
          <small style={{ color: "grey" }}>Duration</small>
          <SelectField placeholder={`Number; e.g., 1`} value={durationDefault} options={durationOptions} isCreatable={true} onChange={(v) => handleMeta("duration", v.value)} />
        </div>
        <div>
          <small style={{ color: "grey" }}>Period</small>
          <SelectField placeholder={`e.g., "Day(s)"`} value={periodDefault} options={periodOptions} isCreatable={true} onChange={(v) => handleMeta("durationType", v.value)} />
        </div>
      </Box>
      <Box type="col left" padding="0" background="none" width="45%" justifyContent="space-between">
        <Checkbox onChange={(v) => handleMeta("isPublished", v)} defaultChecked={isPublished} style={checkStyles}>
          Publish
          <Help title="Unchecked: new students won't see it." />
        </Checkbox>
        <Checkbox onChange={(v) => handleMeta("isOffered", v)} defaultChecked={isOffered} style={checkStyles}>
          Visible on site
          <Help title="i.e. on website" />
        </Checkbox>
        <Checkbox onChange={(v) => handleMeta("enrollable", v)} defaultChecked={enrollable} style={checkStyles}>
          Enrollable
          <Help title="i.e. Can't buy without this even when visible and on website." />
        </Checkbox>
        <Checkbox onChange={(v) => handleMeta("type", v ? "online" : "in-person")} defaultChecked={type} style={checkStyles}>
          Offered online?
          <Help title="As opposed to in-person?" />
        </Checkbox>
        <Checkbox onChange={(v) => handleMeta("inviteOnly", v)} checked={inviteOnly} style={checkStyles}>
          Invitation-based?
          <Help title="Meaning, people could see it, buy it, but not unless they're invited" />
        </Checkbox>
        <Checkbox disabled={true} onChange={(v) => handleMeta("isPublished", v)} defaultChecked={allowDemo} style={{ ...checkStyles, opacity: "0.5" }}>
          Demo allowed
          <Help title="Not" />
        </Checkbox>
      </Box>
    </Box>
  );
}
export function PaymentFields() {}
export function PublicFacingInfo({ data, handleData }) {
  const lowScoreDefault = (data?.about?.scoreRange && Array.isArray(data.about.scoreRange) && data.about.scoreRange[0]) || 0;
  const highScoreDefault = (data?.about?.scoreRange && Array.isArray(data.about.scoreRange) && data.about.scoreRange[1]) || 0;
  const isOfficialDefault = data?.about?.isOfficiallyMarked;
  return (
    <Box type="col left" background="none" padding="0" width="100%" minHeight="auto" justifyContent={"flex-start"}>
      <Box type="row" padding="0" background="none" justifyContent="center">
        <TextField defaultValue={lowScoreDefault} isDecorated={true} label="lowest score" placeholder={"e.g. 472"} onChange={() => {}} wrapStyles={{ flex: "1 1 200px", margin: "1em" }} />
        <TextField defaultValue={highScoreDefault} isDecorated={true} label="highest score" placeholder={"e.g. 472"} onChange={() => {}} wrapStyles={{ flex: "1 1 200px", margin: "1em" }} />
        <Checkbox defaultChecked={isOfficialDefault} onChange={(v) => {}} style={{ whiteSpace: "nowrap", flex: "1 1 150px", margin: "1em" }}>
          Is for an official test
        </Checkbox>
      </Box>
    </Box>
  );
}

function ProductImage({ imgId, handleChange, canEdit = true }) {
  const imgRef = useRef(null);
  const [imgData, setImgData] = useState(null);
  const [showcaseOpen, setShowcaseOpen] = useState(false);

  const onUpload = (img) => {
    setImgData(img);
    if (canEdit) imgRef.current.src = img.uri;
    if (canEdit && handleChange) handleChange(img._id);
  };

  useEffect(() => {
    if (imgId !== imgData?._id) {
      !!imgId ? GET(`/media/${imgId}`).then((result) => setImgData(result)) : setImgData(null);
    }
  }, [imgId, imgData]);

  // DEPRECATED.
  // const onInputChange = (v) => {
  //   if (canEdit && imgRef?.current) imgRef.current.src = v;
  //   if (canEdit && handleChange) handleChange(v);
  // };
  if (!canEdit)
    return (
      <Box background="none" style={{ height: "min-content", flex: "1 1 350px" }} type="col left" padding="0 1em" width="45%">
        <ImageView
          wid="auto"
          onMouseDown={() => {
            if (!showcaseOpen) setShowcaseOpen(true);
          }}
          fwdRef={imgRef}
          img={imgData?.uri}
        />
        {imgData?.title && <small style={{ color: "grey" }}>Title: {imgData?.title}</small>}
      </Box>
    );
  return (
    <Box background="none" style={{ height: "min-content", flex: "1 1 350px" }} type="col left" padding="0 1em" width="45%">
      <ImageView
        wid="auto"
        onMouseDown={() => {
          if (!showcaseOpen) setShowcaseOpen(true);
        }}
        fwdRef={imgRef}
        img={imgData?.uri}
      />

      {/* 
      Removed Image Data.
      <small style={{ color: "grey" }}>Image Link</small>
      <TextField label="Image link" defaultValue={imgData?.uri || ""} placeholder="Insert dropbox etc. link here." onChange={(v) => onInputChange(v)} isDecorated={true} /> */}
      <ImagesManager open={showcaseOpen} setOpen={setShowcaseOpen} onChange={onUpload} />
    </Box>
  );
}

function ProductImageDisplayOnly({ data }) {
  return (
    <Box background="none" style={{ height: "min-content", flex: "1 1 350px" }} type="col left" padding="0 1em" width="45%">
      <ImageView wid="auto" img={{ uri: "https://www.shutterstock.com/image-photo/blue-wildebeest-connochaetes-taurinus-common-zebras-2256738789" }} />
      {data?.title && <small style={{ color: "grey" }}>Title: {data?.title}</small>}
    </Box>
  );
}

function PreviewProduct({ data }) {
  const { abbr, title, isEnrolled, endData } = data;
  return <ProductDisplay title={abbr} isEnrolled={true} endDate={true} />;
}

function StripeProductPricing({ pid, data }) {
  const [stripeProductData, setProdObject] = useState(null);
  const {
    user: { email, id },
  } = useAuth();

  useEffect(() => {
    async function getProd(pid) {
      //https://stripe.com/docs/api/products/object --> documentation.
      GET(`/payments/stripe/product/${pid}`)
        .then((result) => {
          if (result) toast.success("Fetched product from stripe");
          if (!result) toast.warn("Result not found");

          setProdObject(result);
          return result;
        })
        .catch((e) => {
          console.log("error");
          console.log(e);
        });
    }
    if (pid) getProd(pid);
  }, [pid]);

  const handlePricingTableID = async () => {
    const id = stripeProductData?.metadata?.price_table_ID;
    if (!id) toast.warn("No price table id detected");
    if (id && pid) {
      toast.info("Saaaving price table");
      PUT(`/payments/stripe/product/${pid}`, { metadata: { price_table_ID: id } })
        .then(() => {
          toast.success("Price table ID saved.");
        })
        .catch((e) => {
          alert(e.message);
          toast.warn(e.message);
        });
      toast.info("Attempting to save...");
    }
  };
  const updateMetadata = async (key, val) => {
    if (stripeProductData !== null) {
      const metadata = { stripeProductData };
      setProdObject({ ...stripeProductData, metadata: { ...metadata, [key]: val } });
    } else {
      toast.error("Can't save; No product data detected. Check if this product is on the stripe dashboard");
    }
  };
  const createNewStripeProduct = async () => {
    if (!data.title) toast.warn(`Title (under "Required info. tab) is necessary. Add it; save it & refresh. `);
    if (!data.description) toast.warn(`Description (under "Required info. tab) is necessary. Add it; save it, & refresh. `);
    if (!pid) toast.warn(`Product ID is necessary but is unknown. It's likely that the product hasn't been saved yet.`);
    if (data.title && data.description && pid) {
      POST(`/payments/stripe/product/${pid}`, {
        name: data.title,
        description: data.description,
        id: pid,
      }).catch((e) => {
        console.log(e);
      });
    }
  };

  const Row = ({ children, hasError }) => (
    <Box type="row" margin={"1em 0"} justifyContent="space-between" width={"100%"} style={{ background: hasError && "red" }}>
      {children}
    </Box>
  );
  const giveEmoji = (boolean) => (boolean && "✅") || "❌";
  if (!pid && !data) return null;
  if (stripeProductData === undefined) {
    return (
      <Box background="none" type="column" padding="0" justifyContent="space-between">
        <small>Product does not exist in stripe then change tabs to refresh!!!</small>
        <div style={{ margin: "auto 0" }}>
          <RounderBtn style={{ width: "min-content" }} onMouseDown={() => createNewStripeProduct()}>
            Create a new product
          </RounderBtn>
        </div>
      </Box>
    );
  }
  return (
    <Box background="none" type="column" padding="0" justifyContent="space-between">
      <small>
        Note: This data is stored in stripe's dashboard. You can edit these values in{" "}
        <a target="_blank" rel="noreferrer" href={`https://dashboard.stripe.com/products/${pid}`}>
          there
        </a>
        .{" "}
      </small>
      <Row>
        <span>Name</span>
        {stripeProductData?.name}
      </Row>

      <Row>
        <span>Default Price</span> ${stripeProductData?.default_price}
      </Row>
      <Row hasError={!stripeProductData?.active}>
        <span>Active</span> {giveEmoji(stripeProductData?.active)}
      </Row>
      <Row>
        <span>Price Table Created?</span> {giveEmoji(stripeProductData?.metadata?.price_table_ID)}
      </Row>
      <Row hasError={!!stripeProductData?.metadata?.price_table_ID !== !!data.productMetaData?.priceTable}>
        <span>Price Table exists in DB</span> {data.productMetaData?.priceTable} {giveEmoji(data.productMetaData?.priceTable)}
      </Row>

      <Box type="row" padding="0" margin="0 0 1em 0" justifyContent="space-between" width={"100%"}>
        <TextField
          label="Pricing Table ID"
          defaultValue={stripeProductData?.metadata?.price_table_ID}
          placeholder="Found on stripe's dashboard"
          onChange={(v) => updateMetadata("price_table_ID", v)}
          wrapStyles={{ margin: "1em", width: "auto" }}
          isDecorated={true}
          disabled={stripeProductData === null}
        />
        <div style={{ margin: "auto 0" }}>
          <RounderBtn style={{ width: "min-content" }} onMouseDown={() => handlePricingTableID()}>
            Save Pricing Table!
          </RounderBtn>
        </div>
      </Box>
      {stripeProductData?.metadata?.price_table_ID && (
        <div>
          <h3>Pricing Table Preview!</h3>
          <PricingPage priceTableId={stripeProductData?.metadata?.price_table_ID} uid={id} email={email} />
        </div>
      )}
    </Box>
  );
}
