import { Transforms, Editor, Range, Element as SlateElement } from "slate";
import isUrl from "is-url";
//~~~~~~~~~~~~~~~~~~~~~~~~~IN LINE~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~//
export default function withInlines(editor) {
  const { insertData, insertText, isInline } = editor;

  editor.isInline = (element) =>
    [
      "link",
      "button",
      "question",
      "inline-question-void",
      "searchTerm",
    ].includes(element.type) || isInline(element);

  editor.insertText = (text) => {
    if (text && isUrl(text)) {
      handleInline.wrap(editor, text, "link");
    } else {
      insertText(text);
    }
  };
  editor.insertData = (data) => {
    const text = data.getData("text/plain");
    if (text && isUrl(text)) {
      handleInline.wrap(editor, text, "link");
    } else {
      insertData(data);
    }
  };
  return editor;
}

export const handleInline = {
  isActive: function (editor, format) {
    const [el] = Editor.nodes(editor, {
      match: (n) =>
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
    });
    // console.log([el]);
    return !!el;
  },
  unwrap: function (editor, format) {
    Transforms.unwrapNodes(editor, {
      match: (n) =>
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
    });
  },
  wrap: function (editor, format, options) {
    if (this.isActive(editor, format)) {
      this.unwrap(editor, format);
    }
    const { selection } = editor;
    const isCollapsed = selection && Range.isCollapsed(selection);

    const wrappedElement = this.selectWrap(isCollapsed, format, options);

    if (isCollapsed) {
      Transforms.insertNodes(editor, wrappedElement, {
        at: [editor.children.length],
      });
    } else {
      Transforms.wrapNodes(editor, wrappedElement, { split: true });
      //STORE FOR IMMEDIATE USAGE IF NEEDED.
      //! WON'T WORK IF IT'S IMMEDIATELY MODIFIED.
      let [node, nodePath] = Editor.parent(editor, editor.selection.focus);
      //To be made accessible to render Elements, so they know where they're at without having to select things.
      //TODO: Add this to blocks and other inlines too, and voids.
      editor.lastRange = editor.selection;
      editor.lastModifiedNode = node;
      editor.lastModifiedNodePath = nodePath;

      Transforms.collapse(editor, { edge: "end" });
    }
  },

  selectWrap: function (isCollapsed, format, options) {
    if (typeof options === "undefined") {
      options = { text: "" };
    }

    let { text, url } = options;
    switch (format) {
      case "link":
        return {
          type: "link",
          url,
          children: isCollapsed ? [text] : [],
        };

      default:
        return {
          type: format,

          children: isCollapsed ? [text] : [],
        };
    }
  },
  insertElement: function (editor, format, options) {
    if (editor.selection) {
      this.wrap(editor, format, options);
    }
  },
  toggle: function (editor, format, options) {
    let isActive = this.isActive(editor, format);
    if (!isActive) {
      this.insertElement(editor, format, options);
    } else {
      this.unwrap(editor, format, options);
    }
  },
};
