import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ContentEditor from '../editor/slateEditor';
import { getBlocks, createBlock } from '../../helpers/REST/api';
import { Wrapper } from '../editor/UI/styled';

export default function CreateBlock() {
  let { id } = useParams(); //contentBlockId; //* Necessary to fetch;
  const [fragments, setFragments] = useState(() => {
    let saved = localStorage.getItem('content');
    if (!!saved) {
      saved = JSON.parse(saved);
      if (Array.isArray(saved) && saved.length > 0) return saved;
    }
    return [];
  });
  const [block, setBlock] = useState(() => {
    let temp = {
      title: [{ type: 'heading-one', children: [{ text: '' }] }],
      description: '',
      fragments: [],
      questions: [],
    };
    let savedBlock = localStorage.getItem('title');
    if (!!savedBlock) {
      return JSON.parse(savedBlock);
    }
    return temp;
  });

  function addFragment(nodeType) {
    let newFragment = [{ type: nodeType, children: [{ text: '' }] }];
    let saveFragments = [...fragments, newFragment];
    setFragments(saveFragments);
  }
  function updateTitle(content) {
    let saveTitle = Object.assign({}, block);
    saveTitle.title = content;
    setBlock(saveTitle);
    const saveContent = JSON.stringify(saveTitle);
    localStorage.setItem('title', saveContent);
  }
  function saveFragment(index, content) {
    let saveFragments = [...fragments];
    saveFragments[index] = content;
    setFragments(saveFragments);
    const saveContent = JSON.stringify(saveFragments);
    localStorage.setItem('content', saveContent);
  }
  const saveBlockToDb = async function () {
    let data = Object.assign({}, block);
    data.fragments = [...fragments];
    console.log(data);
    let result = await createBlock(data);
    console.log(result);
  };
  useEffect(() => {
    let blockRequest = async () => {
      let res = await getBlocks();
      return res;
    };
  }, []);
  return (
    <div style={{ marginBottom: '250px' }}>
      <button
        className="reg-btn big-save "
        onClick={() => {
          addFragment('paragraph');
        }}
      >
        Add new block
      </button>
      <button
        onClick={() => {
          saveBlockToDb();
        }}
      >
        Save block to Db
      </button>
      <h1>Block</h1>
      <br />
      <label>Title</label>
      <ContentEditor
        placeholder="Block Title..."
        content={block.title}
        save={updateTitle}
      />
      <Wrapper>
        {fragments &&
          fragments.map((fragment, index) => (
            <ContentEditor
              placeholder="This is a testable section..."
              fragmentIndex={{ blockId: id, fragmentIndex: index }}
              content={fragment}
              save={(c) => saveFragment(index, c)}
              key={`slateEditor${index}`}
            />
          ))}
      </Wrapper>
    </div>
  );
}
