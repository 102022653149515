import { CodeElement } from "./elements";
import { QuestionSpan } from "./UI/styled";
import { Tooltip } from "antd";
import styled from "styled-components";

let Commented = styled.span`
  text-decoration-line: underline;
  text-decoration-style: wavy;
  text-decoration-color: var(--primary-color);
`;

export function Leaf({ attributes, children, leaf }) {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }
  if (leaf.code) {
    children = <CodeElement {...attributes}>{children}</CodeElement>;
  }
  if (leaf.italic) {
    children = <em>{children}</em>;
  }
  if (leaf.underline) {
    children = <u>{children}</u>;
  }
  if (leaf.strikethrough) {
    children = <del>{children}</del>;
  }
  if (leaf.question) {
    children = <QuestionSpan>{children}</QuestionSpan>;
  }
  if (leaf.comment) {
    children = (
      <Tooltip contentEditable={false} title={leaf.comment}>
        <Commented>{children}</Commented>
      </Tooltip>
    );
  }
  return <span {...attributes}>{children}</span>;
}
