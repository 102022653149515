import { useEffect, useState, useRef, useCallback } from "react";
import { useParams } from "react-router-dom";
import { GET, POST, DELETE } from "../../helpers/REST/api";
import { Box } from "../layout/containers/box";
import { TextField } from "../layout/containers/input";
import { SelectField } from "../layout/containers/input";
import { ArrowBtn, Button, AddBtn, DeleteBtn } from "../layout/buttons/buttons";
import { useMedia, GetMedias } from "../media";
import { RounderBtn } from "../layout/buttons/buttonStyles";
import { ModalElement } from "../../helpers/hooks/useModal";
import { TrackWrap } from "../editor2/renders/multiMediaPlayer/styled_video";
import { TabGroup } from "../layout/containers/box";
import PlaylistView from "./playlistView";
import TracksView from "./trackView";
import MediaPlayer from "../editor2/renders/multiMediaPlayer/MediaPlayer";
import { Collapse } from "../layout/containers/box";
import styled from "styled-components";
import { PulseStronger } from "../layout/buttons/buttons";
import { Popover } from "antd";
import { TrackNumber, TrackIcon } from "./common";
import { Audio, Film } from "../../images/icons";
import { Edit2 } from "../../images/icons";

import CreateQuestion from "../questions";

import { Checkbox } from "../layout/selection/checkbox";

export default function EditPlaylist({ playlist, updatePlaylists }) {
  const [activeTrack, setActiveTrack] = useState(null);
  const [activeQuestion, setActiveQuestion] = useState(-1); // -1 closes CreateQuestion modal; activeTrack.length creates new question. Else edit
  const [totalDuration, setTotalDuration] = useState(0);
  const rulerRef = useRef(null);

  function saveQuestion(id) {
    alert(id);
  }
  useEffect(() => {
    const calcTotalSeconds = () => {
      let timeInSeconds = 0;
      playlist?.track.forEach((t) => {
        if (t.duration) timeInSeconds += t.duration;
      });
      return timeInSeconds;
    };
    setTotalDuration(calcTotalSeconds);
  }, [playlist]);
  if (playlist === undefined) return null;
  return (
    <Box type="column" background="none" padding="0" width="100%">
      <PlaylistInfo playlist={playlist} rulerRef={rulerRef} />

      <Box type="column" background="none" padding="0 0 1em 0" justifyContent="flex-end" alignItems="center">
        <Box type="row" padding="none" justifyContent="space-between">
          <Box type="column" background="black" padding="0 0 1em 0" alignItems="center" width="70%" style={{ minHeight: "375px" }}>
            {playlist && playlist.track && playlist.track[activeTrack] ? <Video controls src={playlist.track[activeTrack].uri} mediaType={playlist.track[activeTrack].mediaType} /> : null}
          </Box>
          <Box type="column" padding="0em" alignItems="center" width="30%" background="var(--tertiary-color)">
            <SidePanel
              tracks={playlist.track}
              setActiveTrack={setActiveTrack}
              activeTrack={activeTrack}
              questions={playlist.questions}
              activeQuestion={activeQuestion}
              setActiveQuestion={setActiveQuestion}
            />
          </Box>
        </Box>
        <TracksView playlist={playlist} updatePlaylists={updatePlaylists} activeTrack={activeTrack} setActiveTrack={setActiveTrack} totalDuration={totalDuration} rulerRef={rulerRef}>
          <QuestionsMarker playlist={playlist} activeQuestion={activeQuestion} setActiveQuestion={setActiveQuestion} rulerRef={rulerRef} />
        </TracksView>
      </Box>

      <div>
        <Popover
          trigger="click"
          visible={activeQuestion !== -1}
          placement="top"
          content={
            <div style={{ background: "black" }}>
              <CreateQuestion
                onSave={saveQuestion}
                qid={playlist?.questions[activeQuestion]?.q}
                onClose={() => {
                  setActiveQuestion(-1);
                }}
              />
            </div>
          }
        />
      </div>
    </Box>
  );
}

{
  /* {props.playlist && props.playlist.track && <MediaPlayer playlist={props.playlist} />} */
}

const PlaylistInfo = ({ playlist }) => {
  const { title, subtitle, duration, isArchived, audience, owner, tags, questions, track, _id } = playlist;
  const [isOpen, setOpen] = useState(false);

  return (
    <Box type="col left" margin={"1em 0"} background="none" padding="0">
      <Collapse
        isOpen={isOpen}
        onClose={() => {
          setOpen(!isOpen);
        }}
        title={`Playlist: ${title}`}
      >
        <div className="flex-row justify" style={{ margin: "1em 0" }}>
          <TextField isDecorated={true} defaultValue={title} name="title" label="Title" width="32%" />
          <TextField isDecorated={true} defaultValue={subtitle} name="subtitle" label="Subtitle" width="32%" />
          <TextField type="number" isDecorated={true} defaultValue={duration} name="duration" label="Duration (in seconds)" width="32%" />
        </div>
        <div className="flex-row justify" style={{ margin: "1em 0" }}>
          <Checkbox name="isArchived" label="isArchived" defaultChecked={isArchived}>
            Is Published
          </Checkbox>
          <SelectField placeholder="Owner" options={[{ label: "MCAT (Product)" }, { label: "Sohaib (User)" }]} defaultValue={duration} name="duration" label="Duration (in seconds)" width="32%" />

          <Checkbox name="isArchived" label="isArchived" defaultChecked={isArchived}>
            Is Archived
          </Checkbox>
        </div>
        <div className="flex-row justify" style={{ margin: "1em 0" }}></div>
      </Collapse>
    </Box>
  );
};

const QsIndicator = styled.div`
  width: 0px;
  height: 0px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 10px solid ${({ isArchived, isPublished, isActive }) => (isArchived === true || isPublished === false ? "red" : isActive ? "var(--spring-green)" : "white")};

  position: absolute;
  top: -45px;
  left: ${(props) => props.at}px;
  animation: ${PulseStronger} 1s linear 1s infinite ${({ isActive }) => (isActive ? "running" : "pause")};
`;

const QuestionsMarker = ({ playlist, updatePlaylists, activeQuestion, setActiveQuestion, rulerRef }) => {
  const [isOpen, setOpen] = useState(false);
  const [questions, setQuestions] = useState([]);
  const questionsRef = useRef([]);
  const calcTotalSeconds = (track) => {
    let timeInSeconds = 0;
    track.forEach((t) => {
      if (t.duration) timeInSeconds += t.duration;
    });
    return timeInSeconds;
  };
  const total = calcTotalSeconds(playlist.track);
  console.log(playlist);

  useEffect(() => {
    if (playlist.questions && playlist.questions.length !== questions.length) {
      setQuestions(playlist.questions);
    }
  }, [playlist]);

  if (playlist?.questions === undefined) {
    return null;
  }
  return (
    <div className="flex-row justify" style={{ margin: "1em 0", height: "1px", width: "100%", position: "relative", zIndex: 1, top: "47px" }}>
      {questions.map((q, qi) => (
        <QsIndicator
          at={(q.time / total) * rulerRef?.current?.clientWidth}
          key={q + qi}
          onMouseDown={() => {
            setActiveQuestion(qi);
          }}
          isActive={activeQuestion === qi}
        />
      ))}
    </div>
  );
};
const Questions = ({ questions }) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <Collapse
      isOpen={isOpen}
      onClose={() => {
        setOpen(!isOpen);
      }}
      title="Questions"
    ></Collapse>
  );
};
// Add Media; ==> The "bread"; Each has link + duration.
// Add Questions; These are the "cheese": go on top of the "duration"

// !Constraint 1:
const Video = styled.video`
  width: 100%;
  height: auto;
  ${({ mediaType }) => (mediaType === "audio" ? "width:70%;" : "width: 100%;")}
`;

const SidePanel = ({ activeTrack, setActiveTrack, tracks, questions, activeQuestion, setActiveQuestion }) => {
  const [listType, setListType] = useState(0);
  const [hover, setHover] = useState(null);
  const [showImportMediaModal, setShowImportMediaModal] = useState(false);

  const toggleMediaModal = () => {
    setShowImportMediaModal(!showImportMediaModal);
  };

  const MediaTrackList = useCallback(
    () =>
      tracks &&
      tracks.map((t, ti) => (
        <Box
          type="row"
          className={activeTrack === ti ? "hover_primary" : "hover_green"}
          style={{ flexFlow: "row nowrap" }}
          width="100%"
          background={activeTrack === ti ? "var(--primary-color)" : "none"}
          padding="0px 0.5em"
          justify-content="space-between"
          alignItems="center"
          onMouseDown={() => {
            setActiveTrack(ti);
          }}
          key={t.fileName + ti}
        >
          <TrackNumber pli={ti + 1} />
          <div className="track_wrap">
            {t.mediaType === "audio" && (
              <TrackIcon className={`track_icon ${activeTrack === ti && "track_icon_active"}`}>
                <Audio className="bg" />
              </TrackIcon>
            )}
            {t.mediaType === "video" && (
              <TrackIcon className={`track_icon ${activeTrack === ti && "track_icon_active"}`}>
                <Film className="bg" />
              </TrackIcon>
            )}
            <div className="track_info" style={{ maxWidth: "150px" }}>
              <div className="track_title" style={{ maxWidth: "150px", fontSize: "0.7em", fontWeight: "400" }}>
                {t?.fileName}
              </div>
              <div className="track_metadata">
                <span>Seconds: {t.duration} </span>
              </div>
            </div>
          </div>
        </Box>
      )),
    [tracks, activeTrack]
  );

  const QuestionTrackList = useCallback(
    () =>
      questions &&
      questions.map((q, qi) => (
        <Box
          type="row"
          className={activeQuestion === qi ? "hover_primary" : "hover_green"}
          style={{ flexFlow: "row nowrap" }}
          width="100%"
          background={activeQuestion === qi ? "var(--primary-color)" : "none"}
          padding="0px 0.5em"
          justify-content="space-between"
          alignItems="center"
          onMouseDown={() => {
            setActiveQuestion(qi);
          }}
          onMouseEnter={() => {
            setHover(qi);
          }}
          onMouseLeave={() => {
            setHover(null);
          }}
          key={q.q + qi}
        >
          <TrackNumber pli={qi + 1} />
          <div className="track_wrap">
            {q.mediaType === "audio" && (
              <TrackIcon className={`track_icon ${activeQuestion === qi && "track_icon_active"}`}>
                <Audio className="bg" />
              </TrackIcon>
            )}
            {q.mediaType === "video" && (
              <TrackIcon className={`track_icon ${activeQuestion === qi && "track_icon_active"}`}>
                <Film className="bg" />
              </TrackIcon>
            )}
            <div className="track_info" style={{ maxWidth: "100px" }}>
              <div className="track_title" style={{ maxWidth: "150px", fontSize: "0.7em", fontWeight: "400" }}>
                {q?.q}
              </div>
              <div className="track_metadata">
                <span>Seconds: {q.time} </span>
              </div>
            </div>
            <div className="flex-row  track_metrics" style={{ maxWidth: "50px", marginLeft: "auto", justifyContent: "flex-end" }}>
              {hover === qi && listType === 1 && (
                <Edit2
                  width="10px"
                  size="10px"
                  onMouseDown={() => {
                    setActiveQuestion(qi);
                  }}
                />
              )}
            </div>
          </div>
        </Box>
      )),
    [questions, activeQuestion, hover, listType]
  );

  return (
    <>
      <div className="flex-row justify align_center ">
        <SelectField
          style={{ width: "50%", minWidth: "120px" }}
          options={[
            { label: "Media", value: 0 },
            { label: "Questions", value: 1 },
          ]}
          defaultValue={{ label: "Media", value: 0 }}
          onChange={(op) => setListType(op.value)}
        />
      </div>
      <div style={{ maxHeight: "350px", height: "70%", overflowY: "scroll", width: "100%" }}>{listType === 0 ? <MediaTrackList /> : <QuestionTrackList />}</div>
      <div className="flex-row center">
        <AddBtn
          bg={"snow"}
          onMouseDown={() => {
            if (listType === 1) setActiveQuestion(-2);
            if (listType === 0) toggleMediaModal();
          }}
        />
        <ImportMedia showMediaModal={showImportMediaModal} handleImportedMediaId={(id) => console.log(id)} handleAudioDelete toggleMediaModal={toggleMediaModal} />
      </div>
    </>
  );
};

function ImportMedia(props) {
  const { showMediaModal, handleImportedMediaId, handleAudioDelete, toggleMediaModal } = props;
  if (!showMediaModal) return null;
  return (
    <ModalElement isOpen={showMediaModal} fade={showMediaModal} close={() => toggleMediaModal()}>
      <Box type="column" width="70vw" minHeight="70vh" margin="auto" background="none" padding="0" zIndex="10">
        <GetMedias
          onSelect={(v) => {
            handleImportedMediaId(v);
          }}
          optionsArray={["Audio", "Video"]}
        />
      </Box>
    </ModalElement>
  );
}
