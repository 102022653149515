import axios from "axios";
import { toast } from "react-toastify";
// import jwt_decode from "jwt-decode";

let ticket = localStorage.getItem("ticket");
if (ticket && ticket !== "undefined") axios.defaults.headers.authorization = "Bearer " + ticket;
axios.defaults.baseURL = process.env.REACT_APP_BASEURL || "http://localhost:3000";
axios.defaults.withCredentials = true;
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const status = error.response ? error.response.status : null;
    // if (status === 498) {
    //   if (window.location.pathname !== "/login") window.location.href = "/login";
    // }
    return Promise.reject(error);
  }
);

const API = (str) => "localhost:8000/" + str;
const show = (error) => {
  if (error.message && error.message !== "Ticket Error") toast.error(error.message);
  if (error.response && error.response !== "Ticket Error") toast.error(error.response);
  if (error?.response?.data && error?.response?.data !== "Ticket Error") toast.error(error.response.data);

  throw new Error(error);
};
export const confirmEmail = async function (uri, code) {
  let token = localStorage.getItem("ticket");

  let body = {};
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  let response = await axios.post(uri, body, { headers });

  return response;
};
export const resendConfEmail = async (uri) => {
  let response = await axios.get(uri);
  return response;
};
/** BLOCKS **/
export const getBlocks = async function () {
  let result = await axios
    .get("/blocks/")
    .then(({ data }) => {
      console.log(data);
      return data;
    })
    .catch((err) => show(err));
  return result;
};
export const getBlock = async function (id) {
  let payload = { data: id };
  let result = await axios
    .get(`/blocks/${id}`, payload)
    .then(({ data }) => data)
    .catch((e) => show(e));
  return result;
};

export const createBlock = async function (data) {
  let payload = { data: data };
  let result = await axios
    .post("/blocks/", payload)
    .then(() => true)
    .catch((e) => show(e));
  return result;
};
export async function updateBlock(data) {
  let payload = { data };
  let result = await axios
    .put(`/blocks/${data._id}`, payload)
    .then(({ data }) => data)
    .catch((e) => show(e));
  return result;
}
/** COURSE **/
export const createCourse = async (data) => {
  let api = new Rest();
  let result = await api.create("/products", data);
  return result;
};
export const updateCourse = async (pid, data) => PUT(`/products/${pid}`, data);

export const getCourses = async (url, params) => {
  let rest = new Rest();
  let result = await rest.read(url, params);

  return result;
};
export const createProductLayer = async (layerType, data) => {
  return POST(`/modules/node/`, { data });
};
/** DOCUMENTS **/
export async function GET_DocCount() {
  return GET("/documents/count");
}
export async function GET_UserDocs(UID, docTitle) {
  let query = {
    data: { owner: UID, title: docTitle },
  };
  return await GET("/documents/mydocuments", query);
}
export async function GET_Docs(docTitle) {
  let q = new URLSearchParams();
  if (docTitle) q.append("title", docTitle);
  return await GET(`/documents/?${q}`);
}
export async function GET_nonLectureDocs(docTitle) {
  return await GET(`/documents/nonProduct?${docTitle ? "title=" + docTitle : ""}`);
}
export async function GET_SearchDocTitles(docTitle) {
  return await GET(`/documents/search?${docTitle ? "title=" + docTitle : ""}`);
}
export async function getDocTitle(doc_id) {
  return GET(`/documents/${doc_id}/title`);
}
export async function updateDocTitle(doc_id, docTitle) {
  return PUT(`/documents/${doc_id}/title`, { data: { title: docTitle } });
}
export async function POST_Doc(UID, docTitle) {
  return await POST("/documents/", {
    data: { owner: UID, title: docTitle },
  });
}
export async function GET_DocBlocks(doc_id, q) {
  let query = new URLSearchParams({ parentId: doc_id });
  if (q !== undefined) {
    for (var key in q) query.append(key, q[key]);
  }
  return GET(`/blocks/?${query}`);
}
export async function POST_DocBlocks(doc_id) {
  let data = {
    parentId: doc_id,
    title: "",
    doc_elements_rtf: [{ type: "paragraph", children: [{ text: "" }] }],
  };
  if (!doc_id) throw new Error("Requires a document's identification");
  return POST("/blocks/", { data });
}
export async function PUT_DocBlocks(block_id, doc_elements_rtf) {
  return PUT(`/blocks/${block_id}/doc_elements_rtf`, {
    data: { doc_elements_rtf },
  });
}
export async function PUT_DocBlockTitle(blockId, title) {
  return PUT(`/blocks/${blockId}/title`, { data: { title } });
}
export async function POST_ProductDoc({ productId, layerId, doc_id, children }) {
  return POST(`/documents/addProductDoc`, {
    data: { productId, layerId, doc_id, children },
  });
}

/** QUESTIONS **/
export const createQuestion = async function (data) {
  let payload = { data };
  let result = await axios
    .post("/questions/", payload)
    .then(({ data }) => {
      console.log(data);
      return data;
    })
    .catch((e) => show(e));
  return result;
};
export const getBlockQuestions = async function (blockId, fragmentIndex) {
  let result = await axios
    .get(`/questions/block/${blockId}/${fragmentIndex}`)
    .then(({ data }) => data)
    .catch((e) => show(e));
  return result;
};
export const getQuestion = async function (id) {
  let result = await axios
    .get(`/questions/${id}`)
    .then(({ data }) => data)
    .catch((e) => show(e));
  return result;
};
export const serveQuestion = async function (id) {
  let result = await axios
    .get(`/questions/serve/${id}`)
    .then(({ data }) => {
      if (data.correctAns && Array.isArray(data.correctAns)) {
        data.correctAns = data.correctAns.map((ans) => ans.toLowerCase());
      }

      return data;
    })
    .catch((e) => show(e));
  return result;
};
export const checkQsAnswers = async function (id, answers) {
  console.log(answers);
  //created in 2023
  let data = answers;
  let result = await axios
    .put(`/questions/check_answers/${id}?`, {
      data,
    })
    .then(({ data }) => data)
    .catch((e) => show(e));
  return result;
};
export const checkQuestionAnswer = async function (id, selected) {
  let result = await axios
    .get(`/questions/check/${id}/${selected}`)
    .then(({ data }) => data)
    .catch((e) => show(e));
  return result;
};
export const updateQuestion = async function (id, data) {
  let payload = { data };
  let result = await axios
    .put(`/questions/${id}`, payload)
    .then(({ data }) => data)
    .catch((e) => show(e));
  return result;
};
/** MEDIA **/
export const createFragmentMedia = async (data) =>
  await axios
    .post(`/media/`, { data: data })
    .then(({ data }) => data)
    .catch((e) => show(e));

export const updateFragmentAudio = async (id, data) =>
  await axios
    .put(`/media/${id}`, { data: data })
    .then(({ data }) => data)
    .catch((e) => show(e));
export const getFragmentAudio = async (id) =>
  await axios
    .get(`/media/${id}`)
    .then(({ data }) => data)
    .catch((e) => show(e));

/** GENERAL **/
export const GET = async (url, params) => {
  let rest = new Rest();
  //returns data
  let responseData = await rest.read(url, params);
  return responseData;
};

export const POST = async (url, data, params) => {
  let rest = new Rest();
  //returns data
  let responseData = await rest.create(url, data, params);
  return responseData;
};
export const DELETE = async (url, params) => {
  let rest = new Rest();
  //returns data
  let responseData = await rest.delete(url, params);
  return responseData;
};
export const PUT = async (url, params) => {
  let rest = new Rest();
  //returns data
  let responseData = await rest.update(url, params);
  return responseData;
};
/**
 *
 * @param {String} url  e.g. /media/buckets
 * @param {Object} body e.g. {key: value}
 * @param {Object} headers e.g. {'Content-Type': 'application/json}
 * @param {String} headers.contentType e.g. 'application/json
 * @returns
 */
export const UPLOAD = async (url, body, config) => {
  let rest = new Rest();
  let result = await rest.upload(url, body, config);
  return result;
};

export class Rest {
  show = (error) => {
    console.error(error.response);
    if (error.response) toast.error(error.response.data);
  };
  async create(baseUrl, data) {
    let result = await axios
      .post(baseUrl, data)
      .then(({ data }) => data)
      .catch((e) => this.show(e));
    return result;
  }

  async read(baseUrl, params) {
    let result = await axios
      .get(baseUrl, params)
      .then(({ data }) => data)
      .catch((e) => {
        this.show(e);
      });

    return result;
  }
  async read_raw(baseUrl, params) {
    return await axios.get(baseUrl, params);
  }

  async update(baseUrl, params) {
    let result = await axios
      .put(baseUrl, params)
      .then(({ data }) => data)
      .catch((e) => this.show(e));
    return result;
  }
  async upload(baseUrl, formData, config) {
    let result = await axios
      .post(
        baseUrl,
        formData,
        config
          ? config
          : {
              headers: { "Content-Type": "multipart/form-data" },
            }
      )
      .then(({ data }) => data)
      .catch((e) => this.show(e));
    // let result = axios({
    //   method: "post",
    //   url: baseUrl,
    //   data: formData,
    //   headers: { "Content-Type": "multipart/form-data" },
    // })
    //   .then((r) => {
    //     console.log(r);
    //     return r;
    //   })
    //   .catch((err) => show(err));
    return result;
  }
  async delete(baseUrl, params) {
    let result = await axios
      .delete(baseUrl, params)
      .then(({ data }) => data)
      .catch((e) => this.show(e));
    return result;
  }
}

//PLAYLISTS

const getPlaylists = async () => {
  let result = await GET(`/mediaTracks/`).then((res) => {
    return res.data;
  });
  return result;
};
const getIdsOfPlaylists = async () => {
  let result = await GET(`/mediaTracks/`).then((res) => {
    return res.data;
  });
  return result;
};
const getAPropertyOfPlaylists = async () => {
  let result = await GET(`/mediaTracks/`).then((res) => {
    return res.data;
  });
  return result;
};
const createNewPlaylist = async () => {
  let result = await GET(`/mediaTracks/`).then((res) => {
    return res.data;
  });
  return result;
};

const getPlaylist = async (id) => {
  await GET(`/mediaTracks/${id}/`);
};
const getPlaylistTrack = async (id) => {
  await GET(`/mediaTracks/${id}/`);
};
const getPlaylistMediaAtTrackIndex = async (id, index) => {
  await GET(`/mediaTracks/${id}/track/${index}`);
};
