import { useEffect, useState, useRef, useLayoutEffect, useCallback } from "react";
import styled from "styled-components";

import { GET, POST, DELETE, PUT } from "../../helpers/REST/api";
import { Box } from "../layout/containers/box";
import { TextField } from "../layout/containers/input";
import { ArrowBtn, Button, AddBtn, DeleteBtn, ExpandBtn, OptionsBtn, CloseBtn } from "../layout/buttons/buttons";
import { useMedia, GetMedias } from "../media";
import { RounderBtn } from "../layout/buttons/buttonStyles";
import { ModalElement } from "../../helpers/hooks/useModal";

import { toast } from "react-toastify";
import { Audio, Film } from "../../images/icons";
import { PlayBtn } from "../layout/buttons/buttons";
import { Collapse } from "../layout/containers/box";
import Ruler from "./trackRuler";
import { Popover } from "antd";

export default function TracksView({ playlist, updatePlaylists, activeTrack, setActiveTrack, totalDuration, setTotalDuration, rulerRef, children }) {
  const [showCreate, setShowCreate] = useState(false);
  let trackRef = useRef(null);

  const handleMouseDown = (i) => {
    setActiveTrack(i);
    //   setShowAddMedia(true);
    //   setmedia(track);
  };
  function transposeFromTo(fromIndex, toIndex) {
    PUT(`/mediaTracks/m/${playlist._id}/track/${fromIndex}/${toIndex}`)
      .then((data) => {
        if (data) {
          const pCopy = { ...playlist };
          let spliced = pCopy.track.splice(fromIndex, 1);
          pCopy.track.splice(toIndex, 0, spliced[0]);
          updatePlaylists(pCopy);
          toast.success(`Moved playlist from spot ${fromIndex + 1}  to spot ${toIndex + 1}`);
        }
      })
      .then(() => {
        setActiveTrack(toIndex);
      });
  }
  const handleMediaSelect = (data) => {
    let pCopy = { ...playlist };

    POST(`/mediaTracks/m/${playlist._id}/track`, {
      data: { mediaId: data._id },
    }).then((data) => {
      toast.success("Added new track...");
    });
    pCopy.track.push(data);
    updatePlaylists(pCopy);
  };
  async function removeTrackFromPlaylist() {
    if (activeTrack !== null && playlist.track[activeTrack]) {
      let id = playlist._id;
      DELETE(`/mediaTracks/m/${id}/track/${activeTrack}`).then((data) => {
        if (data) {
          let pCopy = { ...playlist };
          pCopy.track.splice(activeTrack, 1);
          updatePlaylists(pCopy);
          toast.success("Track deleted!", { autoClose: 1000 });
          if (playlist.track[activeTrack] === undefined) {
            setActiveTrack(playlist.track.length - 1);
          }
        }
      });
    }
  }
  function activateNextOrPrev(newIndex) {
    if (playlist?.track[newIndex] !== undefined) {
      setActiveTrack(newIndex);
    }
  }
  //! Watch for clash. Resolve this.
  // function updatePlaylists(key, value) {
  //   let copy = { ...playlist };
  //   copy[key] = value;
  //   updatePlaylists(copy);
  // }

  async function insertPlaylistTrack(playlistIndex, trackIndex) {
    if (playlistIndex && trackIndex) {
      let id = playlist._id;
      POST(`/mediaTracks/m/${id}/track/${trackIndex}`).then(({ data }) => {
        let playlistsCopy = [...playlist];
        playlistsCopy[playlistIndex] = data;
        updatePlaylists(playlistsCopy);
      });
    }
  }

  function deActivateTrack() {
    setActiveTrack(null);
  }

  if (playlist === undefined || playlist.track === undefined) {
    return <div className="flex-row center">No playlist or track detected</div>;
  }
  const canMoveRight = playlist.track[activeTrack + 1] !== undefined;
  const canMoveLeft = activeTrack > 0;
  const TrackDetails = () => (
    <Collapse isOpen={activeTrack !== null} onClose={() => deActivateTrack()} showTitle={false} width="75vw">
      {activeTrack !== null && (
        <>
          <Box type="row" padding="0" background="none" justifyContent="space-between" alignItems="center">
            <h3>
              Track Details ({activeTrack + 1} / {playlist?.track.length})
            </h3>
            <CloseBtn onMouseDown={() => deActivateTrack()} />
          </Box>
          <div className="flex-row center">
            <div className="flex-row center" style={{ width: "200px", margin: "1em", transform: "scale(0.8)", color: "#ccc" }}>
              {activeTrack > 0 && activeTrack !== null && (
                <Button theme="grey-white" disabled={!canMoveLeft || activeTrack === null} onClick={() => transposeFromTo(activeTrack, activeTrack - 1)}>
                  &#8592; Transpose Left
                </Button>
              )}
            </div>

            <div className="flex-row center" style={{ width: "200px", transform: "scale(0.8)", color: "#ccc" }}>
              {canMoveRight && activeTrack !== null && (
                <Button theme="grey-white" disabled={!canMoveRight || activeTrack === null} onClick={() => transposeFromTo(activeTrack, activeTrack + 1)}>
                  Transpose Right &#8594;
                </Button>
              )}
            </div>
          </div>
          <div className="flex-row justify">
            <TextField isDecorated={true} wrapStyles={{ margin: "1em 0", maxWidth: "450px" }} name="fileName" label="File Name" defaultValue={playlist?.track[activeTrack].fileName} />
            <TextField isDecorated={true} wrapStyles={{ margin: "1em 0", maxWidth: "100px" }} name="duration" label="Duration" defaultValue={playlist?.track[activeTrack].duration} readOnly={true} />
            <TextField
              isDecorated={true}
              wrapStyles={{ margin: "1em 0", maxWidth: "100px" }}
              name="mediaType"
              label="Media Type"
              defaultValue={playlist?.track[activeTrack].mediaType}
              readOnly={true}
            />

            <TextField isDecorated={true} wrapStyles={{ margin: "1em 0", maxWidth: "200px" }} name="mediaType" label="Media Type" defaultValue={playlist?.track[activeTrack].mediaType} />
          </div>
        </>
      )}
    </Collapse>
  );
  return (
    <>
      <Track onMouseDown={() => setShowCreate(true)} trackRef={rulerRef} totalDuration={totalDuration} activeTrack={activeTrack} questionsMarker={children}>
        {/* {playlist &&
          playlist.track &&
          playlist.track.map((t, ti) => (
            <TrackBtn
              totalWidth={rulerRef?.current?.clientWidth}
              totalDuration={totalDuration}
              isActive={ti === activeTrack}
              track={t}
              index={ti}
              handleMouseDown={handleMouseDown}
              key={playlist._id + t._id + ti ?? t.mediaType + ti}
            />
          ))} */}
      </Track>
      {/* <Popover placement="top" content={<TrackDetails />} visible={activeTrack !== null} /> */}
    </>
  );
}

export const Track = ({ onMouseDown, expanded, trackRef, children, totalDuration, questionsMarker, ...props }) => {
  const RulerCb = useCallback(() => <Ruler totalDuration={totalDuration} />, [totalDuration]);
  return (
    <Box type="column" alignItems="center" padding="0em" background="none" style={{ position: "relative", top: "-2em" }}>
      {questionsMarker}

      {/* Ruler/Time Indicator */}
      <Box type="1 row centered" alignItems="center" padding="0em">
        <RulerCb />
      </Box>

      {/* Track && TrackButtons (as children) */}
      <Box type="1 row centered" alignItems="center" padding="0em" fwdRef={trackRef}>
        <Box
          type="row"
          style={{
            // overflowX: "auto",
            flexWrap: "nowrap",
            cursor: "pointer",
            position: "relative",
          }}
          alignItems="center"
          justifyContent="center"
          padding="0"
          alignText="center"
          minHeight={!expanded ? "5px" : "3.5em"}
        >
          {children}
          <div
            onMouseDown={() => onMouseDown()}
            style={{
              width: "100%",
              position: "absolute",
              height: "100%",
            }}
          ></div>
          <span
            style={{
              position: "absolute",
              left: "50%",
              color: "var(--tertiary-color-darkest)",
            }}
          >
            Track
          </span>
        </Box>
      </Box>
      {/* Navigation */}
      {/* <>
        <div className="flex-row center">
          <div className="flex-row center" style={{ width: "150px", margin: "1em", marginLeft: "auto", color: "#ccc" }}>
            <ArrowBtn direction="l" size="10px" disabled={!canMoveLeft} onClick={() => activateNextOrPrev(activeTrack - 1)} />
            <span>Prev. Track</span>
          </div>
          <div className="flex-row center" style={{ width: "150px", margin: "1em", marginRight: "auto", color: "#ccc" }}>
            <span>Next Track</span>
            <ArrowBtn direction="r" disabled={!canMoveRight} onClick={() => activateNextOrPrev(activeTrack === null ? 0 : activeTrack + 1)} />
          </div>
        </div>
      </> */}
    </Box>
  );
};

export const TrackBtn = (props) => {
  const { handleMouseDown, index, isActive } = props;
  const { track } = props;
  const { totalDuration, totalWidth } = props;
  let width = totalDuration !== 0 && track.duration ? `${(track.duration / totalDuration) * totalWidth}%` : "0px";
  const TrackIcon = () => {
    let color = isActive ? { color: "grey", stroke: "grey" } : { color: "white", stroke: "white" };
    if (typeof track?.mediaType === "string" && track?.mediaType.toLowerCase() === "audio")
      return (
        <div className="flex-row center" style={color}>
          <Audio />
          Track {index + 1}
        </div>
      );
    if (typeof track?.mediaType === "string" && track?.mediaType.toLowerCase() === "video")
      return (
        <div className="flex-row center justify" style={color}>
          <Film />
          Track {index + 1}
        </div>
      );
  };
  return (
    <Box
      type="1 row centered"
      width={"auto"}
      height="100%"
      padding="2px 0px"
      style={{ whitespace: "nowrap", zIndex: "1", flex: "1 1 auto", position: "relative" }}
      background={isActive ? "white" : "grey"}
      key={`track_${track.fileName}_${index}`}
      onMouseDown={(e) => {
        e.preventDefault();
        e.stopPropagation();
        handleMouseDown(index);
      }}
      contentEditable={false}
      // bRadius="0px"
      border="1px solid snow"
    >
      <Box type="column" background="none" padding="5px 0px" width={width} bRadius="0px">
        <small style={{ color: "black", display: "inline-block", width: "auto", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", fontSize: "12px", lineHeight: "16px" }}>
          <TrackIcon />
        </small>
      </Box>
    </Box>
  );
};

// <ModalElement isOpen={showCreate} fade={showCreate} close={() => setShowCreate(false)}>
//   <Box type="column" width="70vw" minHeight="70vh" margin="auto" background="none" padding="0" zIndex="10">
//     <GetMedias
//       onSelect={(v) => {
//         handleMediaSelect(v);
//       }}
//     />
//   </Box>
// </ModalElement>;
