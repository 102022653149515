import styled from "styled-components";

export const Check = styled.input.attrs({ type: "checkbox" })`
  width: 20px;
  height: 20px;
  margin: 0px;
  border: 1px solid darkgray;
  margin: 0px 8px;
  outline: none;
  position: relative;
  top: 5px;
`;
export const Radio = styled(Check).attrs({ type: "radio" })``;
export const PlainTextInput = styled.textarea`
  padding: "5px";
  background-color: ${(props) =>
    props.selected ? "rgb(0,100,0, 0.3)" : "transparent"};
  width: 100%;
  margin: 5px;
  height: auto;
  color: black;
`;
