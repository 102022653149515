import { useState } from "react";
import { Checkbox } from "../layout/selection/checkbox";
import { Radio } from "../layout/selection/radio";
import { Drawer } from "antd";
import {
  Button,
  CloseBtn,
  DropBtn,
  ArrowBtn,
  ShinyBtn,
  ShinyRoundMediaBtn,
} from "../layout/buttons/buttons";
import { QuestionAudioRecorder } from "../media/audioRecorder";
import {
  Box,
  Collapse,
  TabGroup,
  TabGroupInternal,
} from "../layout/containers/box";
import {
  MediaUpload,
  TextField,
  SelectField,
} from "../layout/containers/input";
import ListItem from "../layout/containers/listItem";
import MediaView from "../media";
import AudioRecorder from "../media/audioRecorder";
import { EditorSample } from "../editor/slateEditor";
import Editor from "../editor2/editor";
import Lecture from "../lecture/index";
import { QuestionView } from "../lecture/question/questionView";
import QuizView from "../lecture/question";
import Mic from "../webSpeechAPI";
import { Tooltip } from "antd";
import { ModalElement } from "../../helpers/hooks/useModal";
import {
  Cross,
  Info,
  Plus,
  BackArrow,
  Hamburger,
  Home,
  SettingSliderHorizontal,
  ContentBookEdit,
  ContentBook,
  AlignVerticalBottom,
  OptionsVertical,
  OptionsHorizontal,
  Grid,
  CalendarDone,
  CalendarPending,
  ArrowRight,
  Lock,
  FullScreen,
  Play,
  Pause,
  Sound,
  FormEdit,
  ExternalLink,
} from "../../images/icons";
import CreateQuestion from "../questions";
import UserList from "../UNUSED_users/list";
import DocList from "../documents/addDocToProduct";
import { DeadlinedLayer } from "./student/components/modules";
import { NonDeadlinedLayer } from "./student/components/modules";
const Colors = () => {
  let razmattaz = [
    "#0a0003",
    "#1d000a",
    "#310011",
    "#440018",
    "#58001f",
    "#6c0026",
    "#7f002d",
    "#930034",
    "#a7003b",
    "#ba0042",
    "#ce0049",
    "#f50057",
  ];
  let springGreen = [
    "#000a06",
    "#001d13",
    "#003120",
    "#00442c",
    "#005839",
    "#006c45",
    "#007f52",
    "#00935f",
    "#00a76b",
    "#00ba78",
    "#00ce85",
    "#00e191",
    "#00f59e",
  ];
  const random = [
    "#2B061E" /* Dark purple*/,
    "#FFEED6" /* Papaya Whip*/,
    "#FBBFCA" /* Pink*/,
    "#FF87AB" /* Tickle Pink*/,
  ];
  const grey = [
    "#131B23" /* Rich black*/,
    "#7A7D7D" /* Gray Web*/,
    "#D0CFCF" /* Light Gray*/,
    "#8A8D91" /* Spanish Gray*/,
    "#565254" /* Davy's gray*/,
    "#3E4E50" /* Dark slate gray*/,
    "#BCBDC0",
    "#FFFBFE" /* Snow*/,
    "#FFFCFF" /* Snow*/,
    "#FEFDFF",
    "#FFFFFC",
    "#fff",
  ];

  return (
    <>
      <h2>Colors</h2>
      <h3>Bright</h3>
      <Box type="row" background="rgb(30,30,30)">
        {razmattaz.map((c, i) => (
          <Tooltip title={c} key={"sq inline" + i}>
            <Box type={"square inline"} background={c} />
          </Tooltip>
        ))}
        {springGreen.reverse().map((c, i) => (
          <Box type={"square inline"} background={c} key={"sq inline" + i} />
        ))}
      </Box>
      <br />
      <h2>Other</h2>
      <h3>Black-Gray-White</h3>
      <Box type="card_border row" background="rgb(30,30,30)">
        {grey.map((c, i) => (
          <Box type={"square inline"} background={c} key={"sq inline" + i} />
        ))}
      </Box>
      <br />
      <h3>Random</h3>
      <Box type="card_border row" background="rgb(30,30,30)">
        {random.map((c, i) => (
          <Box type={"square inline"} background={c} key={"sq inline" + i} />
        ))}
      </Box>
      <br />
    </>
  );
};
const Buttons = () => {
  return (
    <>
      <h3>Buttons</h3>

      <Box type="card row" background="rgb(30,30,30)">
        <Button theme="primary">Hello</Button>
        <Button theme="secondary">Hello</Button>
        <Button theme="grey-white">Hello</Button>
        <Button theme="black-white">Hello</Button>
        <Button use="small" theme="white-black">
          Use = Small
        </Button>
        <ShinyBtn />
        <ShinyRoundMediaBtn />
      </Box>
      <Box type="1 row centered">
        <CloseBtn />

        <Button theme="white-black" onMouseDown={() => {}} disabled={false}>
          &#8592; Move
        </Button>
        <Tooltip
          title="Arrow button. Chevron can point in any direction (l, r, u, d)"
          trigger={["hover"]}
        >
          <ArrowBtn direction="l" />
        </Tooltip>
        <DropBtn />
        <Button
          theme="white-black"
          onMouseDown={() => {
            //Move right
          }}
          disabled={false}
        >
          Move &#8594;
        </Button>
      </Box>
      <br />
    </>
  );
};
const Collapsable = () => {
  const [isCollapseOpen, setCollapseState] = useState(false);
  const toggle = () => {
    setCollapseState(!isCollapseOpen);
  };
  return (
    <>
      <h3>Collapse(able)</h3>
      <Button
        theme="primary"
        disabled={isCollapseOpen}
        onClick={() => toggle()}
      >
        Open Collapse
      </Button>
      <Collapse
        isOpen={isCollapseOpen}
        onClose={() => setCollapseState(false)}
        height="250px"
      >
        <TextField
          placeholder={"Enter Text here..."}
          label="Something"
          name="Text"
          defaultValue={"What's up?"}
          onChange={(val) => {
            console.log(val);
          }}
        />
      </Collapse>
      <br />
    </>
  );
};
const Tabbed = (tabs) => {
  const [activeTab, setActiveTab] = useState(0);
  return (
    <>
      <h3>Tabs</h3>
      <TabGroup
        tabs={tabs || ["Tab 1", "Tab 2"]}
        setTab={setActiveTab}
        activeIndex={activeTab}
      />
      <br />
    </>
  );
};
const Inputs = () => {
  const [activeTab, setActiveTab] = useState(0);
  let onChange = (e) => {
    console.log(e);
  };
  const TextInputField = () => {
    const [textState, setState] = useState({
      isDecorated: false,
      isLocked: false,
      readOnly: false,
      detectFocus: false,
    });
    const hc = (value, key) => {
      let newState = { ...textState };
      newState[key] = value;
      setState(newState);
    };
    return (
      <>
        <Box
          type="1 row centered"
          width="100%"
          backgroundColor="rgb(30,30,30)"
          padding="0"
          margin="0"
          justifyContent="space-between"
        >
          <TextField
            onChange={onChange}
            isDecorated={textState.isDecorated}
            isLocked={textState.isLocked}
            readOnly={textState.readOnly}
            detectFocus={textState.detectFocus}
            placeholder="Text goes here"
            label="Text Field"
          />
          <Box
            type="row"
            backgroundColor="none"
            margin="0"
            padding="0"
            alignItems="center"
            justifyContent="space-between"
          >
            <Checkbox onChange={(v) => hc(v, "isDecorated")}>
              isDecorated
            </Checkbox>
            <Checkbox onChange={(v) => hc(v, "isLocked")}>isLocked</Checkbox>
            <Checkbox onChange={(v) => hc(v, "readOnly")}>readOnly</Checkbox>
            <Checkbox onChange={(v) => hc(v, "detectFocus")}>
              detectFocus
            </Checkbox>
          </Box>
        </Box>
        <br />
        LIST ITEM
        <Box type="row nowrap" padding={"0px"} margin="0px" background="none">
          <ListItem width={1} />
        </Box>
        <br />
      </>
    );
  };
  const ClickInput = () => {
    return (
      <>
        {" "}
        <Box
          type="row"
          width="min-width"
          margin="8px"
          padding="0 8px"
          background="var(--davy-grey)"
        >
          <Checkbox onChange={onChange}>CheckBox</Checkbox>
        </Box>
        <Box
          type="row"
          width="min-width"
          margin="8px"
          background="var(--davy-grey)"
        >
          <Radio onChange={onChange}>Radio</Radio>
        </Box>
      </>
    );
  };
  return (
    <>
      <h3>Inputs</h3>
      <Box type="row">
        <TabGroup
          tabs={["Click", "Text Field", "Select"]}
          setTab={setActiveTab}
          activeIndex={activeTab}
          padding="0"
          margin="0"
        />
        {activeTab === 0 && <ClickInput />}
        {activeTab === 1 && (
          <>
            <TextInputField />
          </>
        )}
        {activeTab === 2 && (
          <SelectField
            options={[
              { value: "Option 1" },
              { value: "Option 2" },
              { value: "Option 3" },
            ]}
            width="75%"
          />
        )}
      </Box>
    </>
  );
};
const IconView = () => {
  let icons = [
    Cross,
    Info,
    Plus,
    BackArrow,
    Hamburger,
    Home,
    SettingSliderHorizontal,
    ContentBookEdit,
    ContentBook,
    AlignVerticalBottom,
    OptionsVertical,
    OptionsHorizontal,
    Grid,
    CalendarDone,
    CalendarPending,
    ArrowRight,
    Lock,
    FullScreen,
    Play,
    Pause,
    Sound,
    FormEdit,
    ExternalLink,
  ];
  const names = [
    "Cross",
    "Info",
    "Plus",
    "BackArrow",
    "Hamburger",
    "Home",
    "SettingSliderHorizontal",
    "ContentBookEdit",
    "ContentBook",
    "AlignVerticalBottom",
    "OptionsVertical",
    "OptionsHorizontal",
    "Grid",
    "CalendarDone",
    "CalendarPending",
    "ArrowRight",
    "Lock",
    "FullScreen",
    "Play",
    "Pause",
    "Sound",
    "FormEdit",
    "ExternalLink",
  ];
  let styles = {
    color: "rgb(255,255,255,0.7)",
    stroke: "rgb(255,255,255,0.7)",
    fill: "rgb(255,255,255,0.7)",
    fontSize: "16px",
    width: "16px",
    height: "16px",
    size: "16px",
  };
  return (
    <>
      <h3>Own Icons</h3>
      <Box type="row">
        {icons.map((Ic, i) => {
          return (
            <Box
              type="column"
              width="min-width"
              maxHeight="60px"
              margin="5px"
              background="rgb(40,40,40)"
              alignItems="center"
              justifyContent="space-between"
              fontSize="10px"
            >
              <Ic key={"Ic_" + i} {...styles} /> {names[i]}
            </Box>
          );
        })}
      </Box>
    </>
  );
};

const Rows = () => {
  //return ()
};

const AllComponents = () => {
  const [isCollapseOpen, setCollapseState] = useState(true);
  const [activeTab, setActiveTab] = useState(0);
  //background-color: 9,8,22
  return (
    <Box type="wrap col" overFlowY="visible" background="none">
      <NonDeadlinedLayer
        isActive={true}
        title="Non Deadlined layer: Ungated"
        description="Is active and ungated"
        isGated={false}
      />
      <NonDeadlinedLayer
        isActive={true}
        title="Non Deadlined layer: is gated"
        description="Is active and locked"
        isGated={true}
      />
      <DeadlinedLayer
        isActive={true}
        title="Deadlined layer: Is gated"
        description="Is active and locked"
        isGated={false}
      />
      <DeadlinedLayer
        isActive={false}
        title="Deadlined layer: Is gated"
        description="Is active and locked"
        isGated={true}
      />
      <Colors />
      <DocList />
      <UserList />
      <CreateQuestion qid={"64017748995fd3198c208539"} />
      <QuestionAudioRecorder />
      <Buttons />
      <Collapsable />
      <IconView />
      <Inputs />
      {/* <Checkbox /> */}
      <br />
      <MediaView />
      <br />
      <AudioRecorder />
      <br />
      <br />
      <TabGroupInternal
        tabs={[
          { title: "Editor", Component: Editor },
          { title: "Legacy Slate Editor", Component: EditorSample },
        ]}
      />
      <Drawer
        title="Basic Drawer"
        placement={"right"}
        closable={true}
        onClose={() => {
          console.log("ok");
          setActiveTab(0);
        }}
        visible={activeTab === 1}
        key={"right"}
        keyboard={true}
        bodyStyle={{
          background: "var(--tertiary-color-darkest)",
          color: "white",
        }}
        headerStyle={{
          backgroundColor: "var(--tertiary-color-darkest)",
          color: "white",
        }}
      />
      <br />
      Lecture
      <Lecture />
      <br />
      {/* Web Speech API
      <Mic />
      <br />
      Question View
      <QuestionView />
      <br />
      Quiz View
      <QuizView /> */}
    </Box>
  );
};

export default AllComponents;
