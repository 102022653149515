import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { Tooltip, Popover, Drawer, Tabs } from "antd";
import { AutoComplete, Input } from "antd";
import { Box } from "../../layout/containers/box";
import Tags from "../../layout/tags";
import { useSlate } from "slate-react";
import { toggleBlock } from "../helpers/format";
import {
  Transforms,
  Editor,
  Element as SlateElement,
  Inline,
  Node,
  Text,
} from "slate";
import { GET, POST, Rest } from "../../../helpers/REST/api";
import { DeleteFilled, MoreOutlined } from "@ant-design/icons";
const Span = styled.span`
  color: yellow !important;
  text-decoration: underline;
  text-decoration-style: dotted;
  position: relative;
`;
const TextWrap = styled.span`
  & svg {
    display: inline-block;
    font-size: 12px;
    position: relative;
    top: -10px;
    right: 2px;
    opacity: 0;
    fill: white;
    pointer-events: none;
    transition: opacity 0.3s linear;
  }

  &:hover svg {
    opacity: 0.3;
    pointer-events: auto;
    cursor: pointer;
  }
  &:hover {
    svg:hover {
      opacity: 1;
    }
  }
  .ant-tooltip-inner {
    transition: all 0.3s linear;
  }
`;

const CreateTerm = ({ data, title }) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");
  const [options, setOptions] = useState([]);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const onSelect = () => {};
  const onChange = () => {};
  const onSearch = () => {};

  return (
    <Box
      type="col left"
      background="transparent"
      width="300px"
      padding="none"
      contentEditable={false}
    >
      <span
        style={{ margin: 0, padding: 0, fontSize: "20px", fontWeight: "500" }}
      >
        {title}
      </span>
      <hr style={{ width: "100%" }} />
      <br />
      <Box type="col left" margin="0" padding="0" background="transparent">
        <Box type="row" margin="0" padding="0" background="transparent">
          <small>TAGS</small>
          <Tags
            onSearch={(v) => console.log(v)}
            onSave={(v) => console.log(v)}
          />
        </Box>

        <hr style={{ width: "100%" }} />
      </Box>
      <Tabs
        defaultActiveKey="1"
        tabPosition={"top"}
        style={{
          height: 220,
          width: "100%",
          color: "var(--primary-color)",
        }}
      >
        {}
        <Tabs.TabPane tab="Description" key="item-1">
          Content 1
        </Tabs.TabPane>
        <Tabs.TabPane tab="Suggestions" key="item-2">
          Content 2
        </Tabs.TabPane>
      </Tabs>
    </Box>
  );
};
const WordUsage = ({ attributes, element, children, editor }) => {
  const [open, setOpen] = useState(true);
  const [title, setTitle] = useState("");
  const [termData, setTermData] = useState(null);
  const [initRange, setInitRange] = useState(null);

  function handleDividerClose() {}
  function handleDividerChanges() {}
  function createEntry() {}
  function matchThisExactNode(n, string) {
    // console.log(n);
    // // FOR ERROR CHECKING
    // if (!Editor.isEditor(n) && SlateElement.isElement(n) && !Text.isText(n))
    //   if (SlateElement.isElement(n) && !Text.isText(n)) {
    //     console.log(
    //       n,
    //       `Is editor: ${Editor.isEditor(n)}`,
    //       `Is Element: ${SlateElement.isElement(n)}`,
    //       `has string: ${Node.string(n)}`,
    //       `MATCHED: ${Node.string(n) === string ?? "FALSE"}`
    //     );
    //   }
    let isntEd = !Editor.isEditor(n);
    let isEl = SlateElement.isElement(n) && Array.isArray(n.children);
    let isType = n.type === "searchTerm";
    let idsMatch =
      !!element.term_id === !!n.term_id && element.term_id === n.term_id;
    let stringsMatch = Node.string(element) === Node.string(n);

    let result = isntEd && isEl && isType && stringsMatch && idsMatch;

    return result;
  }
  function removeTerm(atRange) {
    Transforms.unwrapNodes(editor, {
      at: atRange,
      match: (n) => matchThisExactNode(n),
      split: true,
    });
  }

  function NodePaths(string) {
    //! REMEMBER: This won't work when strings don't match: and they won't
    //if some text is not showed! This will certainly be a problem for voids; not sure about inlines
    let nodes = Array.from(
      Editor.nodes(editor, {
        at: [],
        match: (n) => matchThisExactNode(n, string),
      })
    );
    console.log(nodes);
    if (Array.isArray(nodes)) {
      let result = nodes.map((n, i) => {
        return n[1];
      });
      return result;
    }
    return [];
  }
  function addIdToNodes(id, paths, innerText) {
    paths.forEach((path) => {
      Transforms.setNodes(
        editor,
        { term_id: id },
        {
          at: path,
        }
      );
    });
  }

  //TODO: Be careful: this doesn't account for multiwrapping:
  // e.g. make a searchTerm and then wrap it with a question -- it becaomes nested
  useEffect(() => {
    if (!open && !element.term_id) {
      removeTerm(initRange);
    }
  }, [open, removeTerm]);

  useEffect(() => {
    if (editor.lastRange) {
      setInitRange(editor.lastRange);
    }
  }, []);
  useEffect(() => {
    async function checkWordInDb(callback) {
      let type = innerText.trim().includes(" ") ? "phrase" : "word";
      let RestMethods = new Rest();
      let readWord = await RestMethods.read_raw(
        `/dictionary/${type}/${innerText}`
      )
        .then(async ({ data }) => {
          const { _id, ...rest } = data;
          let paths = NodePaths(innerText);
          addIdToNodes(data._id, paths, innerText);
          setTermData(rest);
          return data;
        })
        .catch((e) => {
          saveWordIfError(e, type);
        });
      return readWord;
    }
    async function saveWordIfError(error, type) {
      console.log(error);
      if (error?.response?.data) {
        let postData = await POST(`/dictionary/${type}/${innerText}`);
        const { _id, ...rest } = postData;
        console.log(postData);
        let paths = NodePaths(innerText);
        console.log(paths, _id, innerText);
        addIdToNodes(_id, paths, null);
        setTermData(rest);
      }
    }
    let innerText = attributes?.ref?.current?.innerText;
    //let nodeString = Node.string(element);
    // ^ This works. Is it better? It doesn't only accounts for children: so likely a void text won't show up?
    if (title === "" || title !== innerText) {
      if (innerText) setTitle(innerText);
    }

    if (!element.term_id && termData === null) {
      let id;

      checkWordInDb(saveWordIfError);
      let type = innerText.trim().includes(" ") ? "phrase" : "word";
      // let RestMethods = new Rest();
      // let handleTerm = async () =>
      //   await RestMethods.read_raw(`/dictionary/${type}/${innerText}`)
      //     .then(async ({ data }) => {
      //       const { _id, ...rest } = data;
      //       let paths = NodePaths(innerText);
      //       addIdToNodes(data._id, paths, innerText);

      //       setTermData(rest);
      //       return data;
      //     })
      //     .catch(async (error) => {
      //       if (error?.response?.data) {
      //         let postData = async () =>
      //           await POST(`/dictionary/${type}/${innerText}`);
      //         const { _id, ...rest } = postData;
      //         console.log(id);
      //         let paths = NodePaths(innerText);
      //         addIdToNodes(_id, paths, null);
      //         setTermData(rest);
      //       }
      //     });

      // handleTerm();
    }
  });

  return (
    <>
      <TextWrap>
        <Tooltip
          contentEditable={false}
          title="*Changing the word will remove the word"
          color="var(--primary-color)"
        >
          <Span {...attributes}>{children}</Span>
        </Tooltip>
        <DeleteFilled
          onMouseDown={(e) => {
            e.preventDefault();
            removeTerm();
          }}
        />
        <MoreOutlined
          onMouseDown={(e) => {
            e.preventDefault();
            setOpen(!open);
          }}
        />
      </TextWrap>
      <Drawer
        title={"Diction"}
        placement={"right"}
        closable={true}
        onClose={() => setOpen(false)}
        visible={open}
        key={"right"}
        keyboard={true}
        bodyStyle={{ backgroundColor: "var(--tertiary-color-darkest)" }}
      >
        <CreateTerm data={termData} title={title} />
      </Drawer>
    </>
  );
};

export default WordUsage;
