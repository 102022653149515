import React, { useState, useRef, useEffect } from "react";

export const MediaContext = React.createContext({});

/*
    [{}, {}, {}, {}]

*/

export function MediaStateProvider({ value, children }) {
  const { tracks = [], mediaRef } = value;
  const [playerProgress, setCurrentTime] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [totalTime, setTotalTime] = useState(0);
  const [currentTrack, setCurrentTrack] = useState(0);

  const [isMute, setMute] = useState(false);
  const [volume, changeVolume] = useState(1);
  const [isFullScreen, setFullScreen] = useState(false);

  const [nextQuestionIndex, setNextQuestion] = useState(null);
  const [playDisabledAt, disablePlay] = useState(false);
  const [questions, setQuestions] = useState([
    { q: "1", passed: null, time: 100 },
    { q: "1", passed: null, time: 200 },

    { q: "1", passed: null, time: 1200 },
  ]);
  const [showQs, setShowQs] = useState(false);
  //Refs

  const intervalRef = useRef(null);
  const isReady = useRef([]);
  const rangeRef = useRef(null);

  //convenience
  let audioList = mediaRef.current;
  let activeRef = audioList[currentTrack];

  useEffect(() => {
    let reduction = tracks.reduce(
      (total, current) => total + current.duration,
      0
    );
    if (reduction !== totalTime) setTotalTime(reduction);
  }, [tracks]);

  useEffect(() => {
    // FIRST TO TRIGGER cz current track updates on render;
    if (isReady.current[currentTrack] && isPlaying) {
      //Current track's media is visible; else isn't present

      mediaRef?.current[currentTrack]?.play();
      if (!isPlaying) setIsPlaying(true);
      startTimer();
    } else {
      isReady.current[currentTrack] = true;
    }
  }, [currentTrack, isPlaying]);

  useEffect(() => {
    if (isReady.current[currentTrack]) {
      if (
        typeof mediaRef?.current[currentTrack]?.play === "function" &&
        isPlaying
      ) {
        mediaRef.current[currentTrack].play();
        startTimer();
      } else if (typeof activeRef?.pause === "function") {
        mediaRef.current.forEach((el) => {
          if (!el.paused && !el.ended) {
            el.pause();
          }
        });
        clearInterval(intervalRef.current);
      }
    }
  }, [isPlaying]);

  useEffect(() => {
    let isDisabled = playDisabledAt !== false;
    let timeIsAtQuestion = playDisabledAt === playerProgress;
    let timeIsAfterQuestion = playDisabledAt <= playerProgress;
    if (isDisabled && timeIsAtQuestion) {
      if (isPlaying) setIsPlaying(false);
      if (!showQs) setShowQs(true);
    } else if (isDisabled && timeIsAfterQuestion) {
      if (isPlaying) setIsPlaying(false);
      let { track, time } = findTrackAndItsTime(playDisabledAt);
      mediaRef.current[track].currentTime = time;
      setCurrentTime(playDisabledAt);
    }
  }, [playerProgress, isPlaying]);
  useEffect(() => {
    if (playDisabledAt === false && !isPlaying) {
      setIsPlaying(true);
    } else if (playDisabledAt !== false && isPlaying) {
      setIsPlaying(true);
    }
  }, [playDisabledAt]);
  useEffect(() => {
    //cleanup
    return () => {
      if (typeof mediaRef.current[currentTrack]?.pause === "function")
        mediaRef.current[currentTrack].pause();
      clearInterval(intervalRef.current);
      isReady.current.forEach((t) => {
        t = false;
      });
    };
  }, [tracks]);

  //~~~~~~~~~~~~~~ Player-wide functions ~~~~~~~~~~~~
  function startTimer() {
    clearInterval(intervalRef.current);
    function runEverySecond() {
      if (isNaN(totalTime) && tracks && tracks.length) {
        let red = audioList.reduce(
          (total, current, i) => total + Math.ceil(current.duration),
          0
        );
        setTotalTime(red);
      }

      if (mediaRef?.current[currentTrack]?.ended) {
        if (currentTrack + 1 < tracks.length) {
          setCurrentTrack(currentTrack + 1);
          audioList[currentTrack + 1].play();
        }
      } else {
        let progressCalculated = calcPlayerProgress();
        setPlayerProgress(progressCalculated);
      }
    }
    intervalRef.current = setInterval(runEverySecond, [500]);
  }
  function fwdFiveSec() {
    let fwd = playerProgress + 3 > 0 ? playerProgress + 3 : 0;
    onScrubStart();
    onScrub(fwd);
    setPlayerProgress(fwd);
    onScrubEnd();
  }
  function rwdFiveSec() {
    let rwd = playerProgress - 3 > 0 ? playerProgress - 3 : 0;
    onScrubStart();
    onScrub(rwd);
    setPlayerProgress(rwd);
    onScrubEnd();
  }
  //~~~~~~~~~~~~~~~~CONVERSIONS~~~~~~~~~~~~~~~~~~~~~
  const calcPlayerProgress = function (givenCurrentTrackTime) {
    if (activeRef === undefined) {
      activeRef = mediaRef.current[currentTrack];
    }
    let currplayerProgress = mediaRef.current[currentTrack].currentTime;
    let track;
    if (currentTrack > 0) {
      tracks.forEach((track, i) => {
        if (i < currentTrack) currplayerProgress += track.duration;
      });
    }
    return currplayerProgress;
  };
  function findTrackAndItsTime(value) {
    let submit = false;
    let result = { track: 0, time: 0 };
    let trackTime = value; // 120.

    tracks.forEach((track, i) => {
      if (!submit) {
        if (trackTime <= track.duration) {
          result = { track: i, time: trackTime };
          submit = true;
        } else {
          trackTime -= track.duration;
        }
      }
    });
    if (!submit) throw new Error("Something is wrong, I can feel it");
    return result;
  }
  function convertTimeIntToString(time) {
    var mins = Math.floor(time / 60);
    if (mins < 10) {
      mins = "0" + String(mins);
    }
    var secs = Math.floor(time % 60);
    if (secs < 10) {
      secs = "0" + String(secs);
    }
    return mins + ":" + secs;
  }
  //~~~~~~~~~~~~~~~~EVENT HANDLERS~~~~~~~~~~~~~~~~~~~~~
  function onScrubStart() {
    if (isPlaying) {
      togglePlay();
    }
    return;
  }
  function onScrub(value) {
    onScrubStart();
    // Clear any timers already running
    clearInterval(intervalRef.current);
    value = parseFloat(value);
    if (playerProgress !== value) {
      const { track, time } = findTrackAndItsTime(value);

      if (track !== currentTrack) {
        if (
          !mediaRef?.current[currentTrack]?.paused ||
          !mediaRef?.current[currentTrack]?.ended
        ) {
          mediaRef.current[track].currentTime = Math.ceil(time);
        }
      }
      if (rangeRef && rangeRef.current) {
        rangeRef.current.value = value;
      }
      mediaRef.current[track].currentTime = Math.ceil(time);
      isReady.current[track] = true;

      setPlayerProgress(value);
      setCurrentTrack(track);
      onScrubEnd();
      return;
    }
  }
  const onScrubEnd = () => {
    togglePlay();
    return;
  };

  // KEY PRESSES
  // SEEK
  const handleKeyDown = (e) => {
    switch (e.key) {
      case "ArrowLeft":
        rwdFiveSec();
        break;
      case "ArrowRight":
        fwdFiveSec();
        break;
      case " ":
        e.preventDefault();
        setIsPlaying(!isPlaying);
        break;
      default:
        return;
    }
  };
  const handleKeyUp = (e) => {
    if (e.key === "ArrowLeft" || e.key === "ArrowRight") {
      onScrubEnd();
    }
  };

  //Legacy
  function setPlayerProgress(time) {
    setCurrentTime(time);
  }
  function togglePlay() {
    let isDisabled = playDisabledAt !== false;
    let timeIsAtQuestion = playDisabledAt === playerProgress;
    let timeIsAfterQuestion = playDisabledAt <= playerProgress;
    if (isDisabled && timeIsAtQuestion) {
      if (isPlaying) setIsPlaying(false);
    } else if (isDisabled && timeIsAfterQuestion) {
      if (isPlaying) setIsPlaying(false);
      let { track, time } = findTrackAndItsTime(playDisabledAt);
      mediaRef.current[track].currentTime = time;
      setCurrentTime(playDisabledAt);
    } else {
      setIsPlaying(!isPlaying);
    }
  }

  return (
    <MediaContext.Provider
      value={{
        playerProgress,
        setCurrentTime,
        isPlaying,
        setIsPlaying,
        totalTime,
        setTotalTime,
        currentTrack,
        setCurrentTrack,
        mediaRef,
        rangeRef,
        activeRef,
        isPlaying,
        isMute,
        setMute,
        volume,
        changeVolume,
        convertTimeIntToString,
        setIsPlaying,
        rwdFiveSec,
        fwdFiveSec,
        onScrubEnd,
        onScrubStart,
        onScrub,
        handleKeyDown,
        handleKeyUp,
        isFullScreen,
        setFullScreen,
        questions,
        setQuestions,
        playDisabledAt,
        disablePlay,
        togglePlay,
        showQs,
        setShowQs,
        nextQuestionIndex,
        setNextQuestion,
      }}
    >
      {children}
    </MediaContext.Provider>
  );
}
