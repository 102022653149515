import { Transforms, Editor, Range, Element as SlateElement } from "slate";

export const handleInline = {
  isActive: function (editor, format) {
    const [el] = Editor.nodes(editor, {
      match: (n) =>
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
    });

    return !!el;
  },
  unwrap: function (editor, format) {
    Transforms.unwrapNodes(editor, {
      match: (n) => {
        let match =
          !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format;

        return match;
      },
    });
  },
  wrap: function (editor, format, options) {
    if (this.isActive(editor, format)) {
      this.unwrap(editor, format);
    }
    const { selection } = editor;
    const isCollapsed = selection && Range.isCollapsed(selection);

    const wrappedElement = this.selectWrap(isCollapsed, format, options);

    if (isCollapsed) {
      Transforms.insertNodes(editor, wrappedElement, {
        at: [editor.children.length],
      });
    } else {
      Transforms.wrapNodes(editor, wrappedElement, { split: true });
      Transforms.collapse(editor, { edge: "end" });
    }
  },

  selectWrap: function (isCollapsed, format, options) {
    if (typeof options === "undefined") {
      options = { text: "" };
    }

    let { text, url } = options;
    switch (format) {
      case "link":
        return {
          type: "link",
          url,
          children: isCollapsed ? [text] : [],
        };

      default:
        return {
          type: format,

          children: isCollapsed ? [text] : [],
        };
    }
  },
  insertElement: function (editor, format, options) {
    if (editor.selection) {
      this.wrap(editor, format, options);
    }
  },
  toggle: function (editor, format, options) {
    let isActive = this.isActive(editor, format);
    if (!isActive) {
      this.insertElement(editor, format, options);
    } else {
      this.unwrap(editor, format, options);
    }
  },
};
