import React, { useState, createContext, useContext } from "react";
import styled from "styled-components";
import { useLocation, Link } from "react-router-dom";
import { useAuth } from "../../helpers/auth";
import { SettingSliderHorizontal, Home, ContentBookEdit, AlignVerticalBottom, ContentBook, Grid, FormEdit } from "../../images/icons.js";
import { YoutubeOutlined } from "@ant-design/icons";
import { Box } from "./containers/box";

let sidebarColor = "#180009"; //"rgb(245, 0, 87, 0.2)";
let activeSidebarBtnColor = "rgb(245, 0, 87, 1)";
let primaryPink = "rgb(20,20,20)"; //"rgb(20,20,20)" //"#f50057"
let iconColor = "rgb(243,243,243, 0.7)";

const LongSidebar = styled.div`
  width: 64px;
  height: 100%;
  background-color: ${sidebarColor};
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 10 !important;
  overflow: hidden;
  padding: 0px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-between;
  color: white;
  border: 1px solid rgb(20, 20, 20, 0.1);
`;
const SidebarPanelTop = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
`;
const SidebarPanelBottom = styled(SidebarPanelTop)``;
const SidebarItem = styled(Link)`
  box-sizing: border-box;
  padding: 8px;
  float: left;
  width: auto;
  // height: 54px;
  border: none;
  display: block;
  outline: 0;
  color: ${({ color }) => color || "white"};
  text-align: center;
  background-color: ${(props) => (props.active ? activeSidebarBtnColor : "none")};
    background-color: ${(props) => (props.active ? "white" : "none")};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10.5px;
  flex-flow: column nowrap;
  cursor: pointer;
  border-radius: 8px;
  margin: 2px;
  & svg {
    stroke: ${({ color, active }) => (active ? "white" : color ?? iconColor)};
    
  }
  & span {
    color: ${({ color, active }) => (active ? "white" : color ?? iconColor)};
    margin: 2px 0px;
     color: ${({ color, active }) => "black"};
  }
  & svg path {
    stroke: inherit;
  }
  & svg rect {
    stroke: inherit;
    fill: transparent !important;
  }
  &:hover {
    background-color: ${({ color, active }) =>
      // color ? (active ? primaryPink : "white") : "white"};
      "hsla(0,0%,100%,0.2)"};
    & span {
      color: ${({ color, active }) =>
        // color ? (active ? "var(--primary-color)" : "white") : primaryPink};
        "white"};
    & svg path,
    rect {
      stroke: ${({ color, active }) => "white !important"};
      fill: white;
        // color
        //   ? active
        //     ? "var(--primary-color)"
        //     : "white"
        //   : primaryPink} !important;
    }
  }
`;

const AppNav = ({ handleNavClick, active, sidebarItems, isHorizontal }) => {
  let width = 22;
  let height = 22;
  const { pathname } = useLocation();

  let auth = useAuth();

  if (isHorizontal === null) return null;
  if (isHorizontal) {
    return <HorizontalNav sidebarItems={sidebarItems} handleNavClick={handleNavClick} active={active} logout={auth.signout} color={"black"} />;
  }
  return (
    <LongSidebar>
      <SidebarPanelTop>
        {sidebarItems &&
          sidebarItems.map((item, index) => {
            return <NavIcons pathname={pathname} active={active} handleNavClick={handleNavClick} item={item} index={index} width={width} height={height} key={`navItem_${item.name}`} />;
          })}
      </SidebarPanelTop>
      <SidebarPanelBottom>
        <SidebarItem
          to={"/login"}
          onClick={() => {
            auth.signout();
          }}
        >
          <SettingSliderHorizontal width={width} height={height} />
          <span>Logout</span>
        </SidebarItem>
      </SidebarPanelBottom>
    </LongSidebar>
  );
};

export default AppNav;

const NavIcons = ({ isHorizontal, item, index, pathname, handleNavClick, height, width, active, color }) => {
  switch (item.name) {
    case "Overview":
    case "Home":
      return (
        <SidebarItem to={item.to} color={color} active={pathname === item.to ? "true" : undefined} key={`navItem_${item.name}`} onClick={() => handleNavClick(index)}>
          <Home width={width} height={height} />
          <span>{item.name}</span>
        </SidebarItem>
      );

    case "Our Courses":
    case "Courses":
      return (
        <SidebarItem to={item.to} color={color} onClick={() => handleNavClick(index)} active={index === active ? "true" : undefined} key={`navItem_${item.name}`}>
          <ContentBookEdit width={width} height={height} />
          <span>{item.name}</span>
        </SidebarItem>
      );

    case "Contracts":
    case "ABS":
      return (
        <SidebarItem to={item.to} color={color} onClick={() => handleNavClick(index)} active={index === active ? "true" : undefined} key={`navItem_${item.name}`}>
          <AlignVerticalBottom width={width} height={height} />
          <span>{item.name}</span>
        </SidebarItem>
      );

    case "Essays":
      return (
        <SidebarItem to={item.to} color={color} onClick={() => handleNavClick(index)} active={index === active ? "true" : undefined} key={`navItem_${item.name}`}>
          <ContentBook width={width} height={height} />
          <span>{item.name}</span>
        </SidebarItem>
      );
    case "Components":
      return (
        <SidebarItem to={item.to} color={color} onClick={() => handleNavClick(index)} active={index === active ? "true" : undefined} key={`navItem_${item.name}`}>
          <Grid width={width} height={height} />
          <span>{item.name}</span>
        </SidebarItem>
      );
    case "Documents":
      return (
        <SidebarItem to={item.to} color={isHorizontal ? color : iconColor} onClick={() => handleNavClick(index)} active={index === active ? "true" : undefined} key={`navItem_${item.name}`}>
          <FormEdit width={width} height={height} style={{ fill: isHorizontal ? color : iconColor }} />
          <span style={{ color: isHorizontal ? color : iconColor }}>{item.name}</span>
        </SidebarItem>
      );
    case "Playlists":
      return (
        <SidebarItem to={item.to} color={isHorizontal ? color : iconColor} onClick={() => handleNavClick(index)} active={index === active ? "true" : undefined} key={`navItem_${item.name}`}>
          <YoutubeOutlined width={width} height={height} style={{ color: isHorizontal ? color : iconColor }} />
          <span style={{ color: isHorizontal ? color : iconColor }}>{item.name}</span>
        </SidebarItem>
      );
    default:
      return (
        <SidebarItem to={item.to} color={color} onClick={() => handleNavClick(index)} active={index === active ? "true" : undefined} key={`navItem_${item.name}`}>
          <ContentBook width={width} height={height} />
          <span>{item.name}</span>
        </SidebarItem>
      );
  }
};
const HorizontalNav = ({ sidebarItems, handleNavClick, active, logout, color }) => {
  let width = 14;
  let height = 14;
  const { pathname } = useLocation();
  const { isNavVisible } = useContext(NavContext);

  return (
    <div
      style={{
        // transform: "translateX(50%)",
        position: "fixed",
        color: "black",
        bottom: "1em",
        width: "100%",
        transition: "all 0.2s linear",
        zIndex: isNavVisible ? "10" : "-100",
      }}
    >
      <Box
        type="row nowrap"
        background={"white"}
        padding="0px"
        width="min-content"
        margin="0 auto"
        style={{
          position: "relative",
          //boxShadow: "0px 0px 5px #fff",
          opacity: isNavVisible ? "1" : "0",
          zIndex: isNavVisible ? "10" : "-100",
          boxShadow: "inset 0 0 50px #fff, inset 20px 0 80px #f0f, inset -20px 0 80px #0ff, inset 20px 0 300px #f0f, inset -20px 0 300px #0ff, 0 0 50px #fff, -10px 0 80px #f0f, 10px 0 80px #0ff",
        }}
      >
        {sidebarItems &&
          sidebarItems.map((item, index) => {
            return (
              <NavIcons
                isHorizontal={true}
                pathname={pathname}
                active={active}
                handleNavClick={handleNavClick}
                item={item}
                index={index}
                width={width}
                height={height}
                key={`navItem_${item.name}`}
                color={color}
              />
            );
          })}
        <SidebarItem
          color={color}
          to={"/login"}
          onClick={() => {
            logout();
          }}
        >
          <SettingSliderHorizontal width={width} height={height} />
          <span>Logout</span>
        </SidebarItem>
      </Box>
    </div>
  );
};
export const NavContext = createContext({});
export const NavCtxProvider = ({ children }) => {
  const [isNavVisible, setVisibility] = useState(true);
  const showNav = () => {
    setVisibility(true);
  };
  const hideNav = () => {
    setVisibility(false);
  };
  return <NavContext.Provider value={{ showNav, hideNav, isNavVisible }}>{children}</NavContext.Provider>;
};
