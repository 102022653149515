import ReactDOM from "react-dom";
import styled from "styled-components";

export const Menubar = styled.div`
  width: 600px;
  display: flex;
  flex-flow: row nowrap;
  /* padding: 8px 7px 6px; */
  position: absolute;
  z-index: 2;
  top: -10000px;
  left: -10000px;
  margin-top: -6px;
  opacity: 0;
  background-color: #222;
  border-radius: 4px;
  transition: opacity 0.75;
  select,
  option {
    min-width: 400px !important;
  }
`;

export default function Portal({ children }) {
  return typeof document !== "undefined" &&
    typeof document.querySelector("#portal_container")
    ? ReactDOM.createPortal(
        children,
        document.querySelector("#portal_container")
      )
    : null;
}
