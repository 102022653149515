import React, { useEffect, useState } from "react";
import styled from "styled-components";
import AppNav, { NavCtxProvider } from "./appNav";
import AppHeader from "./appHeader";
import { Outlet, useLocation } from "react-router-dom";

let sectionPaddingBottom, sectionPaddingTop;
sectionPaddingBottom = "64px";
sectionPaddingTop = "64px";
const Section = styled.section`
  position: relative;
  z-index: 1;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  overflow: hidden;
  background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : "inherit")};
  color: "inherit";
  padding-top: ${(props) => (props.reducePaddingTop ? sectionPaddingBottom : sectionPaddingTop)};
  padding-bottom: ${sectionPaddingBottom};

  @media (max-width: 800px) {
    width: 80%;
    padding-right: auto;
    padding-left: auto;
    display: flex;
    justify-content: center;
  }

  // New stuff
  @media (max-width: 800px) {
    display: block;
    margin: 0;
    padding: 0;
    width: 100%;
    margin-top: 1em;
    margin-bottom: 1em;
  }
  @media (min-width: 1080px) {
    max-width: ${(props) => (props.wide ? "100%" : "100%")};
    padding-right: ${(props) => (props.wide ? "calc((100vw - 1080px)/2)" : "0")};
    padding-left: ${(props) => (props.wide ? "calc((100vw - 1080px)/2)" : "0")};
    width: ${(props) => (props.wide ? "auto" : "100%")};
  }

  margin: ${(props) => (props.wide ? "0" : "0 auto")};
`;

const ProfileSection = styled(Section)`
  display: flex;
  flex-flow: column nowrap;
  min-height: 100vh;
  height: auto;
  padding: 0 7.5%;
  width: 90%;
  //margin-left: 70px;

  background: transparent;
  @media (max-width: 1080px) {
    width: 90%;
    //margin-left: 70px;
  }
`;
const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  background: var(--body-background);
  color: white;
  width: 100%;
  height: auto !important;
  /* height: 100%;
  min-height: 100vh; */
`;
const Header = styled.div`
  width: 100%;
  font-size: 30px;
  font-weight: 500;
  margin: 50px 0;
`;
let sidebarItems = [
  { to: "/pages", name: "Home", description: "Pages" },
  {
    to: "/products",
    name: "Products",
    description: "Courses",
  },
  {
    to: "/students",
    name: "Students",
    description: "Students",
  },
  { to: "/essays", name: "Essays", description: "My App. Essays" },
  { to: "/components", name: "Components", description: "My Components" },
  { to: "/documents", name: "Documents", description: "Documents" },
  { to: "/playlists", name: "Playlists", description: "Playlists" },
  { to: "/login", name: "Login", description: "Login to 99point9's portal" },
];

const MainLayout = (props) => {
  const [showNav, setShowNav] = useState(true);
  const [pageName, setPageName] = useState("page");
  const [isHorizontal, setIsHorizontal] = useState(false);
  const [tab, setTab] = useState(0);
  const { pathname } = useLocation();

  const handleNavClick = (tabIndex) => {
    setTab(tabIndex);
  };
  // Active Tab
  useEffect(() => {
    sidebarItems.forEach((item, index) => {
      if (props.appPath === item.to) {
        setTab(index);
      }
    });
  }, [props.appPath]);

  //Show Nav & Nav-alignment
  useEffect(() => {
    let page = pathname.split("/")[1]; // e.g. /documents/ => ["", "documents", ""]
    const noName = page === "login" || page === "signup" || page === "verify";
    // Determine nav arrangement
    const isDocPage = page === "documents";
    const isLecPage = page === "lessons";

    if (isDocPage || isLecPage) {
      setIsHorizontal(true);
    } else if (pathname === "/") {
      setIsHorizontal(null);
    } else {
      setIsHorizontal(false);
    }
    if (noName) {
      setShowNav(false);
    } else if (!setShowNav) setShowNav(true);

    //Set page name
    page = page.slice(0, 1).toUpperCase() + page.slice(1);
    if (document?.title) document.title = page;
    noName ? setPageName("") : setPageName(page);
  }, [pathname]);

  return (
    <Wrapper>
      <NavCtxProvider>
        <AppHeader page={pathname.split("/")[1]} />
        {showNav && <AppNav active={tab} handleNavClick={handleNavClick} sidebarItems={sidebarItems} isHorizontal={isHorizontal} />}

        <ProfileSection>
          {!isHorizontal && <Header>{pageName}</Header>}
          <Outlet />
          {props.children}
        </ProfileSection>
      </NavCtxProvider>
    </Wrapper>
  );
};

export default MainLayout;
