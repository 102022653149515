import { useState, useEffect, useContext, useCallback, useRef } from "react";
import LectureContext from "./context";
import Elements, { StudentElements } from "../editor2/renders/elements";
import styled, { keyframes } from "styled-components";
// import Leafs from "../editor2/renders/leafs";

import { getAllIdsFromText } from "../editor2/features/question";
import { RounderBtn as LoadQuestionBtn } from "../layout/buttons/buttonStyles";

import { QuestionCircleOutlined } from "@ant-design/icons";
import { MonitorCtx } from "./monitor";

const LeafText = styled.span`
  color: white;
  transition: opacity 0.5s ease-in;
  opacity: ${(props) => (props.active ? "1" : "0.3")};
  transition-delay: ${(props) => props.delay + "ms"};
  svg {
    opacity: 1;
  }
  &:hover {
    opacity: 1;
  }
`;

/**
    Render means: convert the "data" tree structure into html. 
    Functionality: only shows a block's children (i.e. elements) and displays only till a given "breakpoint";
    Breakpoint: a point where we can attach "triggers" i.e. quizzes etc.
 */
const JSONElementsToHTML = (props) => {
  const { block, blockIndex, isCurrentBlock } = props;
  const lectureCtx = useContext(LectureContext);
  // const monitorContext = useContext(MonitorCtx);
  // const studyTime = monitorContext?.studyTime;
  // const timerActive = monitorContext?.isActive;
  // const isLive = lectureCtx?.isLive;
  const currentBlockIndex = lectureCtx?.currentBlockIndex;
  const elementIndex = lectureCtx?.elementIndex;
  const setCurrentBlockIndex = lectureCtx?.setCurrentBlockIndex;
  const onInline = lectureCtx?.onInline;
  const onBlock = lectureCtx?.onBlock;
  const isInlineElseBlock = lectureCtx?.isInlineElseBlock;
  const breakPoints = lectureCtx?.breakPoints;
  const setBreakPoints = lectureCtx?.setBreakPoints;
  const startInteraction = lectureCtx?.startInteraction;
  const interactElement = lectureCtx?.interactElement;
  const endInteraction = lectureCtx?.endInteraction;
  // const { isLive, currentBlockIndex, elementIndex, setCurrentBlockIndex, onInline, onBlock, isInlineElseBlock, breakPoints, setBreakPoints, startInteraction, interactElement, endInteraction } =
  //   useContext(LectureContext); // not yet used

  const [prevBreakpoints, setPrevBp] = useState([]);
  let textRefs = useRef([]);
  useEffect(() => {
    if (block !== undefined && Array.isArray(block.children) && isCurrentBlock) {
      let tillFirstBreakpoint = [];
      let breakPointArray = [];
      let startPoint = elementIndex || -1;
      block.children.forEach((el, i) => {
        if (i >= startPoint) {
          // e.g. prev. qustion was at i =2, finish => el index becomes 3. So start from 3
          let questionIds = [...getAllIdsFromText(el)]; //converting from set.
          let noBreaksYet = breakPointArray.length < 1;
          let elHasBreaks = el.isBreakpoint || el?.type === "inline-question-void";
          if (noBreaksYet) tillFirstBreakpoint.push(el);
          if (elHasBreaks) breakPointArray.push({ i, questionIds });
        }
      });

      if (breakPointArray.length > 0) {
        onInline();
      }
      setBreakPoints(breakPointArray);
    }
  }, []);

  useEffect(() => {
    if (isCurrentBlock && blockIndex !== currentBlockIndex) {
      setCurrentBlockIndex(blockIndex);
    }
  });
  const handleRefs = useCallback(function handleRefs(leafRef, index) {
    if (leafRef) {
      textRefs.current[index] = leafRef;
    }
  }, []);
  const isLastText = useCallback(
    function isLastText(leafIndex) {
      if (!isCurrentBlock) return false;
      //console.log(leafIndex);
      let hasPrevBp = prevBreakpoints.length > 0;
      let lastBreakpoint = hasPrevBp && prevBreakpoints[prevBreakpoints.length - 1];
      let lastBpIndex = lastBreakpoint?.i;
      let nextBreakpointIndex = breakPoints[0]?.i;
      if (!nextBreakpointIndex && !lastBpIndex) return true;
      if (!nextBreakpointIndex) return leafIndex > lastBpIndex;
      if (!lastBpIndex) return leafIndex < nextBreakpointIndex;
      return lastBpIndex < leafIndex < nextBreakpointIndex;
    },
    [breakPoints, prevBreakpoints, isCurrentBlock]
  );
  const triggerNextBreakPoint = useCallback(
    function triggerNextBreakPoint() {
      // do action
      let bpCopy = [...breakPoints];
      let last = bpCopy.shift();
      setPrevBp([...prevBreakpoints, last]);
      setBreakPoints(bpCopy);
      if (isInlineElseBlock && bpCopy.length === 0) onBlock();

      endInteraction();
    },
    [breakPoints, prevBreakpoints, isInlineElseBlock, endInteraction, onBlock, setBreakPoints]
  );
  const LeafCallback = useCallback((props) => {
    return <Leafs {...props} />;
  }, []);

  /**
   * No arguments passed.
   * For the given active block and specific breakpoint, this will cause us to move on.
   */

  const handleLeafQuestions = useCallback(
    function handleLeafQuestions(leafIndex) {
      let ctx = {
        completionCallback: triggerNextBreakPoint,
        questionIds: breakPoints[0].questionIds,
      };
      startInteraction(ctx);
    },
    [breakPoints, startInteraction, triggerNextBreakPoint]
  );
  /*
    We know: 
    1. Block will have a "qid"--ID--property indicating that it has questions
    2. children array elements will have "isBreakpoint" -- where we ought to pause.
    3. At breakpoint something needs to occur: now is that one thing, or several?
  */
  const ElementLeaves = useCallback(
    ({ leaf, leafIndex }) => {
      const { text, ...leafAttributes } = leaf;
      let breakpointIndex = breakPoints[0] ? breakPoints[0].i : -1;
      let haveStops = isCurrentBlock && breakPoints.length > 0; //Anymore.
      let renderableLeafs = haveStops && leafIndex <= breakpointIndex;
      let shouldRenderLeaf = !haveStops || renderableLeafs; //Actionable: Determines if this leaf will be rendered

      let atInteractiveLeaf = isCurrentBlock && leafIndex === breakpointIndex;
      let shouldInteract = isCurrentBlock && haveStops && atInteractiveLeaf; //Actionable: Triggers interaction state change

      if (!shouldRenderLeaf) return null;
      if (text) {
        return (
          <LeafText
            ref={(r) => {
              if (handleRefs) handleRefs(r, leafIndex);
            }}
            active={isLastText(leafIndex)}
          >
            <LeafCallback leaf={{ ...leafAttributes }} text={text} handleRefs={handleRefs} index={leafIndex} />
          </LeafText>
        );
      }
      return (
        <span
          ref={(r) => {
            if (handleRefs) handleRefs(r, leafIndex);
          }}
        >
          <LeafCallback leaf={{ ...leafAttributes }} text={text} handleRefs={handleRefs} index={leafIndex}>
            {shouldInteract &&
              !interactElement &&
              (leaf.type === "inline-question-void" ? (
                <QuestionCircleOutlined style={{ color: "#F50057" }} onMouseDown={() => handleLeafQuestions(leafIndex)} />
              ) : (
                <LoadQuestionBtn style={{ display: "inline-flex" }} onMouseDown={() => handleLeafQuestions(leafIndex)}>
                  Continue &#8594;
                </LoadQuestionBtn>
              ))}
          </LeafCallback>
        </span>
      );
    },
    [breakPoints, isCurrentBlock, blockIndex, handleRefs, interactElement, handleLeafQuestions, isLastText]
  );
  const StudentElem = useCallback((props) => <StudentElements {...props} />, []);

  const highlight = "highlight";
  const boxyOutline = "sketch-highlight";
  const circularOutline = "circle-sketch-highlight";
  const de_emphasize = "de-emphasize";
  if (block === undefined || block.children === undefined) return null;
  const { children, ...element } = block;
  return (
    <StudentElem element={element} key={"block_" + blockIndex} fwdRef={props?.fwdRef}>
      {children.map((leaf, leafIndex) => (
        <ElementLeaves leaf={leaf} leafIndex={leafIndex} key={`block_${blockIndex}_leaf_${leafIndex}`} />
      ))}
    </StudentElem>
  );
};

export default JSONElementsToHTML;

export function Leafs(props) {
  let { children, text, leaf, attributes } = props;
  const { bold, code, italic, underline, strikethrough } = leaf;

  if (bold) {
    text = <strong>{text}</strong>;
  }
  if (code) {
    text = <code>{text}</code>;
  }
  if (italic) {
    text = <em>{text}</em>;
  }
  if (underline) {
    text = <u>{text}</u>;
  }
  if (strikethrough) {
    text = <del>{text}</del>;
  }

  if (leaf.type && leaf.text && Array.isArray(leaf.text)) {
    return null;
  }

  return (
    <span {...attributes}>
      {text} {children}
    </span>
  );
}
