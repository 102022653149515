import { useState, useEffect } from "react";
import { Box } from "../../layout/containers/box";
import { ArrowBtn } from "../../layout/buttons/buttons";
import { RounderBtn as SubmitBtn } from "../../layout/buttons/buttonStyles";

const QuizNav = (props) => {
  let { onPrev, onNext, onSubmit, canSubmit, hasPrev, hasNext } = props;

  return (
    <Box
      type="1 row centered"
      width="min-content"
      margin="0 auto"
      background="none"
    >
      {hasPrev && (
        <ArrowBtn
          direction="l"
          onMouseDown={() => onPrev()}
          disabled={!hasPrev}
        />
      )}

      <SubmitBtn
        onMouseDown={() => {
          onSubmit();
        }}
        disabled={!canSubmit}
      >
        Submit
      </SubmitBtn>
      {hasNext && (
        <ArrowBtn
          direction="r"
          onMouseDown={() => onNext()}
          disabled={!hasNext}
        />
      )}
    </Box>
  );
};
export default QuizNav;
