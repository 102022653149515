import { useState, createContext, useContext, useEffect } from "react";
import { ReactEditor, useSlate, useSelected, useFocused } from "slate-react";
import { Range, Editor } from "slate";
import { NavContext } from "../layout/appNav";
import { isOnANewElem } from "./helpers/text";
import { toast } from "react-toastify";
//Is in fact Toolbar Context; not Editor context (later down the line, naming changed --> now named based on feature)
export const EditorContext = createContext({});
export const EditorHook = ({ readOnly, children, containerRef }) => {
  const [toolbarOpen, setToolbarOpen] = useState(false);
  const [blockToolbarOpen, setBlockToolbarOpen] = useState(false);
  const [activeNode, setActiveNode] = useState([]);
  const { showNav, hideNav, isNavVisible } = useContext(NavContext);
  const editor = useSlate();
  const isSelected = useSelected();

  function shouldToolbarBeOpen(editor, isSelected) {
    const { selection } = editor;

    let toolbarShouldClose;
    toolbarShouldClose = !selection;
    toolbarShouldClose = toolbarShouldClose || !ReactEditor.isFocused(editor);
    toolbarShouldClose = toolbarShouldClose || !isSelected;
    // TOOLBAR SHOULD BE CLOSED IF RANGE IS COLLAPSED.
    toolbarShouldClose =
      toolbarShouldClose && selection?.anchor && selection?.focus
        ? Range.isCollapsed(selection)
        : false;

    if (toolbarOpen && toolbarShouldClose) {
      closeToolbar();
      return;
    }
    if (!toolbarOpen && !toolbarShouldClose) {
      openToolbar();
    }
  }
  function shouldBlockToolbarOpen(editor) {
    //This can be used to custom position the toolbar too
    // Get the path; use containerRef to find the path child
    // Find height and put toolbar there.
    let paraElemPath = isOnANewElem(editor);
    if (paraElemPath === false && blockToolbarOpen) {
      setBlockToolbarOpen(false);
    } else if (paraElemPath && !blockToolbarOpen) {
      setBlockToolbarOpen(true);
    }
    return true;
  }
  function closeToolbar() {
    setToolbarOpen(false);
    showNav();
  }
  function openToolbar() {
    setToolbarOpen(true);
    setBlockToolbarOpen(false);
    hideNav();
  }

  useEffect(() => {
    if (editor && editor.selection !== null) {
      shouldToolbarBeOpen(editor, isSelected);
      shouldBlockToolbarOpen(editor);
    }
  });
  useEffect(() => {
    if (blockToolbarOpen) {
      toast.info("Move the cursor to remove this toolbar", {
        toastId: "openBlockToolbar",
        hideProgressBar: true,
      });
      hideNav();
    } else if (!blockToolbarOpen && !isNavVisible && !toolbarOpen) {
      showNav();
    }
  }, [blockToolbarOpen]);

  return (
    <EditorContext.Provider
      value={{
        containerRef,
        closeToolbar,
        openToolbar,
        toolbarOpen,
        shouldToolbarBeOpen,
        readOnly,
        blockToolbarOpen,
      }}
    >
      {children}
    </EditorContext.Provider>
  );
};

export const CommentContext = createContext({});
export const CommentHook = ({ children }) => {
  const [activeComment, setActiveComment] = useState(null);
  const [commentIDs, setCommentIDs] = useState(new Set());
  const [commentDataMapByIDs, setCommentDataMapByIDs] = useState(new Map());

  function addCommentIdAndDataToContext(id, threadData) {
    //Update commentIDs
    if (!commentIDs.has(id)) {
      let newCommentIDsSet = new Set(commentIDs);
      newCommentIDsSet.add(id);
      setCommentIDs(newCommentIDsSet);
    }

    //Update commentIDs
    let newCommentDataSet = new Map(commentDataMapByIDs);
    newCommentDataSet.set(id, threadData);
    setCommentDataMapByIDs(newCommentDataSet);
    setActiveComment(id);

    //TODO Deselect commented text, since comment box would appear now.
  }
  return (
    <CommentContext.Provider
      value={{ activeComment, setActiveComment, addCommentIdAndDataToContext }}
    >
      {children}
    </CommentContext.Provider>
  );
};

/**
 * Children --> X; gives you length.
 * [X,0] --> Start point. undefined --> End point.
 *
 */
