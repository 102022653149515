import styled from "styled-components";
import { useContext } from "react";
import { FontContext } from "../../../helpers/hooks/useFontSize";

// !localStorage.getItem("fontsize")
//   ? "20px"
// : localStorage.getItem("fontsize")
export const Para = styled("p").attrs({ as: "div" })`
  font-size: ${() => {
    const { fontSize } = useContext(FontContext);
    return `${fontSize}px`;
  }};

  font-weight: 300;
  line-height: 32px;
  letter-spacing: -0.08px;
  word-spacing: 5px;
  color: rgb(200, 200, 200);
  &:after {
    position: absolite;
    top: 0;
    opacity: 0.5;
    content: ${({ selected }) => (selected ? "" : "...")};
  }
`;
// ** Wraps

export const Wrapper = styled.div`
  border-bottom: 1px solid rgb(30, 30, 30);
  display: flex;
  flex-direction: column;
  width: ${({ width }) => (width ? width : "100%")};
`;
export const EditorWrap = styled.div`
  opacity: ${({ isFocused }) => (isFocused ? "1" : "0.2")};
  width: ${({ width }) => (width ? width : "100%")};
  background: ${({ background }) => (background ? background : "rgb(30, 30, 30, 0.1)")};
  &.dark {
    background: "rgb(30, 30, 30, 0.1)";
    ${Para} {
      color: snow;
      font-weight: 300;
      ${({ fontSize }) => `font-size:${fontSize}; span {font-size:${fontSize}}`};
    }
  }
  &.light {
    ${({ background }) => (background ? background : "transparent")}
    opacity: 1;
    ${Para} {
      color: black;
      font-weight: 300;
      ${({ fontSize }) => `font-size:${fontSize}; span {font-size:${fontSize}}`};
    }
  }
`;
export const ToolbarWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 50px;
  opacity: ${({ showToolbar }) => (showToolbar ? "1" : "0")};
  visibility: ${({ showToolbar }) => (showToolbar ? "visible" : "hidden")};
  transition: opacity 0.4s ease-in;
`;
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~//
export const qModalStyle = {
  QuestionFormWrap: styled.div`
    display: flex;
    flex-flow: column nowrap;
    height: auto;
    align-items: center;
  `,
  Container: styled.div`
    position: relative;
    z-index: 1;
    background: white;
    box-sizing: border-box;
    overflow: auto;
    height: auto;
    width: auto;
    margin: auto;
    padding-top: 24px;
  `,
  Content: styled.section`
    padding: 0 24px;
    overflow: hidden;
    width: inherit;
  `,
  ButtonWrapper: styled.div`
    display: flex;
    justify-content: center;
    margin: 1em 0;
  `,
  Footer: styled.footer`
    padding: 0 24px;
  `,
  FooterPara: styled.p`
    font-size: 14px;
    line-height: 1.5;
  `,
  InputWrapper: styled.div`
    position: relative;
    margin-top: 0.25rem;
    height: 100%;
    min-width: 50%;
    max-width: 250px;
  `,

  Label: styled.label`
    font-weight: 600;
    font-size: 0.875rem;
  `,
  Input: styled.input`
    position: relative;
    z-index: 1;
    display: block;
    border: 1px solid rgb(91, 105, 135);
    color: rgb(3, 27, 78);
    width: 100%;
    min-height: 48px;
    padding: 0 8px;
    background-color: #fff;
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
    border-radius: 3px;
    font-size: 16px;
    text-rendering: auto;
    letter-spacing: normal;
    word-spacing: normal;
    text-align: left;
    &:active {
      border: 2px dotted rgb(91, 105, 135);
    }
  `,
  Select: styled.select`
    /* position: relative; */
    z-index: 1;
    display: block;
    border: 1px solid rgb(91, 105, 135);
    color: rgb(3, 27, 78);
    width: 100%;
    height: 48px;
    padding: 0 8px;
    background-color: #fff;
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
    border-radius: 3px;
    font-size: 16px;
    text-rendering: auto;
    letter-spacing: normal;
    word-spacing: normal;
    text-align: left;
    width: 250px;
    &:active {
      border: 2px dotted rgb(91, 105, 135);
    }
  `,
};

export const QuestionPopup = styled.div`
  position: absolute;
  z-index: 2;
  top: -10000px;
  left: -10000px;
  left: 50px;
  opacity: 0;
  /* background-color: #222; */
  border-radius: 4px;
  transition: opacity 0.75;
  width: 30%;
`;
//~~~~~~~~~~~~~~~~~~~~~ ELEMENTS ~~~~~~~~~~~~~~~~~~~~~~~~~~~//
export const Pre = styled.pre`
  background: white;
  color: blue;
`;

export const EditorImg = styled.img`
  display: block;
  width: 500px;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  box-shadow: ${(props) => (props.selected && props.focused ? "0 0 0 2px blue;" : "none")};
  border-radius: 15px;
`;
export const QuestionSpan = styled.span`
  cursor: pointer;
  /* background-color: #007aff; */
  color: ${(props) => (props.completed ? "inherit" : "var(--primary-color)")};
  /* text-decoration: ${(props) => (props.completed ? "none" : "underline")}; */
  font-weight: 400;
  padding: 1px 0px;
  border-radius: 4px;
  padding-bottom: ${(props) => (props.completed ? "0px" : "3px")};
  border-bottom: 1px solid var(--primary-color);
`;
//#e5e5f7;
export const Indic = styled.span`
  display: inline-block;
  width: 100%;
  height: auto;
  background: transparent;
  color: #e5e5f7;
  text-align: center;

  opacity: 0.8;
  background-image: radial-gradient(#f50057 0.5px, transparent 0.5px), radial-gradient(#f50057 0.5px, transparent 0.5px);
  background-size: 20px 20px;
  background-position: 0 0, 10px 10px;
`;
