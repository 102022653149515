import styled from "styled-components";
export const ModuleWrap = styled.div`
  margin: 0px auto 16px auto;
  width: 600px;
  border-radius: var(--border-radius-med);
  display: grid;
  grid-template-columns: 0.3fr 2.3fr 0.7fr;
  grid-template-rows: 75px;
  gap: 0px 0px;
  grid-template-areas: "start middle end";
  background: linear-gradient(
    -25deg,
    rgba(30, 30, 30, 1) 0%,
    rgba(50, 50, 50, 0.7) 100%
  );
  align-items: center;
  padding: 8px;
  opacity: ${({ isActive }) => (isActive ? "1" : "0.5")};
`;
export const UnitWrap = styled(ModuleWrap)`
  padding: 5px 8px;
  color: white;
  position: relative;
  // left: 25px;
  margin-bottom: 0;
  background: none;
  // border-left: 5px solid white;
`;

export const Icon = styled.div`
  width: 50px;
  height: 50px;
  grid-area: start;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 25px;
  color: white;
  border-radius: var(--border-radius-med);
  position: relative;
  grid-area: start;
  // background: var(--spanish-grey);
  margin: 0px 10px;
`;
export const Title = styled.div`
  grid-area: middle;
  margin: 0px 10px;
  font-weight: 500;
  display: flex;
  flex-flow: column nowrap;
  .desc {
    font-size: 12px;
    font-style: italic;
  }
`;
export const ButtonWrap = styled.div`
  display: flex;
  flex-flow: row nowrap;
  grid-area: end;
  button {
    color: white;
  }
`;
export const BtnWrap = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  color: white;
`;
export const DateWrap = styled.div`
  height: auto;
  display: flex;
  flex-flow: column nowrap;
  color: black;
  padding: 8px;
  h3 {
    color: black;
  }
`;
