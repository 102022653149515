import React, { useState, useEffect } from "react";
import Lecture from "..";
import { ArrowBtn, Button } from "../../layout/buttons/buttons";
import { Box } from "../../layout/containers/box";
import { QuestionView } from "./questionView";
import RenderRichTextOnly from "../renderRichTextOnly";
import AnswerChoices from "./answerChoices";
import sampleQuestionData from "./sampleData";
import QuizNav from "./quizNav";
import LoadingBook from "./loadingIcon";

const QuizView = (props) => {
  const { qids } = props; // Array.
  const [questions, setQuestions] = useState([]);
  const [active, setActive] = useState(0);
  const [answer, setAnswer] = useState(null);
  const [isMarked, setPassed] = useState(null); // Later
  const [isLoading, setLoading] = useState(true);

  /**
   * SCHEMA:
   * qType: "mcq",
   * qAudio: ""
   * question: Array
   * answerChoices: [{isCorrect, choice, refs, explanation, implication}]
   */

  //Timer.
  //Display one thing at a time?
  // Modal vs. inline, vs. below the section.
  // A question vs. a set of questions vs. a quiz
  // Voice recognition && chatGPT?
  //Be able to skip a question?

  useEffect(() => {
    if (Array.isArray(qids) && questions[active] === undefined) {
      //fetch the question from whereever.
      // set it to data[active] value.
    }
  }, [active, qids, questions]);

  const handleSubmit = async () => {};
  const saveProgress = () => {};
  const nextQuestion = () => {};
  const prevQuestion = () => {};
  const handleChange = () => {};

  if (isLoading) {
    return (
      <Box width="50vw" type="1 row centered">
        <LoadingBook />
      </Box>
    );
  }
  if (!Array.isArray(qids)) return null;
  if (qids.length < 1) return null;
  if (questions[active] === undefined) return null;
  if (!Array.isArray(questions[active])) return null;

  return (
    <Box width="50vw" type="column">
      {!questions[active] ? <LoadingBook /> : <QuestionView data={qids} />}

      <QuizNav
        onPrev={prevQuestion}
        onNext={nextQuestion}
        onSubmit={handleSubmit}
        hasPrev={active > 0}
        hasNext={active < qids.length}
      />
    </Box>
  );
};

export default QuizView;
