import Elements from "../editor2/renders/elements";
import { Leafs } from "./elementRender";
/**
 * @param  {Array} props.data Contains all blocks
 * This component does not interact at all, does not pause at questions, does not comment or anything of
 * that sort.
 *
 * It is only to convert RT into html
 */
const RenderRichTextOnly = (props) => {
  if (props.data === undefined || !Array.isArray(props.data) || !typeof props.data[0] === "object" || !Array.isArray(props.data[0].children)) return null;

  return props.data.map((block, blockIndex) => (
    <Elements element={block} key={"block_" + blockIndex}>
      {block.children.map((leaves, leafIndex) => {
        const { text, ...leafAttributes } = leaves;

        return <Leafs leaf={leafAttributes} key={`block_${blockIndex}_leaf_${leafIndex}`} children={text} />;
      })}
    </Elements>
  ));
};
export default RenderRichTextOnly;
