import React, { useEffect, useRef, useState } from "react";
import { useFocused, useSelected } from "slate-react";
import { Editor } from "slate";

import styled from "styled-components";
import { QuestionSpan, Pre, EditorImg, Para } from "./UI/styled";

import { qModalStyle, Indic } from "./UI/styled";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Modal, Popover } from "antd";
import { Audio } from "./UI/audioPlayer/player";
import EditableMediaPlayer from "./UI/vPlayer/v2/adminMediaPlayer";

import {
  MultipleAnswers,
  TrueOrFalse,
  FindEvidence,
  SingleAnswerMCQ,
} from "./UI/questionModals/questionTypes";
import { getQuestion, getBlockQuestions } from "../../helpers/REST/api";
import { useParams } from "react-router-dom";
const { Label, InputWrapper, Input, Select } = qModalStyle;

export function SaveButton({ content, value, onClick }) {
  let showButton = () =>
    JSON.stringify(content) !== JSON.stringify(value) ? true : false;
  return (
    <button
      className="reg-btn"
      disabled={!showButton()}
      onClick={(e) => {
        onClick(e);
      }}
      style={{
        marginLeft: "auto",
        opacity: showButton() ? "1" : "0",
        visibility: showButton() ? "visible" : "hidden",
      }}
    >
      Save me!
    </button>
  );
}
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~//

export function Element(props) {
  const { attributes, children, element } = props;
  switch (element.type) {
    case "paragraph":
      return (
        <Para {...attributes}>
          <span>{children}</span>
        </Para>
      );
    case "video":
      return <div>{children}</div>;
    case "quote":
      return <blockquote {...attributes}>{children}</blockquote>;
    case "code":
      return (
        <pre>
          <code {...attributes}>{children}</code>
        </pre>
      );
    case "bulleted-list":
      return <ul {...attributes}>{children}</ul>;
    case "heading-one":
      return <h1 {...attributes}>{children}</h1>;
    case "heading-two":
      return <h2 {...attributes}>{children}</h2>;
    case "heading-three":
      return <h3 {...attributes}>{children}</h3>;
    case "heading-four":
      return <h4 {...attributes}>{children}</h4>;
    case "heading-five":
      return <h5 {...attributes}>{children}</h5>;
    case "heading-six":
      return <h6 {...attributes}>{children}</h6>;
    case "list-item":
      return <li {...attributes}>{children}</li>;
    case "numbered-list":
      return <ol {...attributes}>{children}</ol>;
    case "question":
      return <QuestionBlock {...props}>{children}</QuestionBlock>;
    case "inline-question-void":
      return <QuestionVoid {...props} />;
    case "link":
      return (
        <a href={element.url} {...attributes}>
          {children}
        </a>
      );
    case "audio":
      return (
        <div {...attributes}>
          <Indic contentEditable={false}>Audio Section Started</Indic>
          <EditableMediaPlayer element={element}>
            {children}
          </EditableMediaPlayer>
          <Indic contentEditable={false}>Audio Section Ended</Indic>
        </div>
      );
    case "image":
      return <ImageElement {...props} />;
    default:
      return <span>{children}</span>;
  }
}
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~//

export function CodeElement(props) {
  return (
    <Pre {...props.attributes}>
      <code>{props.children}</code>
    </Pre>
  );
}
export function AudioSubtitle() {
  return <div></div>;
}

export const ImageElement = ({ attributes, children, element }) => {
  const selected = useSelected();
  const focused = useFocused();
  return (
    <div {...attributes}>
      {children}
      <EditorImg src={element.url} selected={selected} focussed={focused} />
    </div>
  );
};
export function QuestionBlock({ attributes, children }) {
  const questionRef = useRef(null);

  return (
    <QuestionSpan {...attributes} ref={questionRef}>
      {/* <ChromeBugfix /> */}
      {children}
      {/* <ChromeBugfix /> */}
    </QuestionSpan>
  );
}

const TabWrap = styled.div`
  width: 100%;
  border-bottom: 1px solid grey;
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 15px;
`;
const Tab = styled.div`
  position: relative;
  top: 1px;
  border-bottom: ${(props) =>
    props.active ? "2px solid var(--primary-color)" : "2px solid transparent"};
  color: ${(props) => (props.active ? "var(--primary-color)" : "black")};
  font-weight: ${(props) => (props.active ? "500" : "400")};
  padding: 5px 5px;
  margin-right: 5px;
  cursor: pointer;
`;

function QuestionVoid(props) {
  /*
    If new:
    1. we need to select a new question type,
    2.write it out,
    3. save it,
    4. and add it to db

    if old:
    1. we need to select just the right presenter -- nothing moore.
    2. What to do if it's no longer published? Do old students not seee it?
    3. How do we remove it entirely?
  */
  const { attributes, element, children, editor } = props;
  const [selectValue, setSelectValue] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalTab, setTab] = React.useState(0);
  const [qsData, setQsData] = useState(null);
  const [path, setPath] = useState(null);
  const { id } = useParams();

  const showModal = () => {
    setModalOpen(!modalOpen);
  };
  const handleOk = (e) => {
    e.preventDefault();
    setModalOpen(false);
  };
  const handleCancel = (e) => {
    e.preventDefault();
    setModalOpen(false);
  };
  function handleSelect(e, value) {
    e.preventDefault();
    setSelectValue(value);
  }
  function RenderSelection(selectValue) {
    switch (selectValue) {
      case "mcq_multiple":
        return (
          <MultipleAnswers
            questionId={element.questionId}
            mainEditor={props.editor}
            path={path}
            data={qsData}
          />
        );

      case "mcq":
        return (
          <SingleAnswerMCQ
            questionId={element.questionId}
            mainEditor={props.editor}
            path={path}
            data={qsData}
          />
        );
      case "check_referrent":
        return (
          <MultipleAnswers
            questionId={element.questionId}
            mainEditor={props.editor}
            path={path}
            data={qsData}
          />
        );
      case "boolean":
        return (
          <TrueOrFalse
            questionId={element.questionId}
            mainEditor={props.editor}
            path={path}
            data={qsData}
          />
        );
      default:
        return (
          <FindEvidence
            questionId={element.questionId}
            mainEditor={props.editor}
            path={path}
            data={qsData}
          />
        );
    }
  }
  function getPath() {
    if (editor && editor.selection && !path) {
      let newPath = [...editor.selection.anchor.path];
      newPath.pop();
      setPath(newPath);
    }
  }

  useEffect(() => {
    async function fetchQuestion() {
      let result = await getQuestion(element.questionId);

      setQsData(result);
      setSelectValue(result.questionType);
    }
    async function fetchAllBlockQuestions() {
      let result = await getBlockQuestions(id);
    }
    if (modalOpen && qsData === null && element.questionId) {
      fetchQuestion(element.questionId);
    }
    if (modalTab === 1) {
      fetchAllBlockQuestions();
    }
  }, [modalTab, modalOpen]);

  return (
    <span
      contentEditable={false}
      {...attributes}
      style={{
        padding: "2px 10px",
        cursor: "pointer",
        color: "#F50057",
      }}
    >
      <Popover
        title={"Add Question"}
        trigger="click"
        visible={modalOpen}
        placement="bottom"
        content={
          <div style={{ width: "50vw" }}>
            <TabWrap>
              <Tab
                active={modalTab === 0}
                onMouseDown={() => {
                  setTab(0);
                }}
              >
                Add Question
              </Tab>
              <Tab
                active={modalTab === 1}
                onMouseDown={() => {
                  setTab(1);
                }}
              >
                Unused Questions
              </Tab>
            </TabWrap>

            <SelectQuestion handleSelect={handleSelect} />
            {RenderSelection(selectValue)}
            {children}
          </div>
        }
      >
        <QuestionCircleOutlined
          style={{ color: "#F50057" }}
          onMouseDown={(e) => {
            getPath();
            e.preventDefault();
            setModalOpen(true);
            showModal();
          }}
        />
      </Popover>

      {children}
    </span>
  );
}
function SelectQuestion({ handleSelect }) {
  return (
    <InputWrapper style={{ width: "100%", display: "inline-block" }}>
      <Label>Select Question Type</Label>
      <Select
        style={{ width: "100%", display: "inline-block" }}
        onChange={(e) => {
          handleSelect(e, e.target.value);
        }}
      >
        <option value="mcq">MCQ: One Correct Answer</option>
        <option value="mcq_multiple">MCQ: Multiple Correct Answers</option>
        <option value="boolean">T/F: One Right Answer</option>
        <option value="cite_evidence">Cite: One Word/Sentence</option>
        <option value="cite_referrent">Cite: One Referrent</option>
        <option value="cite_multipart_map">Cite: Multipart Map</option>
        <option value="etymology">Etymology</option>
      </Select>
    </InputWrapper>
  );
}

function TextReplacer() {
  return (
    <span contentEditable={false}>
      <input type="text" />
    </span>
  );
}
