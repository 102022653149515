import React, { useRef, useState, useEffect } from "react";
import { ReactEditor, useSlate, useFocused, useSelected } from "slate-react";
import { Editor, Range } from "slate";
import ReactDOM from "react-dom";
import { Menu, HoverIcons } from "./toolbarMenu.js";
// THIS HAS LOGIC FOR THE HOVER TOOLBAR.
//~~~~~~~~~~~~~~~~~~~~~~~~~TOOLBAR HOVER~~~~~~~~~~~~~~~~~~~~~~~~~~~~~//
export function HoveringToolbar() {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  const el = ref.current;
  const editor = useSlate();
  const isFocused = useFocused();

  function positionToolbar(el) {
    const domSelection = window.getSelection();
    const domRange = domSelection.getRangeAt(0);
    const rect = domRange.getBoundingClientRect();
    //~~ Get position~~//
    let topPos = rect.top + window.pageYOffset - el.offsetHeight - 10;
    let leftPos =
      rect.left + window.pageXOffset - el.offsetWidth / 2 + rect.width / 2;
    //~~ Apply limits~~//
    let leftLimit = 125;
    let rightLimit = 930;
    leftPos =
      leftPos < leftLimit
        ? leftLimit
        : leftPos > rightLimit
        ? rightLimit
        : leftPos;
    //~~ Apply styles~~//
    el.style.opacity = "1";
    el.style.top = `${topPos}px`;
    el.style.left = `${leftPos}px`;
  }
  function removeToolbar(el) {
    el.removeAttribute("style");
  }
  //~~~~~~~~~~~~~~~~~//

  useEffect(() => {
    const { selection } = editor;
    //~~~~~~~~~~~//
    if (!el) return;
    //~~~~~~~~~~~//
    let closeToolbar =
      !selection || //happens on page load
      !ReactEditor.isFocused(editor) || //self-evident
      Range.isCollapsed(selection) ||
      Editor.string(editor, selection) === "";

    if (closeToolbar) {
      removeToolbar(el);

      return;
    }
    //~~~~~~~~~~~//
    if (typeof window !== "undefined") {
      positionToolbar(el);
    }
  });

  return (
    <Portal>
      <Menu forwardRef={ref}>
        <HoverIcons />
      </Menu>
    </Portal>
  );
}
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~//
/* SUPER IMPORTANT SHIT */
function Portal({ children }) {
  return typeof document !== "undefined" &&
    typeof document.querySelector("#portal_container")
    ? ReactDOM.createPortal(
        children,
        document.querySelector("#portal_container")
      )
    : null;
}
