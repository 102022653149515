import React from "react";
import { css } from "styled-components";
import { Transforms, Editor } from "slate";
import { jsx } from "slate-hyperscript";
import isUrl from "is-url";

import { handleInline } from "../helperFunctions/index";

const ELEMENT_TAGS = {
  A: (el) => ({ type: "link", url: el.getAttribute("href") }),
  BLOCKQUOTE: () => ({ type: "quote" }),
  H1: () => ({ type: "heading-one" }),
  H2: () => ({ type: "heading-two" }),
  H3: () => ({ type: "heading-three" }),
  H4: () => ({ type: "heading-four" }),
  H5: () => ({ type: "heading-five" }),
  H6: () => ({ type: "heading-six" }),
  IMG: (el) => ({ type: "image", url: el.getAttribute("src") }),
  LI: () => ({ type: "list-item" }),
  OL: () => ({ type: "numbered-list" }),
  P: () => ({ type: "paragraph" }),
  PRE: () => ({ type: "code" }),
  UL: () => ({ type: "bulleted-list" }),
};
// COMPAT: `B` is omitted here because Google Docs uses `<b>` in weird ways.
const TEXT_TAGS = {
  CODE: () => ({ code: true }),
  DEL: () => ({ strikethrough: true }),
  EM: () => ({ italic: true }),
  I: () => ({ italic: true }),
  S: () => ({ strikethrough: true }),
  STRONG: () => ({ bold: true }),
  U: () => ({ underline: true }),
};
//~~~~~~~~~~~~~~~~~~~~~~~~~ HTML ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~//
const deserialize = (el) => {
  if (el.nodeType === 3) {
    return el.textContent;
  } else if (el.nodeType !== 1) {
    return null;
  } else if (el.nodeName === "BR") {
    return "\n";
  }
  const { nodeName } = el;
  let parent = el;
  if (
    nodeName === "PRE" &&
    el.childNodes[0] &&
    el.childNodes[0].nodeName === "CODE"
  ) {
    parent = el.childNodes[0];
  }
  let children = Array.from(parent.childNodes).map(deserialize).flat();
  if (children.length === 0) {
    children = [{ text: "" }];
  }
  if (el.nodeName === "BODY") {
    return jsx("fragment", {}, children);
  }
  if (ELEMENT_TAGS[nodeName]) {
    const attrs = ELEMENT_TAGS[nodeName](el);
    return jsx("element", attrs, children);
  }
  if (TEXT_TAGS[nodeName]) {
    const attrs = TEXT_TAGS[nodeName](el);
    return children.map((child) => jsx("text", attrs, child));
  }
  return children;
};
export const withHtml = (editor) => {
  const { insertData, isInline, isVoid } = editor;
  editor.isInline = (element) => element.type === "link" || isInline(element);
  editor.isVoid = (element) => {
    return element.type === "image" ? true : isVoid(element);
  };
  editor.insertData = (data) => {
    const html = data.getData("text/html");
    if (html) {
      const parsed = new DOMParser().parseFromString(html, "text/html");
      const fragment = deserialize(parsed.body);
      Transforms.insertFragment(editor, fragment);
      return;
    }
    insertData(data);
  };
  return editor;
};
//~~~~~~~~~~~~~~~~~~~~~~~~~IN LINE~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~//
export function withInlines(editor) {
  const { insertData, insertText, isInline } = editor;

  editor.isInline = (element) =>
    ["link", "button", "question", "inline-question-void"].includes(
      element.type
    ) || isInline(element);

  editor.insertText = (text) => {
    if (text && isUrl(text)) {
      handleInline.wrap(editor, text, "link");
    } else {
      insertText(text);
    }
  };
  editor.insertData = (data) => {
    const text = data.getData("text/plain");
    if (text && isUrl(text)) {
      handleInline.wrap(editor, text, "link");
    } else {
      insertData(data);
    }
  };
  return editor;
}

export function ChromeBugfix() {
  return (
    <span
      contentEditable={false}
      className={css`
        font-size: 0;
      `}
    >
      ${String.fromCodePoint(160) /* Non-breaking space */}
    </span>
  );
}
//~~~~~~~~~~~~~~~~~~~~~~~~~ VOID ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~//

export const withEditableVoids = (editor, fragmentIndex) => {
  const { isVoid } = editor;
  editor.isVoid = (element) => {
    switch (element.type) {
      case "inline-question-void":
        return true;
      case "replace-input":
        return true;
      // case "audio":
      //   return true;
      default:
        return isVoid(element);
    }
  };
  editor.blockFragmentData = fragmentIndex;
  return editor;
};
