module.exports = {
  manageEntry: function (object) {
    let { name, label, placeholder, options, checked, disabled } = object;
    switch (object.type) {
      case "Input":
        return { name, label, placeholder, disabled };
      case "Select":
        return { name, label, options, disabled };
      case "Select_Multiple":
        return { name, label, options, disabled };
      case "Switch":
        return { name, label, disabled, checked };

      default:
        throw new Error({ error: "Unexpected input type object received." });
    }
  },
  createCourse: [
    {
      name: "title",
      label: "Title",
      placeholder: "e.g. MCAT",
      type: "Input",
      rules: [
        {
          required: true,
          message: "A course must have a title!",
        },
      ],
    },
    {
      name: "description",
      label: "Description",
      placeholder: "e.g. Medical College Administration Test",
      type: "Input",
    },
    {
      name: "category",
      label: "Category",
      type: "Select",
      options: ["SERVICE", "COURSE"],
      rules: [
        {
          required: true,
          message: "Which one is it?",
        },
      ],
    },
    {
      name: "hierarchy",
      label: "Course Segmentation/Hierarchy",
      type: "Select_Multiple",
      options: ["SUBJECT", "CHAPTER", "UNIT", "CONCEPT"],
      default: ["SUBJECT", "CHAPTER", "UNIT", "CONCEPT"],
      rules: [
        {
          required: true,
          message: "You need to segment your product",
          type: "array",
        },
      ],
    },
    {
      name: "isOffered",
      label: "Offered right now?",
      type: "Switch",
      disabled: true,
      checked: false,
    },
    {
      name: "isPublished",
      label: "Is it a draft, or is it published?",
      type: "Switch",
      disabled: true,
      checked: false,
    },
    {
      name: "courseType",
      label: "Where is it offered?",
      placeholder: "e.g. online",
      type: "Select",
      options: ["IN_PERSON", "ONLINE"],
      rules: [
        {
          required: true,
          message: "Your product can't be offered in thin air -_-",
        },
      ],
    },
  ],
  createChild: [
    {
      name: "title",
      label: "Title",
      placeholder: "_e.g._ CARS-1",
      type: "Input",
      rules: [
        {
          required: true,
          message: "A module must have a title!",
        },
      ],
    },
    {
      name: "description",
      label: "Description",
      placeholder:
        "_e.g._ 'Teaches you reasoning, that's necessary to think critically'",
      type: "Input",
    },

    {
      name: "isGated",
      label: "Is this currently Gated?",
      type: "Switch",
    },
    {
      name: "gateType",
      label: "How will it be gated?",
      placeholder: "e.g. online",
      type: "Select",
      options: [
        {
          value: "FUNDAMENTALS",
          title: "Fundamental Concepts -- always open ",
        },
        {
          value: "SKILLS",
          title: "Skills -- Open on failing skill _e.g._ reading",
        },
        {
          value: "INTRO",
          title: "Introductory -- Always open e.g. 'welcome to X'",
        },
        { value: "MASTERY_GATED", title: "Locks post-completion" },
        { value: "COMPLETION_GATED", title: "Stays open post-completion" },
        {
          value: "CONDITIONAL_GATED",
          title: "Opens when some condition is met",
          disabled: true,
        },
        {
          value: "CUSTOM",
          title: "Custom opening -- no one knows what this means",
        },
      ],
      rules: [
        {
          required: true,
          message: "What gate is it?",
        },
      ],
    },
  ],
};
