import { useEffect, useState, useRef, useCallback } from "react";

import { GET, POST, DELETE, PUT } from "../../helpers/REST/api";
import { Box } from "../layout/containers/box";
import { TextField } from "../layout/containers/input";
import { ArrowBtn, Button, AddBtn, DeleteBtn, ExpandBtn, OptionsBtn } from "../layout/buttons/buttons";
import { useMedia, GetMedias } from "../media";
import { RounderBtn } from "../layout/buttons/buttonStyles";
import { ModalElement } from "../../helpers/hooks/useModal";
import { TrackWrap } from "../editor2/renders/multiMediaPlayer/styled_video";
import MediaPlayer from "../editor2/renders/multiMediaPlayer/MediaPlayer";

import { toast } from "react-toastify";
import { Audio } from "../../images/icons";
import { PlayBtn } from "../layout/buttons/buttons";
import { TrackNumber, TrackIcon } from "./common";

/**
 ** SHOWS && EDITS PLAYLISTS*
 * @feature Shows order of tracks (audio & video media);
 * @feature shows questions.
 * @feature Can change media order in tracks.
 * @param  {Object} props.playlistData Pre-fetched data: has name, description, track, questions.
 * @param  {String} id  Assumes no playlistData is given: will fetch the given id.
 * @param {function} onSave Callback to parent--> //TODO: Not yet implemented
 * @param {function} onChange callback to parent--> //TODO: Not yet implemented
 */
const PlaylistView = ({ playlistData, id, onSave, onChange, pli, activePlaylist, onMouseDown, updatePlaylists }) => {
  const [playList, setPlaylist] = useState(null); // {_id, fileName, mediaType, }; All Arrays.

  useEffect(() => {
    if (id && !playlistData) {
      GET(`/mediaTracks/m/${id}`).then((data) => {
        setPlaylist(data);
      });
    }
    if (playlistData !== null || playlistData !== undefined) {
      setPlaylist(playlistData);
      // updatePlaylists(playlistData, pli);
    }
  }, [id, playlistData]);

  useEffect(() => {
    if (!id && !playlistData && typeof onSave === "function") {
      if (playList) onSave(playList);
    }
  }, [playList]);

  const createPlaylist = async () => {
    if (!id && !playlistData && playList?.title) {
      await POST("/mediaTracks/", { data: playList }).then(({ data }) => {
        setPlaylist(data);
      });
    }
  };
  const handleTitleChange = (v) => {
    console.log(v);
    let copy = { ...playList };
    copy.title = v;
    setPlaylist(copy);
  };

  const calcTotalDuration = () => {
    let timeInSeconds = 0;
    playList?.track.forEach((t) => {
      if (t.duration) timeInSeconds += t.duration;
    });

    let min = Math.floor(timeInSeconds / 60);
    let sec = timeInSeconds % 60;

    if (!min) return `00:${sec < 11 ? `0${sec}` : sec}`;
    return `${min < 11 ? `0${min}` : `${min}`}:${sec < 11 ? `0${sec}` : sec}`;

    return `${min} Minutes & ${timeInSeconds % 60} Seconds`;
  };

  if (!id && !playlistData) {
    return (
      <>
        <TextField label="Playlist Name" name="title" isDecorated={true} onChange={handleTitleChange} />
        <RounderBtn
          onClick={() => {
            createPlaylist();
          }}
        >
          Save
        </RounderBtn>
      </>
    );
  }
  if (!playList) {
    return null;
  }
  return (
    <div style={{ width: "100%" }}>
      <PlaylistDetails
        playlist={playList}
        duration={calcTotalDuration()}
        isActive={pli === activePlaylist}
        onMouseDown={() => {
          onMouseDown(pli);
        }}
        pli={pli}
      >
        {pli !== activePlaylist ? null : <>{playList?.track && playList.track.length && <MediaPlayer playlist={playList} />}</>}
      </PlaylistDetails>
    </div>
  );
};

export default PlaylistView;

const PlaylistDetails = ({ playlist, duration, isActive, onMouseDown, pli, ...rest }) => {
  let date = new Date(playlist.updatedAt);
  const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  let day = days[date.getDay()].substring(0, 3);
  let month = months[date.getMonth()].substring(0, 3);
  let yr = date.getFullYear().toString().substring(2);
  let lastUpdated = `${day}. ${date.getDate()} ${month}. '${yr}`;
  return (
    <>
      <Box
        type="row"
        className={isActive ? "hover_primary" : "hover_green"}
        style={{ flexFlow: "row nowrap" }}
        width="100%"
        background={isActive ? "var(--primary-color)" : "none"}
        padding="0px 0.5em"
        justify-content="space-between"
        alignItems="center"
        onMouseDown={onMouseDown}
      >
        <TrackNumber pli={pli + 1} />

        <div className="track_wrap">
          <TrackIcon className={`track_icon ${isActive && "track_icon_active"}`}>
            <Audio className="bg" />
          </TrackIcon>
          <div className="track_info">
            <div className="track_title">{playlist?.title}</div>
            <div className="track_metadata">
              <span>Last Updated: {lastUpdated} </span>
            </div>
          </div>
          <div className="flex-row justify track_metrics">
            <span>{duration} </span>
            <span style={{ margin: "0 5px" }}>|</span>
            <span>{playlist.track.length + " Track(s)"}</span>
            <span style={{ margin: "0 5px" }}>| </span>
            <span> {playlist.questions.length + " Qs"}</span>
          </div>
        </div>
        <OptionsBtn stroke="white" bg={"var(--tertiary-color-darker)"} color="white">
          <div className="flex-row  justify">
            <ExpandBtn
              // disabled={activeTrack === null}
              tooltip={"See questions & tracks"}
              onMouseDown={() => {
                // deletePlaylistTrack();
              }}
            />
            <span>More on Playlist</span>
          </div>
          <div className="flex-row justify">
            <AddBtn
              tooltip="Add track to playlist"
              // onClick={() => {
              //   setShowCreate(true);
              // }}
            />
            <span>Add Track</span>
          </div>

          <div className="flex-row justify">
            <DeleteBtn
              // disabled={activeTrack === null}
              tooltip={"Delete track ... not playlist"}
              // onMouseDown={() => {
              //   deletePlaylistTrack();
              // }}
            />
            <span>Delete Track</span>
          </div>
        </OptionsBtn>
      </Box>
      {rest.children}
    </>
  );
};
