import React, { useState, useEffect } from "react";
import { Box } from "../containers/box";
import styled from "styled-components";
import { animated, useSpring, config, useSpringRef, useChain } from "react-spring";

const CheckInput = styled.input`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;
const Svg = styled(animated.svg)`
  display: block;
  height: 20px;
  width: 20px;
  background: #fff;
  border: 2px solid;
  margin: auto 10px auto 0px;

  background: ${(props) => (props.active ? "--var(primary)" : "transparent")};
`;

export function Checkbox({ onChange, checked, name, label, children, defaultChecked, disabled, ...rest }) {
  const [isChecked, setIsChecked] = useState(defaultChecked || checked || false);
  const checkboxAnimationRef = useSpringRef();
  const checkboxAnimationStyle = useSpring({
    backgroundColor: isChecked ? "#f50057" : "#fff",
    borderColor: isChecked ? "#f50057" : "#ddd",
    config: config.gentle,
    ref: checkboxAnimationRef,
  });

  useEffect(() => {
    if (checked !== undefined && checked !== isChecked) setIsChecked(checked);
  }, [checked]);

  function handleChange(e) {
    setIsChecked(e.target.checked);
    if (typeof onChange === "function") onChange(e.target.checked);
  }

  const [checkmarkLength, setCheckmarkLength] = useState(null);
  const checkmarkAnimationRef = useSpringRef();
  const checkmarkAnimationStyle = useSpring({
    x: isChecked ? 0 : checkmarkLength,
    config: config.gentle,
    ref: checkmarkAnimationRef,
  });

  useChain(isChecked ? [checkboxAnimationRef, checkmarkAnimationRef] : [checkmarkAnimationRef, checkboxAnimationRef], [0, 0.1]);

  return (
    <Box type="row nowrap" padding="0" background="none" bRadius="0px" width="auto" alignItems="center" {...rest}>
      <label style={{ color: "inherit" }}>
        <CheckInput type="checkbox" onChange={handleChange} defaultChecked={defaultChecked} disabled={!!disabled} />
        <Svg
          style={checkboxAnimationStyle}
          // This element is purely decorative so
          // we hide it for screen readers
          aria-hidden="true"
          viewBox="0 0 15 11"
          fill="none"
        >
          <animated.path
            d="M1 4.5L5 9L14 1"
            strokeWidth="2"
            stroke="#fff"
            ref={(ref) => {
              if (ref) {
                setCheckmarkLength(ref.getTotalLength());
              }
            }}
            strokeDasharray={checkmarkLength}
            strokeDashoffset={checkmarkAnimationStyle.x}
          />
        </Svg>
      </label>

      <span style={{ whiteSpace: "nowrap", width: "min-content", marginLeft: "1em", display: "inline-block" }}>{children}</span>
    </Box>
  );
}

let radio;
let toggle;
let dropdown;
