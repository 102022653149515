import { useEffect, useState } from "react";
import { Box } from "../../layout/containers/box";
import { Link } from "react-router-dom";
import { AddBtn, Button } from "../../layout/buttons/buttons";
import { RounderBtn } from "../../layout/buttons/buttonStyles";
import { User } from "../../../images/icons";
import { GET_UserDocs } from "../../../helpers/REST/api";

const Documents = () => {
  const [docs, setDocs] = useState([]);
  useEffect(() => {
    GET_UserDocs().then((d) => setDocs(d));
  }, []);

  return (
    <div>
      <Box
        type="1 row centered"
        justifyContent="space-between"
        background="none"
      >
        <h2>Le Docs</h2>
        <Link to="/documents/new">
          <Button>New Document</Button>
        </Link>
      </Box>
      {docs &&
        docs.map((doc, docIndex) => {
          return (
            <Box
              key={"doc" + doc.title + docIndex}
              type="1 row centered"
              justifyContent="space-between"
              marginBottom="0 0 1em 0"
            >
              <UserGroup doc={doc} />
              <Box
                key={"doc" + doc.title + docIndex}
                type="col left"
                justifyContent="flex-start"
                background="none"
              >
                <span style={{ marginRight: "2em" }}>{doc.title}</span>
              </Box>
              <Box
                type="1 row centered"
                width="min-content"
                margin="0"
                padding="0"
                background="none"
              >
                <Link to={`/lessons/${doc._id}`}>
                  <RounderBtn>Learn</RounderBtn>
                </Link>
                <Link to={`/documents/${doc._id}`}>
                  <RounderBtn>Edit</RounderBtn>
                </Link>
                <AddBtn />
              </Box>
            </Box>
          );
        })}
    </div>
  );
};

const UserGroup = ({ doc }) => {
  function getUsername(doc) {
    const uid = localStorage.getItem("uid");
    if (doc?.owner === uid) return "Owner";
    if (doc?.editors?.indexOf(uid) > -1) return "Editor";
    if (doc?.viewers?.indexOf(uid) > -1) return "Viewer";
  }
  return (
    <Box
      type="column"
      justifyContent="space-between"
      padding="0"
      width="min-content"
      background="none"
      style={{ textAlign: "center" }}
    >
      <User />
      <small
        style={{
          color: "#555",
        }}
      >
        {getUsername(doc)}
      </small>
    </Box>
  );
};

export default Documents;
