import { useState, useCallback, useEffect } from "react";
import { DndWrap, DndRowWrap, ListItem } from "../../dragDrop";

import { Draggable } from "react-beautiful-dnd";
import { DropdownWrap, IconBorder, RowIconWrap, DndRowDragOrBackIcon, RowLabel, BackIcon } from "../products/styled";
import { getCourses, GET, PUT } from "../../../helpers/REST/api";
import { Checkbox } from "../../layout/selection/checkbox";
import { Box, Collapse } from "../../layout/containers/box";
import { Tag } from "antd";
import { Button } from "../../layout/buttons/buttons";
import { RounderBtn } from "../../layout/buttons/buttonStyles";
import { CloseBtn } from "../../layout/buttons/buttons";
import { Timeline } from "../../layout/containers/box";
import Tags from "../../layout/tags";

export const DragWrap = ({ data, setData, mid, pid }) => {
  const [activeIndex, setActive] = useState(null);
  function toggleSelect(index) {
    activeIndex === null || index !== activeIndex ? setActive(index) : setActive(null);
  }
  return (
    <DndWrap
      droppableId={`${data._id}_Droppable`}
      grid={8}
      width={500}
      data={data}
      setData={(v) => {
        // console.log(v);
        setData(v);
      }}
      setActive={setActive}
      activeIndex={activeIndex}
    >
      {data &&
        Array.isArray(data) &&
        data.map((item, itemIndex) => {
          let isSelected = itemIndex === activeIndex;
          let isHidden = activeIndex !== null && itemIndex !== activeIndex;
          function activate(e) {
            isSelected ? setActive(null) : setActive(itemIndex);
          }
          function dndRow_mouseDown(e) {
            e.stopPropagation();
            if (!isSelected) activate(e);
          }

          return (
            <Gate
              gate={item}
              gateIndex={itemIndex}
              mid={mid}
              pid={pid}
              key={item._id}
              toggleSelect={toggleSelect}
              activeIndex={activeIndex}
              isSelected={activeIndex === itemIndex}
              isHidden={isHidden}
              reorderData={(newParent) => {
                let newData = [...data];
                newData[itemIndex] = newParent;
                // console.log("In drag wrap", newData);
                setData(newData);
              }}
            />
          );
        })}
    </DndWrap>
  );
};

let Gate = ({ gate, gateIndex, mid, pid, activeIndex, toggleSelect, reorderData, isSelected, isHidden }) => {
  const [data, setData] = useState(null); // Fetched gate data.
  const [isDraggable, setIsDraggable] = useState(true);
  const [activeChildIndex, setActiveChild] = useState(null); // Whichever child isActive.
  const { layerType, layer, childType, _id, children } = gate;

  function reorderChild(newItemChildren, parent, item, itemIndex) {
    // console.log(parent, itemIndex, item, newItemChildren);
    let newParent = { ...parent };
    newParent.children[itemIndex] = newItemChildren;
    reorderData(newParent);
  }

  useEffect(() => {
    let q = `mid=${mid}&pid=${pid}&layerId=${_id}`;
    GET(`/members/layerGate/${layerType}?${q}`).then((r) => {
      if (r.isCompleted) setIsDraggable(false);
      setData(r);
    });
  }, []);

  function toggleChildSelect(index) {
    activeChildIndex === null || index !== activeChildIndex ? setActiveChild(index) : setActiveChild(null);
  }

  const GateChildren = useCallback(
    ({ parent, index, activeChildIndex, toggleSelect }) => (
      <DndWrap
        droppableId={`${"studyPlan"}_Droppable`}
        grid={8}
        width={500}
        data={parent.children}
        setData={(v) => {
          reorderData({ ...gate, children: v });
        }}
        setActive={setActiveChild}
        activeIndex={activeChildIndex}
        style={{ minHeight: "auto" }}
      >
        {parent?.children &&
          parent.children.map((item, itemIndex) => {
            if (parent?.childType === "document") {
              return (
                <MemberLecture
                  productLecture={{ ...item, p_cid: _id }}
                  index={itemIndex}
                  mid={mid}
                  pid={pid}
                  key={item._id}
                  isSelected={activeChildIndex === itemIndex}
                  toggleSelect={() => {
                    setActiveChild(itemIndex);
                  }}
                  parentSelected={isSelected === true}
                  reorderData={(v) => {
                    //!Not used so far.
                    // console.log("member", v);
                    reorderChild(v, item, itemIndex);
                  }}
                  isHidden={activeChildIndex !== null && itemIndex !== activeChildIndex}
                  isLast={itemIndex === parent.children.length - 1}
                />
              );
            }
            return (
              <Gate
                gate={{ ...item, parentLayerId: _id }}
                gateIndex={itemIndex}
                mid={mid}
                pid={pid}
                key={item._id}
                isSelected={activeChildIndex === itemIndex}
                toggleSelect={() => toggleChildSelect(index)}
                reorderData={(v) => {
                  reorderChild(v, parent, item, itemIndex);
                }}
                isHidden={activeChildIndex !== null && itemIndex !== activeChildIndex}
              />
            );
          })}
      </DndWrap>
    ),
    [gate, gate?.children, data, activeChildIndex, isSelected]
  );

  const handleGatedness = (value) => {
    if (typeof value === "boolean" && data !== null)
      PUT(`/members/id/${mid}/gates/${data._id}/isGated`, { isGated: value, __v: data.__v }).then(() => {
        setData({ ...data, isGated: value });
      });
  };
  const handleActiveness = (value) => {
    if (typeof value === "boolean" && data !== null)
      PUT(`/members/id/${mid}/gates/${data._id}/isActive`, { isActive: value, __v: data.__v }).then(() => {
        setData({ ...data, isActive: value });
      });
  };
  const handleCompletion = (value) => {
    if (typeof value === "boolean" && data !== null)
      PUT(`/members/id/${mid}/gates/${data._id}/isCompleted`, { isCompleted: value, __v: data.__v }).then(() => {
        setData({ ...data, isCompleted: value });
      });
  };
  if (data === null) return null;
  const InfoAtAGlance = () => (
    <div>
      <Tag color={data?.attemptNumber > 1 ? "red" : "green"} style={{ width: "min-content" }}>
        Attempt: {data?.attemptNumber}
      </Tag>
      {data?.timeline?.length > 0 && !data?.isGated && (
        <>
          <Tag color="green" style={{ width: "min-content", margin: "0 1em" }}>
            Status: Started
          </Tag>
          <Tag color="yellow" style={{ width: "min-content", margin: "0 1em" }}>
            Progress: {data?.progress}%
          </Tag>
        </>
      )}

      {!data?.timeline?.length > 0 && !data?.isGated && (
        <Tag color="green" style={{ width: "min-content", margin: "0 1em" }}>
          Status: Set deadline
        </Tag>
      )}
      {!data?.timeline?.length > 0 && data?.isGated && (
        <Tag color="green" style={{ width: "min-content", margin: "0 1em" }}>
          Status: Not started
        </Tag>
      )}
    </div>
  );

  return (
    <Draggable draggableId={_id} index={gateIndex} key={_id} isDragDisabled={data?.isCompleted || !!isSelected}>
      {(provided, { isDragging }) => (
        // style={{ display: activeIndex !== null && !isSelected ? "none" : "block" }}
        <div style={{ margin: "0.5em 0", display: isHidden ? "none" : "block" }}>
          <div
            style={{
              ...provided.draggableProps.style,
            }}
            {...provided.dragHandleProps}
            {...provided.draggableProps}
            ref={provided.innerRef}
          >
            <div>
              <Box type="col left" background="#666" style={{ padding: "16px", opacity: data?.isCompleted ? "0.7" : "1" }}>
                <div className="flex-row justify">
                  <div>{data.title}</div>
                  <CloseBtn
                    type={1}
                    width={"26"}
                    isOpen={isSelected}
                    onMouseDown={() => {
                      toggleSelect(gateIndex);
                    }}
                  />
                </div>
                <InfoAtAGlance />
              </Box>
            </div>
          </div>
          <Collapse
            showTitle={false}
            isOpen={!!isSelected}
            onMouseDown={(e) => {
              if (!isSelected) toggleSelect(gateIndex);
              e.stopPropagation();
            }}
            style={{ padding: isDragging ? "1em 0.25em" : "0 0.25em" }}
          >
            <div className="flex-row">
              <Checkbox style={{ marginRight: "1em" }} defaultChecked={data.isActive} label={"isActive"} onChange={handleActiveness}>
                Active
              </Checkbox>
              <Checkbox style={{ margin: "1em" }} defaultChecked={data.isGated} label={"isGated"} onChange={handleGatedness}>
                Locked
              </Checkbox>
              <Checkbox style={{ margin: "1em" }} defaultChecked={data.isCompleted} label={"isActive"} onChange={handleCompletion}>
                Completed
              </Checkbox>
            </div>

            <GateChildren parent={gate} index={gateIndex} toggleSelect={setActiveChild} activeChildIndex={activeChildIndex} />
          </Collapse>
          {provided.placeholder}
        </div>
      )}
    </Draggable>
  );
};

let MemberLecture = ({ productLecture, mid, pid, index, isSelected, toggleSelect, parentSelected, isLast }) => {
  const { _id, p_cid } = productLecture;
  const [memberLecture, setLecture] = useState(null);
  useEffect(() => {
    if (memberLecture === null && parentSelected) {
      let q = `pid=${pid}&p_cid=${p_cid}`;
      GET(`/members/id/${mid}/lectureByDocId/${_id}?${q}`).then((r) => {
        setLecture(r);
      });
    }
  }, [parentSelected]);

  if (memberLecture === null) return null;
  return (
    <div>
      <Draggable draggableId={_id} index={index} key={_id} isDragDisabled={memberLecture?.isCompleted}>
        {(provided, { isDragging }) => (
          <div
            style={{
              ...provided.draggableProps.style,
            }}
            {...provided.dragHandleProps}
            {...provided.draggableProps}
            ref={provided.innerRef}
          >
            <div style={{ margin: "0.5em 0", borderTop: "1px dotted #222", borderBottom: "1px dotted #222", boxSizing: "border-box" }}>
              <Timeline isLast={isLast} width="100%" isComplete={memberLecture.isComplete} progress={memberLecture.progress}>
                <div style={{ width: "min-content", whiteSpace: "nowrap", marginRight: "4em" }}>{memberLecture?.title}</div>
                <Tag color={"green"}>Attempts: </Tag>
                <RounderBtn>Reset</RounderBtn>
                <RounderBtn>Update progress</RounderBtn>
                <RounderBtn>Questions</RounderBtn>
              </Timeline>
            </div>

            {provided.placeholder}
          </div>
        )}
      </Draggable>
    </div>
  );
};
