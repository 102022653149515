import { useEffect, useState, useRef } from "react";
import { Box } from "../layout/containers/box";
import JSONElementsToHTML from "./elementRender";
import { States } from "./context";

import ElementInteraction, { NextBlockTrigger } from "./interactions";

//? displayFirstBlock; done
//? triggerBlockCompletion done
//? blockCompleteNextStep
//? addNextBlock done
//? failedBlock not done
//? EvalBreakpoint kinda done
//? async function registerBlockCompletion() {} kinda done

/**
 * @param  {Array} props.data Supposedly this is a list of all blocks to be rendered.

 */
function Lecture(props) {
  const [data, setData] = useState(Array.isArray(props?.data) && props?.data?.length > 0 ? props?.data : []); // All blocks
  const [blocks, setblocks] = useState(Array.isArray(props?.data) && props?.data?.length > 0 ? [props?.data[0]] : []); // Subset of data currently rendered.
  const blockRefs = useRef([]);
  //NOTE: Last lecture div is assumed to be the current one.

  async function fetchNextBlock() {
    //Insert Axios call

    //placeholder
    let nextBlockIndex = blocks.length === 0 ? 0 : blocks.length;
    let nextBlock = data[nextBlockIndex];
    if (nextBlock !== undefined) {
      setblocks([...blocks, nextBlock]);
    }
  }

  //First render: TODO: manage new page load && page refresh;
  useEffect(() => {
    let areBlocksRendered = blockRefs?.current?.length < 0;
    let isBlockDataSaved = blocks?.length > 0;
    let wasRefereshed = !areBlocksRendered && isBlockDataSaved;
    let newLoad = !areBlocksRendered && !isBlockDataSaved;
    if (wasRefereshed) {
      //Do something
    } else if (newLoad) {
      fetchNextBlock();
    }
  }, []);

  // *DEVELOPMENT: Do this every render.
  useEffect(() => {
    if (props.data === undefined || !Array.isArray(props.data)) {
      window.addEventListener("storage", () => {
        let result = JSON.parse(localStorage.getItem("editor"));
        setData(result);
      });
    }
  });

  //Scrappy way of inserting first block.
  useEffect(() => {
    //If data updates, update the blocks
    setblocks([data[0]]);
  }, [data]);

  return (
    <States>
      <Box type="col left" background="none" margin="0 0 auto 0" padding="0 0 5em 0" minHeight="100vh">
        {blocks &&
          blocks.map((b, i) =>
            b === undefined ? null : (
              <Box type="col left" background="none" margin="1em 0" padding="0" key={i + "block"}>
                <h2>{props?.metaData ? props.metaData[i]?.title : null}</h2>
                <JSONElementsToHTML
                  key={i + "block"}
                  block={b} // e.g. this might be a para, that has text elements--> we're passing this content to "JSONElementsToHTML"
                  blockIndex={i} // i.e. current index;
                  isCurrentBlock={i === blocks.length - 1} // i.e. current index is the last block;
                  nextStepTrigger={fetchNextBlock}
                />
              </Box>
            )
          )}

        <div>
          <ElementInteraction />
          <NextBlockTrigger trigger={fetchNextBlock} data={data} blocks={blocks} />
          {/* <QuestionView_Editor /> */}
          {/* Last_block Completion Trigger--ADD LATER*/}
          {/* Lecture Finished Trigger--ADD LATER*/}
          {/* QuizView: can be overlay or component--ADD LATER*/}
        </div>
      </Box>
    </States>
  );
}

export default Lecture;
