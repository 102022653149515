/**
 * @param  {String} dateString
 * @param {String} format e.g. "y/m/d/ H/M/S" or ymdHMS (order doesn't matter; Separators don't matter)
 * @param  {Object} opts
 * Default Options:
 * * year: numeric
 * * month: long (short)
 * * day: numeric
 * * hour: numeric
 * * minute: numeric
 * !!Note: Under format, "hours", "minutes", and "seconds" are represented by CAPS
 */
let options = {
  year: "numeric",
  month: "long",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
};
export default function dateFormatter(dateString, format, opts) {
  const date = new Date(dateString);

  if (typeof opts === "object" && opts !== null) options = { ...options, ...opts };
  if (typeof format === "string") {
    if (!format.includes("y")) delete options.year;
    if (!format.includes("m")) delete options.month;
    if (!format.includes("d")) delete options.day;
    if (!format.includes("H")) delete options.hour;
    if (!format.includes("M")) delete options.minute;
    if (!format.includes("S")) delete options.second;
  }

  const formattedDate = date.toLocaleDateString(undefined, options);
  return formattedDate;
}
export function dateDifference(earlierDate, laterDate) {
  let diffTime = new Date(laterDate) - new Date(earlierDate);
  let inDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return inDays;
}
export function addDaysToDate(date, days) {
  let formattedDate = new Date(date); //converts to full text
  let result = formattedDate.setDate(formattedDate.getDate() + days); //returns in seconds;
  result = new Date(result).toLocaleDateString(undefined, options);

  return result;
}
