import React, { useRef, useEffect, useContext } from "react";
import { MediaStateProvider, MediaContext } from "./context";
import VideoControls from "./controls/videoControls";
import { AudioControls } from "./controls/audioControls";
import Trackbar from "./trackbar";

import { VidWrap, MediaWrap } from "./styled_video";

export default function MediaElement({ tracks }) {
  const {
    mediaRef,
    currentTrack,
    isMute,
    isFullScreen,
    handleKeyDown,
    handleKeyUp,
    setIsPlaying,
    isPlaying,
    togglePlay,
  } = useContext(MediaContext);
  const fullScreenWrapRef = useRef();

  const RenderTrackbar = React.useCallback(
    () => <Trackbar tracks={tracks} />,
    [tracks]
  );
  const RenderControls = React.useCallback(
    () => <VideoControls tracks={tracks} />,
    [tracks]
  );

  //  Methods.
  // FULL SCREEN
  const checkFullScreen = (elem) => {
    return (
      document.fullScreen ||
      document.mozFullScreen ||
      document.webkitIsFullScreen
    );
  };
  const addRefToContext = (el, i) => {
    mediaRef.current[i] = el;
  };
  const enterFullScreen = (elem) => {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      elem.msRequestFullscreen();
    }
  };
  const exitFullScreen = () => {
    if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else {
      document.webkitCancelFullScreen();
    }
  };

  // Side Effects

  useEffect(() => {
    let fullScreenStatus = checkFullScreen(fullScreenWrapRef.current);
    if (isFullScreen && !fullScreenStatus) {
      enterFullScreen(fullScreenWrapRef.current);
    } else if (!isFullScreen && fullScreenStatus) {
      exitFullScreen(fullScreenWrapRef.current);
    }
  }, [isFullScreen]);

  return (
    <MediaWrap
      ref={fullScreenWrapRef}
      onKeyUp={(e) => {
        console.log("Key up @ Video Element ");
        handleKeyUp(e);
      }}
      onKeyPress={(e) => {
        console.log("Key up @ Video Element ");
        handleKeyDown(e);
      }}
      tabIndex="0"
    >
      {tracks &&
        tracks.map((track, i) => (
          <VidWrap
            isActive={i === currentTrack}
            key={"media_" + track.mediatype + i}
            onMouseDown={() => togglePlay()}
          >
            {track.mediaType === "video" ? (
              <video
                onContextMenu={(e) => {
                  e.preventDefault();
                  return false;
                }}
                src={track.uri}
                type={track.format}
                controlsList="nodownload"
                ref={(el) => {
                  addRefToContext(el, i);
                }}
                width={"100%"}
                height="auto"
                preload="true"
                muted={isMute}
              />
            ) : (
              <audio
                onContextMenu={(e) => {
                  e.preventDefault();
                  return false;
                }}
                src={track.uri}
                type={track.format}
                controlsList="nodownload"
                ref={(el) => {
                  addRefToContext(el, i);
                }}
                width={"100%"}
                height="auto"
                preload="true"
                muted={isMute}
              />
            )}
          </VidWrap>
        ))}
      <RenderTrackbar />
      <RenderControls />
    </MediaWrap>
  );
}
