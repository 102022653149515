import { useState, useEffect, useRef } from "react";
import { Box } from "../../layout/containers/box";
import { TextField } from "../../layout/containers/input";
import { Checkbox } from "../../layout/selection/checkbox";
import { Radio } from "../../layout/selection/radio";
import Mic from "../../webSpeechAPI";
import RenderRichTextOnly from "../renderRichTextOnly";
import { toast } from "react-toastify";

/**
 * @param  {Array} props.data ==> Question data: specifically options.
 * @param {String} props.questionType ==> Question type string.
 * @param {String} props.selected ==> Answer already picked
 */

const AnswerChoices = (props) => {
  const { qsData, handleSelect, selected } = props;
  const { options, questionType, correctAns } = qsData;

  const handleMicError = () => {
    alert("Uh oh! Your mic glitched!");
  };
  const evaluateMicResult = (response) => {
    let isCorrect = undefined;
    //Correcting for multiple word recognition
    if (response.includes(" ")) {
      response.split(" ").forEach((res) => {
        if (answerHash[res] === false) isCorrect = false;
        if (answerHash[res]) isCorrect = true;
      });
    } else {
      isCorrect = answerHash[response];
    }
    if (isCorrect === undefined) {
      alert("That's not the answer I was looking for. Say one word pls.");
    } else if (isCorrect) {
      handleSelect(0, "mic");
    } else {
      handleSelect(1, "mic");
    }
  };

  const evalAudioResponse = (response) => {
    handleSelect(response);
  };
  const answerHash = {
    yes: true,
    yay: true,
    "of course": true,
    ofcourse: true,
    "for sure": true,
    sure: true,
    true: true,
    totally: true,
    ya: true,
    false: false,
    no: false,
    nahi: false,
    never: false,
    wrong: false,
    correct: true,
    yep: true,
  };
  if (questionType === "audioResponse") {
    return (
      <Box type="1 row centered" justifyContent="center" padding="0" background="none" width="min-content" alignItems="center">
        <Mic vocab={correctAns} onError={handleMicError} onResult={evalAudioResponse} size="l" showSpeech={false} />
        <TextField defaultValue={props.micResponse} isDecorated={true} width="250px" label="Response" />
      </Box>
    );
  }
  if (questionType === "boolean") {
    return (
      <>
        <Box background="transparent" margin="0 auto" padding="0" type="col" width="100%" justifyContent="space-between">
          {options &&
            options.map((o, i) => (
              <Box type="1 row centered" width="100%" background="none" padding="0">
                <Radio name="options" value={options[i]._id} onChange={(e) => handleSelect(options[i]._id)} index={i} checked={selected === options[i]._id} margin="10px">
                  {typeof options[i].choice === "string" ? (
                    options[i].choice
                  ) : Array.isArray(options[i].choice) && typeof options[i]?.choice[0] === "string" ? (
                    options[i].choice[0]
                  ) : (
                    <RenderRichTextOnly data={options[i].choice} />
                  )}
                </Radio>
              </Box>
            ))}
        </Box>
        <Box type="row" background="none" textAlign="center" width="min-content" margin="0 auto" justifyContent="center">
          <Mic vocab={Object.keys(answerHash)} onError={handleMicError} onResult={evaluateMicResult} size="l" />
        </Box>
      </>
    );
  }
  if (questionType.indexOf("mcq") > -1) {
    return options.map(({ choice, _id }, index) => {
      return (
        <Box width="100%" type="row" background="none" key={"mcq_option" + index}>
          <Checkbox
            width={"100%"}
            alignItems="center"
            name={_id}
            checked={index === selected || !!props.selected[_id]}
            onChange={(v) => {
              console.log(v);
              handleSelect(v, _id || index);
            }}
          >
            <RenderRichTextOnly data={choice} />
          </Checkbox>
        </Box>
      );
    });
  }
};

export default AnswerChoices;
