import { useState, useEffect } from "react";
import { Box } from "../layout/containers/box";
import { RounderBtn } from "../layout/buttons/buttonStyles";
import { CloseBtn, CopyToClipboard } from "../layout/buttons/buttons";
import { TextField } from "../layout/containers/input";
import { countUsers, fetch10More, fetchAllUsers, searchUsers } from "./api";

/*
  Functions
  1. Gets count. 
  2. Gets all users. Should be authenticated
  3. gets X more. 
  4. Filter should be working well.
  5. Search should be working well.
*/


const UserList = ({
  headings = ["Name", "Status", "Verified", "Email", "Cell"],
  id,
  filter, 
  onSelect,
}) => {
  const [activeRow, setActive] = useState(-1);
  const [field, setField] = useState("");
  const [filtered, setFiltered] = useState([]);
  const [data, setData] = useState([]);

  //METHODS
  function getMoreUsers(skip) {
    fetch10More(skip).then((r) => setData([...data].concat(r)));
  }
  function getAllUsers() {
    fetchAllUsers().then((r) => setData(r));
  }
  function fetchSearched() {
    searchUsers(field).then((result) => {
      setFiltered(result);
    });
  }

  //USE EFFECT: (2)
  useEffect(() => {
    const filterCb = (row, ri) => {
      if (row.name.toLowerCase().includes(field.toLowerCase())) {
        return row;
      }
      return null;
    };
    const handleFilter = (data) => {
      let result = data.filter(filterCb);
      setFiltered(result);
    };
    if (field.length !== 0) {
      handleFilter(data);
    } else if (field.length === 0) {
      setFiltered([]);
    }
  }, [field, data]);

  return (
    <Box
      type="column"
      padding="none"
      style={{ borderRadius: "var(--border-radius-large)", maxHeight: "70dvh" }}
      role="table"
      margin="2em 0"
    >
      <Search onSearch={fetchSearched} onFilter={setField} field={field} />
      <HeadRow headings={headings} />
      <div style={{ overflowY: "auto", width: "100%" }}>
        {filtered.length > 0
          ? filtered.map((row, ri) => (
              <Row
                onMouseDown={() => setActive(ri)}
                isActive={activeRow === ri}
                key={"User_data_row" + ri}
              >
                <Cell
                  isActive={activeRow === ri}
                  content={row.name}
                  index={0}
                />
                <Cell isActive={activeRow === ri} content={row.status} />
                <Cell isActive={activeRow === ri} content={row.isVerified} />
                <Cell isActive={activeRow === ri} content={row.email} />
                <Cell isActive={activeRow === ri} content={row.cell} />
              </Row>
            ))
          : data &&
            data.map((row, ri) => (
              <Row
                onMouseDown={() => setActive(ri)}
                isActive={activeRow === ri}
                key={"User_data_row" + ri}
              >
                <Cell
                  isActive={activeRow === ri}
                  content={row.name}
                  index={0}
                />
                <Cell isActive={activeRow === ri} content={row.status} />
                <Cell isActive={activeRow === ri} content={row.isVerified} />
                <Cell isActive={activeRow === ri} content={row.email} />
                <Cell isActive={activeRow === ri} content={row.phone} />
              </Row>
            ))}
      </div>
      <Footer
        fetchCount={data && data.length}
        loadAll={getAllUsers}
        fetch10More={getMoreUsers}
      />
    </Box>
  );
};
export default UserList;

const Search = ({ onSearch, onFilter, field }) => {
  const [text, setText] = useState("");
  const reset = () => {
    setText("");
    onFilter("");
  };
  const handleKeydown = ({ key }) => {
    if (key === "Enter" && text !== "") onFilter(text);
    if ((key === "Esc" || key === "Escape") && text !== "") reset();
  };
  return (
    <Box
      justifyContent="space-between"
      background={"none"}
      type="row"
      width="100%"
      alignItems="center"
      onKeyDown={(e) => handleKeydown(e)}
    >
      <CloseBtn disabled={text === ""} onMouseDown={() => reset()} />
      <div style={{ width: "70%" }}>
        <TextField
          placeholder="Search..."
          hasEditBtn={false}
          onChange={(v) => {
            setText(v);
          }}
          value={text}
          // isDecorated="true"
          width="90%"
        />
      </div>

      <RounderBtn
        disabled={text === ""}
        onMouseDown={() => {
          if (text.length) onFilter(text);
        }}
      >
        Filter
      </RounderBtn>
      <RounderBtn disabled={text === ""} onMouseDown={() => onSearch(text)}>
        Search
      </RounderBtn>
    </Box>
  );
};
const HeadRow = ({ headings, isActive, ...rest }) => {
  const [active, setActive] = useState(-1);
  return (
    <Box
      padding="0"
      background={"none"}
      type="row"
      width="100%"
      {...rest}
      style={{ cursor: "pointer" }}
    >
      {headings &&
        headings.map((h, hi) => (
          <HeaderCell
            index={hi}
            content={h}
            isActive={active === hi}
            key={"heading_" + hi + h}
            // onMouseDown={() => {
            //   setActive(hi);
            // }}
          />
        ))}
    </Box>
  );
};
const HeaderCell = ({ content, index, isActive, ...rest }) => {
  let dynaFlex = index === 0 ? "2 1 auto" : "1 1 auto";
  return (
    <Box
      type="row"
      background={isActive ? "#333" : "none"}
      width="auto"
      justifyContent="center"
      style={{
        flex: dynaFlex,
        textAlign: "center",
        color: "grey",
        cursor: "pointer",
      }}
      {...rest}
    >
      {content}
    </Box>
  );
};
const Row = ({ children, isActive, ...rest }) => {
  const [bg, setBg] = useState("none");
  const mO = () => {
    if (!isActive && bg !== "var(--spring-green-hover)") {
      setBg("var(--spring-green-hover)");
    }
  };
  const mL = () => {
    if (!isActive && bg !== "none") {
      setBg("none");
    }
  };

  return (
    <Box
      padding="0"
      background={isActive ? "var(--spring-green)" : bg}
      type="row"
      width="100%"
      {...rest}
      style={{
        cursor: "pointer",
        color: bg !== "none" ? "snow" : "grey",
        backgroundOpacity: bg !== "none" ? "0.7" : "0",
      }}
      onMouseOver={() => mO()}
      onMouseLeave={() => mL()}
    >
      {children}
    </Box>
  );
};

const Cell = ({ content, index, isActive }) => {
  const [hover, sethover] = useState("none");
  let dynaFlex = index === 0 ? "2 1 auto" : "1 1 auto";

  const mO = () => {
    sethover(true);
  };
  const mL = () => {
    sethover(false);
  };
  return (
    <Box
      type="row"
      background="none"
      width="auto"
      justifyContent="center"
      style={{
        flex: dynaFlex,
        textAlign: "center",

        opacity: hover ? "0.8" : "1",
        color: isActive ? "snow" : "grey",
        maxWidth: "30%",
      }}
      onMouseOver={() => mO()}
      onMouseLeave={() => mL()}
    >
      {(content && (
        <CopyToClipboard
          style={{
            justifyContent: "center",
            flex: "row nowrap",
            width: "min-content",
            whiteSpace: "nowrap",
          }}
        >
          {content.toString()}
        </CopyToClipboard>
      )) || <em>n.a.</em>}
    </Box>
  );
};

const Footer = ({ fetchCount, loadAll, fetch10More, ...rest }) => {
  const [count, setCount] = useState("NA");
  useEffect(() => {
    if (count === "NA") countUsers().then((d) => setCount(d?.count));
  }, []);

  return (
    <Box
      justifyContent="space-between"
      background={"none"}
      type="row"
      width="100%"
      {...rest}
    >
      <RounderBtn disabled={count <= fetchCount} onMouseDown={() => loadAll()}>
        Load All
      </RounderBtn>
      <small style={{ fontSize: "16px", color: "grey" }}>
        Showing: {fetchCount}/{count}
      </small>
      <RounderBtn
        disabled={count <= fetchCount}
        onMouseDown={() => fetch10More(fetchCount)}
      >
        Load 10 More
      </RounderBtn>
    </Box>
  );
};

// const Actions
