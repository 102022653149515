import { useEffect, useState, useContext, useCallback } from "react";
import AnswerChoices from "./answerChoices";
import RenderRichTextOnly from "../renderRichTextOnly";
import { Box } from "../../layout/containers/box";
import { serveQuestion, checkQsAnswers, GET } from "../../../helpers/REST/api";
import { toast } from "react-toastify";

import { TextField } from "../../layout/containers/input";
import ServeAudioQuestion from "./AudioResponse";

import { RounderBtn as SubmitBtn } from "../../layout/buttons/buttonStyles";
import LoadingBook from "./loadingIcon";
import LectureContext from "../context";
import useSocket from "../../../helpers/hooks/useSocket";
import { useParams } from "react-router-dom";
import { UserContext } from "../../../helpers/hooks/userContext";
//
/**
 * @param  {Object} props.data question (not an array)
 * @param {Object} props.config TBD: will be used for placement & modal etc.
 */

//TODO: Get timer set in for questions
//TODO: Add step by step in there: shoes one thing at a time...
//TODO: Add "Tricycle" mode
/**
 * @param  {object} props.data  Question Object
 * @param  {function} props.handleFinish  Finish callback: pass, failed, or undefined
 * @param  {object} props.config
 */
export const QuestionView = (props) => {
  let { data } = props;
  const { handleFinish } = props;
  const { memLecData, isLive, blockId } = useContext(LectureContext);

  const socket = useSocket();
  const { doc_id } = useParams();
  const { PID, MID, UID } = useContext(UserContext);

  //TODO const config = props.config;
  const [studResponseId, setStudResponseId] = useState(null);
  const [qsData, setQsData] = useState(null);
  const [selected, setSelected] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [micResponse, setMicResponse] = useState("");
  const [redo, setRedo] = useState(false);
  let outcomes = {
    cannotProceed: () => {
      toast.warn("You have to select a response first, please");
    },
    success: (msg) => {
      toast.success(msg || "That's right!");
      handleFinish();
    },
    fail: (msg) => {
      toast.error(msg || "That's not the right answer. Don't guess. \nDo it again; Do it right.");
      setRedo(msg);
    },
    dataError: (msg) => {
      toast.error(msg || "Question skipped: No question data ");
      setLoading(false);
      handleFinish();
    },
  };
  useEffect(() => {
    if (redo) {
      let payload = { lid: memLecData._id, mid: MID, pid: PID, qid: props.data, bid: blockId, did: doc_id, questionType: qsData.questionType };
      socket.emit("lecture_question_createEntry", payload); //also gets lecture Id.
    }
  }, [socket, redo]);

  const onSubmit = async (directInput) => {
    async function checkAnswer() {}
    let unSelected = selected === null;
    let noData = !qsData || qsData === null;
    let qsType = qsData.questionType;
    let isBoolean = qsType === "boolean";
    let isMcq = qsType === "mcq";
    let isAudio = qsType === "audioResponse";

    if (unSelected) {
      outcomes.cannotProceed();
    } else if (noData) {
      outcomes.dataError();
    }
    let hasPassed;
    if (isAudio) {
      let response = directInput ? directInput : micResponse;
      hasPassed = qsData.correctAns.indexOf(response.toLowerCase().trim()) > -1;
      !!hasPassed ? outcomes.success() : outcomes.fail();
    } else {
      if (isLive && socket !== null) {
        let payload = { _id: studResponseId, qid: qsData._id, selected };
        socket.emit("lecture_question_submit", payload);
      } else if (!isLive) {
        hasPassed = await checkQsAnswers(qsData._id, selected);
        !!hasPassed ? outcomes.success() : outcomes.fail();
      }
    }
  };
  const handleSelect = useCallback(
    (indexOrVal, eventOrId) => {
      if (qsData.questionType.includes("mcq")) {
        let selectedCopy = { ...selected };
        let val = indexOrVal;
        let id = eventOrId;
        selectedCopy[id] = val;
        console.log(studResponseId, eventOrId);
        if (isLive) {
          let payload = { lid: memLecData._id, mid: MID, pid: PID, qid: props.data, bid: blockId, did: doc_id, questionType: qsData.questionType };
          socket.emit("lecture_question_onSelect", { query: payload, _id: eventOrId, selected: selectedCopy });
        }

        setSelected(selectedCopy);
      }
      if (qsData.questionType === "audioResponse") {
        if (micResponse !== indexOrVal) setMicResponse(indexOrVal);
        onSubmit(indexOrVal);
      }
      if (qsData.questionType === "boolean") {
        setSelected(indexOrVal); // which is actually id
      }
    },
    [qsData, selected, micResponse, socket, studResponseId, blockId]
  );

  //TODO: Remove when API installed
  //?.1 Fetch question
  useEffect(() => {
    async function getQs(id) {
      await serveQuestion(id)
        .then((data) => {
          console.log(data);
          setQsData(data);
        })
        .catch((err) => {
          console.log(err);
          toast.error("Question not found!");
          handleFinish();
        });
    }
    getQs(data);
  }, [data]);

  //?.2 If any, fetch media
  useEffect(() => {
    if (typeof qsData?.questionAudio === "string") {
      toast.success("Audio string found...fetching!");
      GET(`/media/${qsData.questionAudio}`).then((d) => {
        setQsData({ ...qsData, questionAudio: d });
        toast.success("Audio downloaded!");
      });
    }
    if (qsData !== null && !isLive && isLoading) {
      setLoading(false);
    }
  }, [qsData, isLive, isLoading]);

  //?.3 Socket event handlers;
  useEffect(() => {
    if (isLive && socket !== null && qsData !== null) {
      const onQsSubmission = ({ pass, msg, error }) => {
        if (error) outcomes.dataError(error);
        if (pass === true) outcomes.success(msg);
        if (pass === false) outcomes.fail(msg);
      };
      const onQsCreate = (d) => {
        console.log(d);
        if (d?.selected) setSelected(d.selected);
        if (d?._id && studResponseId === null) setStudResponseId(d?._id);
        if (isLoading) setLoading(false);
      };
      socket.on("lecture_question_submit", onQsSubmission);
      socket.on("lecture_question_lastSelected", (d) => setSelected(d));
      socket.on("lecture_question_createEntry", onQsCreate);
    }
  }, [isLive, socket, qsData, isLoading]);

  //?.4 Creates students' response to question ++ Creates "studResponseId"
  useEffect(() => {
    if (isLive && socket !== null && memLecData !== null && !!MID && !!PID && !!blockId && !!doc_id && !!qsData) {
      let payload = { lid: memLecData._id, mid: MID, pid: PID, qid: props.data, bid: blockId, did: doc_id, questionType: qsData.questionType };
      socket.emit("lecture_question_createEntry", payload); //also gets lecture Id.
    }
  }, [socket, isLive, MID, memLecData, PID, props.data, blockId, doc_id, qsData]);

  //?.5 OnSelect
  useEffect(() => {
    // nothing's been selected
    if (isLive && !!socket && studResponseId !== null && !Object.keys(selected).length) {
      socket.emit("lecture_question_select", { _id: studResponseId });
    }
  }, [isLive, selected, socket, studResponseId]);

  if (isLoading === true) {
    return <LoadingBook />;
  }
  if (qsData === null) return null;
  if (qsData.questionType === "audioResponse") {
    return (
      <>
        <ServeAudioQuestion handleSelect={handleSelect} selected={selected} qsData={qsData} onSubmit={onSubmit}>
          <TextField placeholder="Speak out loud ; other wise type it here..." hasEditBtn={false} onChange={(v) => setMicResponse(v)} style={{ width: "100%" }} />
        </ServeAudioQuestion>
      </>
    );
  }
  if (redo) {
    return (
      <Box type="column" width="85%" margin="1em auto" background={"var(--tertiary-color)"}>
        <h2>You deceived yourself 😔</h2>
        <p style={{ maxWidth: "500px" }}>
          <span>You thought you had it right, but you didn't. Doesn't that tell you that you should maybe be more skeptical of your thoughts?</span>
          <br />
          <span>
            Hint: Stop trying to "answer the question", and don't "try again". Instead, refresh your head: forget what you know so far. Just know that when you tell yourself "I got it", you're at
            least at times wrong. Question it, challenge it--this is not about the answer.
          </span>
        </p>
        <SubmitBtn
          onMouseDown={() => {
            setRedo(false);
          }}
          disabled={!redo}
        >
          Redo Question
        </SubmitBtn>
      </Box>
    );
  }
  return (
    <Box type="column" width="85%" margin="1em auto" background={"var(--tertiary-color)"}>
      {qsData?.questionAudio?.uri && (
        <Box width="100%" type="row" background="transparent" padding="0">
          <audio src={qsData?.questionAudio?.uri} controls autoPlay />
        </Box>
      )}
      <Box width="100%" type="row" background="transparent">
        <RenderRichTextOnly data={qsData?.question} />
      </Box>
      <hr style={{ width: "100%" }} />

      <>
        <AnswerChoices qsData={qsData} selected={selected} handleSelect={handleSelect} />
      </>
      {!isLive ? (
        <SubmitBtn
          onMouseDown={() => {
            handleFinish();
          }}
        >
          {selected === null ? "You need to arrive at an answer..." : "Submit"}
        </SubmitBtn>
      ) : (
        <SubmitBtn
          onMouseDown={() => {
            onSubmit();
          }}
          disabled={selected === null}
        >
          {selected === null ? "You need to arrive at an answer..." : "Submit"}
        </SubmitBtn>
      )}
    </Box>
  );
};
