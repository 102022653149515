import ParaBlock from "./paraBlock";
import Code from "./codeBlock";
import ImageElement from "./imageBlock";
import { QuestionBlock } from "./questionInline";
import { QuestionVoid } from "./questionVoid";
import { AudioBlock } from "./audioBlock";
import WordUsage from "./searchTerm";
import EditableMediaPlayer from "./multiMediaPlayer/MediaEditor";
import MediaPlayer from "./multiMediaPlayer/MediaPlayer";

export default function Elements(props) {
  const { attributes, children, element, editor } = props;

  switch (element.type) {
    case "searchTerm":
      return <WordUsage {...props} />;
    case "paragraph":
      return <ParaBlock {...props} />;
    case "video":
      return <EditableMediaPlayer {...props} />;
    case "quote":
      return <blockquote {...attributes}>{children}</blockquote>;
    case "code":
      return <Code {...props} />;
    case "bulleted-list":
      return <ul {...attributes}>{children}</ul>;
    case "heading-one":
      return <h1 {...attributes}>{children}</h1>;
    case "heading-two":
      return <h2 {...attributes}>{children}</h2>;
    case "heading-three":
      return <h3 {...attributes}>{children}</h3>;
    case "heading-four":
      return <h4 {...attributes}>{children}</h4>;
    case "heading-five":
      return <h5 {...attributes}>{children}</h5>;
    case "heading-six":
      return <h6 {...attributes}>{children}</h6>;
    case "list-item":
      return <li {...attributes}>{children}</li>;
    case "numbered-list":
      return <ol {...attributes}>{children}</ol>;
    case "question":
      return <QuestionBlock {...props}>{children}</QuestionBlock>;
    case "inline-question-void":
      return <QuestionVoid {...props} />;
    case "link":
      return (
        <a href={element.url} {...attributes}>
          {children}
        </a>
      );
    case "audio":
      return <AudioBlock {...props} />;
    case "image":
      return <ImageElement {...props} />;
    default:
      return <span>{children}</span>;
  }
}

export const StudentElements = (props) => {
  const { attributes, children, element, editor } = props;

  switch (element.type) {
    case "searchTerm":
      return <WordUsage {...props} />;
    case "paragraph":
      return <ParaBlock {...props} />;
    case "video":
      return <MediaPlayer {...props} />;
    case "quote":
      return <blockquote {...attributes}>{children}</blockquote>;
    case "code":
      return <Code {...props} />;
    case "bulleted-list":
      return <ul {...attributes}>{children}</ul>;
    case "heading-one":
      return <h1 {...attributes}>{children}</h1>;
    case "heading-two":
      return <h2 {...attributes}>{children}</h2>;
    case "heading-three":
      return <h3 {...attributes}>{children}</h3>;
    case "heading-four":
      return <h4 {...attributes}>{children}</h4>;
    case "heading-five":
      return <h5 {...attributes}>{children}</h5>;
    case "heading-six":
      return <h6 {...attributes}>{children}</h6>;
    case "list-item":
      return <li {...attributes}>{children}</li>;
    case "numbered-list":
      return <ol {...attributes}>{children}</ol>;
    case "question":
      return <QuestionBlock {...props}>{children}</QuestionBlock>;
    case "inline-question-void":
      return <QuestionVoid {...props} />;
    case "link":
      return (
        <a href={element.url} {...attributes}>
          {children}
        </a>
      );
    case "audio":
      return <MediaPlayer {...props} />;
    case "image":
      return <ImageElement {...props} />;
    default:
      return <span>{children}</span>;
  }
};
