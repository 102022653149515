import styled from "styled-components";
import { CollapseWrap, DropWrap } from "../containers/boxStyles.js";
import { Chevron } from "./buttons.js";

export const BtnWrapper = styled.button`
  display: inline-block;
  font-family: inherit;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.009em;
  height: 42px;
  /* width: min-content; */
  min-width: 120px;
  /* aspect-ratio: 1:1; */
  /* max-width: 100px; */
  line-height: 1;
  padding: 0 20px;
  border: var(--border, none);
  overflow: visible;
  white-space: wrap;
  -webkit-appearance: button;
  text-decoration: none;
  margin: 0.25rem;
  cursor: pointer;
  outline: none;
  color: var(--color, var(--text-color));
  border-radius: var(--radius, var(--border-radius-med));
  background: var(--background, var(--primary-color));
  user-select: none;
  /* transition: all 0.2s ease-in-out; */
  transition: background 0.2s ease-in;
  min-width: 100px;
  &:focus {
    border: var(--border, 2px dotted grey;);
  }
  &:hover {
    background: var(--primary-color-hover);
    /* transform: scale(0.97); */
  }
  &:active {
    background: var(--primary-color-active);
    transform: scale(0.95);
  }
  &:disabled {
    background: grey;
    &:hover {
      background: grey;
    }
  }
`;
export const CircularBtnWrap = styled.button`
  cursor: pointer;
  background-color: var(--background, white);
  border-radius: 50%;
  display: flex; /* added */
  align-items: center; /* added */
  justify-content: center;
  outline: none;
  border: none;
  padding: 8px;
  width: ${(props) => props.width + "px" || "30px"};
  height: ${(props) => props.width + "px" || "30px"};
  /* box-sizing: border-box; */
  position: relative;
  z-index: 3;
  border-radius: 50%;

  &:focus {
    border: 2px linear grey;
  }
  &:hover {
    background: var(--primary-color-hover);
    /* transform: scale(0.97); */
  }
`;
export const RounderBtn = styled(CircularBtnWrap)`
  position: relative;
  box-sizing: border-box;
  width: min-content;
  white-space: nowrap;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1px 10px;
  margin: 0 5px;
  background: var(--primary-color);
  color: var(--text-color);
  font-size: 14px;
  color: white;
  border: 2px linear transparent;
  user-select: none;
  &:before {
    content: "";
    color: white;
    ${({ tooltip }) =>
      tooltip &&
      `
    top: -3em;
    background: black;
    padding: 5px 8px;   
    width: auto;
    height: auto;
    z-index: 1;
    content: "${tooltip}";
    box-sizing: border-box;
    margin: 0;
    background-color: rgba(243, 243, 243, 0.75);
    font-size: 11px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    position: absolute;
    z-index: 1070;
    display: block;
    width: max-content;
    max-width: 250px;
    visibility: hidden;
    opacity: 0;
    transition: all 0.1s ease-in;
    color: black;
    `}
  }

  &:hover {
    transition: background 0.2s linear;
    background: var(--primary-color-gradient);
    &:before {
      opacity: 1;
      visibility: visible;
    }
  }
  &:active {
    background: var(--button-color-active);
    border: 2px linear var(--primary-color);
  }
  &:disabled {
    opacity: 0.3;
    background: grey;
    &:before {
      opacity: 0;
      visibility: hidden;
    }
  }
`;
export const CloseBtnWrap = styled.button`
  cursor: pointer;
  background-color: ${({ bg }) => bg || "var(--background, white)"};
  border-radius: 50%;
  display: flex; /* added */
  align-items: center; /* added */
  justify-content: center;
  outline: none;
  border: none;
  padding: 8px;
  width: ${({ width }) => (width ? `${width}px` : "30px")};
  height: ${({ width }) => (width ? `${width}px` : "30px")};
  /* box-sizing: border-box; */
  position: relative;
  z-index: 3;
  border-radius: 50%;
  & svg {
    stroke: var(--stroke, black);
    transform: scale(0.7);
  }
  &:active {
    transform: scale(1.05);
  }
  ${CollapseWrap} & {
    margin-left: auto;
  }
  &:disabled {
    background: grey;
    opacity: 0.5;
    &:hover {
      background: grey;
    }
  }
`;

export const DropBtnWrap = styled(CircularBtnWrap)`
  transform: ${({ isOpen }) => (isOpen ? "rotate(90deg)" : "rotate(0deg)")};
  background-color: ${({ bg }) => bg || "var(--background, white)"};
  width: ${({ width }) => (width ? (typeof width === "string" ? "width" : `${width}px`) : "30px")};
  height: ${({ width }) => (width ? (typeof width === "string" ? "width" : `${width}px`) : "30px")};
  padding: ${({ pad }) => (pad !== undefined ? `${pad}px` : "8px")};
  transition: transform 0.3s ease-in;

  & svg {
    position: relative;
    top: 1px;
    left: 2px;
    stroke: var(--stroke, black);
  }
  &:active {
    transform: scale(1.05);
  }
  ${DropWrap} & {
    margin-left: auto;
  }
`;

export const TabBtnWrapper = styled(BtnWrapper)`
  height: 38px;
  min-width: 100px;
  margin: ${({ isActive }) => (isActive ? "0 1em" : "0")};
  margin: 0;
  padding: 0 25px;
  background: ${({ isActive }) => (isActive ? "var(--primary-color-gradient)" : "transparent")};

  color: white;
  border-radius: inherit;

  transition: all 0.2s ease-in;
  &:hover {
    background: ${({ isActive }) => (isActive ? "var(--primary-color-gradient)" : "var(--tertiary-color)")};
    color: white;
  }
  &:active {
    background: ${({ isActive }) => (isActive ? "var(--primary-color-active)" : "var(--tertiary-color-darker)")};
  }
  &:focus {
    border: none;
  }
`;

const directArrow = (direction) => {
  if (direction === "r") {
    return "transform:rotate(0);";
  }
  if (direction === "l") {
    return "transform:scaleX(-1);";
  }
  if (direction === "d") {
    return "transform:rotate(90deg);";
  }
  if (direction === "u") {
    return "transform:rotate(270deg);";
  }
  return "";
};
const scaleArrow = (direction, isActive) => {
  let activeScale = 0.95;
  let isLeft = direction === "l";
  if (isLeft && isActive) return `scale(-${activeScale})`;
  if (isActive) return `scale(${activeScale})`;
  if (isLeft) return `scale(-1)`;
  return `scale(1)`;
};
export const ArrowCircularWrap = styled(CircularBtnWrap)`
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ direction }) => directArrow(direction)}
  margin: 0 5px;

  & > .chevron {
    position: relative;
    top: 1px;
    left: 2px;
  }
  &:hover {
    & > .chevron {
      stroke: white;
    }
  }
  &:active {
    background: var(--primary-color-active);
    transform: ${({ direction }) => scaleArrow(direction, true)};
  }
  &:disabled {
    background: grey;
    &:hover {
      background: grey;
    }
    &:active {
      transform: ${({ direction }) => scaleArrow(direction)};
      color: white;
    }
    &:hover {
      & > .chevron {
        stroke: black;
      }
    }
  }
`;
export const CopyToClipboardWrap = styled.div`
  background: none;
  border: none;
  width: 100%;
  height: 100%;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & svg {
    opacity: 0;
    position: relative;
    left: 5px;
    top: 1px;
  }
  &:hover svg {
    opacity: 1;
  }
`;
