import React, { useState } from "react";

export const FontContext = React.createContext({});

export const FontProvider = ({ children }) => {
  const [fontSize, setFontSize] = useState(
    localStorage.getItem("fontsize") || 20
  );
  const updateFont = (f) => {
    localStorage.setItem("fontSize", `${f}px`);
    setFontSize(f);
  };

  return (
    <FontContext.Provider value={{ fontSize, updateFont }}>
      {children}
    </FontContext.Provider>
  );
};
