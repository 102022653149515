import { useEffect, useState, useRef } from "react";
import { Box } from "../layout/containers/box";
import { Button } from "../layout/buttons/buttons";
import { Readable } from ".";
import { Checkbox } from "../layout/selection/checkbox";
import { Radio } from "../layout/selection/radio";
import { checkQuestionAnswer } from "../../helpers/REST/api";
import { TextField } from "../layout/containers/input";
const QuestionHandler = (props) => {
  const { questionObject, questionId, onClose, onSubmit } = props;
  const [selected, isSelected] = useState(null);
  const handleSubmit = async (selected) => {
    let response = await checkQuestionAnswer(
      questionId,
      JSON.stringify({
        type: questionObject.questionType,
        selected,
      })
    );

    onSubmit(response.isCorrect);
  };

  return (
    <Box
      background="white"
      type="column"
      padding="0"
      justifyContent="flex-start"
      width="100%"
      color="black"
    >
      <CheckboxOptions
        questionObject={questionObject}
        onSelect={(i) => isSelected(i)}
      />
      <Box
        type="1 row centered"
        justifyContent="flex-end"
        background="transparent"
        padding="0"
        margin="0"
      >
        <Button onClick={() => handleSubmit(selected)}>Submit</Button>
      </Box>
    </Box>
  );
};
export default QuestionHandler;

const CheckboxOptions = (props) => {
  let {
    questionObject: { question, questionType, options, selected },
    onSelect,
    onSubmit,
  } = props;

  // What's picked.
  const [answers, setAnswers] = useState(() =>
    options.map(({ selected }) => (!selected ? false : selected))
  );
  //Update answer state.
  function updateAnswers(index, value) {
    let answerCopy = [...answers];
    answerCopy[index] = value;
    setAnswers(answerCopy);
    onSelect(answerCopy);
  }

  return (
    <>
      <Box background="transparent" type="row" width="100%" padding="0">
        <Readable content={question} />
      </Box>
      {options.map((op, i) => (
        <Box
          background="transparent"
          bRadius="0"
          type="row"
          width="100%"
          padding="0"
          key={`mcq_answerChoice_${i}`}
        >
          <Checkbox
            style={{ position: "relative", top: "-5px" }}
            onChange={(val) => updateAnswers(i, val)}
            checked={selected ? selected : undefined}
          />
          <Readable content={op.choice} />
        </Box>
      ))}
    </>
  );
};
const RadioOptions = ({ questionObject, selected, onSelect, onSubmit }) => {
  let { question, questionType, options } = questionObject;
  return (
    <>
      <Box background="transparent" type="row" width="100%" padding="0">
        <Readable content={question} />
      </Box>
      {options.map((op, i) => (
        <Box
          background="transparent"
          bRadius="0"
          type="row"
          width="100%"
          padding="0"
          key={`mcq_answerChoice_${i}`}
        >
          <Radio
            style={{ position: "relative", top: "-5px" }}
            onChange={() => onSelect(i)}
          />
          <Readable content={op.choice} />
        </Box>
      ))}
    </>
  );
};
const Boolean = ({ question, answers, onSelect, onSubmit }) => {};
const FindEvidence = ({ questionObject, onSelect, onTextSelect, onSubmit }) => {
  let answersRef = useRef({ answers: [] });
  let { question, questionType, options } = questionObject;

  const handleSelection = () => {};
  return (
    <>
      <Box background="transparent" type="row" width="100%" padding="0">
        <Readable content={question} />
      </Box>
      {answersRef.current.answers.map((ans, i) => (
        <Box
          background="transparent"
          bRadius="0"
          type="row"
          width="100%"
          padding="0"
          key={`mcq_answerChoice_${i}`}
        >
          <TextField
            name={`Evidence-${i + 1}`}
            width="100%"
            defaultValue={ans}
            ref={(el) => (answersRef.current[i] = el)}
          />
        </Box>
      ))}
    </>
  );
};
const Etymology = ({ question, answers, onSelect, onSubmit }) => {};
const ShortAnswer = ({ question, answers, onSelect, onSubmit }) => {};
const WordMatch = ({ question, answers, onSelect, onSubmit }) => {};
const OralQuestion = () => {};
