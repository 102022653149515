import {
  BoldOutlined,
  ItalicOutlined,
  UnderlineOutlined,
  QuestionOutlined,
  StrikethroughOutlined,
  QuestionCircleFilled,
  PictureFilled,
  VideoCameraAddOutlined,
  AudioFilled,
  CommentOutlined,
  AlertFilled,
  SecurityScanFilled,
  BranchesOutlined,
  UnorderedListOutlined,
  OrderedListOutlined,
  LinkOutlined,
  PlusOutlined,
  FlagOutlined,
  ReadOutlined,
  AlignLeftOutlined,
} from "@ant-design/icons";
import { Editor } from "slate";
import { toggleButton, isButtonActive } from "../helpers/toolbarHandlers";

import {
  getAllCommentIdsFromText,
  insertCommentThread,
  shouldAllowNewCommentThreadAtSelection,
} from "../features/comment";

import {
  getAllIdsFromText,
  insertQuestion,
  shouldAllowNewQuestionAtSelection,
} from "../features/question";

export const formatTypes = {
  paragraph: {
    type: "block",
    Icon: (props) => <AlignLeftOutlined {...props} />,
    title: "Paragraph",
    isActive: (editor, elementType, format) => {
      return isButtonActive(editor, elementType, format);
    },
    toggle: (editor, elementType, format) => {
      // editor.lastRange = editor.selection;
      toggleButton(editor, elementType, format);
      // editor.lastRange = editor.selection;
    },
  },
  bold: {
    type: "mark",
    Icon: (props) => <BoldOutlined {...props} />,
    title: "Bold",
    isActive: (editor, elementType, format) => {
      return isButtonActive(editor, elementType, format);
    },
    toggle: (editor, elementType, format) => {
      toggleButton(editor, elementType, format);
    },
  },
  italic: {
    type: "mark",
    Icon: (props) => <ItalicOutlined {...props} />,
    title: "Italicize",
    isActive: (editor, elementType, format) => {
      return isButtonActive(editor, elementType, format);
    },
    toggle: (editor, elementType, format) => {
      // editor.lastRange = editor.selection;
      toggleButton(editor, elementType, format);
      // editor.lastRange = editor.selection;
    },
  },
  underline: {
    type: "mark",
    Icon: (props) => <UnderlineOutlined {...props} />,
    title: "Underline",
    isActive: (editor, elementType, format) => {
      return isButtonActive(editor, elementType, format);
    },
    toggle: (editor, elementType, format) => {
      // editor.lastRange = editor.selection;
      toggleButton(editor, elementType, format);
      // editor.lastRange = editor.selection;
    },
  },
  strikethrough: {
    type: "mark",
    Icon: (props) => <StrikethroughOutlined {...props} />,
    title: "Strikethrough",
    isActive: (editor, elementType, format) => {
      return isButtonActive(editor, elementType, format);
    },
    toggle: (editor, elementType, format) => {
      // editor.lastRange = editor.selection;
      toggleButton(editor, elementType, format);
      // editor.lastRange = editor.selection;
    },
  },

  "inline-question-void": {
    type: "inline",
    Icon: (props) => <QuestionCircleFilled {...props} />,
    title: "Question Void",
    isActive: (editor, elementType, format) => {
      return isButtonActive(editor, elementType, format);
    },
    toggle: (editor, elementType, format) => {
      // editor.lastRange = editor.selection;
      toggleButton(editor, elementType, format);
      // editor.lastRange = editor.selection;
    },
  },

  image: {
    type: "block",
    Icon: (props) => <PictureFilled {...props} />,
    title: "Image",
    isActive: (editor, elementType, format) => {
      return isButtonActive(editor, elementType, format);
    },
    toggle: (editor, elementType, format) => {
      // editor.lastRange = editor.selection;
      toggleButton(editor, elementType, format);
      // editor.lastRange = editor.selection;
    },
  },

  video: {
    type: "block",
    Icon: (props) => <VideoCameraAddOutlined {...props} />,
    title: "Video",
    isActive: (editor, elementType, format) => {
      return isButtonActive(editor, elementType, format);
    },
    toggle: (editor, elementType, format) => {
      // editor.lastRange = editor.selection;
      toggleButton(editor, elementType, format);
      // editor.lastRange = editor.selection;
    },
  },
  audio: {
    type: "block",
    Icon: (props) => <AudioFilled {...props} />,
    title: "Audio",
    isActive: (editor, elementType, format) => {
      return isButtonActive(editor, elementType, format);
    },
    toggle: (editor, elementType, format) => {
      // editor.lastRange = editor.selection;
      toggleButton(editor, elementType, format);
      // editor.lastRange = editor.selection;
    },
  },
  searchTerm: {
    type: "inline",
    Icon: (props) => <ReadOutlined {...props} />,
    title: "Search Term",
    isActive: (editor, elementType, format) => {
      return isButtonActive(editor, elementType, format);
    },
    toggle: (editor, elementType, format) => {
      // editor.lastRange = editor.selection;
      toggleButton(editor, elementType, format);
      // editor.lastRange = editor.selection;
    },
  },
  "heading-one": {
    type: "block",
    Icon: (props) => <>H1</>,
    title: "Heading One",
    isActive: (editor, elementType, format) => {
      return isButtonActive(editor, elementType, format);
    },
    toggle: (editor, elementType, format) => {
      // editor.lastRange = editor.selection;
      toggleButton(editor, elementType, format);
      // editor.lastRange = editor.selection;
    },
  },
  "heading-two": {
    type: "block",
    Icon: (props) => <>H2</>,
    title: "Heading Two",
    isActive: (editor, elementType, format) => {
      return isButtonActive(editor, elementType, format);
    },
    toggle: (editor, elementType, format) => {
      // editor.lastRange = editor.selection;
      toggleButton(editor, elementType, format);
      // editor.lastRange = editor.selection;
    },
  },
  "heading-three": {
    type: "block",
    Icon: (props) => <>H3</>,
    title: "Heading Three",
    isActive: (editor, elementType, format) => {
      return isButtonActive(editor, elementType, format);
    },
    toggle: (editor, elementType, format) => {
      // editor.lastRange = editor.selection;
      toggleButton(editor, elementType, format);
      // editor.lastRange = editor.selection;
    },
  },
  "heading-four": {
    type: "block",
    Icon: (props) => <>H4</>,
    title: "Heading Four",
    isActive: (editor, elementType, format) => {
      return isButtonActive(editor, elementType, format);
    },
    toggle: (editor, elementType, format) => {
      // editor.lastRange = editor.selection;
      toggleButton(editor, elementType, format);
      // editor.lastRange = editor.selection;
    },
  },
  "bulleted-list": {
    type: "block",
    Icon: (props) => <UnorderedListOutlined {...props} />,
    title: "Bullets",
    isActive: (editor, elementType, format) => {
      return isButtonActive(editor, elementType, format);
    },
    toggle: (editor, elementType, format) => {
      // editor.lastRange = editor.selection;
      toggleButton(editor, elementType, format);
      // editor.lastRange = editor.selection;
    },
  },

  "numbered-list": {
    type: "block",
    Icon: (props) => <OrderedListOutlined {...props} />,
    title: "List",
    isActive: (editor, elementType, format) => {
      return isButtonActive(editor, elementType, format);
    },
    toggle: (editor, elementType, format) => {
      // editor.lastRange = editor.selection;
      toggleButton(editor, elementType, format);
      // editor.lastRange = editor.selection;
    },
  },
  link: {
    type: "inline",
    Icon: (props) => <LinkOutlined {...props} />,
    title: "Link",
    isActive: (editor, elementType, format) => {
      return isButtonActive(editor, elementType, format);
    },
    toggle: (editor, elementType, format) => {
      // editor.lastRange = editor.selection;
      toggleButton(editor, elementType, format);
      // editor.lastRange = editor.selection;
    },
  },

  comment: {
    type: "mark",
    Icon: (props) => <CommentOutlined {...props} />,
    title: "Comment",
    isActive: (editor, elementType, format) => {
      return false;
    },
    disabled: (editor) => {
      let shouldAllow = shouldAllowNewCommentThreadAtSelection(
        editor,
        editor.selection
      );
      //console.log(shouldAllow);
      return !shouldAllow;
    },
    customToggle: (editor, addNewCommentToContext) => {
      // let marks = Editor.marks(editor);
      // let threads = getAllCommentIdsFromText(marks);

      let shouldAllow = shouldAllowNewCommentThreadAtSelection(
        editor,
        editor.selection
      );
      if (shouldAllow) {
        let threadId = insertCommentThread(editor, addNewCommentToContext);
        return threadId;
      } else {
        alert("Cannot add comment to the selected text: it's too saturated");
      }
    },
  },
  question: {
    type: "mark",
    Icon: (props) => <QuestionOutlined {...props} />,
    title: "Add Question",
    isActive: (editor, elementType, format) => {
      return isButtonActive(editor, elementType, format);
    },
    disabled: (editor) => {
      let shouldAllow = shouldAllowNewQuestionAtSelection(
        editor,
        editor.selection
      );

      return !shouldAllow;
    },
    customToggle: (editor, addNewCommentToContext) => {
      let shouldAllow = shouldAllowNewQuestionAtSelection(
        editor,
        editor.selection
      );
      if (shouldAllow) {
        let threadId = insertQuestion(editor, addNewCommentToContext);
        return threadId;
      } else {
        alert("Cannot add questions to the selected text: it's too saturated");
      }
    },
  },

  // question: {
  //   type: "inline",
  //   Icon: (props) => <QuestionOutlined {...props} />,
  //   title: "Text-Specific Question",
  //   isActive: (editor, elementType, format) => {
  //     return isButtonActive(editor, elementType, format);
  //   },
  //   toggle: (editor, elementType, format) => {
  //     // editor.lastRange = editor.selection;
  //     toggleButton(editor, elementType, format);
  //     // editor.lastRange = editor.selection;
  //   },
  // },

  term: {
    type: "mark",
    Icon: (props) => <PlusOutlined {...props} />,
    title: "Term",
    isActive: (editor, elementType, format) => {
      return isButtonActive(editor, elementType, format);
    },
    toggle: (editor, elementType, format) => {
      // editor.lastRange = editor.selection;
      toggleButton(editor, elementType, format);
      // editor.lastRange = editor.selection;
    },
  },
  flag: {
    type: "block",
    Icon: (props) => <FlagOutlined {...props} />,
    title: "Flag",
    isActive: (editor, elementType, format) => {
      return isButtonActive(editor, elementType, format);
    },
    toggle: (editor, elementType, format) => {
      // editor.lastRange = editor.selection;
      toggleButton(editor, elementType, format);
      // editor.lastRange = editor.selection;
    },
  },
};
let keys = Object.keys(formatTypes);

// !!TODO: This is running every time we have a re-render, everytime we select a person.
export const blockList = keys.filter((k) => formatTypes[k].type === "block");
export const markList = keys.filter((k) => formatTypes[k].type === "mark");
export const inlineList = keys.filter((k) => formatTypes[k].type === "inline");
export const masterList = keys.filter((k) => formatTypes[k]);
export const customList = markList.concat(inlineList);
let blocksThatCanBeAddedAtParaStart = [
  "paragraph",
  "image",
  "video",
  "audio",
  "flag",
  "numbered-list",
  "bulleted-list",
];

export const elementalBlockList = blocksThatCanBeAddedAtParaStart;

// export const inlineVoidList = keys.filter((k) => formatTypes[k].type === "inline-void"); ** None exist.
// export const blockVoidList = keys.filter((k) => formatTypes[k].type === "block-void");   ** None exist.

//DEF., LISTS & REFERENCES: This where Icons, titles, popoevers etc are located. Assoc. renders are located in renders
// export const blockList = [
//   "paragraph",
//   "audio",
//   "video",
//   "ordered-list",
//   "unordered-list",
//   "heading-one",
//   "heading-two",
//   "heading-three",
//   "heading-four",
// ];
// export const markList = [
//   "bold",
//   "italic",
//   "underline",
//   "underline",
//   "strikethrough",
//   "comment",
//   "term",
// ];
