import React, { useRef, useContext } from 'react';
import { MediaContext } from './masterContext';
import styled from 'styled-components';
import { TrackWrap, Range } from './videoStyles.js';
import QuestionBar from './questionBar';

const Trackbar = ({ showControls, tracks }) => {
  const {
    currentTrack,
    mediaRef,
    onScrubEnd,
    onScrub,
    playerProgress,
    totalTime,
    rangeRef,
  } = useContext(MediaContext);

  function handleTouchStart(e) {
    console.log('Touch start @ trackbar');
    var rect = e.target.getBoundingClientRect();
    var x = e.targetTouches[0].pageX - rect.left;
    onScrub((x / rangeRef.current.offsetWidth) * totalTime);
  }
  function handleMouseDown({ nativeEvent }) {
    console.log('Mouse down @ trackbar');
    onScrub(
      (nativeEvent.offsetX / rangeRef.current.offsetWidth) * totalTime
    );
  }
  let value = isNaN(playerProgress / totalTime)
    ? 0
    : (playerProgress / totalTime) * 100;

  if (mediaRef.current[currentTrack]) {
    return (
      <TrackWrap
        showControls={true}
        onMouseUp={() => {
          console.log('Mouse up @ trackbar');
          onScrubEnd();
        }}
        onTouchEnd={() => {
          console.log('TouchEnd @ trackbar');
          onScrubEnd();
        }}
      >
        <Range
          ref={rangeRef}
          min={0}
          max={100}
          onChange={() => {}} //not need
          value={value}
          type="range"
          step="1"
          onMouseDown={handleMouseDown}
          onTouchStart={handleTouchStart}
        />
        <progress min={0} max={100} value={value} />
        <QuestionBar />
      </TrackWrap>
    );
  } else {
    return null;
  }
};
export default Trackbar;
