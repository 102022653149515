import { gql } from "@apollo/client";

export const CREATE_COURSE = gql`
  mutation CreateCourse(
    $title: String!
    $description: String
    $courseType: CourseType!
    $category: CourseCategory!
    $hierarchy: [CourseHierarchy!]
    $isOffered: Boolean
    $isPublished: Boolean
  ) {
    createCourse(
      title: $title
      description: $description
      courseType: $courseType
      category: $category
      hierarchy: $hierarchy
      isOffered: $isOffered
      isPublished: $isPublished
    ) {
      id
      title
      description
      category
      hierarchy
      isOffered
      isPublished
    }
  }
`;
export const CREATE_COURSECHILD = gql`
  mutation mutation(
    $courseId: String!
    $title: String!
    $description: String!
    $moduleType: String!
    $parentLocation: [String]
  ) {
    createCourseChild(
      courseId: $courseId
      title: $title
      description: $description
      moduleType: $moduleType
      parentLocation: $parentLocation
    ) {
      _id
      defaultPlan {
        title
        description
        childType
        children {
          title
          description
          children {
            title
            description
            children {
              title
              description
              children {
                description
                title
                moduleId
              }
              moduleId
              moduleType
            }
            moduleId
          }
          moduleId
          moduleType
        }
        moduleId
        moduleType
      }
    }
  }
`;
export const DELETE_COURSE = gql`
  mutation ($id: ID!) {
    deleteCourse(_id: $id)
  }
`;
