import { useEffect, useState, useRef, useCallback } from "react";
import { useParams } from "react-router-dom";
import { GET, POST, DELETE } from "../../helpers/REST/api";
import { Box } from "../layout/containers/box";
import { TextField } from "../layout/containers/input";
import { SelectField } from "../layout/containers/input";
import { ArrowBtn, Button, AddBtn, DeleteBtn } from "../layout/buttons/buttons";
import { useMedia, GetMedias } from "../media";
import { RounderBtn } from "../layout/buttons/buttonStyles";
import { ModalElement } from "../../helpers/hooks/useModal";
import { TrackWrap } from "../editor2/renders/multiMediaPlayer/styled_video";
import { TabGroup } from "../layout/containers/box";
import PlaylistView, { TracksView } from "./playlistView";
import EditPlaylist from "./editPlaylist";
import { Tooltip } from "antd";

let trackTemplate = {
  title: "",
  subTitle: "",
  parent: [],
  tags: [],
  track: [],
  questions: [],
};
//! Note: Fugly AF
export default function Playlists() {
  const [playlists, setPlaylists] = useState([]); // {track: [], questions: []}
  const [newPlaylist, setNewPlaylist] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [activePlaylist, setActivePlaylist] = useState(0);
  let params = useParams();
  let doc_id = params.doc_id;
  let block_id = params.block_id;

  function handleActivePlaylist() {}
  function initNewTrack() {
    setNewPlaylist(trackTemplate);
  }
  function removeNewPlaylist() {
    setNewPlaylist(null);
  }

  async function createNewPlaylist() {
    if (newPlaylist !== null) {
      POST("/mediaTracks/", { data: newPlaylist }).then(({ data }) => {
        setPlaylists([...playlists, data]);
      });
    }
  }

  function updateActivePlaylist(playlistObject, index) {
    if (index === undefined) {
      const copy = [...playlists];
      copy[activePlaylist] = playlistObject;
      setPlaylists(copy);
    } else if (typeof index === "number") {
      const copy = [...playlists];
      copy[index] = playlistObject;
      setPlaylists(copy);
    }
  }
  useEffect(() => {
    GET("/mediaTracks/_id").then((data) => {
      Promise.all(
        data.map(async (idObject) => {
          let result = await GET(`/mediaTracks/m/${idObject._id}`);
          if (result !== undefined && result !== null) {
            return result;
          }
          return null;
        })
      ).then((allFetched) => {
        setPlaylists(allFetched);
      });
    });
  }, []);
  const TabgroupTitles = [<Tooltip title="Create new Playlist">Create</Tooltip>, <Tooltip title="Overview">Playlists</Tooltip>, <Tooltip title="Use to edit the selected playlist">Edit</Tooltip>];
  return (
    <Box type="col left" margin="0 0 auto 0" background="none">
      <TabGroup width="min-content" tabs={TabgroupTitles} alignTabs={"left"} setTab={setActiveTab} activeIndex={activeTab} />
      {activeTab === 2 ? <EditPlaylist playlist={playlists[activePlaylist]} updatePlaylists={updateActivePlaylist} /> : null}
      {activeTab === 0 ? (
        <Box type="row" padding="0" background="none" width="100%">
          {newPlaylist !== null ? (
            <>
              <CreatePlaylist />
              <Button onMouseDown={() => removeNewPlaylist()}>Remove New Playlist</Button>
            </>
          ) : (
            <Button onMouseDown={() => initNewTrack()}>Create Playlist</Button>
          )}
        </Box>
      ) : null}
      {activeTab === 1 ? (
        <Box type="column" padding="0" background="none" width="100%">
          {playlists &&
            playlists.map((pl, pli) => (
              <PlaylistView
                id={pl._id}
                key={`playlist_${pl._id ?? pli}`}
                pli={pli}
                activePlaylist={activePlaylist}
                onMouseDown={(i) => {
                  setActivePlaylist(i);
                }}
                updatePlaylists={updateActivePlaylist}
                playlist={pl}
              />
            ))}
          {!playlists && <h3>No saved tracks</h3>}
        </Box>
      ) : null}
    </Box>
  );
}
const CreatePlaylist = () => {
  const media = [];

  const [activePlaylist, setActive] = useState(null);

  return (
    <Box type="col left" maxHeight="50vh">
      <h2>Create Playlist</h2>
      <Box type="1 row centered" padding="0" background="none">
        <PlaylistView playlistData={media && activePlaylist && media[activePlaylist]} onSave />
      </Box>
      <Box type="row" padding="0" background="none">
        <Box type="col left" width="35%" padding="0" background="#ccc" style={{ overflowY: "auto" }}>
          {media &&
            media.map((m, mi) => (
              <Box
                padding="0"
                type="col"
                onMouseDown={() => {
                  setActive(mi);
                }}
              >
                <span>{m.fileName}</span>
                <small>{m.duration}</small>
                <small>Last Modif.:{m.modifiedAt}</small>
              </Box>
            ))}
        </Box>
        <Box type="col left" padding="0" style={{ overflowY: "auto" }} background="none"></Box>
      </Box>
    </Box>
  );
};

const EditMediaFile = (props) => {
  //* Can only change file name && what it's assoc. with (tags && parent);
  const handleChange = () => {};
  return (
    <Box>
      <TextField isDecorated={true} onChange={handleChange} name="fieldName" />
    </Box>
  );
};

/** WHEN YOU WANT TO ADD AN AUDIO VIA A LINK */
let mediaTemplate = {
  uri: "",
  fileName: "",
  duration: "",
  location: "dropbox",
  fileType: "audio",
};

//TODO: REMOVE THIS; The one after this is the most current itteration
const AddTrackMediaViaLink = (props) => {
  const { onSave } = props;
  const [media, setMedia] = useState(mediaTemplate);
  const audioRef = useRef(null);
  function handleInput(value, key) {
    setMedia(Object.assign({}, media, { [key]: value }));
  }
  function createTitleFromLink(text) {
    if (!text) return text;
    let title = text.split("/");
    title = title[title.length - 1]; //last item gets title
    title = title.split(".")[0]; // remove formatting at the end.
    title = title.replaceAll("%20", " "); // replace space
    title = title //Capitalize words
      .split(" ")
      .map((w) => w[0].toUpperCase() + w.slice(1))
      .join(" ");
    return title;
  }
  function editDropboxLink(v, ref) {
    let text = v;
    if (v.indexOf(".com") > -1) {
      if (text.indexOf("www.") === -1) text = "www." + text;
      if (text.indexOf("https://") === -1) text = "https://" + text;
    }
    text = text.replace("dropbox.com", "dl.dropboxusercontent.com");
    ref.value = text;
    handleInput(text, ref.name);
  }
  function handleUriChange(v, ref) {
    editDropboxLink(v, ref);
  }
  function handleFileNameChange(v, ref) {
    handleInput(v, ref.name);
  }
  function fileNameMouseDown(e) {
    e.preventDefault();
    let format = createTitleFromLink(media.uri);
    e.target.value = format;
    handleInput(format, e.target.name);
  }
  function handleDurationChange(v, ref) {
    handleInput(v, ref.name);
  }
  function durationMouseDown(e) {
    if (!audioRef?.current?.duration) {
      alert("Something is wrong w. the link");
    } else {
      e.target.value = audioRef.current.duration;
      handleInput(audioRef.current.duration, e.target.name);
    }
  }

  function Audio() {
    return (
      <Box type="1 row centered" background="white" width="45%" padding="0">
        <audio ref={audioRef} controls src={media.uri} />
      </Box>
    );
  }

  return (
    <>
      <Box type=" row" background="none" flexFlow="row nowrap" justifyContent="space-between">
        <TextField isDecorated={true} name="uri" width="45%" label="Dropbox Link" defaultValue={media.uri} onChange={handleUriChange} />
        <TextField
          isDecorated={true}
          width="45%"
          style={{ fontSize: "14" }}
          name="fileName"
          placeholder="What do you want to call it?"
          defaultValue={media.fileName}
          onChange={handleFileNameChange}
          onMouseDown={fileNameMouseDown}
        />
      </Box>
      <Box type="row" background="none" justifyContent="space-between" padding="0">
        <Audio />
        <TextField
          isDecorated={true}
          width="45%"
          name="duration"
          label="Click to get duration"
          type="number"
          defaultValue={media.duration}
          onMouseDown={durationMouseDown}
          onChange={handleDurationChange}
          style={{ opacity: media.uri ? "1" : "0.3" }}
          readOnly={media.uri ? false : true}
        />
      </Box>
    </>
  );
};

//TODO: Add "prevState"
//TODO: ADD A SAVE BUTTON.
//TODO: SEND DATA TO BACKEND.
//TODO: Keep it free from any dependencies
export const AddOrEditNewTrack = (props) => {
  let { onSave, prevData, audioRef } = props;
  const [media, setMedia] = useState(prevData || mediaTemplate);
  // Incase we're editing prev. data
  useEffect(() => {
    setMedia(props.prevData);
  }, [props.prevData]);

  const mediaRef = useRef(null);
  audioRef = audioRef || mediaRef;
  //Applies to all inputs;
  function handleInput(value, key) {
    let updatedNewAudio;
    let valIsObject = typeof value === "object";
    let keyDef = key === undefined;
    if (valIsObject && keyDef) {
      updatedNewAudio = Object.assign({}, media, value);
    } else {
      updatedNewAudio = Object.assign({}, media);
      updatedNewAudio[key] = value;
    }
    setMedia(updatedNewAudio);
  }
  //TITLE
  function createTitleFromLink(text) {
    if (!text) return text;
    let title = text.split("/");
    title = title[title.length - 1]; //last item gets title
    title = title.split(".")[0]; // remove formatting at the end.
    title = title.replaceAll("%20", " "); // replace space
    title = title //Capitalize words
      .split(" ")
      .map((w) => w[0].toUpperCase() + w.slice(1))
      .join(" ");
    return title;
  }
  function editDropboxLink(v, ref) {
    let result = {};
    let text = v;
    let isDropboxLink = text.includes("dropbox.com") || text.includes("dropboxusercontent.com");
    if (v.indexOf(".com") > -1) {
      if (text.indexOf("www.") === -1) text = "www." + text;
      if (text.indexOf("https://") === -1) text = "https://" + text;
    }
    result.location = isDropboxLink ? "dropbox" : "other";
    text = text.replace("dropbox.com", "dl.dropboxusercontent.com");
    ref.value = text;
    result[ref.name] = text;
    handleInput(result);
  }
  //SELECT
  const selectOptions = [
    {
      label: "Add Video link",
      value: "link_video",
      fileType: "video/mp4",
      mediaType: "video",
    },
    {
      label: "Add Audio link",
      value: "link_audio",
      fileType: "audio/mp4",
      mediaType: "audio",
    },
    {
      label: "Upload New Audio file; Not Available",
      value: "upload_audio",
      isDisabled: true,
    },
    {
      label: "Upload New Video file; Not Available",
      value: "upload_video",
      isDisabled: true,
    },
  ];
  function selectMediaType(option) {
    console.log(option);
    if (option?.value?.includes("link")) {
      console.log(option.fileType, option.mediaType);
      const { fileType, mediaType } = option;
      handleInput({ fileType, mediaType });
    }
  }
  //FILE_NAME
  function handleFileNameChange(v, ref) {
    handleInput(v, ref.name);
  }
  function fileNameMouseDown(e) {
    e.preventDefault();
    let format = createTitleFromLink(media.uri);
    e.target.value = format;
    handleInput(format, e.target.name);
  }
  //DURATION
  function handleDurationChange(v, ref) {
    handleInput(v, ref.name);
  }
  function durationMouseDown(e) {
    if (!audioRef?.current?.duration) {
      alert("Something is wrong w. the link");
    } else {
      e.target.value = audioRef.current.duration;
      handleInput(audioRef.current.duration, e.target.name);
    }
  }
  //AUDIO PLAYER
  const AudioPlayer = () => {
    <Box type="1 row centered" background="white" width="45%" padding="0">
      <audio ref={audioRef} controls src={media.uri} />
    </Box>;
  };
  if (!media) return null;
  return (
    <>
      <Box type="1 row centered" background="none" flexFlow="row nowrap" justifyContent="space-between" padding="0">
        <TextField isDecorated={true} name="uri" width="90%" label="Dropbox Link" defaultValue={media.uri} onChange={editDropboxLink} />
      </Box>
      <Box type=" row" background="none" flexFlow="row nowrap" justifyContent="space-between" padding="1em 0">
        <SelectField classNamePrefix={"Select"} name="selectMediaType" width="45%" placeholder={"Select Media Type: Audio/Video"} onChange={(o) => selectMediaType(o)} options={selectOptions} />
        <TextField
          isDecorated={true}
          width="45%"
          style={{ fontSize: "14" }}
          name="fileName"
          placeholder="What do you want to call it?"
          defaultValue={media.fileName}
          onChange={handleFileNameChange}
          onMouseDown={fileNameMouseDown}
        />
      </Box>
      <Box type="row" background="none" justifyContent="space-between" padding="1em 0">
        <AudioPlayer />
        <TextField
          isDecorated={true}
          width="45%"
          name="duration"
          label="Click to get duration"
          type="number"
          defaultValue={media.duration}
          onMouseDown={durationMouseDown}
          onChange={handleDurationChange}
        />
      </Box>
    </>
  );
};
