import React, { useEffect, useContext, useState } from "react";
import { toast } from "react-toastify";

import { DeadlinedLayer, UnitLayer, TopModule } from "./components/modules.js";
import { getCourses, POST, DELETE, GET } from "../../../helpers/REST/api";
import { UserContext } from "../../../helpers/hooks/userContext.js";
import { Button } from "../../layout/buttons/buttons";
import "react-datepicker/dist/react-datepicker.css";

export default function Dashboard() {
  const { standing, studyPlan, setOverview, MID } = useContext(UserContext);

  async function deleteAllGates() {
    let result = await DELETE(`/members/id/${MID}/gates`).then((r) => r);
    alert(result);
  }
  async function enrol() {
    let result = await POST(
      `/members/enrol/product/626a0f0c379622863327ebc7/`,
      { userId: "626c6cfbd080617a34c19d01" }
    );
    alert(result);
  }
  useEffect(() => {
    /**
     * Gets student standing && study plan;
     * saves to context
     */
    const getOverview = async () => {
      /** 3 STEP PROCESS: Check, fetch, update **/
      //TODO: Lots more error checking
      const validateMemberId = async () =>
        await GET(`/members/id/${MID}/status/`);
      const fetchOverview = async () => await GET(`/members/${MID}/overview`);
      const updateStanding = async (overview) => {
        /** Check if index is updated **/
        let currentIndex = overview?.standing?.currentIndex;
        if (currentIndex && currentIndex.length === 0) {
          let query = { params: { membershipId: MID } };
          let currentIndex = await getCourses("/members/nextStep", query);
          overview.standing.currentIndex = currentIndex;
          return overview;
        } else {
          return overview;
        }
      };

      validateMemberId()
        .then(async (d) => {
          if (d) {
            let updated = await fetchOverview().then(updateStanding);
            setOverview(updated);
          }
        })
        .catch((e) => {
          toast.warning(e.message || "Member doesn't exist");
        });
    };
    // getOverview();
  }, []);
  function ControlButtons() {
    return (
      <>
        <Button
          onClick={() => {
            deleteAllGates();
          }}
        >
          Delete Gates
        </Button>
        <Button style={{ background: "#FF6600" }}>Delete Member</Button>
        <Button
          onClick={() => {
            enrol();
          }}
        >
          Enrol
        </Button>
        <Button style={{ background: "#7DFDFE", color: "black" }}>
          Create Member
        </Button>
      </>
    );
  }
  function ModuleList(moduleLayer, isTop) {
    const [hasSomeActive, setHasActive] = useState(null);

    async function activateModule(_id) {}
    return (
      Array.isArray(studyPlan) &&
      studyPlan?.map((item, index) => {
        let isLastItem = index === studyPlan.length - 1;
        let isntActive = !item.isActive;
        let noOtherActive = hasSomeActive === null;
        if (!isntActive && noOtherActive) {
          setHasActive(true);
        } else if (isLastItem && isntActive && noOtherActive) {
          activateModule(studyPlan[0]._id);
        }

        /**
         * Prev. logic:
         * Use "studyPlan.currentIndex" array to if hierarchy index matches this
         * **/

        return (
          <DeadlinedLayer
            {...item}
            index={index}
            key={item._id}
            isActive={item.isActive}
          />
        );
      })
    );
  }
  return (
    <div>
      <TopModule />
    </div>
  );
}

// /**
//  PRODUCT TOP LAYER (a.k.a. Module)
//  * @param  {object} props; Product Layer properties
//  * @property {string} props._id
//  * @property {string} props.startDate
//  * @property {string} props.title
//  * @property {string} props.description
//  * @property {string} props.isGated
//  * @property {array} props.children
//  */
// const TopModule = (props) => {
//   const { isActive, title, description, isGated, startDate, _id, progress } =
//     props;
//   return (
//     <ModuleWrap isActive={isActive}>
//       <Icon className="start">
//         {!isGated ? (
//           <RoundProgress
//             sqSize={"50"}
//             strWidth={"1"}
//             percentage={progress || 0}
//           />
//         ) : (
//           <Lock color="white" size="20px" />
//         )}
//       </Icon>

//       <Title className="middle">
//         <div>
//           {title}{" "}
//           <Info round={true} color={"white"} style={{ margin: "auto 0" }} />
//         </div>
//         <div className="desc">{description}</div>
//       </Title>

//       <ButtonWrap className="end">
//         {isActive ? (
//           !startDate ? (
//             <Deadline {...props} />
//           ) : (
//             <>
//               <Button style={{ height: "35px", fontWeight: 400 }}>
//                 Progress
//               </Button>
//               <Link to={`/gallery/${_id}`}>
//                 <Button style={{ height: "35px", fontWeight: 400 }}>
//                   Resume
//                 </Button>
//               </Link>
//             </>
//           )
//         ) : null}
//       </ButtonWrap>
//     </ModuleWrap>
//   );
// };
// /**
//  DEADLINE OR RESUME
//  * @param  {object} props; Product Layer properties
//  * @property {string} props._id
//  * @property {string} props.startDate
//  * @property {string} [props.title]
//  * @property {string} [props.description]
//  * @property {string} [props.isGated]
//  * @property {array} [props.children]
//  */

// export const Deadline = (props) => {
//   const { startDate, _id } = props;
//   const pickerRef = useRef();
//   const [loading, setLoading] = useState(null);
//   const [open, setOpen] = useState(false);
//   const [dateRange, setDateRange] = useState([new Date(), null]);
//   const [start, end] = dateRange;
//   const initiateModule = async () => {
//     await POST(`/members/id/6276bc940595d32004517f61/modules/${_id}/start`, {
//       productId: "626a0f0c379622863327ebc7",
//       startDate: start,
//       endDate: end,
//     }).then((result) => {
//       setLoading(false);
//     });
//   };
//   // DATE COMPONENTS
//   const DateWrapper = ({ className, children }) => {
//     return (
//       <DateWrap>
//         <h1 style={{ color: "black", margin: "0 auto" }}>Deadline</h1>
//         <h3>Expected Time To Complete 4 days</h3>
//         <h3>Recommended Deadline: 4 days</h3>
//         <h3>Current Days: </h3>
//         {children}
//         <BtnWrap>
//           <Button onClick={() => setOpen(false)}>Cancel</Button>
//           <Button
//             onClick={() => {
//               initiateModule();
//               setOpen(false);
//             }}
//           >
//             Save
//           </Button>
//         </BtnWrap>
//       </DateWrap>
//     );
//   };
//   const DateDropDown = () => {
//     if (!open) {
//       return null;
//     }
//     return (
//       <div
//         style={{
//           position: "absolute",
//           top: "50vh",
//           zIndex: "2",
//           width: "300px",
//           background: "white",
//           height: "auto",
//         }}
//       >
//         <DatePicker
//           ref={(ref) => {
//             pickerRef.current = ref;
//           }}
//           selectsRange={true}
//           startDate={start}
//           minDate={new Date()}
//           openToDate={new Date()}
//           endDate={end}
//           onChange={(update) => {
//             setDateRange(update);
//           }}
//           inline
//           calendarContainer={DateWrapper}
//         />
//       </div>
//     );
//   };

//   // BUTTON COMPONENTS
//   const SetDeadlineBtn = () => (
//     <Button
//       style={{ height: "35px", fontWeight: 400 }}
//       onClick={(e) => {
//         e.preventDefault();
//         setOpen(!open);
//         pickerRef?.current?.setOpen(open);
//       }}
//     >
//       Set Deadline
//     </Button>
//   );
//   const ResumeBtn = () => (
//     <Button
//       style={{ height: "35px", fontWeight: 400 }}
//       onClick={(e) => {
//         e.preventDefault();
//       }}
//       to={`/gallery`}
//     >
//       Resume
//     </Button>
//   );

//   if (startDate || loading === false) {
//     return <ResumeBtn />;
//   }
//   return (
//     <div>
//       <SetDeadlineBtn />
//       <DateDropDown />
//     </div>
//   );
// };
