import React, { useState, useEffect, useContext, useCallback } from "react";
import { useLocation, Navigate, useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { UserContext } from "./hooks/userContext";
import { POST } from "./REST/api";

import axios from "axios";

let AuthContext = React.createContext();

function AuthProvider({ children }) {
  let [user, setUser] = useState(null);
  let navigate = useNavigate();
  let location = useLocation();
  const isAuthenticated = user !== null;

  function getValidTicket() {
    let ticket = localStorage.getItem("ticket");
    let decoded = !!ticket && ticket !== "undefined" && jwt_decode(ticket);
    let ticketIsObject = typeof decoded === "object";
    let exp = typeof decoded.exp === "number" && decoded.exp;
    let isValid = Date.now() / 1000 < exp;
    console.log("Ticket validity: " + isValid);
    console.log("decoded", decoded, ticketIsObject, isValid);
    if (isValid && ticketIsObject) return decoded;
    return null;
  }

  function processSignIn(encryptedJwtToken) {
    let decoded = jwt_decode(encryptedJwtToken);
    localStorage.setItem("uid", decoded.id); // for long term.
    localStorage.setItem("ticket", encryptedJwtToken);
    axios.defaults.headers.authorization = "Bearer " + encryptedJwtToken;
    axios.defaults.baseURL = window.location.hostname.indexOf("localhost") > -1 ? "http://localhost:8000" : "https://api.sohaibahmed.ca";
    setUser(decoded);
    return decoded;
  }

  let signup = async (url, body) => {
    POST(url, body).then((d) => {
      processSignIn(d);
      return true;
    });
  };

  let signin = async (url, body) => {
    const { email, password } = body;
    let result = await POST(url, {
      email,
      password,
    }).then((d) => processSignIn(d));

    return result;
  };

  let signout = () => {
    setUser(null);
    localStorage.removeItem("uid");
    localStorage.removeItem("ticket");
    navigate("/login");
    console.log("Signing out");
  };
  let findAuth = useCallback(() => {
    let ticket = localStorage.getItem("ticket");
    let decoded = !!ticket && ticket !== "undefined" && jwt_decode(ticket);
    let ticketIsObject = typeof decoded === "object";
    let exp = typeof decoded.exp === "number" && decoded.exp;
    let isValid = Date.now() / 1000 < exp;
    if (ticket && ticketIsObject && isValid) {
      return true;
    }

    return false;
  }, [user]);
  //Sign-out;

  useEffect(() => {
    const unprotectedRoutes = ["/login", "/signup", "/"];
    if (user === null && !findAuth() && !unprotectedRoutes.includes(location.pathname)) {
      navigate("/login");
    }
  }, [location, navigate, user, findAuth]);

  useEffect(() => {
    if (user === null) {
      let decoded = getValidTicket();
      if (decoded !== null) setUser({ name: decoded.name, email: decoded.email, id: decoded.id });
    }
  }, []);
  let value = { user, signup, signin, signout, isAuthenticated, findAuth };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function useAuth() {
  return useContext(AuthContext);
}

function RequireAuth({ children }) {
  let foundAuth = useAuth().findAuth();
  let location = useLocation();

  if (!foundAuth) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
}

export { AuthProvider, useAuth, RequireAuth };
