import React, { useState, useRef, useEffect, useContext } from "react";

import { MediaStateProvider, MediaContext } from "./context";
import { Box } from "../../../layout/containers/box";
import VideoControls from "./controls/videoControls";
import { VidWrap, MediaWrap } from "./styled_video";
import { AudioQuestions } from "./features/addQs";
import { useParams } from "react-router-dom";
import { getFragmentAudio } from "../../../../helpers/REST/api";
import { QuestionView } from "../../../lecture/question/questionView";
import QuizView from "../../../lecture/question";
import { TrackWrap, Range } from "./styled_video";
import styled from "styled-components";
import Flag from "./icons/flag";

export default function MediaPlayer({ element, playlist, children }) {
  const mediaRef = useRef([]);

  const [tracks, setTracks] = useState([]);
  const [questions, setQuestions] = useState([]);

  //Block data is saved to db; inherited from editor.
  let { block_id } = useParams();

  useEffect(() => {
    async function fetchTracksOnLoad() {
      let fetched = await (async () => {
        let promised = await Promise.all(
          element?.mediaId.map(async (mediaId) => {
            let t = await getFragmentAudio(mediaId);
            return t;
          })
        );
        return promised;
      })();
      console.log(fetched);
      setTracks(fetched);
    }
    if (playlist) {
      setTracks(playlist?.track);
      if (playlist.questions) setQuestions(playlist.questions);
    } else if (Array.isArray(element?.mediaId) && element?.mediaId.length) {
      fetchTracksOnLoad();
    }
  }, [element, playlist]);
  /**
   * * Adds track object at index i.
   *
   * @param  {Object} obj Track
   * @param  {Number} i Index I
   **/

  return (
    <div style={{ position: "relative" }}>
      <MediaStateProvider
        value={{
          tracks,
          mediaRef,
          questions,
          setQuestions,
        }}
      >
        <div contentEditable={false}>
          <MediaElement tracks={tracks} />
        </div>
        {children ? <span>{children}</span> : null}
      </MediaStateProvider>
    </div>
  );
}

function MediaElement({ tracks }) {
  const { mediaRef, currentTrack, isMute, isFullScreen, handleKeyDown, handleKeyUp, setIsPlaying, isPlaying, togglePlay } = useContext(MediaContext);
  const fullScreenWrapRef = useRef();

  const RenderTrackbar = React.useCallback(() => <Trackbar tracks={tracks} />, [tracks]);
  const RenderControls = React.useCallback(() => <VideoControls tracks={tracks} />, [tracks]);

  //  Methods.
  // FULL SCREEN
  const checkFullScreen = (elem) => {
    return document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen;
  };
  const addRefToContext = (el, i) => {
    mediaRef.current[i] = el;
  };
  const enterFullScreen = (elem) => {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      elem.msRequestFullscreen();
    }
  };
  const exitFullScreen = () => {
    if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else {
      document.webkitCancelFullScreen();
    }
  };

  // Side Effects

  useEffect(() => {
    let fullScreenStatus = checkFullScreen(fullScreenWrapRef.current);
    if (isFullScreen && !fullScreenStatus) {
      enterFullScreen(fullScreenWrapRef.current);
    } else if (!isFullScreen && fullScreenStatus) {
      exitFullScreen(fullScreenWrapRef.current);
    }
  }, [isFullScreen]);

  return (
    <MediaWrap
      ref={fullScreenWrapRef}
      onKeyUp={(e) => {
        console.log("Key up @ Video Element ");
        handleKeyUp(e);
      }}
      onKeyPress={(e) => {
        console.log("Key up @ Video Element ");
        handleKeyDown(e);
      }}
      tabIndex="0"
    >
      {tracks &&
        tracks.map((track, i) => (
          <VidWrap isActive={i === currentTrack} key={"media_" + track.mediatype + i} onMouseDown={() => togglePlay()}>
            {track.mediaType === "video" ? (
              <video
                onContextMenu={(e) => {
                  e.preventDefault();
                  return false;
                }}
                src={track.uri}
                type={track.format}
                controlsList="nodownload"
                ref={(el) => {
                  addRefToContext(el, i);
                }}
                width={"100%"}
                height="auto"
                preload="true"
                muted={isMute}
              />
            ) : (
              <audio
                onContextMenu={(e) => {
                  e.preventDefault();
                  return false;
                }}
                src={track.uri}
                type={track.format}
                controlsList="nodownload"
                ref={(el) => {
                  addRefToContext(el, i);
                }}
                width={"100%"}
                height="auto"
                preload="true"
                muted={isMute}
              />
            )}
          </VidWrap>
        ))}
      <RenderTrackbar />
      <RenderControls />
    </MediaWrap>
  );
}

const Trackbar = ({ showControls, tracks }) => {
  const { currentTrack, mediaRef, onScrubEnd, onScrub, playerProgress, totalTime, rangeRef } = useContext(MediaContext);

  function handleTouchStart(e) {
    console.log("Touch start @ trackbar");
    var rect = e.target.getBoundingClientRect();
    var x = e.targetTouches[0].pageX - rect.left;
    onScrub((x / rangeRef.current.offsetWidth) * totalTime);
  }
  function handleMouseDown({ nativeEvent }) {
    console.log("Mouse down @ trackbar");
    onScrub((nativeEvent.offsetX / rangeRef.current.offsetWidth) * totalTime);
  }
  let value = isNaN(playerProgress / totalTime) ? 0 : (playerProgress / totalTime) * 100;

  if (mediaRef.current[currentTrack]) {
    return (
      <>
        {" "}
        <TrackWrap
          showControls={true}
          onMouseUp={() => {
            console.log("Mouse up @ trackbar");
            onScrubEnd();
          }}
          onTouchEnd={() => {
            console.log("TouchEnd @ trackbar");
            onScrubEnd();
          }}
        >
          <Range
            ref={rangeRef}
            min={0}
            max={100}
            onChange={() => {}} //not need
            value={value}
            type="range"
            step="1"
            onMouseDown={handleMouseDown}
            onTouchStart={handleTouchStart}
          />
          <progress min={0} max={100} value={value} />
        </TrackWrap>
        <QuestionBar />
      </>
    );
  } else {
    return null;
  }
};

const Bar = styled(Range).attrs({
  as: "div",
})`
  width: 100%;
  height: 10px;
  display: flex;
  flex-flow: row unwrap;
`;

const QIndicator = styled.div`
  position: relative;
  left: ${({ left }) => left || "0px"};
  height: 100%;
  width: 2px;
  position: absolute;
  top: -20px;
`;
function QuestionBar(props) {
  const { questions, setQuestions, nextQuestionIndex, setNextQuestion, totalTime, playerProgress, playDisabledAt, disablePlay, setShowQs } = useContext(MediaContext);

  const indicatorsRef = useRef([]);
  const qsBarRef = useRef();

  useEffect(() => {
    let qsExist = questions !== undefined && Array.isArray(questions) && questions.length > 0;
    let hasNextQs;
    console.log(qsExist, questions);
    if (qsExist) {
      questions.forEach((q, qI) => {
        if (hasNextQs === undefined) {
          hasNextQs = true;
          setNextQuestion(qI);
        }
      });
    }
  }, [questions, nextQuestionIndex, setNextQuestion]);

  useEffect(() => {
    if (nextQuestionIndex !== null && questions[nextQuestionIndex]?.time >= playerProgress && playDisabledAt === false) {
      console.log(questions[nextQuestionIndex]);
      disablePlay(questions[nextQuestionIndex]?.time);
    }
  }, [playerProgress]);

  useEffect(() => {}, [nextQuestionIndex]);
  function handleIndicRefs(el, i, q) {
    indicatorsRef.current[i] = el;
    if (el) {
      setQuesLocOnBar(indicatorsRef.current[i], q);
    }
  }
  function setQuesLocOnBar(elem, q) {
    //totalTime, givenTime; // givenFullWidth --> TotalTime
    let quesTime = q.time;
    let clientWidth = qsBarRef.current ? qsBarRef.current.clientWidth : 0;
    let conversion = (clientWidth / totalTime) * quesTime;
    elem.style.left = `${conversion}px`;
  }
  function colorFlags(q, qI) {
    if (q.passed === null) {
      return qI === nextQuestionIndex ? "red" : "transparent";
    }
    {
      return q.passed ? "green" : "red";
    }
  }

  return (
    <>
      <Bar ref={qsBarRef}>
        {questions !== undefined &&
          Array.isArray(questions) &&
          questions.map((q, qI) => (
            <QIndicator
              key={"indicator" + qI}
              ref={(el, qI) => {
                handleIndicRefs(el, qI, q);
                return el;
              }}
              onMouseDown={() => {}}
            >
              <Flag stroke={colorFlags(q, qI)} fill={colorFlags(q, qI)} />
            </QIndicator>
          ))}
      </Bar>
      <MediaModal>Question goes here</MediaModal>
    </>
  );
}

export const MediaModal = ({ children }) => {
  const { showQs, setShowQs, questions, setQuestions, nextQuestionIndex, disablePlay, togglePlay } = React.useContext(MediaContext);
  function evaluateQuestion() {}
  function submitQuestion() {
    let copy = [...questions];
    copy[nextQuestionIndex].passed = true;
    setQuestions(copy);
    setShowQs(false);
    disablePlay(false);
    togglePlay();
  }
  function cancelQuestion() {}
  if (!showQs) return null;
  return (
    <div style={{ position: "relative" }}>
      <Box
        type="col left"
        padding="0"
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          zIndex: "100",
        }}
        background="none"
      >
        <QuestionView data={questions[nextQuestionIndex].q} handleFinish={submitQuestion} />
      </Box>
    </div>
  );
};
