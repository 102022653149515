import React, { useState, useRef, useEffect, Children } from "react";
import { useSpring, animated as a } from "react-spring";
import { BoxWrap, CollapseWrap, DropWrap, TimelineProgress, TimelineWrap, DropDown2Wrap, Row } from "./boxStyles";
import { CloseBtn, DropBtn, Tab } from "../buttons/buttons";
import { CheckCircle } from "../../../images/icons";
import RoundProgress from "../../progressBar/round";
import { Rest } from "../../../helpers/REST/api";
import { Tooltip } from "antd";
import uuid4 from "uuid4";

/**
 *
 * @param {string} props.type "hidden" "wrap col", "row", "row nowrap", "1 row centered", "col left", "column", "card border", "square inline"
 * @param {string} props.background Pass rgb colors
 * @returns
 */
export const Box = ({ type, children, fwdRef, ...rest }) => {
  function handleType(boxType) {
    switch (boxType) {
      case "wrap col": {
        return {
          flexFlow: "column nowrap",
        };
      }
      case "row":
        return {
          flexFlow: "row wrap",
          border: "none",
        };
      case "row nowrap":
        return {
          flexFlow: "row nowrap",
          border: "none",
        };
      case "1 row centered":
        return {
          flexFlow: "row nowrap",
          justifyContent: "center",
          alignItems: "center",
        };
      case "col left":
        return {
          flexFlow: "column nowrap",
          justifyContent: "flex-start",
        };
      case "column":
        return {
          flexFlow: "column nowrap",
          alignItems: "center",
        };
      case "card_border":
        return { padding: "16px" };

      case "square inline":
        return {
          width: "0px",
          padding: "16px",
        };
      case "hidden":
        return {
          display: "hidden",
          visibility: "none",
          zIndex: "-100",
        };
      default:
        return { border: "none" };
    }
  }
  return (
    <BoxWrap {...handleType(type)} ref={fwdRef} {...rest}>
      {children}
    </BoxWrap>
  );
};
export const Card1 = ({ children, ...rest }) => {
  return (
    <Box type="col left" width="100%" margin="0 auto" backgroundColor="rgb(30.30,30)" style={{ height: "70dvh" }} {...rest}>
      {children}
    </Box>
  );
};
export const Circle = ({}) => {};

/**
 * @param {Boolean} props.isOpen
 * @param {Boolean} props.onClose
 * @param {String} props.title
 * @param {Boolean} props.showTitle
 * @param {Boolean} props.children
 * @param {Object} props...rest
 */
export const Collapse = ({ isOpen, onClose, title, showTitle = true, children, ...rest }) => {
  if (showTitle) {
    return (
      <Box type="col left" padding="0.8em">
        <Box type="row nowrap" justifyContent="space-between" background="none" padding="0" transform={isOpen ? "opacity(1)" : "opacity(0)"}>
          <h3>{title}</h3>
          <CloseBtn
            isOpen={isOpen}
            type={1}
            onClick={(e) => {
              onClose(e);
            }}
          />
        </Box>
        <CollapseWrap isOpen={isOpen} showTitle={showTitle} {...rest} background="none" style={!isOpen ? { padding: "0", margin: "0" } : {}}>
          <Box type="wrap col" background="none" padding="0">
            {children}
          </Box>
        </CollapseWrap>
      </Box>
    );
  }
  return (
    <CollapseWrap isOpen={isOpen} showTitle={showTitle} {...rest}>
      <Box type="wrap col" background="none" padding="0">
        {showTitle && (
          <Box type="row nowrap" justifyContent="space-between" background="none" transform={isOpen ? "opacity(1)" : "opacity(0)"}>
            <h2>{title}</h2>
            <CloseBtn
              onClick={(e) => {
                onClose(e);
              }}
            />
          </Box>
        )}
        {children}
      </Box>
    </CollapseWrap>
  );
};

/**
 * !TODO: CHANGE THE STYLES ON THIS. It's horrible
 * @param {Boolean} props.defaultOpen
 * @param {string} props.title
 * @param {Array} props.children
 * @param {Object} props.buttons
 * @param {Boolean} props.dropOnly
 * @returns
 */
export const Dropdown = ({ defaultOpen, title, children, btns, dropOnly, ...rest }) => {
  const [isOpen, setIsOpen] = useState(defaultOpen || false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  function Drop() {
    return (
      <DropWrap isOpen={isOpen} {...rest} background="rgb(30,30,30)">
        <Box type="wrap col" background="none" height="500px" overFlowY="scroll">
          {children}
        </Box>
      </DropWrap>
    );
  }
  if (dropOnly) {
    return <Drop />;
  }

  return (
    <>
      <Box
        type="1 row centered"
        justifyContent="space-between"
        alignItems="center"
        background="rgb(30,30,30)"
        padding="16px"
        transform={isOpen ? "opacity(1)" : "opacity(0)"}
        onMouseDown={() => {
          toggle();
        }}
        style={{
          border: isOpen ? "1px solid var(--primary-color)" : "1px solid transparent",
        }}
      >
        <h4>{title}</h4>
        {btns}
        <DropBtn isOpen={isOpen} />
      </Box>
      <Drop />
    </>
  );
};

export const Dropdown2 = ({ parentRef, title, isOpen, setIsOpen, children, wide, background }) => {
  // const [isOpen, setIsOpen] = useState(false);
  const dropRef = useRef(null);
  useEffect(() => {
    if (isOpen && dropRef?.current) {
      //Goals: hide on outside click && show after repositioned.
      if (parentRef) {
        let parentRect = parentRef.current.getBoundingClientRect();
        let rect = dropRef.current.getBoundingClientRect();
        if (wide) {
          dropRef.current.style.top = parentRect.top + 40 + "px";
          dropRef.current.style.left = parentRect.left - 120 + "px";
        }

        if (wide)
          if (wide)
            if (!wide) {
              //Used for options constrained in the margin. Wide used for profile button on the header

              dropRef.current.style.left = parentRect.left - 50 + "px";
              let bottom = parentRect.bottom;
              if (window.innerHeight < bottom) {
                let height = rect.height;
                dropRef.current.style.top = -1 * height - 25 + "px";
              }
              const windowRight = window.innerWidth - window.innerWidth * 0.2;

              const rightBorder = parentRect.x + parseInt(dropRef.current.offsetWidth);
              const newLeftBorder = windowRight - parseInt(dropRef.current.offsetWidth) - 150;

              if (windowRight < rightBorder) {
                dropRef.current.style.left = newLeftBorder + "px";
              }
            }
        dropRef.current.style.display = "block"; // To show after repositioned; else jumps on screen.
      }
    }
  }, [isOpen, parentRef]);

  if (!title) {
    if (!isOpen) return null;
    return (
      <Box
        fwdRef={dropRef}
        style={{
          position: "absolute",
          display: "none",
          overflowY: "scroll",
          zIndex: "10",
        }}
        padding="0"
        margin="0"
        background={"white"}
        width="auto"
        type="col left"
        // overflowY="scroll"
        height="250px"
        className="dropdown"
      >
        {children &&
          Children.map(children, (child) => (
            <Row className="hover_primary" style={{ color: "black" }} {...child.props}>
              {child}
            </Row>
          ))}
      </Box>
    );
  }

  return (
    <DropDown2Wrap
      isOpen={isOpen}
      onMouseDown={(e) => {
        e.preventDefault();
        setIsOpen(!isOpen);
      }}
    >
      {title || "Select..."}
      {isOpen && children?.length && (
        <Box
          fwdRef={dropRef}
          style={{
            position: "absolute",
            top: "75px",

            overflowY: "scroll",
          }}
          padding="0"
          margin="0"
          background="white"
          width="150px"
          type="col left"
          overflowY="scroll"
          height="250px"
        >
          {children && Children.map(children, (child) => <Row>{child}</Row>)}
        </Box>
      )}
    </DropDown2Wrap>
  );
};

export const Dropdown3 = ({ children, disabled, title, active, wrapStyles, ...rest }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropRef = useRef(null);
  return (
    <DropDown2Wrap
      isOpen={isOpen}
      onMouseDown={(e) => {
        e.preventDefault();
        e.stopPropagation();
        if (!disabled) setIsOpen(!isOpen);
      }}
      style={{ ...wrapStyles, background: disabled ? "#444" : wrapStyles?.background }}
    >
      {title || "Select..."}
      {isOpen && children?.length && (
        <Box
          fwdRef={dropRef}
          style={{
            position: "absolute",
            top: "75px",

            overflowY: "scroll",
          }}
          padding="0"
          margin="0"
          background="white"
          width="150px"
          type="col left"
          overflowY="scroll"
          height="250px"
        >
          {Children.map(children, (child, index) => {
            return (
              <Row key={uuid4()} {...rest} active={child.props.active}>
                {child}
              </Row>
            );
          })}
        </Box>
      )}
    </DropDown2Wrap>
  );
};

export const TabGroup = ({ children, tabs, setTab, width, activeIndex, alignTabs, style, ...rest }) => {
  function onKeyDown(e) {
    if (e.keyCode === 39 || e.keyCode === 37) {
      if (e.keyCode === 39) {
        //* Move right
        tabs[activeIndex + 1] !== undefined ? setTab(activeIndex + 1) : setTab(0);
      } else if (e.keyCode === 37) {
        //* Move left
        activeIndex === 0 ? setTab(tabs.length - 1) : setTab(activeIndex - 1);
      }
    }
  }
  return (
    <Box type="column" background="none" {...rest} style={{ paddingLeft: "0", paddingRight: "0", ...style }}>
      <Box
        type="row wrap"
        onKeyDown={onKeyDown}
        role="tablist"
        padding="0"
        justifyContent={alignTabs || "center"}
        margin="0 0 16px 0"
        style={{
          overFlowX: "scroll",
          overFlowY: "hidden",
          width: width ?? undefined,
        }}
      >
        {tabs &&
          tabs.map((t, i) => (
            <Tab
              isActive={i === activeIndex}
              key={`tabGroup_${t}_${i}`}
              role="tab"
              aria-selected={(i === activeIndex).toString()}
              aria-controls={`panel-${i + 1}`}
              tabIndex={i === activeIndex ? "0" : "-1"}
              onMouseDown={() => {
                setTab(i);
              }}
            >
              {t}
            </Tab>
          ))}
      </Box>

      {children}
    </Box>
  );
};

/**
 * Made so that the tab heading & child can be passed directly; The state is internal to the component.
 * @param {Array} props.tabs Array of objects.
 * @param {String} props.tabs.title Title of the given component.
 * @param {String} props.tabs.Component The component itself.
 * @param {Function} props.apiCb API Callback: e.g. onChange etc.
 * @returns
 */
export const TabGroupInternal = ({ children, tabs, apiCb, ...rest }) => {
  const [activeIndex, setTab] = useState(0);
  function onKeyDown(e) {
    if (e.keyCode === 39 || e.keyCode === 37) {
      if (e.keyCode === 39) {
        //* Move right
        tabs[activeIndex + 1] !== undefined ? setTab(activeIndex + 1) : setTab(0);
      } else if (e.keyCode === 37) {
        //* Move left
        activeIndex === 0 ? setTab(tabs.length - 1) : setTab(activeIndex - 1);
      }
    }
  }
  let TabItem = tabs[activeIndex].Component;
  return (
    <Box type="column" background="none" padding="0" {...rest}>
      <Box
        type="row wrap"
        onKeyDown={onKeyDown}
        role="tablist"
        padding="0"
        justifyContent={"center"}
        margin="16px 0 16px 0"
        overFlowX="scroll"
        overFlowY="hidden"

        // justifyContent="space-between"
      >
        {tabs &&
          tabs.map((tab, i) => (
            <Tab
              isActive={i === activeIndex}
              key={`tabGroup_${tab.title}_${i}`}
              role="tab"
              aria-selected={(i === activeIndex).toString()}
              aria-controls={`panel-${i + 1}`}
              tabIndex={i === activeIndex ? "0" : "-1"}
              onMouseDown={() => {
                setTab(i);
              }}
            >
              {tab.title}
            </Tab>
          ))}
      </Box>

      <TabItem />
    </Box>
  );
};
export const TabBtn = Tab;

export const PanelHeader = ({ onClose, title, isOpen, children, ...rest }) => {
  return (
    <Box type="row nowrap" justifyContent="space-between" background="none" transform={isOpen ? "opacity(1)" : "opacity(0)"} {...rest}>
      <h3 style={{ color: "inherit" }}>{title}</h3>
      <CloseBtn
        onClick={() => {
          onClose();
        }}
        style={{ cursor: "pointer" }}
      />
    </Box>
  );
};

export const Timeline = ({ children, isComplete, progress, ...props }) => {
  const title = progress === 1 ? "Completed!" : progress === 0 ? "Not started" : `${Math.ceil(progress * 100) || 0}% completed`;
  return (
    <TimelineWrap {...props} width={props.width ?? "100%"}>
      <Tooltip title={title}>
        <TimelineProgress>{isComplete ? <CheckCircle /> : <RoundProgress fill_stroke={"red"} sqSize={"21"} strWidth={"2"} percentage={progress * 100 || 0} showPercentage={false} />}</TimelineProgress>
      </Tooltip>
      {children}
    </TimelineWrap>
  );
};
