import styled from "styled-components";
import { useState, useContext, useEffect } from "react";
import { UserContext } from "../../helpers/hooks/userContext";
import { useAuth } from "../../helpers/auth";
import axios from "axios";

const Modal = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justiify-content: center;
  position: absolute;
  top: 4em;
  right: 2em;
  background: rgb(40, 40, 40, 0.7);
  overflow-y: auto;
  border-radius: var(--border-radius-large);

  padding: 5px 10px;
`;
const BottomLeft = styled.div`
  position: absolute;
  display: fixed;
  top: 1em;
  left: 1em;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1px solid green;
  z-index: 100000000000;
`;
const Row = styled.div`
  display: flex;
  flex-flow: row wrap;
  height: 40px;
  width: 100%;
  min-width: 300px;
  align-items: center;
  user-select: none;
  justify-content: space-between;

  & span {
    color: grey;
  }
`;

const ModalDisplay = () => {
  const { UID, PID, MID } = useContext(UserContext);

  const Auth = useAuth();
  let [data, setData] = useState(Array.from(Array(9).keys()));
  const [row, setRow] = useState([
    { n: "Server-1: Auth" },
    { n: "Server-2: Main" },
    { n: "UID" },
    { n: "MID" },
    { n: "PID" },
    { n: "Wallet has ticket?" },
    { n: "Are you saying that off memory?" },
    { n: "But, you also checked your wallet?" },
    { n: "And, server accepted this ticket?" },
  ]);

  useEffect(() => {
    async function checkServer1() {
      let url = window.location.hostname.indexOf("localhost") > -1 ? "http://localhost:8050/is_online" : "https://auth.sohaibahmed.ca/is_online";
      const result = await axios
        .get(url)
        .then((d) => "✅")
        .catch((err) => {
          return "❌";
        });
      return result;
    }
    async function checkServer2() {
      let url = window.location.hostname.indexOf("localhost") > -1 ? "http://localhost:8000/is_online" : "https://api.sohaibahmed.ca/is_online";
      const result = await axios
        .get(url)
        .then((d) => "✅")
        .catch((err) => {
          return "❌";
        });
      return result;
    }
    function checkTicket() {
      let t = localStorage.getItem("ticket");
      return t !== undefined && t !== "" && t !== "undefined" ? "✅" : "❌";
    }
    function technicallyLoggedIn() {
      return Auth.isAuthenticated ? "✅" : "❌";
    }
    function findAuth() {
      return Auth.findAuth() ? "✅" : "❌";
    }
    async function verifyJWT() {
      let url = window.location.hostname.indexOf("localhost") > -1 ? "http://localhost:8050/verify" : "https://auth.sohaibahmed.ca/verify";
      const result = await axios
        .get(url)
        .then((d) => "✅")
        .catch((err) => {
          return "❌";
        });
      return result;
    }
    Promise.all([
      checkServer1(),
      checkServer2(),
      UID && UID.length ? "✅" : "❌",
      PID && PID.length ? "✅" : "❌",
      MID && MID.length ? "✅" : "❌",
      checkTicket(),
      technicallyLoggedIn(),
      findAuth(),
      verifyJWT(),
    ]).then((d) => {
      setData(d);
    });
  }, []);
  return (
    <Modal>
      {row.map((r, ri) => {
        let a = r.md;
        return (
          <Row key={r.n + ri}>
            <span>{r.n}</span>
            <span>{data[ri]}</span>
          </Row>
        );
      })}
    </Modal>
  );
};
const Monitor = () => {
  return <ModalDisplay />;
};
export default Monitor;
