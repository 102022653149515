import { useState, useContext } from "react";
import { Box } from "../../layout/containers/box";
import { Button } from "../../layout/buttons/buttons";
import { TextField } from "../../layout/containers/input";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../helpers/hooks/userContext";
import { toast } from "react-toastify";
import { POST_Doc } from "../../../helpers/REST/api";

const CreateDoc = () => {
  const { UID } = useContext(UserContext);
  const [docTitle, setDocTitle] = useState(null);
  const [settings, setSettings] = useState({
    audience: "",
    editors: [],
    viewers: [],
  });
  const navigate = useNavigate();
  function updateTitle(v) {
    setDocTitle(v);
  }
  async function createDocument(e) {
    e.preventDefault();
    if (!UID) {
      alert("Not logged in");
    } else if (typeof docTitle === "string" && docTitle.length > 0) {
      let newDoc = POST_Doc(UID, docTitle)
        .then((res) => {
          toast.success("Document created");
          return res.data;
        })
        .catch((e) => {
          toast.warn("Error creating document");
          throw new Error(e);
        });
      setDocTitle(newDoc);
      localStorage.setItem(newDoc._id.toString(), JSON.stringify(newDoc));
      navigate(`/documents/${newDoc._id}`);
    }
  }
  return (
    <Box
      width="100%"
      minHeight="100vh"
      background="none"
      position="absolute"
      left="0"
      type="col left"
    >
      <h1>Created Doc</h1>
      <Box
        type="1 row cenetered"
        background="none"
        alignItems="centered"
        justifyContent="space-between"
      >
        <TextField
          onChange={updateTitle}
          name="document_title"
          isDecorated={true}
          label="Title"
        />

        <Button
          onMouseDown={(e) => createDocument(e)}
          onChange={updateTitle}
          disabled={docTitle === null || !docTitle?.length}
        >
          Create
        </Button>
      </Box>
    </Box>
  );
};
export default CreateDoc;
