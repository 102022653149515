import { GET } from "../../helpers/REST/api";
import { BE_AUTH } from "../../helpers/URI";
export async function fetchAllUsers() {
  return await GET(BE_AUTH("/users/list/"));
}
export async function fetch10More(skip) {
  return await GET(BE_AUTH(`/users/lists/fromTill/?skip=${skip || 0}`));
}
export async function searchUsers(text) {
  return await GET(BE_AUTH(`/users/search/?search=${text}`));
}
export async function countUsers() {
  return await GET(BE_AUTH(`/users/count`));
}
