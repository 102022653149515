import React, { useEffect, useContext, useRef } from "react";
import styled from "styled-components";
import { MediaContext } from "../context";
import { Range } from "../styled_video";
import Flag from "../icons/flag";
import { Modal } from "antd";

//Entire thing is only useful if we have questions at all. So it shouldn't be rendered otherwise/
// if questions;
//check what's been attempted so far. if not null, then place them inside at their designated times.
//Look for the next guy, and then kind of place him in there?
//Hovering on each of them should lead to something.

//shouldn't be able to play further past the next question.

const Bar = styled(Range).attrs({
  as: "div",
})`
  width: 100%;
  height: 10px;
  display: flex;
  flex-flow: row unwrap;
`;

const QIndicator = styled.div`
  position: relative;
  left: ${({ left }) => left || "0px"};
  height: 100%;
  width: 2px;
  position: absolute;
  top: -20px;
`;
export default function QuestionBar(props) {
  const {
    questions,
    setQuestions,
    nextQuestionIndex,
    setNextQuestion,
    totalTime,
    playerProgress,
    playDisabledAt,
    disablePlay,
    setShowQs,
  } = useContext(MediaContext);

  const indicatorsRef = useRef([]);
  const qsBarRef = useRef();

  useEffect(() => {
    let qsExist =
      questions !== undefined &&
      Array.isArray(questions) &&
      questions.length > 0;
    let hasNextQs;
    console.log(qsExist, questions);
    if (qsExist) {
      questions.forEach((q, qI) => {
        if (q.passed === null && hasNextQs === undefined) {
          hasNextQs = true;
          setNextQuestion(qI);
        }
      });
    }
  }, [questions, nextQuestionIndex]);

  useEffect(() => {
    if (
      nextQuestionIndex !== null &&
      questions[nextQuestionIndex]?.time >= playerProgress &&
      playDisabledAt === false
    ) {
      console.log(questions[nextQuestionIndex]);
      disablePlay(questions[nextQuestionIndex]?.time);
    }
  }, [playerProgress]);

  useEffect(() => {}, [nextQuestionIndex]);
  function handleIndicRefs(el, i, q) {
    indicatorsRef.current[i] = el;
    if (el) {
      setQuesLocOnBar(indicatorsRef.current[i], q);
    }
  }
  function setQuesLocOnBar(elem, q) {
    //totalTime, givenTime; // givenFullWidth --> TotalTime
    let quesTime = q.time;
    let clientWidth = qsBarRef.current ? qsBarRef.current.clientWidth : 0;
    let conversion = (clientWidth / totalTime) * quesTime;
    elem.style.left = `${conversion}px`;
  }
  function colorFlags(q, qI) {
    if (q.passed === null) {
      return qI === nextQuestionIndex ? "red" : "transparent";
    }
    {
      return q.passed ? "green" : "red";
    }
  }

  return (
    <>
      <Bar ref={qsBarRef}>
        {questions !== undefined &&
          Array.isArray(questions) &&
          questions.map((q, qI) => (
            <QIndicator
              key={"indicator" + qI}
              ref={(el, qI) => {
                handleIndicRefs(el, qI, q);
                return el;
              }}
              onMouseDown={() => {}}
            >
              <Flag stroke={colorFlags(q, qI)} fill={colorFlags(q, qI)} />
            </QIndicator>
          ))}
      </Bar>
      <MediaModal>Question goes here</MediaModal>
    </>
  );
}

export const MediaModal = ({ children }) => {
  const {
    showQs,
    setShowQs,

    questions,
    setQuestions,
    nextQuestionIndex,
    disablePlay,
    togglePlay,
  } = React.useContext(MediaContext);
  function evaluateQuestion() {}
  function submitQuestion() {
    let copy = [...questions];
    copy[nextQuestionIndex].passed = true;
    setQuestions(copy);
    setShowQs(false);
    disablePlay(false);
    togglePlay();
  }
  function cancelQuestion() {}
  return (
    <Modal
      title="Questions"
      visible={showQs}
      onOk={() => submitQuestion()}
      // confirmLoading={confirmLoading}
      onCancel={() => setShowQs(false)}
    >
      <p>Question appears here</p>
    </Modal>
  );
};
