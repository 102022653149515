import styled from "styled-components";
import React, {
  useEffect,
  useRef,
  useState,
  createContext,
  useContext,
} from "react";
import { qModalStyle } from "../styled";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Modal, Tabs } from "antd";

import QsEditor from "../../questionEditor";
import { useSlate } from "slate-react";
import { Transforms } from "slate";
import { useParams } from "react-router-dom";
import { createQuestion, updateQuestion } from "../../../../helpers/REST/api";
import { PlainTextInput } from "./styles";
import { Checkbox } from "../../../layout/selection/checkbox";
import { Radio } from "../../../layout/selection/radio";
import { Box } from "../../../layout/containers/box";
import { toast } from "react-toastify";
import { QuestionAudioRecorder } from "../../../media/audioRecorder";
import {
  AddBtn,
  Button,
  CloseBtn,
  DeleteBtn,
} from "../../../layout/buttons/buttons";
import { RounderBtn } from "../../../layout/buttons/buttonStyles";
import { TextField } from "../../../layout/containers/input";
import { GET, PUT } from "../../../../helpers/REST/api";

const { Label, InputWrapper, Input, Select } = qModalStyle;

//helper function: Applies to all Question types
async function createQsInDb(block_id, state, editor, path) {
  if (!block_id) {
    toast.warn(
      "There is no question-block_identifier -- you won't be able to filter questions by block. Ask so for a quick fix"
    );
  }
  let question = Object.assign({}, state);
  question.belongsToBlock = [block_id];
  await createQuestion(question)
    .then((savedId) => {
      let qidAttribute = {};
      let key = "qid_" + savedId;
      qidAttribute[key] = true;
      Transforms.setNodes(editor, qidAttribute, { at: path, voids: true });
      toast.success("Question saved and added");
      return savedId;
    })
    .catch((e) => {
      toast.error("Error saving question: " + e.message);
    });
}

export function MultipleAnswers({
  mainEditor,
  path,
  data,
  questionId,
  editorClassName,
}) {
  const editor = useSlate();
  const { id, block_id } = useParams();
  if (!block_id) {
    alert("No blockId");
  }
  const [state, setState] = React.useState(
    data || {
      questionAudio: undefined,
      questionType: "mcq",
      isPublished: false,
      isArchived: false,
      question: "",
      options: [],
    }
  );

  async function saveChangesInDb() {
    await updateQuestion(data._id, state);
  }
  function handleEditorSave(stateKey, value, ansIndex, ansProperty) {
    let newState = Object.assign({}, state);
    if (stateKey === "question") {
      newState.question = value;
      setState(newState);
      return;
    } else if (stateKey === "options") {
      if (newState.options[ansIndex] === undefined) {
        let ansChoiceObj = {};
        ansChoiceObj[ansProperty] = value;
        newState.options[ansIndex] = ansChoiceObj;
      } else {
        newState.options[ansIndex][ansProperty] = value;
      }
      setState(newState);
      return;
    }
  }
  function addAnsChoice(e) {
    e.preventDefault();
    console.log("mouse down");
    let newState = Object.assign({ options: [] }, state);
    newState.options.push({ isCorrect: false });
    setState(newState);
    return;
  }
  function toggleAnsCorrectness(e, ansIndex) {
    let newState = Object.assign({}, state);
    let choice = newState.options[ansIndex];
    choice.isCorrect = !choice.isCorrect;
    setState(newState);
  }
  function manageSaving() {
    if (!questionId) {
      return (
        <button
          onClick={() => createQsInDb(block_id || id, state, editor, path)}
        >
          Create Question
        </button>
      );
    } else if (JSON.stringify(data) !== JSON.stringify(state)) {
      return <button onClick={() => saveChangesInDb()}>Save Question</button>;
    }
  }
  function attachAudioIdToQs({ _id }) {
    if (state === null || state === undefined) {
      setState({ questionAudio: _id });
    } else if (typeof state === "object") {
      setState({ ...state, questionAudio: _id });
    }
  }

  return (
    <>
      <Box type="column" background="transparent">
        <QuestionAudioRecorder uploadCallback={attachAudioIdToQs} />
      </Box>
      <QsEditor
        placeholder="Type question..."
        background={"transparent"}
        onChange={(v) => handleEditorSave("question", v)}
        content={state.question}
        className={editorClassName}
      />
      <button onMouseDown={(e) => addAnsChoice(e)}>Add Answer</button>
      {state.options.map((item, i) => {
        return (
          <Box
            type="1 row centered"
            background="transparent"
            bRadius="0"
            width="95%"
            padding="0"
            justifyContent="flex-start"
            key={`ansChoice_question_${i}`}
          >
            <Checkbox
              checked={!!item.isCorrect}
              onChange={(e) => {
                toggleAnsCorrectness(e, i);
              }}
              style={{
                position: "relative",
                top: "-5px",
              }}
            />

            <QsEditor
              content={item.choice}
              placeholder="Type Answer..."
              background={"transparent"}
              onChange={(v) => handleEditorSave("options", v, i, "choice")}
              width="80%"
              className={editorClassName}
            />
          </Box>
        );
      })}
      {manageSaving()}
    </>
  );
}
export function SingleAnswerMCQ({
  mainEditor,
  path,
  data,
  questionId,
  background,
}) {
  const editor = useSlate();
  const { block_id, id } = useParams();
  if (!block_id) {
    alert("No blockId");
  }
  const [state, setState] = React.useState(
    data || {
      questionType: "mcq",
      isPublished: false,
      isArchived: false,
      question: "",
      options: [],
    }
  );

  async function saveChangesInDb() {
    await updateQuestion(data._id, state);
  }
  function handleEditorSave(stateKey, value, ansIndex, ansProperty) {
    let newState = Object.assign({}, state);
    if (stateKey === "question") {
      newState.question = value;
      setState(newState);
      return;
    } else if (stateKey === "options") {
      if (newState.options[ansIndex] === undefined) {
        let ansChoiceObj = {};
        ansChoiceObj[ansProperty] = value;
        newState.options[ansIndex] = ansChoiceObj;
      } else {
        newState.options[ansIndex][ansProperty] = value;
      }
      setState(newState);
      return;
    }
  }

  function toggleAnsCorrectness(e, ansIndex) {
    let newState = Object.assign({}, state);
    newState.options[ansIndex].isCorrect = true;

    newState.options[ansIndex === 0 ? 1 : 0].isCorrect = false;

    setState(newState);
  }
  function manageSaving() {
    if (!questionId) {
      return (
        <button
          className="btn-right"
          onClick={() => createQsInDb(block_id || id, state, editor, path)}
        >
          Create Question
        </button>
      );
    } else if (JSON.stringify(data) !== JSON.stringify(state)) {
      return (
        <button className="btn-right" onClick={() => saveChangesInDb()}>
          Save Question
        </button>
      );
    }
  }
  function attachAudioIdToQs({ _id }) {
    if (state === null || state === undefined) {
      setState({ questionAudio: _id });
    } else if (typeof state === "object") {
      setState({ ...state, questionAudio: _id });
    }
  }
  return (
    <>
      <Box background="transparent" type="row" width="50vw" padding="0">
        <Box type="column" background="transparent">
          <QuestionAudioRecorder uploadCallback={attachAudioIdToQs} />
        </Box>
        <QsEditor
          placeholder="Type question..."
          background={"transparent"}
          onChange={(v) => handleEditorSave("question", v)}
          content={state.question}
        />
      </Box>
      {state.options.map((op, i) => (
        <Box
          type="1 row centered"
          background="transparent"
          bRadius="0"
          width="95%"
          padding="0"
          justifyContent="flex-start"
          key={`mcq_answerChoice_${i}`}
        >
          <Radio
            checked={!!op.isCorrect}
            style={{ position: "relative", top: "-6px" }}
            onChange={(e) => toggleAnsCorrectness(e, i)}
          />

          <QsEditor
            content={op.choice}
            placeholder="Type Answer..."
            background={"transparent"}
            onChange={(v) => handleEditorSave("options", v, i, "choice")}
            width="80%"
          />
        </Box>
      ))}
    </>
  );
}
export function TrueOrFalse({
  mainEditor,
  path,
  data,
  questionId,
  background,
}) {
  const editor = useSlate();
  const { block_id, id } = useParams();
  if (!block_id) {
    alert("No blockId");
  }
  const [state, setState] = React.useState(
    data || {
      questionType: "boolean",
      isPublished: false,
      isArchived: false,
      question: "",
      correctAns: null,
      options: [
        { isCorrect: true, choice: ["True"] },
        { isCorrect: false, choice: ["False"] },
      ],
      refs: [],
      explantion: [],
    }
  );
  // async function createQsInDb() {
  //   if (!id) {
  //     alert(
  //       "There is no questionblock_id -- you won't be able to filter questions by block. Ask so for a quick fix"
  //     );
  //   }
  //   let question = Object.assign({}, state);
  //   question.belongsToBlock = [id];
  //   let savedId = await createQuestion(question);
  //   if (!!savedId) {
  //     Transforms.setNodes(
  //       editor,
  //       { questionId: savedId },
  //       { at: path, voids: true }
  //     );
  //     alert("should be done?");
  //   }
  // }

  async function saveChangesInDb() {
    await updateQuestion(data._id, state);
  }
  function handleEditorSave(stateKey, value, ansIndex, ansProperty) {
    let newState = Object.assign({}, state);
    if (stateKey === "question") {
      newState.question = value;
      setState(newState);
      return;
    } else if (stateKey === "options") {
      if (newState.options[ansIndex] === undefined) {
        let ansChoiceObj = {};
        ansChoiceObj[ansProperty] = value;
        newState.options[ansIndex] = ansChoiceObj;
      } else {
        newState.options[ansIndex][ansProperty] = value;
      }
      setState(newState);
      return;
    }
  }

  function toggleAnsCorrectness(e, ansIndex) {
    let newState = Object.assign({}, state);
    newState.options[ansIndex].isCorrect = true;

    newState.options[ansIndex === 0 ? 1 : 0].isCorrect = false;
    console.log(newState);
    setState(newState);
  }
  function manageSaving() {
    if (!questionId) {
      return (
        <button
          className="btn-right"
          onClick={() => createQsInDb(block_id || id, state, editor, path)}
        >
          Create Question
        </button>
      );
    } else if (JSON.stringify(data) !== JSON.stringify(state)) {
      return (
        <button className="btn-right" onClick={() => saveChangesInDb()}>
          Save Question
        </button>
      );
    }
  }
  function attachAudioIdToQs({ _id }) {
    if (state === null || state === undefined) {
      setState({ questionAudio: _id });
    } else if (typeof state === "object") {
      setState({ ...state, questionAudio: _id });
    }
  }
  return (
    <div style={{ width: "50vw" }}>
      <Box type="column" background="transparent">
        <QuestionAudioRecorder uploadCallback={attachAudioIdToQs} />
      </Box>
      <QsEditor
        placeholder="Type question..."
        background={"transparent"}
        onChange={(v) => handleEditorSave("question", v)}
        content={state.question}
      />
      <div>
        <span
          onClick={(e) => {
            toggleAnsCorrectness(e, 0);
          }}
        >
          <Radio name="true" checked={!!state.options[0].isCorrect} />
          <Label htmlFor="true">True</Label>
        </span>
        <span
          onClick={(e) => {
            toggleAnsCorrectness(e, 1);
          }}
        >
          <Radio name="false" checked={!!state.options[1].isCorrect} />
          <Label htmlFor="False">False</Label>
        </span>
      </div>
      {manageSaving()}
    </div>
  );
}
export function FindEvidence({ mainEditor, path, data, questionId }) {
  const editor = useSlate();
  const { block_id, id } = useParams();
  if (!block_id) {
    alert("No blockId");
  }
  const fieldRef = useRef([]);
  const [activeField, setActiveField] = useState(null);
  const [state, setState] = React.useState(
    data || {
      questionType: "mcq",
      isPublished: false,
      isArchived: false,
      question: "",
      options: [],
    }
  );
  // async function createQsInDb() {
  //   if (!id) {
  //     alert(
  //       "There is no questionblock_id -- you won't be able to filter questions by block. Ask so for a quick fix"
  //     );
  //   }
  //   let question = Object.assign({}, state);
  //   question.belongsToBlock = [id];
  //   let savedId = await createQuestion(question);
  //   if (!!savedId) {
  //     Transforms.setNodes(
  //       editor,
  //       { questionId: savedId },
  //       { at: path, voids: true }
  //     );
  //     alert("should be done?");
  //   }
  // }

  async function saveChangesInDb() {
    await updateQuestion(data._id, state);
  }
  function handleEditorSave(stateKey, value, ansIndex, ansProperty) {
    let newState = Object.assign({}, state);
    if (stateKey === "question") {
      newState.question = value;
      setState(newState);
      return;
    } else if (stateKey === "options") {
      if (newState.options[ansIndex] === undefined) {
        let ansChoiceObj = {};
        ansChoiceObj[ansProperty] = value;
        newState.options[ansIndex] = ansChoiceObj;
      } else {
        newState.options[ansIndex][ansProperty] = value;
      }
      setState(newState);
      return;
    }
  }
  function addAnsChoice(e) {
    e.preventDefault();

    let newState = Object.assign({ options: [] }, state);
    newState.options.push({ isCorrect: false });
    setState(newState);
    return;
  }
  function toggleAnsCorrectness(e, ansIndex) {
    let newState = Object.assign({}, state);
    let choice = newState.options[ansIndex];
    choice.isCorrect = !choice.isCorrect;
    setState(newState);
  }
  function manageSaving() {
    if (!questionId) {
      return (
        <button
          onClick={() => createQsInDb(block_id || id, state, editor, path)}
        >
          Create Question
        </button>
      );
    } else if (JSON.stringify(data) !== JSON.stringify(state)) {
      return <button onClick={() => saveChangesInDb()}>Save Question</button>;
    }
  }
  function handleFieldClick(e, i) {
    if (activeField !== i) {
      setActiveField(i);
    } else {
      setActiveField(null);
    }
  }
  function addRef(el, i) {
    fieldRef.current[i] = el;
  }
  useEffect(() => {
    if (activeField !== null) {
      let { selection } = mainEditor;

      if (
        selection &&
        selection?.anchor?.offset !== selection?.focus?.offset &&
        window.getSelection().toString()
      ) {
        fieldRef.current[activeField].value = window.getSelection().toString();
      }
    }
  });
  function attachAudioIdToQs({ _id }) {
    if (state === null || state === undefined) {
      setState({ questionAudio: _id });
    } else if (typeof state === "object") {
      setState({ ...state, questionAudio: _id });
    }
  }

  // console.log(window.getSelection().toString());
  return (
    <>
      <Box type="column" background="transparent">
        <QuestionAudioRecorder uploadCallback={attachAudioIdToQs} />
      </Box>
      <QsEditor
        placeholder="Type question..."
        background={"transparent"}
        onChange={(v) => handleEditorSave("question", v)}
        content={state.question}
      />
      <button onMouseDown={(e) => addAnsChoice(e)}>Add Answer</button>
      {state.options.map((item, i) => {
        return (
          <div
            className={"flex-row"}
            key={`ansChoice_question_${i}`}
            style={{ width: "100%" }}
          >
            <PlainTextInput
              ref={(el) => addRef(el, i)}
              placeholder={
                activeField !== i
                  ? "Click to activate this field; then select text ONLY from the editor..."
                  : "Great! Now select text from the editor"
              }
              selected={activeField === i}
              onMouseDown={(e) => {
                handleFieldClick(e, i);
              }}
            />

            {/* <QsEditor
              content={item.choice}
              placeholder="Type Answer..."
              background={"transparent"}
              onChange={(v) => handleEditorSave("options", v, i, "choice")}
              width="90%"
            /> */}
          </div>
        );
      })}
      {manageSaving()}
    </>
  );
}
export function Etymology({ mainEditor, path, data, questionId }) {
  const editor = useSlate();
  const { block_id, id } = useParams();
  const [stage, setStage] = useState({
    stage: 0,
  });

  const [state, setState] = React.useState(
    data || {
      wordType: "polysyllabic",
      isPublished: false,
      isArchived: false,
      word: "polysyllabic",
      breakdown: [["ic"], ["lab"], ["syl"], ["poly"]],
      //went backwards;
      //got each one
      //knew what it meant.
      // "ic" --> belonging to.
    }
  );
  return <></>;
}
