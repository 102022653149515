const apiKey = "pk_test_t0wDGDr5oNtXlBdEu3jykxr5";
function PricingPage({ priceTableId, stripeApiKey, uid, email }) {
  //Note: this creates a new customer every time, and isn't what we're looking for.
  return (
    <stripe-pricing-table
      pricing-table-id={priceTableId}
      publishable-key={apiKey}
      client-reference-id={uid}
      customer-email={"sohaib.ahmed556@gmail.com"}
      customer={"cus_OLM8cRLSTNMIhh"}
    ></stripe-pricing-table>
  );
}

export default PricingPage;
