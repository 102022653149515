import React, { useEffect, useState, useContext, useRef } from "react";
import { UserContext } from "../../../../helpers/hooks/userContext";
import { Link } from "react-router-dom";
import { Info, Lock } from "../../../../images/icons";
import { Button } from "../../../layout/buttons/buttons";
import RoundProgress from "../../../progressBar/round";
import { getCourses, POST, DELETE, GET } from "../../../../helpers/REST/api";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DropBtn } from "../../../layout/buttons/buttons";
import { Collapse, Box, Timeline } from "../../../layout/containers/box";

import { ModuleWrap, UnitWrap, Icon, Title, ButtonWrap, BtnWrap, DateWrap } from "../components/styled";
import { RounderBtn } from "../../../layout/buttons/buttonStyles";

export const TopModule = () => {
  const { standing, studyPlan, MID, PID, units, setUnits } = useContext(UserContext);
  const [hasSomeActive, setHasActive] = useState(null);
  useEffect(() => {
    //GETs original modules, with gate properties.
    if (standing && standing.hierarchy && !units.length) {
      let gType = standing.hierarchy[0];
      let q = `mid=${MID}&pid=${PID}&plids=`;
      studyPlan.forEach((i) => (q += i._id + ","));
      q = q.slice(0, -1);
      GET(`/members/layerGates/${gType}?${q}`).then((r) => {
        console.log(r);
        setUnits(
          r.map((item) => {
            return { ...item, isGated: Boolean(item.isGated) };
          })
        );
      });
    }
  }, [MID, PID, standing, studyPlan, setUnits, units]);

  if (standing?.hierarchy[0].toLowerCase() === "unit" && units !== null) {
    function updateTimeline(index, { timeline, isGated }) {
      let copy = [...units];
      copy[index].timeline = timeline;
      copy[index].isGated = isGated;
      setUnits(copy);
    }
    return (
      units &&
      units.map((item, index) => {
        return (
          <UnitLayer
            key={item._id}
            index={index}
            gateType={standing.hierarchy[0]}
            timeline={item.timeline}
            {...item}
            updateTimeline={(t) => {
              updateTimeline(index, t);
            }}
          />
        );
      })
    );
  } else {
    return (
      Array.isArray(studyPlan) &&
      studyPlan?.map((item, index) => {
        let isLastItem = index === studyPlan.length - 1;
        let isntActive = !item.isActive;
        let noOtherActive = hasSomeActive === null;
        if (!isntActive && noOtherActive) {
          setHasActive(true);
        } else if (isLastItem && isntActive && noOtherActive) {
          // activateModule(studyPlan[0]._id);
        }

        /**
         * Prev. logic:
         * Use "studyPlan.currentIndex" array to if hierarchy index matches this
         * **/

        return <DeadlinedLayer {...item} index={index} key={item._id} isActive={item.isActive} />;
      })
    );
  }
};
/**
 PRODUCT TOP LAYER (a.k.a. Module)
 * @param  {object} props; Product Layer properties
 * @property {string} props._id
 * @property {string} props.startDate
 * @property {string} props.title
 * @property {string} props.description
 * @property {string} props.isGated
 * @property {array} props.children
 */
export const DeadlinedLayer = (props) => {
  const { updateTimeline } = props;
  const resumeLink = `/gallery/${props._id}`;

  function CallToAction() {
    const { isActive, startDate } = props;
    if (isActive && !startDate) {
      return <SetDeadline {...props} gateId={props.gateId} updateTimeline={updateTimeline} />;
    }
    if (isActive && startDate) {
      return (
        <>
          <Button style={{ height: "35px", fontWeight: 400 }}>Progress</Button>
          <Link to={resumeLink}>
            <Button style={{ height: "35px", fontWeight: 400 }}>Resume</Button>
          </Link>
        </>
      );
    }
    return null;
  }
  return (
    <ModuleWrap isActive={props.isActive}>
      <Icon className="start">
        <LockOrProgress isGated={props.isGated} progress={props.progress} />
      </Icon>
      <Title className="middle">
        <LayerInfo title={props.title} description={`${props.gateType} ${props.index + 1}`} />
      </Title>

      <ButtonWrap className="end">
        <CallToAction />
      </ButtonWrap>
    </ModuleWrap>
  );
};
export const NonDeadlinedLayer = (props) => {
  const [isExpanded, setExpand] = useState(false);

  function ExpandCTA() {
    const { isActive, _id } = props;
    // <Link to={`/gallery/${_id}`}>
    //   <Button style={{ height: "35px", fontWeight: 400, color: "white" }}>
    //     Expand
    //   </Button>
    // </Link>;

    return (
      <ButtonWrap className="end" style={{ marginLeft: "auto", marginRight: "25px" }}>
        <>
          {isActive ? (
            <DropBtn
              width={28}
              isOpen={isExpanded}
              onClick={() => {
                setExpand(!isExpanded);
              }}
            />
          ) : null}
        </>
      </ButtonWrap>
    );
  }
  return (
    <>
      <ModuleWrap isActive={props.isActive}>
        <Icon className="start">
          <LockOrProgress isGated={props.isGated} progress={props.progress} />
        </Icon>
        <Title className="middle">
          <LayerInfo title={props.title} description={props.description} />
        </Title>

        <ButtonWrap className="end">
          <ExpandCTA />
        </ButtonWrap>
      </ModuleWrap>
      {!isExpanded ? null : props.children}
    </>
  );
};
export const UnitLayer = (props) => {
  const { gateType, isActive, updateTimeline } = props;

  function CallToAction() {
    const { isActive, timeline } = props;
    let startDate, endDate, pastEndDate;
    let lastDeadline = timeline?.length && timeline[0];
    if (lastDeadline) {
      startDate = new Date(lastDeadline.startDate);
      endDate = new Date(lastDeadline.endDate);
      pastEndDate = new Date() < new Date(endDate);
    }

    if (isActive && (!lastDeadline || !pastEndDate)) {
      return <SetDeadline {...props} gateId={props.gateId} startDate={startDate} updateTimeline={updateTimeline} />;
    }
    if (isActive && startDate) {
      return (
        <>
          {/* <Button style={{ height: "35px", fontWeight: 400 }}>Progress</Button> */}
          <Link to={"/lecture/"}>
            <Button style={{ height: "35px", fontWeight: 400 }}>Resume</Button>
          </Link>
        </>
      );
    }
    return null;
  }

  return (
    <Box type="column" width="600px" padding="0" justifyContent="center" margin="2em auto">
      <UnitWrap isActive={isActive}>
        <Icon className="start">
          <LockOrProgress isGated={props.isGated} progress={props.progress} />
        </Icon>
        <Title className="middle">
          <LayerInfo title={props.title} description={`${gateType} ${props.index + 1}`} />
        </Title>
        <ButtonWrap className="end">
          <CallToAction />
        </ButtonWrap>
      </UnitWrap>

      <UnitLectures {...props} gateId={props._id} />
    </Box>
  );
};
export const UnitLectures = (props) => {
  const { setLecDocs, lecDocs, PID, MID } = useContext(UserContext);
  useEffect(() => {
    if (props.gateId && !props.isGated && props.timeline !== undefined) {
      let q = `pid=${PID}&mid=${MID}&lid=${props._id}`;
      GET(`/documents/products/lectures?${q}`).then((r) => {
        setLecDocs(r);
      });
    }
  }, [PID, props._id, props.isGated]);

  return (
    <Box type="column" width="600px" padding="0" justifyContent="center" background="none">
      <Collapse isOpen={props.isActive && !props.isGated && lecDocs?.length} background="none" title="Documents" showTitle={false} style={{ padding: "0 30px", background: "transparent" }}>
        <Box type="column" background="none" padding="0" margin="0">
          {lecDocs &&
            lecDocs?.map((doc, di) => (
              <Box type="row" background="none" padding="0" key={doc._id} alignItems="center">
                <Timeline isLast={lecDocs.length - 1 === di} width="80%" isComplete={doc.isComplete} progress={doc.progress}>
                  {doc.title}
                </Timeline>
                {doc.started && (
                  <Link to={`lecture/${props._id}/${doc._id}`}>
                    <RounderBtn
                      style={{
                        background: "transparent",
                        border: "1px solid white",
                        fontSize: "12px",
                      }}
                    >
                      {doc.isComplete ? "Review" : "Lecture"}
                    </RounderBtn>
                  </Link>
                )}
              </Box>
            ))}
        </Box>
      </Collapse>
    </Box>
  );
};
/**
 SetDeadline OR RESUME
 * @param  {object} props; Product Layer properties
 * @property {string} props._id
 * @property {string} props.startDate
 * @property {string} [props.title]
 * @property {string} [props.description]
 * @property {string} [props.isGated]
 * @property {array} [props.children]
 */
export const SetDeadline = (props) => {
  const { MID, PID } = useContext(UserContext);
  const { startDate, _id, gateId, updateTimeline } = props;

  const pickerRef = useRef();
  const [loading, setLoading] = useState(null);
  const [open, setOpen] = useState(false);
  const [dateRange, setDateRange] = useState([new Date(), null]);
  const [start, end] = dateRange;
  const initiateModule = async () => {
    await POST(`/members/id/${MID}/modules/${_id}/start`, {
      gateId: gateId,
      productId: PID,
      startDate: start,
      endDate: end,
    }).then((result) => {
      updateTimeline(result);
      setLoading(false);
    });
  };
  // DATE COMPONENTS
  const DateWrapper = ({ className, children }) => {
    return (
      <DateWrap>
        <h1 style={{ color: "black", margin: "0 auto" }}>SetDeadline</h1>
        <h3>Expected Time To Complete 4 days</h3>
        <h3>Recommended SetDeadline: 4 days</h3>
        <h3>Current Days: </h3>
        {children}
        <BtnWrap>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button
            onClick={() => {
              initiateModule();
              setOpen(false);
            }}
          >
            Save
          </Button>
        </BtnWrap>
      </DateWrap>
    );
  };
  const DateDropDown = () => {
    if (!open) {
      return null;
    }

    return (
      <div
        id="calendar_bigy"
        style={{
          position: "absolute",
          top: "-350px",
          right: "0",
          zIndex: "2",
          width: "300px",
          background: "white",
          height: "auto",
        }}
      >
        <DatePicker
          ref={(ref) => {
            pickerRef.current = ref;
          }}
          selectsRange={true}
          startDate={start}
          minDate={new Date()}
          openToDate={new Date()}
          endDate={end}
          onChange={(update) => {
            setDateRange(update);
          }}
          inline
          calendarContainer={DateWrapper}
        />
      </div>
    );
  };

  // BUTTON COMPONENTS
  const SetSetDeadlineBtn = () => (
    <Button
      style={{ height: "35px", fontWeight: 400 }}
      onClick={(e) => {
        e.preventDefault();
        setOpen(!open);
        pickerRef?.current?.setOpen(open);
      }}
    >
      Set Deadline
    </Button>
  );
  const ResumeBtn = () => (
    <Button
      style={{ height: "35px", fontWeight: 400 }}
      onClick={(e) => {
        e.preventDefault();
      }}
      to={`/gallery`}
    >
      Resume
    </Button>
  );

  if (startDate || loading === false) {
    return <ResumeBtn />;
  }
  return (
    <div>
      <SetSetDeadlineBtn />
      <DateDropDown />
    </div>
  );
};
function LockOrProgress({ isGated, progress }) {
  return !isGated ? <RoundProgress sqSize={"50"} strWidth={"1"} percentage={progress || 0} /> : <Lock color="white" size="20px" />;
}
function LayerInfo({ title, description }) {
  function capitalizeFirstLetter(string) {
    if (typeof string === "string" && string.length) {
      let words = string.split(" ").map((word, wordIndex) => {
        let copy = word.substring();

        copy = copy.length && copy.toLowerCase();

        copy = copy.length && copy[0].toUpperCase() + copy.substring(1);
        return copy;
      });
      let result = words.join(" ");
      return result;
    }
  }

  return (
    <>
      <div>
        {capitalizeFirstLetter(title)} <Info round={true} color={"white"} style={{ margin: "auto 0" }} />
      </div>
      <div className="desc">{capitalizeFirstLetter(description)}</div>
    </>
  );
}
