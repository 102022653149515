import React, {useState, useEffect} from "react";
import { Box } from "../../layout/containers/box";
import {
  getBlocks,
  
} from "../../../helpers/REST/api";
import { Link } from "react-router-dom";
export default function Blocks() {
  const [blockList, setBlockList] = useState([]);
  //onload 
  //refresh button
  useEffect(() => {
    async function fetchBlocks () {
      let result = await getBlocks();
      console.log(result);
      setBlockList(result);
    }
    fetchBlocks();
  }, [])
  return <div style={{ marginBottom: "250px" }}>
    <h1>All Blocks</h1>
    {blockList && blockList.length && blockList.map((b, i) => <Box key={`blockList_item_${i}`}type="card_border row" background="rgb(30,30,30)">
       <div><Link to={`/edit_block/${b._id}`}><h3>Block {i}</h3></Link>
       <small>Created on: {b.createdAt}</small></div>
      </Box>)}
  </div>;
}
