// import styled from "styled-components";
// import useBoop from "../../../helpers/hooks/useBoop";
// import { animated as a } from "react-spring";
import { useState, useRef, useEffect, useCallback } from "react";
import { BtnWrapper, CloseBtnWrap, DropBtnWrap, TabBtnWrapper, ArrowCircularWrap, CiruclarParent, CopyToClipboardWrap } from "./buttonStyles";
import { Plus, Edit2 } from "../../../images/icons";
import { DeleteOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import styled, { keyframes } from "styled-components";
import { Play, Pause, Record, Stop, Maximize, OptionsVertical, Check } from "../../../images/icons";
import { AudioFilled, SaveFilled } from "@ant-design/icons";
import { Clipboard } from "../../../images/icons";
import { toast } from "react-toastify";
import { Dropdown2 } from "../containers/box";
import { User, NotifAlert } from "../../../images/icons";

// import { useSpring, animated as a } from "react-spring";

// const Boop = ({ children, ...boopConfig }) => {
//   const [style, trigger] = useBoop(boopConfig);
//   return (
//     <a.span onMouseEnter={trigger} style={style}>
//       {children}
//     </a.span>
//   );
// };
/**
 * @param  {string} props.use Options: small, large
 * @param  {string} props.theme Options: "primary", "secondary", "grey-white", "black-white", "white-black"
 * @param  {string} props.shape Unused
 * @param  {} props.status Unused
 * @param  {} props.children Unused
 * @param  {} ...rest}
 */
export function Button({ use, tooltip, theme, shape, status, children, fwdRef, ...rest }) {
  const genStyle = () => {
    switch (theme) {
      case "primary":
        return undefined;
      case "secondary":
        return {
          "--color": "black",
          "--background": "var(--secondary-color)",
        };
      case "grey-white":
        return {
          "--color": "var(--snow-2)",
          "--background": "var(--davy-grey)",
        };
      case "black-white":
        return {
          "--color": "var(--snow-1)",
          "--background": "var(--rich-black)",
        };
      case "white-black":
        return {
          "--background": "var(--snow-1)",
          "--color": "var(--rich-black)",
        };
      case "transparent":
        return {
          "--background": "transparent",
          "--color": "snow",
          "--border": "1px solid grey",
        };
      default:
        return undefined;
    }
  };
  const small = {
    fontSize: "11px",
    fontWeight: "400",
    lineHeight: "1",
    padding: "0 10px",
    margin: "auto 0.25rem",
    height: "20px",
    borderRadius: "5px",
    minWidth: "20px",
    whiteSpace: "wrap",
  };
  let handleStyle = () => {
    let result = { ...genStyle() };
    if (use === "small") {
      result = { ...genStyle(), ...small };
    }
    return result;
  };
  if (tooltip) {
    return (
      <Tooltip title={tooltip}>
        <BtnWrapper style={handleStyle()} {...rest} ref={fwdRef}>
          {children}
        </BtnWrapper>
      </Tooltip>
    );
  }
  return (
    <BtnWrapper style={handleStyle()} {...rest} ref={fwdRef}>
      {children}
    </BtnWrapper>
  );
}
export const CopyToClipboard = ({ children, onClick, onMouseDown, ...rest }) => {
  let textRef = useRef(null);
  const handleEvent = (e) => {
    let text = textRef.current.innerText;
    console.log(text);
    navigator.clipboard.writeText(text).then(() => {
      toast.success("Copied!");
    });
    if (onMouseDown !== undefined) onMouseDown(e);
    if (onClick !== undefined) onClick(e);
  };
  return (
    <Tooltip title="Click to copy">
      <CopyToClipboardWrap onMouseDown={(e) => handleEvent(e)} {...rest}>
        <span ref={textRef}>
          {children} <Clipboard width="12px" />
        </span>
      </CopyToClipboardWrap>
    </Tooltip>
  );
};
/**
 * @param  {isOpen} {color
 * @param  {Boolean} props.isOpen; Default: true
 * @param  {string} width e.g. "50". Used for type=1 & the wrap itself.
 * @param  {string} size e.g. "50"; used for default; for SVG width/height;
 * @param  {} bg 
 * @param  {Number} type 1 = plus button. None = Cross.
  e.g. 
  <CloseBtn
    type={1}
    width={"25"}
    isOpen={isSelected}
    onMouseDown={() => {
      toggleSelect(gateIndex);
    }}
  />
 */
export const CloseBtn = ({ color, isOpen = true, width, size, bg, type, ...rest }) => {
  if (type === 1) {
    return <AddBtn {...rest} width={width} bg={bg} style={{ transition: "transform 0.1s linear", transform: isOpen ? "rotate(45deg)" : "rotate(0deg)" }} />;
  }
  return (
    <CloseBtnWrap {...rest} width={width} bg={bg}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size || "50"}
        height={size || "50"}
        viewBox={size ? `0 0 ${size} ${size}` : "0 0 50 50"}
        overflow="visible"
        stroke={color || "black"}
        strokeWidth="10"
        strokeLinecap="round"
      >
        <line x2="50" y2="50" />
        <line x1="50" y2="50" />
      </svg>
    </CloseBtnWrap>
  );
};
export const AddBtn = ({ tooltip, color, width, size, bg, ...rest }) => {
  if (tooltip !== undefined) {
    return (
      <Tooltip title={tooltip}>
        <CloseBtnWrap width={width} bg={bg} {...rest}>
          <Plus size={size} color={color} />
        </CloseBtnWrap>
      </Tooltip>
    );
  }
  return (
    <CloseBtnWrap width={width} bg={bg} {...rest}>
      <Plus size={size} color={color} />
    </CloseBtnWrap>
  );
};
export const EditBtn = ({ tooltip, color, width, size, bg, ...rest }) => {
  if (tooltip) {
    return (
      <Tooltip title={tooltip}>
        <CloseBtnWrap width={width} bg={bg} {...rest}>
          <Edit2 size={size} color={color} />
        </CloseBtnWrap>
      </Tooltip>
    );
  }
  return (
    <CloseBtnWrap width={width} bg={bg} {...rest}>
      <Edit2 size={size} color={color} />
    </CloseBtnWrap>
  );
};
export const OptionsBtn = ({ tooltip, color, width, size, bg, ...rest }) => {
  const [open, setOpen] = useState(false);
  const btnRef = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (open && btnRef.current && !btnRef.current.contains(event.target)) {
        setOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open]);
  if (tooltip) {
    return (
      <>
        <Tooltip title={tooltip}>
          <CloseBtnWrap width={width} bg={bg} {...rest} onMouseDown={() => setOpen(true)}>
            <OptionsVertical round={true} size={size} bg={bg} color={color} />
          </CloseBtnWrap>
        </Tooltip>
        <Dropdown2 defaultOpen={open} dropOnly>
          {rest.children}
        </Dropdown2>
      </>
    );
  }
  return (
    <>
      <CloseBtnWrap width={width} bg={bg} {...rest} onMouseDown={() => setOpen(true)}>
        <OptionsVertical round={true} size={size} bg={bg} color={color} />
      </CloseBtnWrap>

      <Dropdown2 defaultOpen={open} dropOnly>
        {rest.children}
      </Dropdown2>
    </>
  );
};
export const ProfileBtn = ({ color, width, size, bg, ...rest }) => {
  const [open, setOpen] = useState(false);
  const btnRef = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (open && btnRef.current && !btnRef.current.contains(event.target)) {
        setOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open]);

  return (
    <>
      <User fwdRef={btnRef} onMouseDown={() => setOpen(!open)} />

      <Dropdown2 isOpen={open} setIsOpen={setOpen} parentRef={btnRef} wide={true} background={"var(--tertiary-color-darker)"}>
        {rest.children}
      </Dropdown2>
    </>
  );
};
export const NotifAlertBtn = ({ color, width, size, bg, ...rest }) => {
  const [open, setOpen] = useState(false);
  const btnRef = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (open && btnRef.current && !btnRef.current.contains(event.target)) {
        setOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open]);

  return (
    <>
      <NotifAlert fwdRef={btnRef} active={!open} onMouseDown={() => setOpen(!open)} />
      <Dropdown2 isOpen={open} setIsOpen={setOpen} parentRef={btnRef} wide={true} background={"var(--tertiary-color-darker)"}>
        {rest.children}
      </Dropdown2>
    </>
  );
};

export const ExpandBtn = ({ tooltip, color, width, size, bg, ...rest }) => {
  if (tooltip) {
    return (
      <Tooltip title={tooltip}>
        <CloseBtnWrap width={width} bg={bg} {...rest}>
          <Maximize size={size} bg={bg} color={color} />
        </CloseBtnWrap>
      </Tooltip>
    );
  }
  return (
    <CloseBtnWrap width={width} bg={bg} {...rest}>
      <Maximize size={size} bg={bg} color={color} />
    </CloseBtnWrap>
  );
};
export const MicBtn = ({ tooltip, color, width, size, bg, ...rest }) => {
  if (tooltip) {
    return (
      <Tooltip title={tooltip}>
        <CloseBtnWrap width={width} bg={bg} {...rest}>
          <AudioFilled size={size} bg={bg} color={color} />
        </CloseBtnWrap>
      </Tooltip>
    );
  }
  return (
    <CloseBtnWrap width={width} bg={bg} {...rest}>
      <AudioFilled size={size} bg={bg} color={color} />
    </CloseBtnWrap>
  );
};
export const PlayBtn = ({ tooltip, color, width, size, bg, ...rest }) => {
  if (tooltip) {
    return (
      <Tooltip title={tooltip}>
        <CloseBtnWrap width={width} bg={bg} {...rest}>
          <Play size={size} bg={bg} color={color} />
        </CloseBtnWrap>
      </Tooltip>
    );
  }
  return (
    <CloseBtnWrap width={width} bg={bg} {...rest}>
      <Play size={size} bg={bg} color={color} />
    </CloseBtnWrap>
  );
};

/**
 * Dropdown Button
 * @param  {callback} props.onClick Click callback
 * @param  {string} props.color SVG color
 * @param  {string} props.bg  background color
 * @param  {string} props.width svg 
 * @param  {number} props.size SVG size in px.
 * @param  {string} props.pad div padding

 */

export const DropBtn = (props) => {
  const { onClick, color, bg, width, size, pad, ...rest } = props;
  function handleClick(e) {
    if (typeof onClick === "function") {
      onClick(e);
    }
  }

  return (
    <DropBtnWrap onClick={handleClick} width={width} bg={bg} pad={pad} {...rest}>
      <Chevron
        xmlns="http://www.w3.org/2000/svg"
        width={size ? size + "px" : "20px"}
        height={size ? size + "px" : "20px"}
        viewBox={size ? `0 0 ${size} ${size}` : "0 0 18 18"}
        overflow="visible"
        stroke={color || "black"}
        strokeWidth="3"
        strokeLinecap="round"
        direction="l"
      >
        <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
      </Chevron>
    </DropBtnWrap>
  );
};

export const Tab = ({ children, ...rest }) => {
  return <TabBtnWrapper {...rest}>{children}</TabBtnWrapper>;
};
/**
 * @param  {Number} props.size;
 * @param  {String} props.color;
 * @param  {string} props.direction; "r", "l", "d", "up"; Default = right;
 * @param  {String} props.postHover; final Direction
 */
export const ArrowBtn = (props) => {
  const { size, color, direction, postHover, ...rest } = props;

  return (
    <ArrowCircularWrap direction={direction} postHover={postHover} width={size} {...rest}>
      <Chevron />
    </ArrowCircularWrap>
  );
};
export const CheckButton = (props) => {
  const { onClick, color, bg, width, size, pad, ...rest } = props;
  function handleClick(e) {
    if (typeof onClick === "function") {
      onClick(e);
    }
  }
  return (
    <DropBtnWrap onClick={handleClick} width={width || "24"} bg={bg} pad={pad || "6"} {...rest}>
      <Check />
    </DropBtnWrap>
  );
};

export const Chevron = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ? size + "px" : "20px"}
      height={size ? size + "px" : "20px"}
      viewBox={size ? `0 0 ${size} ${size}` : "0 0 18 18"}
      overflow="visible"
      stroke={color || "black"}
      strokeWidth="3"
      strokeLinecap="round"
      className="chevron"
    >
      <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
    </svg>
  );
};
export const DeleteBtn = ({
  tooltip,

  color,
  width,
  size,
  bg,
  ...rest
}) => {
  if (!tooltip) {
    return (
      <CloseBtnWrap width={width} bg={bg} {...rest}>
        <DeleteOutlined size={size} bg={bg} />
      </CloseBtnWrap>
    );
  }
  return (
    <Tooltip title={tooltip}>
      <CloseBtnWrap width={width} bg={bg} {...rest}>
        <DeleteOutlined size={size} bg={bg} />
      </CloseBtnWrap>
    </Tooltip>
  );
};

export const ShinyDiv = styled.div`
  position: relative;
  color: white;
  height: 50px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:before {
    content: "";
    position: absolute;
    z-index: 1;
    top: 2px;
    left: 6px;
    right: 6px;
    height: 20%;
    background: linear-gradient(rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0));
    border-radius: 20px 20px 100px 100px / 14px 14px 30px 30px;
  }
  &:active::before {
    // transform: scale(-1);
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 2px;
    right: 0px;
    left: 0px;
    height: 30%;
    background: linear-gradient(rgba(0, 0, 0, 0.17), rgba(0, 0, 0, 0));
    border-radius: 4px 4px 0px 0px;
  }
  &:after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0px;
    left: 0px;
    right: 0px;
    height: 30%;
    //background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.17));
    border-radius: 0px 0px 4px 4px;
  }
  &:active::after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 2px;
    left: 0px;
    right: 0px;
    height: 10%;
    background: linear-gradient(rgba(255, 255, 255, 0.17), rgba(255, 255, 255, 0));
    border-radius: 100px 100px 20px 20px / 30px 30px 14px 14px;
    pointer-events: none;
  }
`;

export const Shiny = styled.button`
  position: relative;
  color: white;
  text-shadow: rgb(0 0 0 / 15%) 1px 1px 1px;
  font-size: 18px;
  height: 50px;
  font-weight: var(--font-weight-medium);
  border-radius: 4px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  background: black;
  backface-visibility: hidden;
  border: none;
  width: 175px;
  --magic-rainbow-color-newsletter-signup-button-0: #bb0d4b; //#f50057; //hsl(230deg, 100%, 45%);
  --magic-rainbow-color-newsletter-signup-button-1: #ff005a; //#ce0049; //hsl(240deg, 100%, 45%);
  --magic-rainbow-color-newsletter-signup-button-2: #ba0042; //hsl(260deg, 100%, 55%);
  transition: --magic-rainbow-color-newsletter-signup-button-0 1625ms linear, --magic-rainbow-color-newsletter-signup-button-1 1625ms linear, --magic-rainbow-color-newsletter-signup-button-2 1625ms linear;
  background: radial-gradient( circle at top left, var(--magic-rainbow-color-newsletter-signup-button-2), var(--magic-rainbow-color-newsletter-signup-button-1), var(--magic-rainbow-color-newsletter-signup-button-0) );
}
`;
export const ShinyBtn = () => {
  return (
    <ShinyDiv>
      <Shiny>Subscribe</Shiny>
    </ShinyDiv>
  );
};
export const PulseStronger = keyframes`
  0% { box-shadow:0 0 16px var(--spring-green), inset 0 0 16px var(--spring-green); }
  50% { box-shadow:0 0 30px var(--spring-green), inset 0 0 25px var(--spring-green); }
  100% { box-shadow:0 0 16px var(--spring-green), inset 0 0 16px var(--spring-green); }
`;
export const Pulse = keyframes`
  0% { box-shadow:0 0 16px #ab003c, inset 0 0 16px #ab003c; }
  50% { box-shadow:0 0 30px #ab003c, inset 0 0 25px #ab003c; }
  100% { box-shadow:0 0 16px #ab003c, inset 0 0 16px #ab003c; }
`;
const Pulse2 = keyframes`
  0% { box-shadow: 0 0 0 0 #ab003c;}
  70% {box-shadow: 0 0 5px 5px #ab003c;}
  100% { box-shadow: 0 0 0 0 #ab003c; }
`;
const RotateRight = keyframes`
   0%{ transform: translateX(5px) scale(1); rotateY(5);}
   10%{ transform: translateX(7px) scale(0.9); rotateY(10);}
   20%{ transform: translateX(9px) scale(0.8); rotateY(15);}
   30%{ transform: translateX(11px) scale(0.7); rotateY(20);}
   40%{ transform: translateX(13px) scale(0.6); rotateY(25);}
   50%{ transform: translateX(15px) scale(0.5); rotateY(30);}
   60% {transform: translateX(17px) scale(0.4); rotateY(35);}
   70% {transform: translateX(19px) scale(0.3); rotateY(40);}
   80% {transform: translateX(20px) scale(0.2); rotateY(45)};
   90% {transform: translateX(21px) scale(0.1); rotateY(50);}
   100%{ transform: translateX(22px) scale(0); rotateY(55);}
`;
const RotateLeftToRight = keyframes`
  100%{ transform: translateX(-0px) scale(1);}
   90%{ transform: translateX(-7px) scale(0.9);}
   80%{ transform: translateX(-9px) scale(0.8);}
   70%{ transform: translateX(-11px) scale(0.7);}
   60%{ transform: translateX(-13px) scale(0.6);}
   50%{ transform: translateX(-15px) scale(0.5);}
   40% {transform: translateX(-17px) scale(0.4);}
   30% {transform: translateX(-19px) scale(0.3);}
   20% {transform: translateX(-20px) scale(0.2); }
   10% {transform: translateX(-21px) scale(0.1);}
   0%{ transform: translateX(-22px) scale(0);}
`;

const Spooky = keyframes`
  0% {
    transform: none;
  }
  20% {
    transform: translateX(-7px) translateY(3px) skewX(15deg) skewY(-10deg) scale(0.95);
  }
  25%, 44% {
    transform: none;
  }
  50%, 60% {
    transform: translateX(-7px) translateY(-4px) skewX(5deg) skewY(2deg) scaleX(0.95);
  }
  66%, 100% {
    transform: none;
  }

`;
const Bubble = keyframes`
  0% {
    transform: scale(1);
  }
  20% {
    transform: scaleY(0.95) scaleX(1.05);
  }
  48% {
    transform: scaleY(1.1) scaleX(0.9);
  }
  68% {
    transform: scaleY(0.98) scaleX(1.02);
  }
  80% {
    transform: scaleY(1.02) scaleX(0.98);
  }
  97%, 100% {
    transform: scale(1);
  }
`;

export const ShinySphere = styled(ShinyDiv)`
  animation: ${Pulse} 2s linear 1s infinite ${({ isRecording }) => (isRecording ? "running" : "pause")};
  left: ${({ isRecording }) => (isRecording ? "-10px" : "0px")};
  cursor: pointer;
  margin: 0 10px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 14px;
  z-index: 3;
  //animation: ${Bubble} 1s linear 2s infinite;

  transition: ${({ color }) => color.split(", ")[0]} 1625ms linear, ${({ color }) => color.split(", ")[1]} 1625ms linear, ${({ color }) => color.split(", ")[2]} 1625ms linear;
  background: radial-gradient(circle at top left, ${({ color }) => color});
  &:hover {
    box-shadow: none;
    //animation: ${Bubble} 2s linear 1s infinite;
    & svg {
      //animation-fill-mode: forwards;
      //animation: ${RotateLeftToRight} 1s ease normal forwards;
    }
  }

  &:active svg {
    //animation: ${RotateRight} 1.5s linear normal forwards;
  }

  &:before {
    top: 1px;
    left: 10px;
    right: 10px;
    border-radius: 50%;
  }
  &:active::before {
    left: 10px;
    right: 10px;
    border-radius: 50%;
  }
  &:active::after {
    height: 10%;
    bottom: 2px;
    left: 13px;
    right: 13px;
  }
  & svg {
    fill: white;
    stroke: white;
    cursor: pointer;
  }
`;
export const ShinySphereInnerBtn = styled(Shiny)`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 12px;
  cursor: pointer;
  position: relative;
  transition: ${({ color }) => color.split(", ")[0]} 1625ms linear, ${({ color }) => color.split(", ")[1]} 1625ms linear, ${({ color }) => color.split(", ")[2]} 1625ms linear;
  background: radial-gradient(circle at top left, ${({ color }) => color});
`;

export const RoundBtnWrap = styled.div`
  svg {
    width: 18px;
    height: 18px;
  }
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  transition: ${({ color }) => color.split(", ")[0]} 1625ms linear, ${({ color }) => color.split(", ")[1]} 1625ms linear, ${({ color }) => color.split(", ")[2]} 1625ms linear;
  background: radial-gradient(circle at top left, ${({ color }) => color});
  background: black;
  width: ${({ expand }) => (expand ? "250px" : "75px")};
  padding: 1px;
  border-radius: 50px;
  z-index: 10;
  box-sizing: border-box;
  margin: 2px;
  transition: all 0.2s linear;
  & .round,
  .wider {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    box-sizing: border-box;
    width: 50%;
    left: 5px;
    height: 100%;
    border-radius: 50px;
    padding: 10px 10px;
    margin: auto 0;
    z-index: 0;
    opacity: 0.1;
    transition: opacity 0.2s linear;
    background-color: #2c2c2c;
    cursor: pointer;
    background-image: radial-gradient(circle at center center, #d445f7, #2c2c2c), repeating-radial-gradient(circle at left center, #d445f7, #d445f7, 10px, transparent 20px, transparent 10px);
    background-blend-mode: multiply;
    background-position: left;

    :active {
      svg {
        transform: scale(0.9);
      }
    }
    :hover {
      opacity: 1;
      svg {
        fill: white;
        stroke: white;
      }
    }
  }
  .wider {
    width: 100%;
    background-color: #34083b;
    opacity: 0.1;
    background-image: radial-gradient(circle at center center, #f50057, #34083b), repeating-radial-gradient(circle at left, #f50057, #f50057, 10px, transparent 20px, transparent 10px);
    background-blend-mode: multiply;
    z-index: -5;
    padding-right: 15%;
    :hover {
      opacity: 1;
    }
  }
`;
const Spin = keyframes`
    100% { 
      transform:rotate(360deg); 
    } 
`;
export const Spoke = styled.div`
  position: absolute;
  width: 5px;
  height: 100%;
  background: ${({ color }) => color.split(", ")[3]};
  animation: ${Spin} ${({ state }) => (state > 5 ? "2s" : "25s")} ${({ state }) => (state === 7 ? "reverse" : "linear")} infinite ${({ state }) => (state === 0 ? "pause" : "running")};

  &:before,
  &:after {
    content: "";
    position: absolute;
    width: 5px;
    height: 100%;
    background: ${({ color }) => color.split(", ")[3]};
  }
  &:before {
    transform: rotate(60deg);
  }
  &:after {
    transform: rotate(-60deg);
  }
`;

export const ShinyRoundMediaBtn = ({ useRecorder, ...props }) => {
  let { audio, src, canRecord } = props;
  const startRecording = props.startRecording;
  const cancelRecording = props.cancelRecording;
  const saveRecording = props.saveRecording;
  const resumeRecording = props.resumeRecording;
  const pauseRecording = props.pauseRecording;
  const status = props.recordingStatus;
  const recorderState = props.recorderState;
  const uploadAudio = props.uploadAudio;

  const [state, setState] = useState(0); // 0 (nothing); 1: record; 2: play; 3: resume; 4: pause; 5: pending response 6: forward, 7: rewind
  const [isRecording, setIsRecording] = useState(false);
  const [isAudioPlaying, setAudioPlaying] = useState(false);
  const audioRef = useRef(null);

  const recordColor = "salmon, maroon, darkred, darkred";
  const playColor = "lightgreen, darkgreen, green, green";
  const normalColor = "#bb0d4b, #ff005a, #ba0042, #da6890"; //4th color for spoke
  useEffect(() => {
    if (props.recordingStatus === "recording" && !isRecording) {
      setIsRecording(true);
      setState(1);
    } else if (props.recordingStatus !== "recording" && isRecording) {
      setIsRecording(false);
    }
  }, [props, isRecording]);

  function rewind() {
    setState(7);
    let time = audioRef?.current?.currentTime;
    if (time === undefined && time - 5 >= 0) {
      audioRef.current.currentTime = time - 5;
    } else if (time === undefined && time - 5 >= -5) {
      audioRef.current.currentTime = 0;
    }
  }
  function forward() {
    setState(6);
    let time = audioRef?.current?.currentTime;
    let duration = audioRef?.current?.duration;
    if (time && time + 5 <= duration) {
      audioRef.current.currentTime = time + 5;
    } else if (time && time + 5 > duration) {
      audioRef.current.currentTime = duration;
    }
  }

  function handleKeyDown(e) {
    if (e.key === "ArrowLeft" && !isRecording) {
      e.preventDefault();
      if (state !== 7) setState(7);
      rewind();
    }
    if (e.key === "ArrowRight" && !isRecording) {
      e.preventDefault();
      if (state !== 6) setState(6);
      forward();
    }
  }
  function handleKeyUp(e) {
    if (e.key === "ArrowLeft" && !isRecording) {
      e.preventDefault();
      if (state === 7) setState(1);
    }
    if (e.key === "ArrowRight" && !isRecording) {
      e.preventDefault();
      if (state === 6) setState(1);
    }
  }
  function handleSave() {
    saveRecording();
    setIsRecording(false);
    setState(2);
  }
  async function handleUpload() {
    if (Array.isArray(recorderState?.audio_blob) && recorderState?.audio_blob.length > 0) {
      uploadAudio(recorderState.audio_blob[0]).then((data) => {
        console.log("The mediaId is :", data);
        if (typeof props.handleMediaId === "function") {
          props.handleMediaId(data);
        }
        //TODO: reset the audio.
      });
    }
  }
  function handleInteraction() {
    if (!isRecording && status !== "paused") {
      startRecording();
    } else if (!isRecording && status === "paused") {
      resumeRecording();
    } else if (isRecording) {
      pauseRecording();
    }
  }

  const MainIcon = useCallback(() => {
    if (!isRecording) return <Record />;
    if (isRecording) return <Record animate={true} />;
    if (state === 0) return <Record />;
    if (state === 1) return <Pause />;
    if (state === 2) return null;
    if (state === 3) return <AudioFilled />;
    if (state === 4) return <Record />;
    if (state === 6) return null; //forward;
    if (state === 7) return null; //rewind;
    return null;
  }, [isRecording, state]);
  //**AUDIO COMPONENT MANAGEMENT **//
  const handleAudioPlay = () => {
    setAudioPlaying(true);
  };
  const handleAudioPause = () => {
    setAudioPlaying(false);
  };
  const manageAudioRef = (ref) => {
    audioRef.current = ref;
    if (audioRef?.current) {
      audioRef.current.onplay = handleAudioPlay;
      audioRef.current.onpause = handleAudioPause;
    }
    return ref;
  };
  const AudioPlayback = (ref) => {
    const play = () => audioRef?.current.play();
    const pause = () => audioRef?.current.pause();
    const stop = () => saveRecording();
    const recNotStopped = recorderState?.audio === null;
    if (recNotStopped && status === "paused")
      return (
        <div className="round" onClick={() => handleSave()}>
          <Stop />
        </div>
      );
    if (!recNotStopped && !audioRef?.current?.paused)
      return (
        <div className="round" onClick={() => pause()}>
          <Pause />
        </div>
      );
    if (!recNotStopped && audioRef.current.paused)
      return (
        <div className="round" onClick={() => play()}>
          <Play />
        </div>
      );
    return null;
  };
  const Audio = useCallback(() => {
    return <audio ref={(r) => manageAudioRef(r)} style={{ opacity: "0", display: "hidden" }} src={(recorderState?.audio !== null && recorderState?.audio[0]) || undefined} />;
  }, [recorderState, audioRef]);
  return (
    <RoundBtnWrap
      expand={!isRecording}
      color={state === 1 ? recordColor : state === 2 ? playColor : normalColor}
      onClick={() => {}}
      onKeyDown={(e) => handleKeyDown(e)}
      onKeyUp={(e) => handleKeyUp(e)}
      tabIndex="0"
    >
      <Audio />

      <ShinySphere
        color={state === 1 ? recordColor : state === 2 ? playColor : normalColor}
        onClick={(e) => {
          e.stopPropagation();
          handleInteraction();
        }}
        isRecording={isRecording}
      >
        <Spoke state={state} color={state === 1 ? recordColor : state === 2 ? playColor : normalColor} />
        <ShinySphereInnerBtn color={state === 1 ? recordColor : state === 2 ? playColor : normalColor}>
          <MainIcon />
        </ShinySphereInnerBtn>
      </ShinySphere>
      {!isRecording && state !== "undefined" && (
        <>
          <AudioPlayback audioRef={audioRef} />
          <div
            className="wider"
            onMouseDown={() => {
              handleUpload();
            }}
          >
            <SaveFilled fill="white" />
          </div>
        </>
      )}
    </RoundBtnWrap>
  );
};
