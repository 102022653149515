import axios from "axios";
import { gql } from "@apollo/client";

let gqlAddress = "http://localhost:8000/graphql";
let headers = {
  headers: {
    "Content-Type": "application/json",
  },
};

export async function fetchLogin(username, password) {
  let post = await axios
    .post(
      gqlAddress,
      {
        query: `query {
          Users {
          email

        }
        }`,
      },
      headers
    )
    .catch((e) => {
      console.log(e.message);
    });

  console.log(post.data);
  return post;
}

export const LOGIN_QUERY = gql`
  query {
    Users {
      email
    }
  }
`;
export const COURSES_QUERY = gql`
  query {
    Courses {
      _id
      title
      description
      isPublished
      isOffered
      hierarchy
      defaultPlan {
        title
        description
        childType
        children {
          moduleType
          moduleId
          childType
          children {
            title
            description
            childType
          }
        }
      }
    }
  }
`;
